import ApiError from './utils/errors/ApiError'

class UnauthorizedError extends Error {
  constructor(message: string) {
    super(message || 'unknown_error')
    this.name = 'UnauthorizedError'
  }
}

/**
 * Vérifie si la session de l'utilisateur est valide.
 * En cas de 403, lance une UnauthorizedError.
 * @param response - Réponse du serveur
 */
function assertSessionValid(response: Response) {
  if (response.status === 403) {
    throw new UnauthorizedError('Unauthorized')
    // Ne pas appeler `store.dispatch(logout())` car la gestion de l'erreur Unauthorized est gérée dans store.ts
  }
}

/**
 * Effectue une requête fetch personnalisée avec gestion des erreurs.
 * @param url - URL de la requête
 * @param options - Options de la requête fetch
 * @returns Response de la requête fetch
 */
export default async function customFetch(
  url: RequestInfo,
  options?: RequestInit,
): Promise<Response> {
  const response = await fetch(url, options)

  if (!response.ok) {
    assertSessionValid(response)

    let responseBody
    try {
      responseBody = await response.json()
    } catch (error) {
      console.error('Error parsing response JSON:', error)
    }

    const reason = responseBody?.reason || responseBody?.message
    const data = responseBody?.details

    throw new ApiError(response.status.toString(), data, reason)
  }

  return response
}

/* 
   *** Another version that overrides builtin fetch function ***
        Put code in comment bellow in index.tsx or App.tsx:

//////////////////////////////////////////////////
    import { globalFetch } from './customFetch'

    declare global {
    interface Window {
        oldFetch : typeof fetch
        fetch: typeof globalFetch
    }
    }
    window.oldFetch = window.fetch
    window.fetch = globalFetch    declare global {
    interface Window {
        oldFetch : typeof fetch
        fetch: typeof globalFetch
    }
    }
    window.oldFetch = window.fetch
    window.fetch = globalFetch
//////////////////////////////////////////////////

import { logout } from './features/auth/redux/authSlice'
import { store } from './store'
import i18n from './i18n'

export function assertSessionValid(response: Response) {
    if(response.statusText === "Unauthorized"){
        dispatch(ToastOpen(
          {            
            message: i18n.t('error.unauthorized'),
            duration: 30,
            type: "warning",
          }
        )
        store.dispatch(logout())
    }
}

export async function customFetch(url: RequestInfo, options?: RequestInit): Promise<Response>{
    const response = await window.oldFetch(url, options)
    assertSessionValid(response)
    return response
}

export const globalFetch: typeof fetch = async (url, options) => customFetch(url as RequestInfo, options) 

*/
