import React from 'react'
import { Button, Col, Row, Select, Tabs } from 'antd'
import { useTranslation } from 'react-i18next'
import { BsExclamationCircleFill, BsFilePerson } from 'react-icons/bs'
import { TbVideo, TbMicrophoneOff } from 'react-icons/tb'
import { IoDocumentTextOutline } from 'react-icons/io5'
import { FiArrowLeftCircle } from 'react-icons/fi'
import classnames from 'classnames'
import dayjs from 'dayjs'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import { callLogoFilename } from '../../../assets/personnalization'
import { LanguageSwitchPreview } from './LanguageSwitchPreview'
import { HiOutlineSpeakerWave } from 'react-icons/hi2'
import { FiRefreshCcw } from 'react-icons/fi'

interface Props {
  logo: string | undefined
}

function AntechamberPreview({ logo }: Props) {
  const { t, i18n } = useTranslation('antechamber')
  dayjs.extend(LocalizedFormat)
  const { TabPane } = Tabs

  return (
    <div className="Antechamber">
      <div className="Antechamber-header-container">
        <div className="Antechamber-header">
          <img
            className="Antechamber-header-medscope"
            src={logo ? logo : `/logo/${callLogoFilename}`}
            alt="Auxamed logo large"
          />

          <div className="d-flex d-flex-middle">
            <a className="Antechamber-back">
              <FiArrowLeftCircle size="2rem" strokeWidth={1} />
              <span className="ml-05rem hideOnMobile">
                {t('Back to the meeting list')}
              </span>
            </a>
            <div className="ml-1rem">
              <LanguageSwitchPreview />
            </div>
          </div>
        </div>
        <div className="Antechamber-main-title-container">
          <h1 className="Antechamber-main-title">
            {t('Meeting of')} <span>{dayjs(new Date()).format('LLL')}</span>
          </h1>
          <div className="Antechamber-room-title">
            <strong>
              {t('Title', { ns: 'meetings' })}
              {' : '}
            </strong>
            <span className="room-name">{t('Room name')}</span>
          </div>
        </div>
      </div>

      <div className="Antechamber-main">
        <Tabs defaultActiveKey="1" className="Antechamber-tabs" type="card">
          <TabPane tab={'Configuration'} key="1">
            <h4 className="devices-configuration-label">{t('Configuration')}</h4>
            <hr />
            <Row align="middle">
              <Col span={24} sm={12} className="d-flex d-flex-center">
                <div className="devices-configuration-video-container">
                  <img
                    src="/images/fake_video_small.png"
                    className={classnames({
                      'devices-configuration-video': true,
                    })}
                  />
                  <div className="devices-configuration-webcam-container">
                    <div className={classnames('media-control-container')}>
                      <TbVideo strokeWidth={1} color="#FFFFFF" />
                    </div>

                    <div
                      className={classnames('media-control-container', {
                        'control-off': true,
                      })}
                    >
                      <TbMicrophoneOff size="2rem" strokeWidth={1} color="#FFFFFF" />
                    </div>

                    <div
                      className={classnames('media-control-container', {
                        'control-off': true,
                      })}
                    >
                      <BsFilePerson color="#FFFFFF" />
                    </div>
                  </div>
                </div>
              </Col>
              <Col span={24} sm={12}>
                <div className="devices-configuration-mediasettings-container">
                  <div className="mb-1rem">
                    <div className="mb-05rem">{t('Camera', { ns: 'common' })}</div>
                    <Row>
                      <Col span={20}>
                        <Select
                          value={'Webcam 1'}
                          style={{ width: '100%' }}
                          options={[
                            { value: 'Webcam 1', label: 'Webcam 1' },
                            { value: 'Webcam 2', label: 'Webcam 2' },
                          ]}
                        />
                      </Col>
                    </Row>
                  </div>

                  <div className="mb-1rem">
                    <div className="mb-05rem">{t('Microphone', { ns: 'common' })}</div>
                    <div className="devices-configuration-mediasettings-label">
                      <BsExclamationCircleFill
                        fill="#ff4d4f"
                        size="1.4rem"
                        className="mr-1rem"
                      />
                      {t('You have disabled the microphone.')}
                    </div>
                  </div>
                  <div className="mb-05rem">{t('Speaker', { ns: 'common' })}</div>
                  <Row>
                    <Col span={20}>
                      <Select
                        value={'Speaker 1'}
                        style={{ width: '100%' }}
                        options={[
                          { value: 'Speaker 1', label: 'Speaker 1' },
                          { value: 'Speaker 2', label: 'Speaker 2' },
                        ]}
                      />
                    </Col>
                    <Col span={4} className="d-flex d-flex-middle d-flex-right">
                      <Button
                        className="test-btn btn-secondary"
                        icon={<HiOutlineSpeakerWave size="1.7em" />}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-1rem">
                    <Col span={20} className="refresh-container">
                      <FiRefreshCcw size="1em" />
                      <span>{t('Refresh devices', { ns: 'common' })}</span>
                    </Col>
                    <Col span={4} />
                  </Row>
                </div>
              </Col>
            </Row>
          </TabPane>
          <TabPane tab={t('General information')} key="2" disabled />
          <TabPane tab={t('Documents', { ns: 'common' })} key="3" disabled />
        </Tabs>
        <Col
          span={24}
          className="Antechamber-join-button-container d-flex d-flex-middle d-flex-column"
        >
          <Row className="w-100 responsive-row join-btn">
            <Col span={8} />
            <Col span={8} className="d-flex d-flex-middle d-flex-center">
              <Button type="primary">{t('Join this meeting')}</Button>
            </Col>
            <Col span={8} className="d-flex d-flex-middle sovereignty-img-container">
              <img
                className="sovereignty-img"
                src={`/images/sovereignty_${i18n.language}.png`}
                alt="sovereignty"
              />
              <img
                className="sovereignty-img"
                src={`/images/notarisation_${i18n.language}.png`}
                alt="sovereignty"
              />
            </Col>
          </Row>
        </Col>
      </div>
    </div>
  )
}

export default AntechamberPreview
