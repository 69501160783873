import React from 'react'
import Layout from '../../../components/Layout/Layout'
import NewMeetingForm from './NewMeetingForm'
import { useNavigate } from 'react-router-dom'
import MeetingsMenu from '../MeetingsMenu'
import { useTranslation } from 'react-i18next'

function NewMeeting() {
  const navigate = useNavigate()
  const { t } = useTranslation('meetings')
  return (
    <Layout
      title="Meetings"
      headerTitle={<h1 className="title">{t('Meetings')}</h1>}
      menu={<MeetingsMenu />}
      content={
        <NewMeetingForm
          onMeetingCreated={() => navigate('/upcoming-meetings')}
          onPendingMeetingCreated={() => navigate('/pending-meetings')}
          onMeetingCancel={() => navigate('/upcoming-meetings')}
        />
      }
      footer={false}
    />
  )
}

export default NewMeeting
