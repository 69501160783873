import { createSlice } from '@reduxjs/toolkit'
import { setRoomState } from './roomSlice'
import { ScoreModel, ConsumerModel } from './model'

interface Consumers {
  [id: string]: ConsumerModel
}

interface State {
  consumers: Consumers
}

export const initialState: State = {
  consumers: {},
}

const consumersSlice = createSlice({
  initialState,
  name: 'consumers',
  reducers: {
    addConsumer: (
      state,
      action: { payload: { consumer: ConsumerModel; peerId: string } },
    ) => {
      state.consumers[action.payload.consumer.id] = action.payload.consumer
    },

    removeConsumer: (
      state,
      action: { payload: { consumerId: string; peerId: string } },
    ) => {
      delete state.consumers[action.payload.consumerId]
    },

    setConsumerPaused: (
      state,
      action: { payload: { consumerId: string; originator: string } },
    ) => {
      const consumer = state.consumers[action.payload.consumerId]
      if (!consumer) {
        return
      }

      if (action.payload.originator === 'local') {
        consumer.locallyPaused = true
      } else {
        consumer.remotelyPaused = true
      }
    },

    setConsumerResumed: (
      state,
      action: { payload: { consumerId: string; originator: string } },
    ) => {
      const consumer = state.consumers[action.payload.consumerId]
      if (!consumer) {
        return
      }

      if (action.payload.originator === 'local') {
        consumer.locallyPaused = false
      } else {
        consumer.remotelyPaused = false
      }
    },

    setConsumerCurrentLayers: (
      state,
      action: {
        payload: { consumerId: string; spatialLayer: number; temporalLayer: number }
      },
    ) => {
      const consumer = state.consumers[action.payload.consumerId]
      if (!consumer) {
        return
      }

      consumer.currentSpatialLayer = action.payload.spatialLayer
      consumer.currentTemporalLayer = action.payload.temporalLayer
    },

    setConsumerPreferredLayersState: (
      state,
      action: {
        payload: { consumerId: string; spatialLayer: number; temporalLayer: number }
      },
    ) => {
      const consumer = state.consumers[action.payload.consumerId]
      if (!consumer) {
        return
      }

      consumer.preferredSpatialLayer = action.payload.spatialLayer
      consumer.preferredTemporalLayer = action.payload.temporalLayer
    },

    setConsumerPriorityState: (
      state,
      action: { payload: { consumerId: string; priority: number } },
    ) => {
      const consumer = state.consumers[action.payload.consumerId]
      if (!consumer) {
        return
      }

      consumer.priority = action.payload.priority
    },

    setConsumerTrack: (
      state,
      action: { payload: { consumerId: string; track: MediaStreamTrack } },
    ) => {
      const consumer = state.consumers[action.payload.consumerId]
      if (!consumer) {
        return
      }

      consumer.track = action.payload.track
    },

    setConsumerScore: (
      state,
      action: { payload: { consumerId: string; score: ScoreModel } },
    ) => {
      const consumer = state.consumers[action.payload.consumerId]
      if (!consumer) {
        return
      }

      consumer.score = action.payload.score
    },
  },

  extraReducers: (builder) => {
    builder.addCase(setRoomState, (state, action: { payload: string }) => {
      if (action.payload === 'closed') {
        return initialState
      }
    })
  },
})

export default consumersSlice.reducer

export const {
  addConsumer,
  removeConsumer,
  setConsumerPaused,
  setConsumerResumed,
  setConsumerCurrentLayers,
  setConsumerPreferredLayersState,
  setConsumerPriorityState,
  setConsumerTrack,
  setConsumerScore,
} = consumersSlice.actions
