import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FetchStatus } from '../../utils/FetchStatus'
import { RoomParticipant, RoomParticipantRole } from '../room/RoomInterface'

export enum EventType {
  MEETING_STARTED = 'meeting_started',
  MEETING_UPDATED = 'meeting_updated',
  MEETING_ENDED = 'meeting_ended',
  MEETING_ARCHIVED = 'meeting_archived',
  MEETING_MODIFIED = 'meeting_modified',
  MEETING_CREATED = 'meeting_created',

  PEER_DISCONNECTED = 'peer_disconnected',
  PEER_JOINED = 'peer_joined',
  PEER_INVITED = 'peer_invited',
  PEER_REMOVED = 'peer_removed',
  PEER_NOTIFY_MISSING_PARTICIPANTS = 'peer_notify_missing_participants',

  FILE_UPLOADED = 'file_uploaded',
  FILE_REMOVED = 'file_removed',
  FILE_DOWNLOAD = 'file_download',

  NEW_CHAT_STARTED = 'new_chat_started',
}

export interface NotarisationEvent {
  meetingId: string
  peer: string
  type: EventType
  createdDt: string
  filename?: string
  targetEmail?: string
  peerDestination?: string
}

interface State {
  fetchStatus: FetchStatus
  newNotarisationEventStatus: FetchStatus
  newNotarisationEventError?: string
  data?: NotarisationEvent[]
  total?: number
  peers?: { [email: string]: RoomParticipant }
  userRole?: RoomParticipantRole
}

export const initialState: State = {
  fetchStatus: 'idle',
  newNotarisationEventStatus: 'idle',
}

export const fetchNotarisation = createAsyncThunk(
  'rooms/fetchNotarisation',
  async (
    {
      roomId,
      filterType,
      filterPeer,
      pageNumber,
      pageSize,
      sortField,
      sortOrder,
    }: {
      roomId: string
      filterType?: string
      filterPeer?: string
      pageNumber: number
      pageSize: number
      sortField?: string
      sortOrder?: string
    },
    { getState },
  ) => {
    const { auth } = getState() as { auth: { jwt: string; email: string } }
    const response = await fetch(
      `${process.env.REACT_APP_MEDIA_BASE_URL}/notarisation/fetch-notarisation-data`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${auth.jwt}`,
        },
        body: JSON.stringify({
          roomId,
          filterType,
          filterPeer,
          pageNumber,
          pageSize,
          sortField,
          sortOrder,
        }),
      },
    )

    if (!response.ok) {
      throw new Error('Could not fetch notarisation')
    }

    const data: { list: NotarisationEvent[]; total: number; peers: RoomParticipant[] } =
      await response.json()

    return {
      roomId,
      data: data.list,
      total: data.total,
      peers: data.peers,
      user: auth.email,
    }
  },
)

export const newNotarisationEvent = createAsyncThunk(
  'rooms/newNotarisationEvent',
  async (
    payload: {
      roomId: string
      type: EventType
      peer: string
      timestamp: number
      fileId?: string
      filename?: string
    },
    { getState },
  ) => {
    const { auth } = getState() as { auth: { jwt: string; email: string } }

    const response = await fetch(
      `${process.env.REACT_APP_MEDIA_BASE_URL}/notarisation/new_event`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${auth.jwt}`,
        },
        body: JSON.stringify(payload),
      },
    )

    if (!response.ok) {
      throw new Error('Could not create new notarisation event')
    }
  },
)

const roomNotarisation = createSlice({
  name: 'roomNotarisation',
  initialState,
  reducers: {
    idleNewNotarisationEvent: (state) => {
      state.newNotarisationEventStatus = 'idle'
      state.newNotarisationEventError = undefined
    },
    idleFetchNotarisation: (state) => {
      state.fetchStatus = 'idle'
    },
  },
  extraReducers: {
    [fetchNotarisation.pending.type]: (state) => {
      state.fetchStatus = 'loading'
    },
    [fetchNotarisation.fulfilled.type]: (
      state,
      {
        payload,
      }: PayloadAction<{
        roomId: string
        data: NotarisationEvent[]
        total: number
        peers: { [email: string]: RoomParticipant }
        user: string
      }>,
    ) => {
      state.data = payload.data
      state.total = payload.total
      state.peers = payload.peers
      state.fetchStatus = 'success'
      state.userRole = payload.peers[payload.user]?.role
    },
    [fetchNotarisation.rejected.type]: (state, action) => {
      state.fetchStatus = 'error'
    },

    [newNotarisationEvent.pending.type]: (state) => {
      state.newNotarisationEventStatus = 'loading'
    },
    [newNotarisationEvent.fulfilled.type]: (state) => {
      state.newNotarisationEventStatus = 'success'
    },
    [newNotarisationEvent.rejected.type]: (state, action) => {
      state.newNotarisationEventStatus = 'error'
      state.newNotarisationEventError = action.error.message
    },
  },
})

export const { idleNewNotarisationEvent, idleFetchNotarisation } =
  roomNotarisation.actions

export default roomNotarisation.reducer
