import React, {
  forwardRef,
  useState,
  Ref,
  useImperativeHandle,
  useRef,
  useEffect,
} from 'react'
import { attemptSignup, idleAttemptSignupStatus } from './redux/authSlice'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Form, FormInstance, Input, Modal, Select } from 'antd'
import { useTranslation } from 'react-i18next'
import { AppDispatch, RootState } from '../../store'
import { ModalConfirmationInterface } from '../../components/Modal/ModalConfirmation'
import { useAttemptsListener } from 'auxasphere-react-kit'
import SelectLanguage from '../../components/Select/SelectLanguage'
import { useToastContext } from '../../components/Toast/ToastContext'

interface FormValues {
  email: string
  firstName: string
  lastName: string
  tel: string
  lang: string
  password: string
  confirm: string
}

const ModalSignUp = forwardRef(({}, ref: Ref<ModalConfirmationInterface>) => {
  const [form] = Form.useForm<FormValues>()
  useImperativeHandle(ref, () => ({
    openModal: () => {
      setModalVisible(true)
      form.setFieldValue('email', form.getFieldsValue()['email'])
    },
    close: () => setModalVisible(false), //will not be called
    isOpen: () => {
      //will not be called
      return modalVisible
    },
  }))

  const attemptSignupStatus = useSelector(
    (state: RootState) => state.auth.attemptSignupStatus,
  )
  const dispatch = useDispatch<AppDispatch>()
  const { ToastOpen } = useToastContext()
  const { t } = useTranslation('auth')

  const [modalVisible, setModalVisible] = useState(false)

  useAttemptsListener(
    [
      [
        attemptSignupStatus,
        {
          EMAIL_ALREADY_TAKEN: () =>
            form.setFields([
              { name: 'email', errors: [t('This email was already taken')] },
            ]),
          unknown_error: () =>
            ToastOpen({
              message: t('Unexpected error. Please try later.'),
              type: 'error',
            }),
        },
      ],
    ],
    () => dispatch(idleAttemptSignupStatus()),
  )

  return (
    <Modal
      centered
      title={t('Sign up')}
      open={modalVisible}
      onCancel={() => setModalVisible(false)}
      footer={
        <div className="signup-modal-footer">
          <Button onClick={() => setModalVisible(false)}>
            {t('Cancel', { ns: 'common' })}
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            form="signup-form"
            loading={attemptSignupStatus === 'loading'}
          >
            {t('Sign up')}
          </Button>
        </div>
      }
      destroyOnClose={true}
    >
      <SignUpForm form={form} />
    </Modal>
  )
})

function SignUpForm({ form }: { form: FormInstance<FormValues> }) {
  const dispatch = useDispatch<AppDispatch>()
  const { i18n, t } = useTranslation('auth')
  const { Option } = Select

  useEffect(() => {
    console.log('set ' + i18n.language)
    form.setFieldValue('lang', i18n.language)
  }, [i18n.language])

  const signup = (values: any) => {
    dispatch(
      attemptSignup({
        email: values['email'],
        firstName: values['firstName'],
        lastName: values['lastName'],
        tel: values['tel'],
        lang: values['lang'],
        password: values['password'],
      }),
    )
  }

  return (
    <Form
      id="signup-form"
      form={form}
      autoComplete="off"
      labelCol={{ span: 10 }}
      wrapperCol={{ span: 14 }}
      onFinish={signup}
      scrollToFirstError={true}
    >
      <Form.Item
        label={t('Email')}
        name="email"
        rules={[{ required: true, message: t('Please input your email') }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={t('First name')}
        name="firstName"
        rules={[{ required: true, message: t('Please input your first name') }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={t('Last name')}
        name="lastName"
        rules={[{ required: true, message: t('Please input your last name') }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={t('Phone')}
        name="tel"
        rules={[{ required: true, message: t('Please input your phone') }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={t('Preferred language')}
        name="lang"
        rules={[{ required: true, message: t('Please select your preferred language') }]}
      >
        <SelectLanguage />
      </Form.Item>

      <Form.Item
        name="password"
        label={t('Password')}
        rules={[{ required: true, message: t('Please input your password') }]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item
        name="confirm"
        label={t('Confirm Password')}
        dependencies={['password']}
        rules={[
          { required: true, message: t('Please confirm your password') },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve()
              }
              return Promise.reject(
                new Error(t('The two passwords that you entered do not match')),
              )
            },
          }),
        ]}
      >
        <Input.Password />
      </Form.Item>
    </Form>
  )
}

ModalSignUp.displayName = 'ModalSignUp'
export default ModalSignUp
