import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import { Button, Divider } from 'antd'
import { RiUserSearchLine } from 'react-icons/ri'

function OrganisationHeader() {
  const { t } = useTranslation('organisation')

  const organization = useSelector((state: RootState) => state.organisation.organisation)
  const remainingLicences =
    organization?.licences && organization?.licencesTaken
      ? organization.licences - organization.licencesTaken
      : 0

  return (
    <>
      <div className="layout-header-bottom">
        <h4 className="layout-list-header">
          <>{t('Users of my organisation')}</>
        </h4>
        <div className="d-flex">
          {!process.env.REACT_APP_LIMITE_SIGN_UP && (
            <Button
              type="primary"
              icon={<RiUserSearchLine size="1.5rem" />}
              className="mr-1rem"
              disabled={remainingLicences <= 0}
            >
              <span className="hideOnMobile">{t('Attach user')}</span>
            </Button>
          )}
        </div>
      </div>
      <Divider />
    </>
  )
}

export default OrganisationHeader
