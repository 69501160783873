import React from 'react'
import { useTranslation } from 'react-i18next'
import { ViewFile, setFileToView } from '../../Viewer/ViewerSlice'
import noImage from './no-image.png'
import { downloadEncryptedFile, renderIcon } from '../../../utils/Utils'
import { BsDownload } from 'react-icons/bs'
import { AiOutlineEye, AiOutlineDelete } from 'react-icons/ai'
import { Button, Tooltip } from 'antd'
import { AppDispatch } from '../../../store'
import { useDispatch } from 'react-redux'
import classNames from 'classnames'

interface Props {
  encryptKey: string
  content: string
  withControls: boolean
  onDelete?: (fileId: string) => void
  deletable?: boolean
}

export default function ChatMessageFile({
  encryptKey,
  content,
  withControls,
  onDelete,
  deletable,
}: Props) {
  const { t } = useTranslation('chat')
  const dispatch = useDispatch<AppDispatch>()

  try {
    const files: ViewFile[] = JSON.parse(content)
    const images = []
    const other = []
    for (const f of files) {
      if (f.mimeType && f.mimeType.startsWith('image/')) {
        images.push(f)
      } else {
        other.push(f)
      }
    }

    return (
      <>
        {images.length > 0 && (
          <div className="chat_message_images_container">
            {images.map((f) => (
              <div
                className="chat_message_image"
                key={f.id}
                onClick={() =>
                  !deletable && dispatch(setFileToView({ file: f, encryptKey }))
                }
              >
                {deletable && f.mimeType && f.name ? (
                  <>
                    <img src={renderIcon(f.mimeType, f.name)} className="file-icon" />
                    <div className="name">{f.name}</div>
                  </>
                ) : (
                  <>
                    <img
                      src={`${process.env.REACT_APP_FILE_ENCRYPTOR_V2_BASE_URL}/${f.id}/${encryptKey}`}
                      onError={({ currentTarget }) => {
                        console.log('oneror')
                        currentTarget.onerror = null
                        currentTarget.src = noImage
                      }}
                    />
                    <div
                      className="chat_actions_hover chat_actions_hover--image"
                      hidden={!withControls}
                    >
                      <Tooltip title={t('View')}>
                        <div
                          className="chat_action chat_action--border-left"
                          onClick={() => dispatch(setFileToView({ file: f, encryptKey }))}
                        >
                          <AiOutlineEye size="1.2rem" />
                        </div>
                      </Tooltip>
                      <Tooltip title={t('Download')}>
                        <div
                          className="chat_action chat_action--border-right"
                          onClick={(e) => {
                            e.stopPropagation()
                            downloadEncryptedFile(f.id || '', encryptKey, f.name)
                          }}
                        >
                          <BsDownload size="1.2rem" />
                        </div>
                      </Tooltip>
                    </div>
                  </>
                )}
                {onDelete && (
                  <Tooltip title={t('Delete')}>
                    <div
                      className="chat_action"
                      onClick={(e) => {
                        e.stopPropagation()
                        onDelete(f.id || '')
                      }}
                    >
                      <AiOutlineDelete size="1.2rem" />
                    </div>
                  </Tooltip>
                )}
              </div>
            ))}
          </div>
        )}

        {other.length > 0 && (
          <div className="chat_message_files_container">
            {other.map((f) => (
              <div
                key={f.id}
                className="chat_message_file"
                onClick={() =>
                  !deletable && dispatch(setFileToView({ file: f, encryptKey }))
                }
              >
                <div
                  className={classNames({
                    chat_message_icon_name: true,
                    'chat_message_icon_name--deletable': deletable,
                  })}
                >
                  {f.mimeType && f.name && (
                    <img src={renderIcon(f.mimeType, f.name)} className="file-icon" />
                  )}

                  <div className="name">{f.name}</div>
                </div>
                <div className="chat_actions_hover" hidden={!withControls}>
                  <Tooltip title={t('View')}>
                    <div
                      className="chat_action chat_action--border-left"
                      onClick={() => dispatch(setFileToView({ file: f, encryptKey }))}
                    >
                      <AiOutlineEye size="1.2rem" />
                    </div>
                  </Tooltip>
                  <Tooltip title={t('Download')}>
                    <div
                      className="chat_action chat_action--border-right"
                      onClick={(e) => {
                        e.stopPropagation()
                        downloadEncryptedFile(f.id || '', encryptKey, f.name)
                      }}
                    >
                      <BsDownload size="1.2rem" />
                    </div>
                  </Tooltip>
                  {onDelete && (
                    <Tooltip title={t('Delete')}>
                      <div
                        className="chat_action"
                        onClick={(e) => {
                          e.stopPropagation()
                          onDelete(f.id || '')
                        }}
                      >
                        <AiOutlineDelete size="1.2rem" />
                      </div>
                    </Tooltip>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
      </>
    )
  } catch (e) {}

  return <>{t('Cannot decrypt this file.')}</>
}
