import { Chat } from '../../../components/ChatComponent/Chat/chatsSlice'
import { RoomInterface, RoomParticipant } from '../RoomInterface'
import { ChatInterface } from '../redux/roomSlice'
export const DEFAULT_CHATS: ChatInterface[] = [
  {
    messages: [],
    unread: 0,
    icon: 'G',
    name: 'General',
    id: 'chat',
  },
]

/**
 *
 * @param state
 * @returns
 */
export function getAllParticipants(
  state: RoomInterface,
): Omit<RoomParticipant, 'role'>[] | undefined {
  let allParticipants: Omit<RoomParticipant, 'role'>[] = []

  if (state.creator) {
    allParticipants = allParticipants.concat(state.creator)
  }
  if (state.participants) {
    allParticipants = allParticipants.concat(state.participants)
  }
  if (state.invited) {
    allParticipants = allParticipants.concat(state.invited)
  }

  return allParticipants
}

/**
 *
 */
export function buildChatId(email: string, peerEmail: string): string {
  return [email, peerEmail].sort().join(';')
}

/**
 *
 * @param chats
 * @returns
 */
export function sortByLatestMessageTimestamp(chats: ChatInterface[]): ChatInterface[] {
  const sortByTimestamp = (a: ChatInterface, b: ChatInterface) => {
    const latestMessageTimestampA =
      a.messages.length > 0 ? a.messages[a.messages.length - 1].timestamp : 0
    const latestMessageTimestampB =
      b.messages.length > 0 ? b.messages[b.messages.length - 1].timestamp : 0

    return latestMessageTimestampB - latestMessageTimestampA
  }

  const prioritizedChat = chats.filter((chat) =>
    DEFAULT_CHATS.some((defaultChat) => defaultChat.id === chat.id),
  )
  const otherChats = chats.filter(
    (chat) => !DEFAULT_CHATS.some((defaultChat) => defaultChat.id === chat.id),
  )
  otherChats.sort(sortByTimestamp)

  return prioritizedChat ? prioritizedChat.concat(otherChats) : otherChats
}

export function generateUniqueColor(key: string): string {
  if (key.length === 0) return '#224160'
  if (key == 'General') {
    return '#224160'
  }
  let hash = 0
  key.split('').forEach((char) => {
    hash = char.charCodeAt(0) + ((hash << 5) - hash)
  })
  let hashToNum = Math.abs(hash)
  const color =
    'hsl(' +
    (hashToNum % 330) +
    ',' +
    (25 + (hashToNum % 70)) +
    '%,' +
    (50 + (hashToNum % 10)) +
    '%)'
  return color
}

export function getChatDetails(chat: Chat) {
  let name: string | undefined
  let icon: string | undefined
  let backgroundColor: string | undefined

  if (chat.everybody) {
    name = 'General'
    backgroundColor = generateUniqueColor('General')
  } else if (chat.peerToPeer && chat.peer) {
    if (chat.peer.firstName && chat.peer.lastName) {
      name = `${chat.peer.firstName} ${chat.peer.lastName}`.trim()
      icon = ((chat.peer.firstName[0] || '') + (chat.peer.lastName[0] || ''))
        .trim()
        .toUpperCase()
    } else {
      name = chat.peer.email
      icon = chat.peer.email.substring(0, 2).toUpperCase()
    }

    backgroundColor = generateUniqueColor(chat.peer.email)
  }

  if (!name) {
    name = chat.title
  }

  if (!icon) {
    icon = (name[0] || '?').toUpperCase()
  }

  if (!backgroundColor) {
    backgroundColor = generateUniqueColor(chat.id)
  }

  return { name, icon, backgroundColor }
}
