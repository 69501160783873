import FAQ from 'auxasphere-react-kit/assets/logos/FAQ.svg'
import logo_auxadoc from 'auxasphere-react-kit/assets/logos/logo_auxadoc.svg'
import logo_auxadrive from 'auxasphere-react-kit/assets/logos/logo_auxadrive.svg'
import logo_auxameetings from 'auxasphere-react-kit/assets/logos/logo_auxameetings.svg'
import logo_auxaplanning from 'auxasphere-react-kit/assets/logos/logo_auxaplanning.svg'
import logo_auxateam from 'auxasphere-react-kit/assets/logos/logo_auxateam.svg'
import logo_auxatransfer from 'auxasphere-react-kit/assets/logos/logo_auxatransfer.svg'
import logo_groups from 'auxasphere-react-kit/assets/logos/logo_groups.svg'
import logo_param from 'auxasphere-react-kit/assets/logos/logo_param.svg'
import logo_users from 'auxasphere-react-kit/assets/logos/logo_users.svg'
import menu from 'auxasphere-react-kit/assets/logos/menu.svg'
import zoom_in from 'auxasphere-react-kit/assets/logos/zoom_in.svg'
import zoom_out from 'auxasphere-react-kit/assets/logos/zoom_out.svg'
//TODO: Add logo_customer_area.svg to auxasphere-react-kit

export const LOGO = {
  FAQ,
  logo_auxadoc,
  logo_auxadrive,
  logo_auxameetings,
  logo_auxaplanning,
  logo_auxateam,
  logo_auxatransfer,
  logo_groups,
  logo_param,
  logo_users,
  menu,
  zoom_in,
  zoom_out,
}
