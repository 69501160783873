import React, { MutableRefObject } from 'react'
import { Button, Col, Row, Spin, Tabs, TabsProps } from 'antd'
import { useTranslation } from 'react-i18next'
import './Antechamber.scss'
import RoomClient from '../RoomClient'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../../store'
import dayjs from 'dayjs'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import RoomDevicesConfigurations from '../RoomDevicesConfiguration/RoomDevicesConfiguration'
import RoomGeneralInformation from '../RoomGeneralInformation/RoomGeneralInformation'
import RoomDocuments from '../RoomDocuments/RoomDocuments'
import useRoomListener from '../../../utils/hooks/useRoomListener'
import { BiError } from 'react-icons/bi'
import { localFormatDate } from '../../../utils/Utils'
import Layout from '../../../components/Layout/Layout'
import { unescape } from 'html-escaper'
import { useToastContext } from '../../../components/Toast/ToastContext'
// import { useNavigate } from 'react-router-dom' //Unauthorized access modal
// import { setIsUnauthorized } from '../../../redux/appSlice'
// import { useAttemptsListener } from 'auxasphere-react-kit'

interface Props {
  roomClient: MutableRefObject<RoomClient>
  onJoin: () => void
  onDocumentClick: () => void
}

function Antechamber({ roomClient, onJoin }: Props) {
  dayjs.extend(LocalizedFormat)
  const { t, i18n } = useTranslation('antechamber')
  //const navigate = useNavigate()
  const room = useSelector((state: RootState) => state.room)
  const encryptKey = room?.decrypt_key || null
  const { ToastOpen } = useToastContext()

  function onApprovedToJoin() {
    ToastOpen({
      type: 'success',
      message: t('Moderator has approved your participation.'),
    })
  }

  function onDeniedToJoin() {
    ToastOpen({
      type: 'error',
      message: t('Organizer has denied your participation.'),
    })
  }

  useRoomListener(roomClient, {
    ALLOWED_TO_JOIN: onApprovedToJoin,
    DENIED_TO_JOIN: onDeniedToJoin,
  })

  const items: TabsProps['items'] = [
    {
      key: 'configuration',
      label: t('Configuration'),
      children: <RoomDevicesConfigurations roomClient={roomClient} />,
    },
    {
      key: '2',
      label: t('General information'),
      children: <RoomGeneralInformation />,
    },
    {
      key: '3',
      label: t('Documents', { ns: 'common' }),
      children: <RoomDocuments />,
      disabled: !encryptKey,
    },
  ]

  // useAttemptsListener([
  //   [
  //     room.fetchAccessTokenError ?? '',
  //     {
  //       NO_PARTICIPANT_IN_MEETING_ERROR: () => {
  //         dispatch(setIsUnauthorized(true))
  //         navigate('/applications')
  //       },
  //     },
  //   ],
  // ])

  function renderContent() {
    return (
      <div className="Antechamber">
        <div className="Antechamber-header-container">
          {room.fetchAccessTokenStatus === 'success' && (
            <div className="Antechamber-main-title-container">
              <h1 className="Antechamber-main-title">
                {t('Meeting of')}{' '}
                {room.startDate && localFormatDate(dayjs(room.startDate))}
              </h1>
              <div className="Antechamber-room-title">
                <strong>
                  {t('Title', { ns: 'meetings' })}
                  {' : '}
                </strong>
                <span className="room-name">{unescape(room.name || '')}</span>
              </div>
            </div>
          )}
        </div>

        <div className="Antechamber-main">
          {(room.fetchAccessTokenStatus === 'success' && (
            <Tabs
              defaultActiveKey="1"
              items={items}
              className="Antechamber-tabs"
              type="card"
            />
          )) ||
            (room.fetchAccessTokenStatus === 'loading' && (
              <div className="d-flex d-flex-center mt-1rem h-30rem">
                <Spin /> {t('Connecting, wait please')}
              </div>
            )) ||
            (room.fetchAccessTokenStatus === 'error' &&
              ((room.fetchAccessTokenError === 'NO_PARTICIPANT_IN_MEETING_ERROR' && (
                <>
                  <div className="Antechamber-connection-error">
                    <BiError size="4em" color="#ff4d4f" className="mt-2rem" />
                    <div className="Antechamber-connection-error">
                      {t('You are not authorized to access this meeting.')}
                    </div>
                  </div>
                </>
              )) || (
                <div className="Antechamber-connection-error">
                  <BiError size="4em" color="#ff4d4f" className="mt-2rem" />
                  <div className="Antechamber-connection-error">
                    {t('You are not authorized to access this meeting.')}
                  </div>
                </div>
              )))}

          {room.fetchAccessTokenStatus === 'success' && (
            <Col
              span={24}
              className="Antechamber-join-button-container d-flex d-flex-middle d-flex-column"
            >
              {room.fetchAccessTokenStatus === 'success' && (
                <div className="mb-05rem flashing-text italic">
                  {(!room.allowed && t('Connecting, wait please')) ||
                    (room.allowed === 'unknown' &&
                      t("Please wait for organizer's approval")) ||
                    (room.allowed === 'prohibited' &&
                      t('Organizer has denied your participation.'))}
                </div>
              )}
              <Row
                className="w-100 responsive-row join-btn"
                hidden={room.fetchAccessTokenStatus != 'success'}
              >
                <Col span={8} />
                <Col span={8} className="d-flex d-flex-middle d-flex-center">
                  <Button
                    type="primary"
                    disabled={room.allowed !== 'allowed'}
                    onClick={onJoin}
                  >
                    {t('Join this meeting')}
                  </Button>
                </Col>
              </Row>
            </Col>
          )}
        </div>
      </div>
    )
  }

  return (
    <Layout
      content={renderContent()}
      contentFullSize={true}
      primaryContent={true}
      footer={true}
    />
  )
}

export default Antechamber
