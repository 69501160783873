import React, { ReactElement, useRef } from 'react'
import {
  Button,
  Collapse,
  Dropdown,
  FloatButton,
  Form,
  Menu,
  MenuProps,
  Spin,
  Tooltip,
} from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../../store'
import {
  FolderInterface,
  idleDeleteDriveStatus,
  setCreateDrive,
  setDriveToDelete,
  setDriveToModify,
  setExpandedFolderKeys,
  setOpenCreateFolder,
  setSelectedDriveFolder,
} from '../../../features/Drives/redux/drivesSlice'
import './DrivesMenu.scss'
import { useTranslation } from 'react-i18next'
import CreateDriveModal from '../CreateDrive/CreateDriveModal'
import UpdateDriveModal from '../CreateDrive/UpdateDriveModal'
import { Scrollbars } from 'react-custom-scrollbars-2'
import DeleteDriveModal from '../CreateDrive/DeleteDriveModal'
import { useAttemptsListener } from 'auxasphere-react-kit'
import { IoAddCircle, IoHomeOutline } from 'react-icons/io5'
import { LuHardDriveDownload } from 'react-icons/lu'
import {
  MdDriveFolderUpload,
  MdOutlineCreateNewFolder,
  MdOutlineUploadFile,
} from 'react-icons/md'
import DrivesFileUpload from '../DrivesHeader/DrivesFileUpload'
import { useDriveLoader } from '../../../utils/hooks/UseDriveLoader'
import { useToastContext } from '../../Toast/ToastContext'
import DrivesTree from '../DriveListContent/DriveTree/DrivesTree'
import { useDrivesContext } from '../../../features/Drives/DrivesContext'
import { useNavigate } from 'react-router-dom'
import { RiHardDrive3Line } from 'react-icons/ri'
import { FiUsers } from 'react-icons/fi'
import { IoMdAdd } from 'react-icons/io'

interface Props {
  adminRootFolders: FolderInterface[]
  sharedRootFolders: FolderInterface[]
}

export enum MenuItems {
  HOME = 'HOME',
  MY_DRIVES = 'MY_DRIVES',
}

/**
 *
 * @param param0
 * @returns
 */
function DrivesMenu({ adminRootFolders, sharedRootFolders }: Props) {
  const { t } = useTranslation('drives')
  const dispatch = useDispatch<AppDispatch>()
  const { ToastOpen } = useToastContext()
  const fetchDrivesStatus = useSelector(
    (state: RootState) => state.drive.fetchDrivesStatus,
  )
  const deleteDriveStatus = useSelector(
    (state: RootState) => state.drive.deleteDriveStatus,
  )
  const { fileRights, folderRights, selectedDriveFolder } = useDriveLoader()
  const inputFile = useRef<HTMLInputElement>(null)
  const inputFolder = useRef<HTMLInputElement>(null)
  const navigate = useNavigate()

  const items: MenuProps['items'] = [
    {
      key: 'create_drive',
      className: 'menu-create-drive',
      label: (
        <Form.Item className="mb-0">
          <label>{t('Create drive')}</label>
        </Form.Item>
      ),
      onClick: () => dispatch(setCreateDrive(true)),
      icon: <LuHardDriveDownload size="1rem" />,
    },
  ]

  if (selectedDriveFolder) {
    if (folderRights) {
      items.push(
        {
          type: 'divider',
        },
        {
          key: 'create_folder',
          className: 'menu-create-drive',
          label: (
            <>
              <Form.Item className="mb-0">
                <label>{t('Create folder')}</label>
              </Form.Item>
            </>
          ),
          onClick: () => dispatch(setOpenCreateFolder(true)),
          icon: <MdOutlineCreateNewFolder size="1rem" />,
        },
      )
    }
    if (fileRights && folderRights) {
      items.push({
        key: 'import_folder',
        className: 'menu-create-drive',
        label: (
          <span onClick={(event) => event.stopPropagation()}>
            <DrivesFileUpload inputFolder={inputFolder} />
          </span>
        ),
        icon: <MdDriveFolderUpload size="1rem" />,
        onClick: () => inputFolder.current?.click(),
      })
    }
    if (fileRights) {
      items.push(
        {
          type: 'divider',
        },
        {
          key: 'import_file',
          className: 'menu-create-drive',
          label: (
            <span onClick={(event) => event.stopPropagation()}>
              <DrivesFileUpload inputFile={inputFile} />
            </span>
          ),
          icon: <MdOutlineUploadFile size="1rem" />,
          onClick: () => inputFile.current?.click(),
        },
      )
    }
  }

  /**
   *
   */
  function goDriveHome() {
    navigate('/drives/home')
    dispatch(setSelectedDriveFolder(undefined))
  }

  const itemsMenu: MenuProps['items'] = [
    {
      key: 'home',
      label: t('Home', { ns: 'common' }),
      icon: <IoHomeOutline />,
      onClick: () => goDriveHome(),
    },
  ]

  useAttemptsListener([
    [
      deleteDriveStatus,
      {
        success: () => {
          dispatch(setDriveToDelete(undefined))
          dispatch(setDriveToModify(undefined))
          ToastOpen({
            message: t('Drive was successfully deleted.'),
            type: 'success',
          })
          dispatch(idleDeleteDriveStatus())
        },
        error: () =>
          ToastOpen({
            message: t('Error deleting drive.'),
            type: 'error',
          }),
      },
    ],
  ])

  return (
    <>
      {fetchDrivesStatus === 'success' && (
        <>
          <div className="menu-action-btn-container">
            <Dropdown menu={{ items }} trigger={['click']}>
              <Button
                className="menu-action-btn"
                type="primary"
                icon={<IoAddCircle size="1.5rem" color="white" />}
              >
                {t('New', { ns: 'common' })}
              </Button>
            </Dropdown>
          </div>
          <Menu
            className="menu"
            mode="inline"
            selectedKeys={(location.pathname === '/drives/home' && ['home']) || []}
            items={itemsMenu}
          />
          {selectedDriveFolder && !fileRights && !folderRights ? (
            <Tooltip title={t("You don't have any rights for this drive.")}>
              <Dropdown
                menu={{ items: !selectedDriveFolder ? items : items.slice(2) }}
                trigger={['click']}
              >
                <FloatButton
                  type="primary"
                  icon={<IoMdAdd size="1.7em" />}
                  className="float-btn-disabled"
                />
              </Dropdown>
            </Tooltip>
          ) : (
            <Dropdown
              menu={{ items: !selectedDriveFolder ? items : items.slice(2) }}
              trigger={['click']}
            >
              <FloatButton type="primary" icon={<IoMdAdd size="1.7em" />} />
            </Dropdown>
          )}

          <Scrollbars autoHide className="DrivesMenu">
            <>
              <DriveCollapse
                drivesFolders={adminRootFolders}
                selectedDriveFolder={selectedDriveFolder}
                icon={<RiHardDrive3Line size="1.1em" />}
                title={<div className="title">{t('My drives')}</div>}
              />
              <DriveCollapse
                drivesFolders={sharedRootFolders}
                selectedDriveFolder={selectedDriveFolder}
                icon={<FiUsers size="1.1em" />}
                title={<div className="title">{t('Shared with me')}</div>}
              />
            </>
          </Scrollbars>
          <UpdateDriveModal />
          <DeleteDriveModal />
          <CreateDriveModal />
        </>
      )}
    </>
  )
}

export default DrivesMenu

interface DriveCollapseProps {
  drivesFolders: FolderInterface[]
  selectedDriveFolder: FolderInterface | undefined
  icon: ReactElement
  title: ReactElement
}

function DriveCollapse({
  drivesFolders,
  selectedDriveFolder,
  icon,
  title,
}: DriveCollapseProps) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { fetchAndSelectFolder } = useDrivesContext()
  const expandedKeys = useSelector((state: RootState) => state.drive.expandedFolderKeys)

  const handleExpandChange = () => {
    navigate('/drives/my-drives')
  }

  return (
    <Collapse
      className="menu_group"
      collapsible="header"
      ghost
      onChange={handleExpandChange}
      expandIconPosition="end"
      items={[
        {
          label: (
            <div className="menu_group_title-container">
              {icon}
              {title}
            </div>
          ),
          children: (
            <DrivesTree
              drivesFolders={drivesFolders}
              manageDrive={true}
              selectedFolder={selectedDriveFolder}
              onSelectFolder={fetchAndSelectFolder}
              expandedKeys={expandedKeys}
              setExpandedKeys={(keys) => dispatch(setExpandedFolderKeys(keys))}
            />
          ),
        },
      ]}
    />
  )
}
