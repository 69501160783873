import React, { MutableRefObject } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import { useTranslation } from 'react-i18next'
import { ProducerModel } from '../../features/room/redux/model'
import { Tooltip } from 'antd'
import RoomClient from '../../features/room/RoomClient'
import { TbMicrophone, TbMicrophoneOff } from 'react-icons/tb'
import { BsMic } from 'react-icons/bs'

interface Props {
  roomClient: MutableRefObject<RoomClient>
}

function CallControlsMic({ roomClient }: Props) {
  const canSendMic = useSelector((state: RootState) => state.me.canSendMic)
  const micStatus = useSelector((state: RootState) => state.me.micStatus)
  const { t } = useTranslation('roomDevicesConfiguration')
  const audioProducer: ProducerModel | undefined = useSelector((state: RootState) =>
    Object.values(state.producers.producers).find(
      (producer) => producer.type === 'audio',
    ),
  )

  let micState = 'off'
  if (!canSendMic) {
    micState = 'unsupported'
  } else if (audioProducer && !audioProducer.paused) {
    micState = 'on'
  }

  let tooltip
  switch (micStatus) {
    case 'ASKING_PERMISSIONS':
      tooltip = t('MIC_STATUS_ASKING_PERMISSIONS')
      break

    case 'FAILED':
      tooltip = t('MIC_STATUS_FAILED')
      break

    case 'NOT_ALLOWED':
      tooltip = (
        <div>
          {' '}
          {t('MIC_STATUS_NOT_ALLOWED')}{' '}
          <a href="/faq/devices" target="_blank">
            {t('Please visit our FAQ.', { ns: 'common' })}
          </a>
        </div>
      )
      break

    case 'NOT_FOUND':
      tooltip = t('MIC_STATUS_NOT_FOUND')
      break

    case 'STOPPED':
      tooltip = t('MIC_STATUS_STOPPED')
      break
  }

  if (micState === 'on') {
    return (
      <Tooltip title={t('MIC_STATUS_STARTED')}>
        <div
          className="call-control call-control--active"
          onClick={() => roomClient.current.muteMic()}
        >
          <TbMicrophone size="2rem" color="#ffffff" strokeWidth={1} />
        </div>
      </Tooltip>
    )
  }

  if (micState === 'off') {
    return (
      <Tooltip title={tooltip}>
        <div
          className="call-control call-control--inactive"
          onClick={() => roomClient.current.unmuteMic()}
        >
          <TbMicrophoneOff size="2rem" color="#ffffff" strokeWidth={1} />
        </div>
      </Tooltip>
    )
  }

  return (
    <Tooltip title={tooltip}>
      <div className="call-control" onClick={() => roomClient.current.unmuteMic()}>
        <BsMic size="2rem" color="#777777" strokeWidth={1} />
      </div>
    </Tooltip>
  )
}

export default CallControlsMic
