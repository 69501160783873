import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../store'
import { Pagination } from 'antd'
import { Trans } from 'react-i18next'
import { fetchUsers, idleUsers } from '../Organisation/redux/organisationSlice'

export default function ParticipantsPagination() {
  const organisation = useSelector((state: RootState) => state.organisation)
  const [pageDetails, setPageDetails] = useState<{ page: number; pageSize: number }>({
    page: 1,
    pageSize: 8,
  })

  const dispatch = useDispatch<AppDispatch>()

  function updateUsers() {
    dispatch(
      fetchUsers({
        page: pageDetails.page,
        usersPerPage: pageDetails.pageSize,
      }),
    )
  }

  useEffect(() => {
    //TODO: refactoring (don't fetch previously fetched pages)
    updateUsers()
    return () => {
      dispatch(idleUsers())
    }
  }, [pageDetails])

  if (organisation.usersTotalNumber > 8)
    return (
      <Pagination
        style={{ alignSelf: 'center', justifyContent: 'center' }}
        showTotal={(total, range) => (
          <Trans
            ns="common"
            i18nKey="TABLE_PAGINATION"
            values={{
              range0: range[0],
              range1: range[1],
              total: total,
            }}
          />
        )}
        className="mt-1rem"
        onChange={(page, pageSize) => setPageDetails({ page, pageSize })}
        onShowSizeChange={(page, pageSize) => setPageDetails({ page, pageSize })}
        current={pageDetails.page}
        pageSize={pageDetails.pageSize}
        total={organisation.usersTotalNumber}
        showSizeChanger={true}
        pageSizeOptions={[8, 10, 20, 50, 100]}
      />
    )
  else return <></>
}
