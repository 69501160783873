import { Form, Input, InputRef, Modal } from 'antd'
import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import {
  idleRenameDriveFileStatus,
  renameDriveFile,
  setFileToRename,
} from '../../features/Drives/redux/drivesSlice'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../store'
import { useAttemptsListener } from 'auxasphere-react-kit'
import { useToastContext } from '../Toast/ToastContext'

function RenameFileModal() {
  const dispatch = useDispatch<AppDispatch>()
  const { t } = useTranslation('drives')
  const [renameFileForm] = Form.useForm()
  const { ToastOpen } = useToastContext()

  const selectedDriveFolder = useSelector(
    (state: RootState) => state.drive.selectedDriveFolder,
  )

  const renameDriveFileStatus = useSelector(
    (state: RootState) => state.drive.renameDriveFileStatus,
  )
  const inputRef = useRef<InputRef>(null)

  const fileToRename = useSelector((state: RootState) => state.drive.fileToRename)

  useAttemptsListener([
    [
      renameDriveFileStatus,
      {
        success: () => {
          ToastOpen({
            message: t('File was successfully renamed.'),
            type: 'success',
          })
          resetRenameFileModal()
        },
        NOT_ENOUGH_FILE_RIGHT: () => {
          ToastOpen({
            message: t(
              'You do not have the necessary rights to rename files in this project.',
            ),
            type: 'error',
          })
          resetRenameFileModal()
        },
        FETCH_ERROR: () => {
          ToastOpen({
            message: t('The drive service is not available at the moment.'),
            type: 'error',
            duration: 50,
          })
        },
        unknown_error: () => {
          ToastOpen({
            message: t('Error renaming file.'),
            type: 'error',
          })
        },
      },
      () => dispatch(idleRenameDriveFileStatus()),
    ],
  ])

  /**
   *
   */
  function resetRenameFileModal() {
    renameFileForm.resetFields()
    dispatch(setFileToRename(undefined))
  }

  /**
   *
   */
  function onRenameFile() {
    if (selectedDriveFolder && fileToRename) {
      const newName = renameFileForm.getFieldsValue().name
      const oldExtension =
        fileToRename?.name.split('.').length > 1
          ? fileToRename?.name.split('.').pop()
          : ''
      const newFileName = newName + (oldExtension ? `.${oldExtension}` : '')

      dispatch(
        renameDriveFile({
          filename: newFileName,
          fileId: fileToRename.id,
          driveId: selectedDriveFolder?.driveId,
        }),
      )
    }
  }

  useEffect(() => {
    if (fileToRename) {
      renameFileForm.setFieldValue('name', fileToRename.name.replace(/\.\w+$/, ''))
    }
  }, [fileToRename])

  return (
    <Modal
      centered
      open={fileToRename != undefined}
      title={t('Rename file')}
      onOk={renameFileForm.submit}
      onCancel={resetRenameFileModal}
      okText={t('Validate', { ns: 'common' })}
      cancelText={t('Cancel', { ns: 'common' })}
      afterOpenChange={(open) => open && inputRef.current?.focus({ cursor: 'all' })}
    >
      <div className="modal-content-container">
        <Form
          name="renameFileForm"
          layout="vertical"
          autoComplete="off"
          form={renameFileForm}
          onFinish={onRenameFile}
        >
          <Form.Item
            style={{ marginBottom: '0px' }}
            name="name"
            label={t('File name')}
            rules={[{ required: true, message: t('Please input file name') }]}
          >
            <Input ref={inputRef} />
          </Form.Item>
        </Form>
      </div>
    </Modal>
  )
}

export default RenameFileModal
