import React, { useState, useEffect } from 'react'
import dayjs from 'dayjs'

export const DateTime = () => {
  const [date, setDate] = useState(dayjs())

  useEffect(() => {
    let timer = setInterval(() => setDate(dayjs()), 1000)

    return () => {
      clearInterval(timer)
    }
  }, [])

  return <>{date.format('HH:mm')}</>
}

export default DateTime
