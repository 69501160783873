import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { FetchStatus } from '../../../utils/FetchStatus'
import {
  fetchMeetingDateVotes,
  fetchRoomById,
  fetchSendSelectedDateStatus,
  fetchUpdateVote,
} from '../../../utils/MeetingUtils'
import { RootState } from '../../../store'

export type VoteResponse = 'yes' | 'no' | 'maybe' | ''

interface State {
  votes: {
    [key: string]: { [key: string]: VoteResponse }
  }
  selectedDate?: {
    date: string
    yes: string[]
    no: string[]
    maybe: string[]
  }
  getVotesStatus: FetchStatus
  sendVotesStatus: FetchStatus
  sendSelectedDateStatus: FetchStatus
}

export const initialState: State = {
  votes: {},
  getVotesStatus: 'idle',
  sendVotesStatus: 'idle',
  sendSelectedDateStatus: 'idle',
  selectedDate: undefined,
}

export const getVotes = createAsyncThunk(
  'meetingVote/getVotes',
  async (meetingId: string, { getState, dispatch }) => {
    const { auth, invite } = getState() as RootState

    const response = await fetchMeetingDateVotes(meetingId, auth.jwt || invite.jwt || '')
    dispatch(setVotes(response))
  },
)

export const sendVote = createAsyncThunk(
  'meetingVote/sendVote',
  async (
    {
      meetingId,
      myVotes,
    }: { meetingId: string; myVotes: { yes: string[]; no: string[]; maybe: string[] } },
    { getState },
  ) => {
    const { auth, invite } = getState() as RootState
    await fetchUpdateVote(meetingId, myVotes, auth.jwt || invite.jwt || '')
  },
)

export const sendSelectedDate = createAsyncThunk(
  'meetingVote/sendSelectedDate',
  async ({ meetingId, date }: { meetingId: string; date: string }, { getState }) => {
    const { auth, invite } = getState() as RootState
    await fetchSendSelectedDateStatus(meetingId, date, auth.jwt || invite.jwt || '')
  },
)

const meetingVoteSlice = createSlice({
  name: 'meetingVote',
  initialState,
  reducers: {
    setVotes: (
      state,
      { payload }: PayloadAction<{ [key: string]: { [key: string]: VoteResponse } }>,
    ) => {
      state.votes = payload
    },
    setMyVote: (
      state,
      { payload }: PayloadAction<{ date: string; email: string; vote: VoteResponse }>,
    ) => {
      state.votes[payload.date][payload.email] = payload.vote
    },
    idleSendVotesStatus: (state) => {
      state.sendVotesStatus = 'idle'
    },
    idleGetVotesStatus: (state) => {
      state.getVotesStatus = 'idle'
    },
    idleSendSelectedDateStatus: (state) => {
      state.sendSelectedDateStatus = 'idle'
    },
    setSelectedDate(state, { payload }: PayloadAction<string | undefined>) {
      if (!payload) {
        state.selectedDate = undefined
      } else {
        state.selectedDate = {
          date: payload,
          yes: Object.entries(state.votes[payload])
            .filter(([_, vote]) => vote === 'yes')
            .map(([email, _]) => {
              return email
            }),
          no: Object.entries(state.votes[payload])
            .filter(([_, vote]) => vote === 'no')
            .map(([email, _]) => {
              return email
            }),
          maybe: Object.entries(state.votes[payload])
            .filter(([_, vote]) => vote === 'maybe')
            .map(([email, _]) => {
              return email
            }),
        }
      }
    },
  },
  extraReducers: {
    [getVotes.pending.type]: (state, action) => {
      state.getVotesStatus = 'loading'
    },
    [getVotes.fulfilled.type]: (state, action) => {
      state.getVotesStatus = 'success'
    },
    [getVotes.rejected.type]: (state, action) => {
      state.getVotesStatus = 'error'
    },
    [sendVote.pending.type]: (state, action) => {
      state.sendVotesStatus = 'loading'
    },
    [sendVote.fulfilled.type]: (state, action) => {
      state.sendVotesStatus = 'success'
    },
    [sendVote.rejected.type]: (state, action) => {
      state.sendVotesStatus = 'error'
    },
    [sendSelectedDate.pending.type]: (state, action) => {
      state.sendSelectedDateStatus = 'loading'
    },
    [sendSelectedDate.fulfilled.type]: (state, action) => {
      state.sendSelectedDateStatus = 'success'
    },
    [sendSelectedDate.rejected.type]: (state, action) => {
      state.sendSelectedDateStatus = 'error'
    },
  },
})

export default meetingVoteSlice.reducer
export const {
  setVotes,
  setMyVote,
  idleGetVotesStatus,
  idleSendVotesStatus,
  idleSendSelectedDateStatus,
  setSelectedDate,
} = meetingVoteSlice.actions
