import React, { useEffect, useState } from 'react'
import { useTimezoneSelect, allTimezones } from 'react-timezone-select'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { Select } from 'antd'
import './time-zone-visualize.scss'
import { useTranslation } from 'react-i18next'

interface Props {
  date?: string
}

function TimeZoneVisualizer({ date }: Props) {
  const { t } = useTranslation('meetings')
  const [selectedTimezone, setSelectedTimezone] = useState<any>(
    Intl.DateTimeFormat().resolvedOptions().timeZone,
  )

  const labelStyle = 'original'
  const timezones = {
    ...allTimezones,
  }

  const { options } = useTimezoneSelect({ labelStyle, timezones })

  dayjs.extend(utc)
  dayjs.extend(timezone)

  return (
    <div className="timezone-container">
      <Select
        className="select-timezone"
        onSelect={setSelectedTimezone}
        defaultValue={'Europe/Paris'}
        options={options}
        filterOption={(inputValue, option) =>
          option
            ? option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
            : false
        }
        showSearch
      ></Select>
      {selectedTimezone && date && (
        <div className="d-flex d-flex-center d-flex-middle">
          <h4 className="nowrap">
            {t('Begin:')} {dayjs(date).tz(selectedTimezone).format('HH:mm')}
          </h4>
        </div>
      )}
    </div>
  )
}

export default TimeZoneVisualizer
