import dayjs, { OpUnitType } from 'dayjs'

export function buildCalendarUrl(currentDate: string, view: OpUnitType) {
  const min = dayjs(currentDate).startOf(view).toISOString()
  const max = dayjs(currentDate).endOf(view).add(1, 'milliseconds').toISOString()
  const queryParams = new URLSearchParams({
    minDate: min,
    maxDate: max,
    view: view,
    currentDate: currentDate,
  })

  return `/calendar?${queryParams}`
}
