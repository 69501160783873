import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, useParams } from 'react-router'
import { AppDispatch, RootState } from '../../store'
//import { setIsUnauthorized } from '../../redux/appSlice' //development paused
//import { useNavigate } from 'react-router-dom'

function RoomRequireAuth({ children }: { children: any; invitedAccepted?: boolean }) {
  //const dispatch = useDispatch<AppDispatch>()
  const username = useSelector(
    (state: RootState) => state.auth.email || state.invite.email,
  )
  const { inviteToken, roomId, page } = useParams()

  if (!username && !inviteToken) {
    const redirectPath = page
      ? `/login/${roomId}/${page}`
      : roomId
        ? `/login/${roomId}`
        : '/login'
    //dispatch(setIsUnauthorized(true)) //needed when a user attempt to access a mettings while not being logged in
    return <Navigate to={redirectPath} replace />
  } else if (!username && inviteToken) {
    return <Navigate to={`/invite/${inviteToken}`} replace />
  }

  return children
}

export default RoomRequireAuth
