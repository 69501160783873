import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import { useTranslation } from 'react-i18next'
import { AiOutlineFolderAdd } from 'react-icons/ai'
import { IoCloudUploadOutline } from 'react-icons/io5'

interface Props {
  dragged: Boolean
}

function UploadBanner({ dragged }: Props) {
  const { t } = useTranslation('drives')
  const selectedDriveFolder = useSelector(
    (state: RootState) => state.drive.selectedDriveFolder,
  )
  const targetedFolder = useSelector((state: RootState) => state.drive.targetedFolder)

  return (
    <>
      {dragged &&
        selectedDriveFolder &&
        (selectedDriveFolder.childrenFiles.length > 0 ||
          selectedDriveFolder.childrenFolders.length > 0) && (
          <div className="upload-banner-container pointer-events-none">
            <div className="upload-banner">
              <IoCloudUploadOutline size="2em" className="upload-icon" />
              <div className="upload-banner-text">
                <h3 className="mb-05rem">{t('Import files into folder:')}</h3>
                <h3 className="d-flex d-flex-center d-flex-middle g-0_2rem mb-0">
                  <AiOutlineFolderAdd size="1.5em" className="mb-0_1rem" />
                  <strong>{targetedFolder?.name}</strong>
                </h3>
              </div>
            </div>
          </div>
        )}
    </>
  )
}

export default UploadBanner
