import React from 'react'
import { Modal } from 'antd'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../../store'
import { deleteDrive, setDriveToDelete } from '../../../features/Drives/redux/drivesSlice'
import { unescape } from 'lodash'
import { limitText } from '../../../utils/Utils'

export default function DeleteDriveModal() {
  const { t } = useTranslation('drives')
  const dispatch = useDispatch<AppDispatch>()
  const driveToDelete = useSelector((state: RootState) => state.drive.driveToDelete)
  const deleteDriveStatus = useSelector(
    (state: RootState) => state.drive.deleteDriveStatus,
  )

  return (
    <Modal
      centered
      title={t('Delete drive')}
      open={!!driveToDelete}
      onOk={() => dispatch(deleteDrive())}
      okText={t('Yes', { ns: 'common' })}
      confirmLoading={deleteDriveStatus === 'loading'}
      onCancel={() => dispatch(setDriveToDelete(undefined))}
      cancelText={t('No', { ns: 'common' })}
    >
      <p>
        <Trans
          i18nKey="DELETE_DRIVE"
          shouldUnescape={true}
          ns="drives"
          values={{
            name: unescape(limitText(driveToDelete?.name)),
          }}
          components={{ bold: <strong /> }}
        />
      </p>
    </Modal>
  )
}
