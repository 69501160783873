import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../store'
import Notifier from './Notifier'
import { Popover, Tooltip, Drawer } from 'antd'
import { IoIosNotificationsOutline } from 'react-icons/io'
import { Trans, useTranslation } from 'react-i18next'
import {
  attemptDeleteAllNotifications,
  attemptFetchLiveNotifications,
  attemptMarkAllNotificationsSeen,
} from './liveWidgetSlice'
import './NotificationWidget.scss'
import { Link, useNavigate } from 'react-router-dom'
import { IoCheckmarkCircleOutline, IoTrashOutline } from 'react-icons/io5'
import { BsListColumnsReverse } from 'react-icons/bs'
import NotificationList from './NotificationList'
import useSound from 'use-sound'
import sound from './assets/notifications.mp3'
import sanitizeHtml from 'sanitize-html'
import { LOGO } from '../../assets/logos'
import { setCurrentChatId } from '../../components/ChatComponent/Chat/chatsSlice'

interface NotificationMisc {
  senderFirstName?: string
  senderLastName?: string
  text?: string
  [key: string]: any
}

function NotificationWidget() {
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()
  const jwt = useSelector((state: RootState) => state.auth.jwt)
  const email = useSelector((state: RootState) => state.auth.email)
  const unseen = useSelector((state: RootState) => state.liveNotification.unseen)
  const desktopNotificationState = useSelector(
    (state: RootState) => state.account.IsDesktopNotificationEnabled,
  )
  const notifications = useSelector(
    (state: RootState) => state.liveNotification.notifications,
  )
  const [oldUnseen, setOldUnseen] = useState(unseen)
  const notifier = useRef(new Notifier(dispatch, jwt || '', email || ''))
  const [play] = useSound(sound, {
    interrupt: true,
  })
  const [drawerVisible, setDrawerVisible] = useState(false)
  useState<NotificationMisc | null>(null)

  const toggleDrawer = () => {
    setDrawerVisible(!drawerVisible)
  }

  useEffect(() => {
    if (!jwt) {
      return
    }

    dispatch(attemptFetchLiveNotifications())
    notifier.current.connect()

    return () => {
      notifier.current.disconnect()
    }
  }, [])

  useEffect(() => {
    if (unseen != oldUnseen) {
      if ((unseen as number) > (oldUnseen as number)) {
        play()
        sendDesktopNotification()
      }
      setOldUnseen(unseen)
    }
  }, [unseen])

  function sendDesktopNotification() {
    if (desktopNotificationState) {
      let latestTimestamp = 0
      let lastReceivedNotification: NotificationMisc | undefined
      notifications.forEach((notification) => {
        const notificationDate = new Date(notification?.createdAt ?? '')
        if (notificationDate.getTime() > latestTimestamp) {
          latestTimestamp = notificationDate.getTime()
          lastReceivedNotification = notification.misc
        }
      })

      if (
        lastReceivedNotification !== undefined &&
        lastReceivedNotification.senderFirstName !== undefined
      ) {
        const notification = new Notification(
          `${lastReceivedNotification?.senderFirstName || ''} ${
            lastReceivedNotification?.senderLastName || ''
          }`,
          {
            body: sanitizeHtml(
              lastReceivedNotification?.text || 'No text detected in message',
              {
                allowedTags: [''],
              },
            ),
            icon: LOGO.logo_auxateam,
          },
        )

        notification.onclick = () => {
          dispatch(setCurrentChatId(lastReceivedNotification?.chatId))
          navigate('/messages')
        }
      }
    }
  }

  return (
    <>
      <Popover
        content={<Content />}
        trigger="click"
        color="#f6f6f6"
        placement="bottomRight"
        className="hideOnMobile"
        arrow={{ pointAtCenter: true }}
        align={{ offset: [21, 14] }}
      >
        <div className="notification_widget">
          <IoIosNotificationsOutline size="2em" />
          {unseen !== undefined && unseen > 0 && (
            <div className="notification_widget_unseen_count">{unseen}</div>
          )}
        </div>
      </Popover>
      <div className="notification_widget" onClick={toggleDrawer}>
        <IoIosNotificationsOutline size="2em" />
        {unseen !== undefined && unseen > 0 && (
          <div className="notification_widget_unseen_count">{unseen}</div>
        )}
        <Drawer
          width="40rem"
          title="Notifications"
          placement="right"
          onClose={toggleDrawer}
          open={drawerVisible}
        >
          <Content />
        </Drawer>
      </div>
    </>
  )
}

function Content() {
  const { t } = useTranslation('notification')
  const dispatch = useDispatch<AppDispatch>()
  const notifications = useSelector(
    (state: RootState) => state.liveNotification.notifications,
  )
  const total = useSelector((state: RootState) => state.liveNotification.total)

  return (
    <div className="notification_widget_content">
      {notifications.length > 0 ? (
        <>
          <div className="notification_widget_content_actions">
            <Link to="/notifications" className="notification_widget_content_action">
              <BsListColumnsReverse />
              &nbsp;
              <span className="notification_widget_content_all">
                {t('See all notifications')}
              </span>
            </Link>

            <div className="mt-0_2rem">
              <Tooltip title={t('Mark all as read')}>
                <IoCheckmarkCircleOutline
                  onClick={() => dispatch(attemptMarkAllNotificationsSeen())}
                  className="notification_widget_content_action"
                />
              </Tooltip>

              <Tooltip title={t('Delete all')}>
                <IoTrashOutline
                  onClick={() => dispatch(attemptDeleteAllNotifications())}
                  className="notification_widget_content_action"
                />
              </Tooltip>
            </div>
          </div>
          {notifications.map((notification) => (
            <NotificationList notification={notification} key={notification.id} />
          ))}
        </>
      ) : (
        <div>{t('No notification')}</div>
      )}
    </div>
  )
}

export default NotificationWidget
