import React, { useEffect, useRef, useState } from 'react'
import { Modal, Form, InputRef } from 'antd'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../store'
import {
  createFavorite,
  idleCreateFavoriteStatus,
  setAddFavoriteModalVisible,
} from './redux/applicationsSlice'
import { useAttemptsListener } from 'auxasphere-react-kit'
import { useToastContext } from '../../components/Toast/ToastContext'
import FavoriteForm from './FavoriteForm'

function AddFavoriteModal() {
  const { t } = useTranslation('applications')
  const dispatch = useDispatch<AppDispatch>()
  const inputRef = useRef<InputRef>(null)
  const [favoriteForm] = Form.useForm()
  const [iconUploaded, setIconUploaded] = useState<string | undefined>()
  const visible = useSelector(
    (state: RootState) => state.applications.addFavoriteModalVisible,
  )
  const createFavoriteStatus = useSelector(
    (state: RootState) => state.applications.createFavoriteStatus,
  )
  const { ToastOpen } = useToastContext()

  useAttemptsListener(
    [
      [
        createFavoriteStatus,
        {
          success: () => {
            ToastOpen({
              message: t('Bookmark was successfully created.'),
              type: 'success',
            })
            clear()
          },
          URL_ALREADY_EXIST: () => {
            ToastOpen({
              message: t("Bookmark's url already exists."),
              type: 'error',
            })
          },

          NAME_ALREADY_EXIST: () => {
            ToastOpen({
              message: t("Bookmark's name already exists."),
              type: 'error',
            })
          },
          unknown_error: () => {
            ToastOpen({
              message: t('Error creating bookmark.'),
              type: 'error',
            })
          },
        },
      ],
    ],
    () => dispatch(idleCreateFavoriteStatus()),
  )

  function clear() {
    favoriteForm.resetFields()
    dispatch(setAddFavoriteModalVisible(false))
    setIconUploaded(undefined)
  }

  /**
   *
   */
  function onFavoriteSubmit() {
    dispatch(
      createFavorite({
        name: favoriteForm.getFieldsValue().name,
        url: favoriteForm.getFieldsValue().url,
        icon: iconUploaded,
      }),
    )
  }

  return (
    <Modal
      centered
      open={visible}
      title={t('Add bookmark')}
      onOk={favoriteForm.submit}
      onCancel={clear}
      okText={t('Create', { ns: 'common' })}
      cancelText={t('Cancel', { ns: 'common' })}
      afterOpenChange={(open) => open && inputRef.current?.focus()}
      maskClosable={false}
      confirmLoading={createFavoriteStatus === 'loading'}
    >
      <div className="modal-content-container">
        <FavoriteForm
          form={favoriteForm}
          onSubmit={onFavoriteSubmit}
          inputFocusRef={inputRef}
          iconUploaded={iconUploaded}
          setIconUploaded={setIconUploaded}
        />
      </div>
    </Modal>
  )
}

export default AddFavoriteModal
