import React, { useEffect, useState } from 'react'
import { Breadcrumb } from 'antd'
import {
  BreadcrumbItemType,
  BreadcrumbSeparatorType,
  ItemType,
} from 'antd/es/breadcrumb/Breadcrumb'
import { AiFillHome } from 'react-icons/ai'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../../store'
import {
  DriveInterface,
  FolderInterface,
  fetchFolderById,
} from '../../../features/Drives/redux/drivesSlice'
import { BiDotsHorizontalRounded } from 'react-icons/bi'

interface PathFolder {
  find: boolean
  breadcrumb: ItemType[]
}

function DrivesBreadcrumb() {
  const dispatch = useDispatch<AppDispatch>()
  const [breadcrumbItems, setBreadcrumbItems] = useState<ItemType[]>([])
  const selectedDriveFolder = useSelector(
    (state: RootState) => state.drive.selectedDriveFolder,
  )
  const drives = useSelector((state: RootState) => state.drive.drives)

  const TAIL_BREADCRUMB_SIZE = 3

  /**
   *
   */
  useEffect(() => {
    const findPathResult = buildBreadcrumb(selectedDriveFolder, drives)
    if (findPathResult.breadcrumb.length > TAIL_BREADCRUMB_SIZE) {
      const folderNotVisible = findPathResult.breadcrumb.slice(
        0,
        findPathResult.breadcrumb.length - TAIL_BREADCRUMB_SIZE,
      )
      const folderVisible = findPathResult.breadcrumb.slice(
        findPathResult.breadcrumb.length - TAIL_BREADCRUMB_SIZE,
      )

      const breadcrumbItems = [
        {
          title: <BiDotsHorizontalRounded size="1.5em" />,
          key: 'hidden',
          menu: { items: buildBreadcrumbDropDown(folderNotVisible) },
          className: 'breadcrumb-dropdown',
        },
        ...folderVisible,
      ]

      setBreadcrumbItems(breadcrumbItems || [])
    } else {
      setBreadcrumbItems(findPathResult.breadcrumb || [])
    }
  }, [selectedDriveFolder])

  function buildBreadcrumbDropDown(
    items: Partial<BreadcrumbItemType & BreadcrumbSeparatorType>[],
  ) {
    return items.map((item) => {
      return {
        key: item.key,
        label: item.title,
        className: item.className,
        onClick: item.onClick,
      }
    })
  }

  /**
   * Build the breadcrumb to a specific folder
   * @param selectedDriveFolder the specific folder
   * @param drivesFolders all folders
   * @returns
   */
  function buildBreadcrumb(
    selectedDriveFolder: FolderInterface | undefined,
    drivesFolders: DriveInterface[] | undefined,
  ) {
    const getMenuItems = (folder: FolderInterface) => {
      return (folder?.childrenFolders || [])
        .filter((subFolder) => subFolder)
        .map((subFolder) => ({
          label: subFolder.name,
          key: subFolder.id,
          className: 'wrap-dropdown',
          onClick: async () => {
            await dispatch(
              fetchFolderById({
                id: subFolder.id,
                driveId: subFolder.driveId,
                selected: true,
              }),
            )
          },
        }))
    }

    const buildBreadcrumbRec = (
      folder: FolderInterface | undefined,
      breadcrumb: ItemType[],
    ): PathFolder => {
      if (!folder) {
        return { find: false, breadcrumb }
      }

      const breadcrumbCurrentFolder: ItemType = {
        title: <a className={breadcrumb.length == 0 ? 'uppercase' : ''}>{folder.name}</a>,
        key: folder.id,
      }

      if (folder.id === selectedDriveFolder?.id) {
        const menuItems = getMenuItems(selectedDriveFolder)

        if (menuItems.length > 0) {
          breadcrumbCurrentFolder.menu = { items: menuItems }
        }

        return { find: true, breadcrumb: breadcrumb.concat(breadcrumbCurrentFolder) }
      } else {
        const folderHasChildren =
          folder.childrenFolders && folder.childrenFolders.length > 0
        if (folderHasChildren) {
          breadcrumbCurrentFolder.onClick = async () =>
            await dispatch(
              fetchFolderById({
                id: folder.id,
                driveId: folder.driveId,
                selected: true,
              }),
            )
        }

        const currentBreadcrumb = breadcrumb.concat(breadcrumbCurrentFolder)
        let bestResultSubFolder = { find: false, breadcrumb: currentBreadcrumb }

        if (folderHasChildren) {
          for (const subFolder of folder.childrenFolders) {
            const subResult = buildBreadcrumbRec(subFolder, currentBreadcrumb)
            if (subResult.find) {
              bestResultSubFolder = subResult
            }
          }
        }

        return bestResultSubFolder
      }
    }

    const rootFolder = drivesFolders?.find(
      (drive) => drive.id === selectedDriveFolder?.driveId,
    )?.rootFolder

    return buildBreadcrumbRec(rootFolder, [])
  }

  return (
    <div className="Drives-breadcrumb-container">
      <AiFillHome size="1.1rem" />
      <Breadcrumb items={breadcrumbItems} separator="/" />
    </div>
  )
}

export default DrivesBreadcrumb
