import React, { useEffect, ReactNode } from 'react'
import { MEDIA_DEVICE_STATUS } from '../redux/meSlice'
import { useTranslation } from 'react-i18next'
import { Alert, Spin, Tooltip } from 'antd'
import classnames from 'classnames'

interface Props {
  deviceStatus: MEDIA_DEVICE_STATUS
  off: ReactNode
  on: ReactNode
  messages: {
    ASKING_PERMISSIONS: string
    FAILED: string
    NOT_ALLOWED: string
    NOT_FOUND: string
    STOPPED: string
    STARTED: string
  }
  onClick: () => void
}

export default function AntechamberControls({
  deviceStatus,
  off,
  on,
  messages,
  onClick,
}: Props) {
  const { t, i18n } = useTranslation('common')

  return (
    <div
      className={classnames(
        'media-control-container',
        { 'control-off': deviceStatus === 'STOPPED' },
        { 'control-disabled': deviceStatus === 'ASKING_PERMISSIONS' },
      )}
      onClick={onClick}
    >
      <Tooltip
        title={
          <>
            {deviceStatus === 'ASKING_PERMISSIONS' && messages.ASKING_PERMISSIONS}
            {deviceStatus === 'FAILED' && messages.FAILED}
            {deviceStatus === 'NOT_ALLOWED' && (
              <div>
                {messages.NOT_ALLOWED}
                <a href="/faq/devices" target="_blank">
                  {t('Please visit our FAQ.', { ns: 'common' })}
                </a>
              </div>
            )}
            {deviceStatus === 'NOT_FOUND' && messages.NOT_FOUND}
            {deviceStatus === 'STARTED' && messages.STARTED}
            {deviceStatus === 'STOPPED' && messages.STOPPED}
          </>
        }
      >
        {deviceStatus === 'ASKING_PERMISSIONS' && <Spin />}
        {deviceStatus === 'STARTED' && on}
        {['UNKNOWN', 'FAILED', 'NOT_ALLOWED', 'NOT_FOUND', 'STOPPED'].includes(
          deviceStatus,
        ) && off}
      </Tooltip>
    </div>
  )
}
