import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { AiOutlineCloudUpload } from 'react-icons/ai'
import { MdOutlineCloudOff } from 'react-icons/md'
import { useDriveLoader } from '../../utils/hooks/UseDriveLoader'

interface Props {
  dragged: Boolean
}

/**
 *
 */
function UploadArea({ dragged }: Props) {
  const { t } = useTranslation('drives')

  const { fileRights, selectedDriveFolder } = useDriveLoader()

  const droppedFilesLoading = useSelector(
    (state: RootState) => state.drive.droppedFilesLoading,
  )

  const emptySelectedFolder =
    selectedDriveFolder &&
    selectedDriveFolder.childrenFolders.length === 0 &&
    selectedDriveFolder.childrenFiles.length === 0
  return (
    <>
      {emptySelectedFolder && !fileRights && (
        <div className="DriveListContent-empty-container">
          <MdOutlineCloudOff size="3.5em" />
          <div className="empty-text">
            <h2>
              {t(
                'The folder is empty. You do not have the necessary rights to import files.',
              )}
            </h2>
          </div>
        </div>
      )}

      {emptySelectedFolder && fileRights && (
        <div
          className={classNames({
            'DriveListContent-empty-container': true,
            'DriveListContent-empty-container-dragged': droppedFilesLoading,
            'DriveListContent-empty-container-drag-over': dragged,
          })}
        >
          <AiOutlineCloudUpload size="4em" />
          <div className="empty-text">
            <h2>{t('Drag and drop files to import them into the folder')}</h2>
            <h2 className="name">
              <strong>{selectedDriveFolder.name}</strong>
            </h2>
          </div>
        </div>
      )}
    </>
  )
}

export default UploadArea
