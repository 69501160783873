import React from 'react'
import { Tooltip } from 'antd'
import { BsCameraVideo, BsCameraVideoOff } from 'react-icons/bs'

interface Props {
  active: boolean
  toggle: () => void
}

function CallControlsWebcam({ active, toggle }: Props) {
  return (
    <Tooltip title="Webcam">
      <div className="call-control" onClick={toggle}>
        {active ? (
          <BsCameraVideo size="2rem" color="#ffffff" />
        ) : (
          <BsCameraVideoOff size="2rem" color="#ffffff" />
        )}
      </div>
    </Tooltip>
  )
}

export default CallControlsWebcam
