import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useToastContext } from '../../components/Toast/ToastContext'
import { setDesktopNotification } from '../../features/account/redux/accountSlice'

export function useDesktopNotificationPermission() {
  const dispatch = useDispatch()
  const { ToastOpen } = useToastContext()
  const { t } = useTranslation('account')

  const askPermission = () => {
    dispatch(setDesktopNotification({ status: undefined }))
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        dispatch(setDesktopNotification({ status: true }))
      } else if (permission === 'denied') {
        ToastOpen({
          message:
            t(`Desktop notification disabled for`) +
            ` ${window.location.origin}. ` +
            t(`To reactivate them, go to your navigator settings`),
          type: 'error',
        })
        dispatch(setDesktopNotification({ status: false }))
      }
    })
  }

  return { askPermission }
}
