import React, { useContext, useRef, useState } from 'react'
import { Button, Form, Input, InputRef, Modal, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import { WebSocketConnectionContext } from '../WebsocketConnection/WebSocketConnectionContext'
import './CreateChat.scss'
import ModifyChatPeers from './ModifyChatPeers'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store'
import {
  SearchGroupDTO,
  SearchUserDTO,
} from '../../../features/Organisation/OrganisationService'
import { IoAddCircle } from 'react-icons/io5'

interface Props {
  squadId: string
}

function CreateChat({ squadId }: Props) {
  const { t } = useTranslation('chat')
  const [form] = Form.useForm()
  const ws = useContext(WebSocketConnectionContext)
  const [createChatOpen, setCreateChatOpen] = useState(false)
  const inputTitleRef = useRef<InputRef>(null)

  const auth = useSelector((state: RootState) => state.auth)
  const defaultPeersToAdd: SearchUserDTO[] = [
    {
      email: auth.email || '',
      firstName: auth.firstName || '',
      lastName: auth.lastName || '',
      organizationRole: auth.organizationRole,
    },
  ]
  const [peersToAdd, setPeersToAdd] = useState<SearchUserDTO[]>(defaultPeersToAdd)
  const [groupsToAdd, setGroupsToAdd] = useState<SearchGroupDTO[]>([])

  function onSubmit({ title }: { title: string }) {
    ws?.sendCreateChat(
      title,
      squadId,
      peersToAdd.map((p) => p.email),
      groupsToAdd.map((g) => g.id),
    )
    form.setFieldsValue({
      title: '',
    })
    setCreateChatOpen(false)
    setPeersToAdd(defaultPeersToAdd)
  }

  function onOpen(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault()
    e.stopPropagation()
    setCreateChatOpen(true)
  }

  function onCancel() {
    setCreateChatOpen(false)
    form.setFieldsValue({
      title: '',
    })
    setPeersToAdd(defaultPeersToAdd)
  }

  return (
    <>
      {auth.email && auth.firstName && auth.lastName && (
        <>
          <div className="menu-action-btn-container">
            <Button
              className="menu-action-btn"
              type="primary"
              icon={<IoAddCircle size="1.5rem" color="white" />}
              onClick={onOpen}
            >
              {t('New', { ns: 'common' })}
            </Button>
          </div>

          <Modal
            title={t('Create a discussion channel')}
            open={createChatOpen}
            onCancel={onCancel}
            onOk={() => form.submit()}
            okText={t('Create', { ns: 'common' })}
            centered
            afterOpenChange={(open) => open && inputTitleRef.current?.focus()}
            width="50rem"
          >
            <div className="chat-modal">
              <Form form={form} layout="vertical" onFinish={onSubmit}>
                <Form.Item
                  name="title"
                  label={t('Discussion channel name')}
                  className="chat_create_title_input"
                  rules={[
                    {
                      required: true,
                      message: t('Please input a discussion channel name'),
                    },
                  ]}
                >
                  <Input ref={inputTitleRef} />
                </Form.Item>
                <label>
                  {t('Search and choose users or groups to add', { ns: 'drives' })}
                </label>
              </Form>
              <ModifyChatPeers
                peers={peersToAdd}
                groups={groupsToAdd}
                onUsersUpdate={setPeersToAdd}
                onGroupsUpdate={setGroupsToAdd}
              />
            </div>
          </Modal>
        </>
      )}
    </>
  )
}

export default CreateChat
