import React, { useState } from 'react'
import { Popover } from 'antd'
import { CgMenuGridO } from 'react-icons/cg'
import './HeaderMenuNavigation.scss'
import HeaderMenuApplications from './HeaderMenuApplications'
import HeaderMenuManagment from './HeaderMenuManagment'

function HeaderMenuNavigation() {
  const [open, setOpen] = useState(false)

  const handleTogglePopover = () => {
    setOpen(!open)
  }

  const content = (
    <div>
      <HeaderMenuApplications />
      <HeaderMenuManagment handleTogglePopover={handleTogglePopover} />
    </div>
  )

  return (
    <Popover
      content={content}
      trigger="click"
      color="#f6f6f6"
      placement="bottomRight"
      open={open}
      onOpenChange={(visible) => setOpen(visible)}
      arrow={{ pointAtCenter: true }}
      align={{ offset: [21, 7] }}
    >
      <div className="header-action-rounded-icon hideOnMobile">
        <CgMenuGridO
          size="2.7rem"
          className="cursor-pointer"
          style={{ marginLeft: 2, marginTop: 2 }}
        />
      </div>
    </Popover>
  )
}

export default HeaderMenuNavigation
