import React, { ReactNode, useEffect, useRef, useState } from 'react'
import useEventListener from './useEventListener'

interface Props {
  children: ReactNode
  onOutsideClick: () => void
  className?: string
}
export default function OutsideClickHandler({
  onOutsideClick,
  children,
  className = '',
}: Props) {
  const wrapperRef = useRef<HTMLDivElement>(null)
  useEventListener('click', (e: any) => {
    if (wrapperRef.current && !wrapperRef.current.contains(e.target as Node)) {
      onOutsideClick()
    }
  })
  return (
    <div ref={wrapperRef} className={className}>
      {children}
    </div>
  )
}
