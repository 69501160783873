import React from 'react'
import { useTranslation } from 'react-i18next'
import { AiOutlineFile } from 'react-icons/ai'
import { renderIcon } from '../../../utils/Utils'
import { ViewFile } from '../../Viewer/ViewerSlice'

interface Props {
  content: string
}

function ChatMessageFileInList({ content }: Props) {
  const { t } = useTranslation('chat')

  try {
    const files = JSON.parse(content)
    const file: ViewFile = files[0]
    const { mimeType, name } = file

    return mimeType && name ? (
      <div className="chat_message_file chat_item_last_message">
        <div className="d-flex d-flex-middle g-0_2rem">
          <img src={renderIcon(mimeType, name)} className="file-icon" />
          {name}
        </div>
      </div>
    ) : (
      <></>
    )
  } catch (e) {}

  return <>{t('Cannot decrypt this file.')}</>
}

export default ChatMessageFileInList
