import React, { useState, forwardRef, useImperativeHandle, Ref, ReactNode } from 'react'
import { Button, Modal, ModalFuncProps, ModalProps } from 'antd'
import { useTranslation } from 'react-i18next'
import useEventListener from '../../utils/hooks/useEventListener'
import './Modal.scss'

// TODO? modify this component. see example: https://codesandbox.io/s/h3de0p?file=/demo.tsx:192-460
// const { confirm } = Modal // then remove enter from event listener

interface Props extends Omit<ModalProps, 'onOk' | 'onCancel'> {
  cancelText?: string
  okText?: string
  onOk?: () => void
  onCancel?: () => void
  question?: string
  children?: ReactNode
}

export interface ModalConfirmationInterface {
  openModal: () => void //TODO: try pass confirmation value and return it onOk
  isOpen: () => boolean
  close: () => void
}
const ModalConfirmation = forwardRef(
  (props: Props, ref: Ref<ModalConfirmationInterface>) => {
    useImperativeHandle(ref, () => ({
      openModal: () => setModalVisible(true),
      close: () => setModalVisible(false),
      isOpen: () => {
        return modalVisible
      },
    }))
    const { onOk, onCancel, question, children, cancelText, okText } = props
    const [modalVisible, setModalVisible] = useState<boolean>(false)
    const { t } = useTranslation('modal')

    function ok() {
      setModalVisible(false)
      onOk && onOk()
    }

    function cancel() {
      setModalVisible(false)
      onCancel && onCancel()
    }

    useEventListener('keydown', (e: any) => {
      if (e.key === 'Enter') modalVisible && ok()
      if (e.key === 'Esc') modalVisible && cancel()
    })

    return (
      <Modal
        centered
        maskClosable={false}
        closable={false}
        title={t('Confirmation', { ns: 'modal' })}
        open={modalVisible}
        {...props}
        onOk={ok}
        okText={t(okText || 'footer_Confirm', { ns: 'modal' })}
        onCancel={cancel}
        cancelText={t(cancelText || 'footer_Cancel', { ns: 'modal' })}
        cancelButtonProps={{ style: { display: onCancel ? 'block' : 'none' } }}
      >
        {question && <p className="question">{t(question, { ns: 'modal' })}</p>}
        {children && <div className="children">{children}</div>}
      </Modal>
    )
  },
)

ModalConfirmation.displayName = 'ModalConfirmation'
export default ModalConfirmation
