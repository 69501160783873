import React from 'react'
import { t } from 'i18next'
import dayjs from 'dayjs'
import { localFormatDate } from '../../../utils/Utils'
import { useTranslation } from 'react-i18next'

interface Props {
  createdAt: number
  updatedAt: number | null
}

function DriveDateTooltip({ createdAt, updatedAt }: Props) {
  const { t } = useTranslation('drives')

  return (
    <>
      <div>
        <span>
          {t('Creation date', { ns: 'common' }) +
            ' : ' +
            localFormatDate(dayjs(createdAt))}
        </span>
      </div>
      <div>
        <span>
          {t('Last modified') +
            ' : ' +
            localFormatDate(dayjs(updatedAt ? updatedAt : createdAt))}
        </span>
      </div>
    </>
  )
}

export default DriveDateTooltip
