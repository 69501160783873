import { Menu } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { AccountItem } from './Account'

interface Props {
  accountItem: AccountItem
  setAccountItem: (accountItem: AccountItem) => void
}

function AccountMenu({ accountItem, setAccountItem }: Props) {
  const { t } = useTranslation('account')
  const items: any = [
    {
      key: 'general',
      label: t('General'),
      onClick: () => setAccountItem(AccountItem.General),
    },
    {
      key: 'password',
      label: t('Password'),
      onClick: () => setAccountItem(AccountItem.Password),
    },
    {
      key: 'notifications',
      label: t('Notifications'),
      onClick: () => setAccountItem(AccountItem.Notifications),
    },
  ]

  return (
    <div className="account-menu">
      <Menu
        className="menu"
        mode="inline"
        items={items}
        selectedKeys={
          (accountItem === AccountItem.General && ['general']) ||
          (accountItem === AccountItem.Password && ['password']) ||
          (accountItem === AccountItem.Notifications && ['notifications']) ||
          []
        }
      />
    </div>
  )
}

export default AccountMenu
