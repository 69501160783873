import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

export default function useStrongPassword() {
  const { t } = useTranslation('reinitpassword')

  const pwdRules = [
    {
      message: t('8 characters'),
      regex: /^.{8,}$/,
    },
    {
      message: t('1 lowercase letter'),
      regex: /^(?=.*[a-z])/,
    },
    {
      message: t('1 uppercase letter'),
      regex: /^(?=.*[A-Z])/,
    },
    {
      message: t('1 special character'),
      regex: /^(?=.*[@$!%*#?&.])/,
    },
    {
      message: t('1 digit'),
      regex: /^(?=.*\d)/,
    },
  ]

  function constructErrorMessage(messages: string[]): string {
    return `${t('Password should contain at least: ')} ${messages.join('; ')}`
  }

  function verifyProvidedPassword(value: any) {
    const failedRequirements: string[] = []
    if (value) {
      pwdRules.forEach((rule) => {
        if (!rule.regex.test(value)) {
          failedRequirements.push(rule.message)
        }
      })

      if (failedRequirements.length > 0) {
        return constructErrorMessage(failedRequirements)
      }
    }
  }

  return {
    verifyProvidedPassword,
  }
}
