import { Modal } from 'antd'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

interface Props {
  openSovereignty: boolean
  setOpenSovereignty: (open: boolean) => void
}

function DigitalSovereigntyModal({ openSovereignty, setOpenSovereignty }: Props) {
  const { t } = useTranslation('layout')

  return (
    <Modal
      title={t('Digital sovereignty')}
      open={openSovereignty}
      onCancel={() => setOpenSovereignty(false)}
      cancelText={t('Close', { ns: 'common' })}
      centered
      okButtonProps={{ style: { display: 'none' } }}
      className="large-modal"
    >
      <p>
        <Trans ns="layout" i18nKey="DIGITAL_SOVEREIGNTY_1" />
      </p>
      <p>
        <Trans ns="layout" i18nKey="DIGITAL_SOVEREIGNTY_2" />
      </p>
      <p>
        <Trans ns="layout" i18nKey="DIGITAL_SOVEREIGNTY_3" />
      </p>
      <p>
        <Trans ns="layout" i18nKey="DIGITAL_SOVEREIGNTY_4" />
      </p>
      <p>
        <Trans ns="layout" i18nKey="DIGITAL_SOVEREIGNTY_5" />
      </p>
      <p>
        <Trans ns="layout" i18nKey="DIGITAL_SOVEREIGNTY_6" />
      </p>
    </Modal>
  )
}

export default DigitalSovereigntyModal
