import React, { useRef, useState } from 'react'
import { Button, Dropdown, Menu, Modal } from 'antd'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { RiUserLine, RiUserAddLine } from 'react-icons/ri'
import { MdOutlineGroups2 } from 'react-icons/md'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import { TbUsersPlus } from 'react-icons/tb'
import classNames from 'classnames'
import { IoAddCircle } from 'react-icons/io5'
import CreateGroupModal, { CreateGroupModalInterface } from '../Groups/CreateGroupModal'
import AddUserModal from '../Groups/AddUserModal'
import { OrganizationRole } from './OrganizationInterface'

function OrganisationMenu() {
  const navigate = useNavigate()
  const { t } = useTranslation('organisation')
  const isAdmin = useSelector(
    (state: RootState) => state.auth.organizationRole === OrganizationRole.ADMIN,
  )
  const [openGetMoreLicencesModal, setOpenGetMoreLicencesModal] = useState(false)
  const selectOrganization = (state: RootState) => state.organisation.organisation
  const organization = useSelector(selectOrganization)
  const createGroupModalRef = useRef<CreateGroupModalInterface>(null)
  const [addParticipantModalOpen, setAddParticipantModalOpen] = useState(false)
  const [attachParticipantModalOpen, setAttachParticipantModalOpen] = useState(false)

  const remainingLicences =
    organization?.licences && organization?.licencesTaken
      ? organization.licences - organization.licencesTaken
      : 0

  const items_menu: any = [
    {
      key: 'organization',
      label: t('Members', { ns: 'common' }),
      icon: <RiUserLine />,
      onClick: () => navigate('/organization'),
    },
    {
      key: 'groups',
      label: t('Groups', { ns: 'common' }),
      icon: <MdOutlineGroups2 />,
      onClick: () => navigate('/groups'),
    },
  ]

  const items: any = [
    {
      key: 'create_organization',
      label: t('Add user'),
      icon: <RiUserAddLine />,
      onClick: () => setAddParticipantModalOpen(true),
    },
    {
      key: 'Create_group',
      label: t('Create a group', { ns: 'common' }),
      icon: <MdOutlineGroups2 />,
      onClick: () => createGroupModalRef.current?.open(),
    },
  ]

  return (
    <>
      {isAdmin && (
        <div className="menu-action-btn-container">
          <Dropdown menu={{ items }} trigger={['click']}>
            <Button
              className="menu-action-btn"
              type="primary"
              icon={<IoAddCircle size="1.5rem" color="white" />}
            >
              {t('New', { ns: 'common' })}
            </Button>
          </Dropdown>
        </div>
      )}
      <div className="organisation-menu">
        <Menu
          className="menu"
          mode="inline"
          selectedKeys={
            (location.pathname === '/organization' && ['organization']) ||
            (location.pathname === '/groups' && ['groups']) ||
            []
          }
          items={items_menu}
        />
        {isAdmin && (
          <div className="license">
            <TbUsersPlus size="2rem" />
            <div className="d-flex d-flex-column">
              <span>
                <span
                  className={classNames('license-number', {
                    'license-number--red': (remainingLicences as number) <= 5,
                  })}
                >
                  {organization?.licencesTaken} / {organization?.licences}
                </span>{' '}
                {t('Licences used')}
              </span>
              <a href="#" onClick={() => setOpenGetMoreLicencesModal(true)}>
                {t('Get more licences')}
              </a>
              {openGetMoreLicencesModal && (
                <Modal
                  centered
                  title={t('Get more licences')}
                  open={openGetMoreLicencesModal}
                  onCancel={() => setOpenGetMoreLicencesModal(false)}
                  footer={
                    <Button onClick={() => setOpenGetMoreLicencesModal(false)}>
                      {t('Close', { ns: 'common' })}
                    </Button>
                  }
                >
                  <p>{t('For more licences, please contact sales support.')}</p>
                </Modal>
              )}
            </div>
          </div>
        )}
      </div>
      {isAdmin && (
        <>
          <CreateGroupModal ref={createGroupModalRef} />
          <AddUserModal
            addParticipantModalOpen={addParticipantModalOpen}
            setAddParticipantModalOpen={setAddParticipantModalOpen}
            attachParticipantModalOpen={attachParticipantModalOpen}
            setAttachParticipantModalOpen={setAttachParticipantModalOpen}
          />
        </>
      )}
    </>
  )
}

export default OrganisationMenu
