import React from 'react'
import { Pagination } from 'antd'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import './DrivesPagination.scss'

interface Props {
  currentPage: number
  itemsPerPage: number
  setCurrentPage: (page: number) => void
  setItemsPerPage: (itemsPerPage: number) => void
}

function DrivesPagination({
  currentPage,
  itemsPerPage,
  setCurrentPage,
  setItemsPerPage,
}: Props) {
  const { t } = useTranslation('drives')
  const selectedDriveFolder = useSelector(
    (state: RootState) => state.drive.selectedDriveFolder,
  )

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
  }

  return (
    <>
      {selectedDriveFolder &&
        [...selectedDriveFolder.childrenFolders, ...selectedDriveFolder.childrenFiles]
          .length > 10 && (
          <div className="DrivesPagination">
            <Pagination
              current={currentPage}
              total={
                selectedDriveFolder.childrenFolders.length +
                selectedDriveFolder.childrenFiles.length
              }
              pageSize={itemsPerPage}
              onChange={handlePageChange}
              showSizeChanger
              onShowSizeChange={(current, size) => setItemsPerPage(size)}
              showTotal={(total, range) => (
                <Trans
                  ns="common"
                  i18nKey="TABLE_PAGINATION"
                  values={{
                    range0: range[0],
                    range1: range[1],
                    total: total,
                  }}
                />
              )}
            />
          </div>
        )}
    </>
  )
}

export default DrivesPagination
