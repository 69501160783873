import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FetchStatus } from '../../../utils/FetchStatus'

export interface FavoriteInterface {
  id: string
  name: string
  icon?: string
  url: string
}
interface State {
  createFavoriteStatus: FetchStatus
  updateFavoriteStatus: FetchStatus
  deleteFavoriteStatus: FetchStatus
  fetchFavoritesStatus: FetchStatus
  addFavoriteModalVisible: boolean
  favorites?: FavoriteInterface[]
  favoriteToModify?: FavoriteInterface
}

export const initialState: State = {
  updateFavoriteStatus: 'idle',
  deleteFavoriteStatus: 'idle',
  createFavoriteStatus: 'idle',
  fetchFavoritesStatus: 'idle',
  addFavoriteModalVisible: false,
}

export const createFavorite = createAsyncThunk(
  'applications/createFavoriteStatus',
  async (
    payload: {
      name: string
      url: string
      icon?: string
    },
    { getState },
  ) => {
    const { auth } = getState() as { auth: { jwt: string } }

    const response = await fetch(process.env.REACT_APP_BASE_FAVORITE_URL + '/create', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${auth.jwt}`,
      },
      body: JSON.stringify(payload),
    })

    const responseBody = await response.json()

    if (!response.ok) {
      throw new Error(responseBody.error || 'unknown_error')
    }

    return {
      favorite: responseBody,
    }
  },
)

export const updateFavorite = createAsyncThunk(
  'applications/updateFavoriteStatus',
  async (
    payload: {
      id: string
      name: string
      url: string
      icon?: string
    },
    { getState },
  ) => {
    const { auth } = getState() as { auth: { jwt: string } }

    const response = await fetch(process.env.REACT_APP_BASE_FAVORITE_URL + '/modify', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${auth.jwt}`,
      },
      body: JSON.stringify(payload),
    })

    const responseBody = await response.json()

    if (!response.ok) {
      throw new Error(responseBody.error || 'unknown_error')
    }

    return {
      favorite: responseBody,
    }
  },
)

export const deleteFavorite = createAsyncThunk(
  'applications/deleteFavoriteStatus',
  async (
    payload: {
      id: string
    },
    { getState },
  ) => {
    const { auth } = getState() as { auth: { jwt: string } }

    const response = await fetch(
      `${process.env.REACT_APP_BASE_FAVORITE_URL}/${payload.id}`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${auth.jwt}`,
        },
      },
    )

    if (!response.ok) {
      const responseBody = await response.json()
      throw new Error(responseBody.message || 'unknown_error')
    }

    return {
      id: payload.id,
    }
  },
)

export const fetchFavorites = createAsyncThunk(
  'applications/fetchFavoritesStatus',
  async (payload, { getState }) => {
    const { auth } = getState() as { auth: { jwt: string } }

    const response = await fetch(process.env.REACT_APP_BASE_FAVORITE_URL + '/list', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${auth.jwt}`,
      },
    })

    const responseBody = await response.json()

    if (!response.ok) {
      throw new Error(responseBody.message || 'unknown_error')
    }

    return {
      favorites: responseBody,
    }
  },
)

export const applicationsSlice = createSlice({
  name: 'applications',
  initialState,
  reducers: {
    idleCreateFavoriteStatus: (state) => {
      state.createFavoriteStatus = 'idle'
    },
    idleDeleteFavoriteStatus: (state) => {
      state.deleteFavoriteStatus = 'idle'
    },
    idleUpdateFavoriteStatus: (state) => {
      state.updateFavoriteStatus = 'idle'
    },
    setAddFavoriteModalVisible: (state, { payload }: PayloadAction<boolean>) => {
      state.addFavoriteModalVisible = payload
    },
    setFavoriteToModify: (state, { payload }: PayloadAction<FavoriteInterface>) => {
      state.favoriteToModify = payload
    },
    idleFavoriteToModify: (state) => {
      state.favoriteToModify = undefined
    },
  },
  extraReducers: {
    [createFavorite.pending.type]: (state) => {
      state.createFavoriteStatus = 'loading'
    },
    [createFavorite.fulfilled.type]: (
      state,
      action: PayloadAction<{ favorite: FavoriteInterface }>,
    ) => {
      state.createFavoriteStatus = 'success'
      state.favorites = [...(state.favorites ?? []), action.payload.favorite].sort(
        (a, b) => a.name.localeCompare(b.name),
      )
    },
    [createFavorite.rejected.type]: (state, action) => {
      const error = action.error
      state.createFavoriteStatus = error.message ?? 'unknown_error'
    },
    [updateFavorite.pending.type]: (state) => {
      state.updateFavoriteStatus = 'loading'
    },
    [updateFavorite.fulfilled.type]: (
      state,
      action: PayloadAction<{ favorite: FavoriteInterface }>,
    ) => {
      state.updateFavoriteStatus = 'success'
      state.favorites = state.favorites
        ?.map((favorite) =>
          favorite.id === action.payload.favorite.id ? action.payload.favorite : favorite,
        )
        .sort((a, b) => a.name.localeCompare(b.name)) ?? [action.payload.favorite]
    },
    [updateFavorite.rejected.type]: (state, action) => {
      const error = action.error
      state.updateFavoriteStatus = error.message ?? 'unknown_error'
    },
    [deleteFavorite.pending.type]: (state) => {
      state.deleteFavoriteStatus = 'loading'
    },
    [deleteFavorite.fulfilled.type]: (state, action: PayloadAction<{ id: string }>) => {
      state.deleteFavoriteStatus = 'success'
      state.favorites = (state.favorites ?? [])
        .filter((favorite) => favorite.id !== action.payload.id)
        .sort((a, b) => a.name.localeCompare(b.name))
    },
    [deleteFavorite.rejected.type]: (state, action) => {
      const error = action.error
      state.deleteFavoriteStatus = error.message ?? 'unknown_error'
    },
    [fetchFavorites.pending.type]: (state) => {
      state.fetchFavoritesStatus = 'loading'
    },
    [fetchFavorites.fulfilled.type]: (
      state,
      action: PayloadAction<{ favorites: FavoriteInterface[] }>,
    ) => {
      state.fetchFavoritesStatus = 'success'
      state.favorites = action.payload.favorites
    },
    [fetchFavorites.rejected.type]: (state, action) => {
      const error = action.error
      state.fetchFavoritesStatus =
        error.name === 'ApiError' ? error.message : 'unknown_error'
    },
  },
})

export const {
  idleCreateFavoriteStatus,
  idleDeleteFavoriteStatus,
  idleUpdateFavoriteStatus,
  setAddFavoriteModalVisible,
  setFavoriteToModify,
  idleFavoriteToModify,
} = applicationsSlice.actions

export default applicationsSlice.reducer
