import React, { forwardRef, useImperativeHandle, useState } from 'react'
import classnames from 'classnames'
import './VolumeLevel.scss'

export interface VolumeLevelInterface {
  setVolume: (value: number) => void
}

const VolumeLevel = forwardRef<VolumeLevelInterface>(({}, ref) => {
  useImperativeHandle(ref, () => ({
    setVolume: (dBs: number) => {
      // The exact formula to convert from dBs (-100..0) to linear (0..1) is:
      //   Math.pow(10, dBs / 20)
      // However it does not produce a visually useful output, so let exagerate
      // it a bit. Also, let convert it from 0..1 to 0..10 and avoid value 1 to
      // minimize component renderings.
      let newAudioVolume = Math.round(Math.pow(10, dBs / 85) * 10)

      if (newAudioVolume === 1) {
        newAudioVolume = 0
      }

      if (newAudioVolume !== volume) setVolumeValue(newAudioVolume)
    },
  }))
  const [volume, setVolumeValue] = useState<number>()

  return (
    <>
      <div className="volume-container">
        <div className={classnames('bar', `level${volume}`)} />
      </div>
    </>
  )
})

VolumeLevel.displayName = 'VolumeLevel'
export default VolumeLevel
