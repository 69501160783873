import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store'
import { Button, Card, Col, List, Row } from 'antd'
import './ModifyChatPeers.scss'
import {
  SearchGroupDTO,
  SearchUserDTO,
} from '../../../features/Organisation/OrganisationService'
import UserAndGroupAutocomplete from '../../UserAndGroupAutocomplete/UserAndGroupAutocomplete'
import { useTranslation } from 'react-i18next'
import { IoTrashOutline } from 'react-icons/io5'
import ChatUsers from './ChatUsers'
import ChatGroups from './ChatGroups'

interface Props {
  peers: SearchUserDTO[]
  groups: SearchGroupDTO[]
  onUsersUpdate: (peers: SearchUserDTO[]) => void
  onGroupsUpdate: (peers: SearchGroupDTO[]) => void
}

function ModifyChatPeers({ peers, groups, onUsersUpdate, onGroupsUpdate }: Props) {
  const { t } = useTranslation('chat')
  const jwt = useSelector((state: RootState) => state.auth.jwt)
  const email = useSelector((state: RootState) => state.auth.email)

  const organisationId = useSelector(
    (state: RootState) => state.organisation.organisation?.id,
  )

  function addPeer(peer: SearchUserDTO) {
    onUsersUpdate([...peers, peer])
  }

  function deletePeer(email: string) {
    onUsersUpdate(peers.filter((p) => p.email !== email))
  }

  function addGroup(group: SearchGroupDTO) {
    onGroupsUpdate([...groups, group])
  }

  function deleteGroup(id: string) {
    onGroupsUpdate(groups.filter((g) => g.id !== id))
  }

  return (
    <>
      {jwt && email && organisationId && (
        <UserAndGroupAutocomplete
          jwt={jwt}
          email={email}
          organisationId={organisationId}
          peers={peers}
          groups={groups}
          onUserSelect={addPeer}
          onGroupSelect={addGroup}
        />
      )}
      <div className="participants-list">
        <Card title={t('Users', { ns: 'layout' })}>
          <ChatUsers peers={peers} deletePeer={deletePeer} />
        </Card>
        <Card title={t('Groups', { ns: 'common' })}>
          <ChatGroups groups={groups} deleteGroup={deleteGroup} />
        </Card>
      </div>
    </>
  )
}

export default ModifyChatPeers
