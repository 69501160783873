import React, { useEffect } from 'react'
import { Col, Input, Form, Row, Select, Button } from 'antd'
import { Option } from 'antd/es/mentions'
import { useTranslation } from 'react-i18next'
import useStrongPassword from './useStrongPassword'
import './Account.scss'
import '../ReinitPassword/ReinitPassword.scss'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../store'

function AccountPassword() {
  const dispatch = useDispatch<AppDispatch>()
  const { t } = useTranslation(['reinitpassword', 'account'])
  const auth = useSelector((state: RootState) => state.auth)
  const { verifyProvidedPassword } = useStrongPassword()
  const [form] = Form.useForm()

  useEffect(() => {
    form.setFieldsValue({
      licence: 'Enterprise',
    })
  }, [auth.jwt])

  return (
    <Row gutter={[24, 8]}>
      <Col span={24}>
        <div className="password-requirements">
          <p>
            {t(
              'Your password must contain at least:\n8 characters; 1 lowercase letter; 1 uppercase letter; 1 digit; 1 special character',
            )}
          </p>
        </div>
        <Form.Item
          name="oldPassword"
          label={t('Current password')}
          dependencies={['newPassword']}
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value && getFieldValue('newPassword').length > 0) {
                  return Promise.reject(
                    new Error(
                      t('Please enter your current password', {
                        ns: 'reinitpassword',
                      }),
                    ),
                  )
                }
                return Promise.resolve()
              },
            }),
          ]}
        >
          <Input.Password
            placeholder={t(
              'To change your password, please enter your current password.',
            )}
          />
        </Form.Item>
      </Col>
      <Col span={24} sm={12}>
        <Form.Item
          className="password-input"
          name="newPassword"
          label={t('New password')}
          dependencies={['oldPassword']}
          validateTrigger="onSubmit"
          rules={[
            { message: t('Please enter a new password') },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('oldPassword') != value) {
                  return Promise.resolve()
                }
                return Promise.reject(
                  new Error(
                    t('The new password must be different from the current one.', {
                      ns: 'reinitpassword',
                    }),
                  ),
                )
              },
            }),
            {
              validator(_, value) {
                const notPassed = verifyProvidedPassword(value)
                if (notPassed) {
                  return Promise.reject(
                    new Error(
                      t('The format of the password is not correct', {
                        ns: 'reinitpassword',
                      }),
                    ),
                  )
                }
                return Promise.resolve()
              },
            },
          ]}
        >
          <Input.Password autoComplete="new-password" placeholder="****" />
        </Form.Item>
      </Col>
      <Col span={24} sm={12}>
        <Form.Item
          name="confirm"
          label={t('Confirm password')}
          dependencies={['newPassword']}
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('newPassword') === value) {
                  return Promise.resolve()
                }
                return Promise.reject(
                  new Error(t('The two passwords that you entered do not match')),
                )
              },
            }),
          ]}
        >
          <Input.Password autoComplete="new-password" placeholder="****" />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item label={t('License')} name="licence">
          <Select disabled>
            <Option value="Enterprise">
              <span>Enterprise</span>
            </Option>
          </Select>
        </Form.Item>
      </Col>
    </Row>
  )
}

export default AccountPassword
