import React from 'react'
import { useTranslation } from 'react-i18next'
import SectionHeader from '../../SectionHeader'
import DriveCard from '../DriveCard'
import './DrivesList.scss'
import { FolderInterface } from '../../../features/Drives/redux/drivesSlice'

interface Props {
  adminRootFolders: FolderInterface[]
  sharedRootFolders: FolderInterface[]
}

function DrivesList({ adminRootFolders, sharedRootFolders }: Props) {
  const { t } = useTranslation('drives')

  return (
    <div className="DrivesList">
      <DriveSection title={t('My drives')} folders={adminRootFolders} />
      <DriveSection title={t('Shared with me')} folders={sharedRootFolders} />
    </div>
  )
}

export default DrivesList

function DriveSection({ title, folders }: { title: string; folders: FolderInterface[] }) {
  return (
    <div>
      <SectionHeader title={title} />
      <div className="DrivesList DrivesList--container">
        {folders?.map((driveRootFolder) => (
          <div key={driveRootFolder.id} className="DrivesList DrivesList--item">
            <DriveCard driveRootFolder={driveRootFolder} />
          </div>
        ))}
      </div>
    </div>
  )
}
