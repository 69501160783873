import { useState, type Dispatch, type SetStateAction, useEffect, useRef } from 'react'
import { debounce } from 'lodash'

const debounceCallback = debounce((value, onChanged) => {
  onChanged(value)
}, 300)

export function useStateWithDebouncedCallback<T>(
  val: T,
  onChanged: (e: T) => void,
): [T, Dispatch<SetStateAction<T>>] {
  const [value, setValue] = useState<T>(val)

  const setValueWithCallback: Dispatch<SetStateAction<T>> = (newValue) => {
    if (typeof newValue === 'function') {
      throw new Error('Functions as setters are not supported with useStateWithCallback')
    }
    debounceCallback(newValue, onChanged)
    setValue(newValue)
  }

  return [value, setValueWithCallback]
}
