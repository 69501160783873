import React, { ReactNode } from 'react'
import { Tooltip } from 'antd'
import {
  Notification,
  attemptDeleteNotification,
  attemptMarkNotificationSeen,
} from '../liveWidgetSlice'
import { localFormatDate } from '../../../utils/Utils'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { IoCheckmarkCircleOutline, IoTrashOutline } from 'react-icons/io5'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../../../store'

interface Props {
  notification: Notification
  onClick?: () => void
  icon: ReactNode
  children: ReactNode
  isRowTable?: boolean
}

export default function NotificationItem({
  notification,
  onClick,
  icon,
  children,
  isRowTable,
}: Props) {
  const { t } = useTranslation('notification')
  const dispatch = useDispatch<AppDispatch>()

  function markAsRead(e: React.MouseEvent<SVGElement, MouseEvent>) {
    e.stopPropagation()
    dispatch(attemptMarkNotificationSeen(notification))
  }

  function onItemClick() {
    if (onClick) {
      onClick()
    }
    dispatch(attemptMarkNotificationSeen(notification))
  }
  return (
    <div onClick={onItemClick}>
      {!isRowTable && (
        <div className="notification_widget_content_item_chat_message_header">
          <div className="notification_widget_content_item_chat_message_header_date">
            {localFormatDate(dayjs(notification.createdAt))}
          </div>
          <div className="notification_actions">
            {notification.unseenCount && notification.unseenCount > 0 ? (
              <Tooltip title={t('Mark as read')}>
                <IoCheckmarkCircleOutline
                  onClick={(e) => markAsRead(e)}
                  className="notification_widget_content_action"
                />
              </Tooltip>
            ) : (
              <></>
            )}

            <Tooltip title={t('Delete', { ns: 'common' })}>
              <IoTrashOutline
                onClick={() => dispatch(attemptDeleteNotification(notification))}
                className="notification_widget_content_action"
              />
            </Tooltip>
          </div>
        </div>
      )}
      <div className="notification_widget_content_item_chat_message_body">
        {icon}
        {children}
      </div>
    </div>
  )
}
