import React, { useEffect, useState } from 'react'
import { Tour } from 'antd'
import { TourProps } from 'antd/lib'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../store'
import { NewFeaturePages, updateNewFeaturesSeen } from './newFeaturesSeenSlice'

interface Props {
  titleRef: React.RefObject<HTMLDivElement>
  favoritesRowRef: React.RefObject<HTMLDivElement>
  addFavoriteRef: React.RefObject<HTMLDivElement>
}

function HomeNewFeaturesTour({ titleRef, favoritesRowRef, addFavoriteRef }: Props) {
  const { t } = useTranslation('applications')
  const [tourVisible, setTourVisible] = useState(false)
  const newFeaturesHomeSeen = useSelector(
    (state: RootState) => state.auth.newFeaturesSeen?.home,
  )
  const dispatch = useDispatch<AppDispatch>()

  useEffect(() => {
    if (!newFeaturesHomeSeen) {
      setTourVisible(true)
      dispatch(updateNewFeaturesSeen({ newFeaturePageSeen: NewFeaturePages.HOME }))
    }
  }, [])

  const steps: TourProps['steps'] = [
    {
      title: t('Welcome to your home page'),
      description: t('Here you can find all your Auxa solutions.'),
      target: () => titleRef.current as HTMLElement,
      nextButtonProps: {
        children: t('Next', { ns: 'common' }),
      },
    },
    {
      title: t('Your workspace'),
      description: t('Manage your favorite applications here.'),
      target: () => favoritesRowRef.current as HTMLElement,
      prevButtonProps: {
        children: t('Previous', { ns: 'common' }),
      },
      nextButtonProps: {
        children: t('Next', { ns: 'common' }),
      },
    },
    {
      title: t('Add new favorite'),
      description: t('Click here to add a new favorite application.'),
      target: () => addFavoriteRef.current as HTMLElement,
      prevButtonProps: {
        children: t('Previous', { ns: 'common' }),
      },
      nextButtonProps: {
        children: t('Finish', { ns: 'common' }),
      },
    },
  ]

  return (
    <Tour
      open={tourVisible}
      onClose={() => setTourVisible(false)}
      steps={steps}
      gap={{ radius: 20 }}
      disabledInteraction
    />
  )
}

export default HomeNewFeaturesTour
