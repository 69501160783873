import React from 'react'
import { Link } from 'react-router-dom'
import './not_found.scss'

function NotFound() {
  return (
    <div className="not-found">
      <h1>Page not found</h1>
      <Link to="/applications">Go to home</Link>
    </div>
  )
}

export default NotFound
