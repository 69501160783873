export default class ApiError extends Error {
  response: string
  data?: Object
  reason?: string

  constructor(response: string, data?: Object, reason?: string) {
    const status = response
    const errorMessage = reason || ApiError.getResponseStatus(status)
    super(errorMessage)

    this.response = response
    this.data = data
    this.reason = reason
    this.name = 'ApiError'
  }

  static getResponseStatus(status: string): string {
    switch (status) {
      case '400':
        return 'BAD_REQUEST'
      case '401':
        return 'UNAUTHORIZED'
      case '403':
        return 'FORBIDDEN'
      case '404':
        return 'NOT_FOUND'
      case '500':
        return 'INTERNAL_ERROR'
      case '503':
        return 'SERVICE_UNAVAILABLE'
      default:
        return 'unknown_error'
    }
  }
}
