import React, { useEffect, ReactNode } from 'react'
import { MEDIA_DEVICE_STATUS } from '../features/room/redux/meSlice'
import { useTranslation } from 'react-i18next'
import { Alert, Spin } from 'antd'

interface Props {
  deviceStatus: MEDIA_DEVICE_STATUS
  children: ReactNode
  messages: {
    ASKING_PERMISSIONS: string
    FAILED: string
    NOT_ALLOWED: string
    NOT_FOUND: string
    STOPPED: string
  }
  askingPermissionsCallback?: () => void
  failedCallback?: () => void
  notAllowedCallback?: () => void
  notFoundCallback?: () => void
  stoppedCallback?: () => void
  startedCallback?: () => void
}

export default function DeviceNotReadyHandler({
  deviceStatus,
  children,
  messages,
  askingPermissionsCallback,
  failedCallback,
  notAllowedCallback,
  notFoundCallback,
  stoppedCallback,
  startedCallback,
}: Props) {
  const { t, i18n } = useTranslation('common')

  useEffect(() => {
    switch (deviceStatus) {
      case 'ASKING_PERMISSIONS':
        askingPermissionsCallback && askingPermissionsCallback()
        break
      case 'FAILED':
        failedCallback && failedCallback()
        break
      case 'NOT_ALLOWED':
        notAllowedCallback && notAllowedCallback()
        break
      case 'NOT_FOUND':
        notFoundCallback && notFoundCallback()
        break
      case 'STOPPED':
        stoppedCallback && stoppedCallback()
        break
      case 'STARTED':
        startedCallback && startedCallback()
        break
    }
  }, [deviceStatus])

  switch (deviceStatus) {
    case 'ASKING_PERMISSIONS':
      return (
        <Alert
          message={messages.ASKING_PERMISSIONS}
          type="warning"
          icon={<Spin />}
          showIcon={true}
        />
      )
    case 'FAILED':
      return <Alert message={messages.FAILED} type="error" />
    case 'NOT_ALLOWED':
      return (
        <Alert
          message={
            <div>
              {messages.NOT_ALLOWED}{' '}
              <a href="/faq/devices" target="_blank">
                {t('Please visit our FAQ.')}
              </a>
            </div>
          }
          type="error"
        />
      )
    case 'NOT_FOUND':
      return <Alert message={messages.NOT_FOUND} type="error" />
    case 'STOPPED':
      return <Alert message={messages.STOPPED} type="error" />
    case 'STARTED':
      return <>{children}</>
    default:
      return <Alert message={'should not appear'} type="error" />
  }
}
