import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../store'
import { logout } from './redux/authSlice'
import { useTranslation } from 'react-i18next'
import { redirect } from 'react-router-dom'
import { useToastContext } from '../../components/Toast/ToastContext'

const SESSION_TIMEOUT = parseInt(process.env.REACT_APP_SESSION_TIMEOUT_MS || '28800000')
const WARNING_DELAY = 5000

function DisconnectAFK() {
  const dispatch = useDispatch<AppDispatch>()
  const { ToastOpen, ToastDestroy } = useToastContext()
  const { t } = useTranslation('auth')
  const jwt = useSelector((state: RootState) => state.auth.jwt)
  const joinedToRoom = useSelector((state: RootState) => state.room.state === 'joined')
  const timeoutDisconnect = useRef<ReturnType<typeof setTimeout> | undefined>()
  const timeoutWarning = useRef<ReturnType<typeof setTimeout> | undefined>()
  const activateTimers = useRef(false)
  const warningToasterShown = useRef(false)

  useEffect(() => {
    window.addEventListener('mousemove', userActivityHandler)

    return () => {
      window.removeEventListener('mousemove', userActivityHandler)
    }
  }, [])

  useEffect(() => {
    activateTimers.current = jwt != undefined && !joinedToRoom
    if (activateTimers.current) {
      ToastDestroy('timeout-toast')
    }
  }, [jwt, joinedToRoom])

  useEffect(() => {
    if (activateTimers.current) {
      updateTimeouts()
    } else {
      clearTimeouts()
    }
  }, [jwt, joinedToRoom])

  function updateTimeouts() {
    clearTimeouts()

    timeoutWarning.current = setTimeout(() => {
      warningToasterShown.current = true
      ToastOpen({
        key: 'timeout-warning-toast',
        message: t('Due to inactivity, you will automatically logged out.'),
        type: 'warning',
        duration: WARNING_DELAY / 1000,
      })
    }, SESSION_TIMEOUT - WARNING_DELAY)

    timeoutDisconnect.current = setTimeout(() => {
      ToastOpen({
        key: 'timeout-toast',
        message: t('Your account was automatically logged out because you were away.'),
        type: 'warning',
        duration: 0,
      })
      dispatch(logout())
      redirect('/login')
    }, SESSION_TIMEOUT)
  }

  function clearTimeouts() {
    if (warningToasterShown.current) {
      ToastDestroy('timeout-warning-toast')
      warningToasterShown.current = false
    }
    clearTimeout(timeoutWarning.current)
    clearTimeout(timeoutDisconnect.current)
  }

  function userActivityHandler() {
    if (activateTimers.current) {
      updateTimeouts()
    }
  }

  return <></>
}

export default DisconnectAFK
