import React from 'react'
import { MessageInputDraft } from './chatsSlice'
import sanitizeHtml from 'sanitize-html'
import ChatMessageFileInList from './ChatMessageFileInList'
import { useTranslation } from 'react-i18next'

interface DraftMessageProps {
  messageInputDraft: MessageInputDraft
}

function DraftMessage({ messageInputDraft }: DraftMessageProps) {
  const { t } = useTranslation('chat')

  return (
    <>
      <span className="draft-indicator">{t('Draft:')} &nbsp;</span>
      {messageInputDraft.text && (
        <div
          className="chat_item_last_message"
          dangerouslySetInnerHTML={{
            //TODO: translate Draft
            __html: sanitizeHtml(messageInputDraft.text, {
              allowedTags: [],
            }),
          }}
        />
      )}
      {messageInputDraft.files && messageInputDraft.files.length > 0 && (
        <ChatMessageFileInList
          content={JSON.stringify(
            messageInputDraft.files.map((f) => {
              return {
                id: f.response.id,
                size: f.response.size,
                mimeType: f.response.mimeType,
                name: f.response.name,
              }
            }),
          )}
        />
      )}
    </>
  )
}
export default DraftMessage
