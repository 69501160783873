import React, { Ref, forwardRef, useImperativeHandle, useState } from 'react'
import { Progress } from 'antd'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store'
import './VolumeLevel.scss'

export interface VolumeProgressInterface {
  setVolume: (value: number) => void
}

interface Props {
  width: any
}

const VolumeProgress = forwardRef((props: Props, ref: Ref<VolumeProgressInterface>) => {
  const { width } = props
  useImperativeHandle(ref, () => ({
    setVolume: (dBs: number) => {
      setVolumeValue(convertDbToPercentage(dBs))
    },
  }))
  const micActive = useSelector((state: RootState) => state.me.micActive)
  const [volume, setVolumeValue] = useState<number>(0)

  const getColor = (percent: number) => {
    const red = Math.round(255 - percent * 2.55)
    const green = Math.round(percent * 2.55)
    return `rgb(${green},${red}, 0)`
  }

  return (
    <>
      {micActive && (
        // <VolumeCanvas volume={volume} />
        <>
          <Progress
            className="volume-progress"
            trailColor={'gray'}
            status="active"
            strokeColor={getColor(volume)}
            size="small"
            percent={volume}
            showInfo={false}
            steps={Math.round(width / 4)}
          />
        </>
      )}
    </>
  )
})

// interface Props{
//   volume: number
// }
// function VolumeCanvas({volume}: Props){
//   const canvasRef = useRef<HTMLCanvasElement>(null)
//   const getColor = (percent: number) => {
//     const red = Math.round(255 - percent * 2.55)
//     const green = Math.round(percent * 2.55)
//     return `rgb(${green},${red}, 0)`
//   }
//   useEffect(() => {
//     const canvas = canvasRef.current
//     if(!canvas) return
//     const context = canvas.getContext('2d')
//     const canvasWidth = canvas.width;
//     const canvasHeight = canvas.height;
//     const lineWidth = 5;
//     const lineColor = getColor(volume); // Assuming getColor(volume) returns the appropriate color
//     const progressWidth = (volume / 100) * (canvasWidth - lineWidth);
//     const centerY = canvasHeight / 2;

//     if(!context) return
//     // Clear canvas
//     context.clearRect(0, 0, canvasWidth, canvasHeight);

//     // Draw gray trail
//     context.beginPath();
//     context.moveTo(lineWidth / 2, centerY);
//     context.lineTo(canvasWidth - lineWidth / 2, centerY);
//     context.strokeStyle = 'gray';
//     context.lineWidth = lineWidth;
//     context.stroke();

//     // Draw colored progress
//     context.beginPath();
//     context.moveTo(lineWidth / 2, centerY);
//     context.lineTo(lineWidth / 2 + progressWidth, centerY);
//     context.strokeStyle = lineColor;
//     context.lineWidth = lineWidth;
//     context.stroke();
//   }, [volume])

//   return <canvas ref={canvasRef} className="volume-canvas" />
// }

/**
 *
 * @param dB
 * The loudest sounds on your system will be at 0dB, and silence in webaudio is -100dB.
 * Speech seems to be above -50dB depending on the volume and type of source.
 * @returns
 */
function convertDbToPercentage(dB: number): number {
  if (dB <= -90) {
    return 0
  } else if (dB >= -30) {
    return 100
  } else {
    return Math.round(((dB + 90) / 60) * 100)
  }
}

VolumeProgress.displayName = 'VolumeProgress'
export default VolumeProgress
