import React from 'react'
import { Button, List } from 'antd'
import VirtualList from 'rc-virtual-list'
import { SearchGroupDTO } from '../../../features/Organisation/OrganisationService'
import { IoTrashOutline } from 'react-icons/io5'
import { useTranslation } from 'react-i18next'

interface Props {
  groups: SearchGroupDTO[]
  deleteGroup: (id: string) => void
}

function ChatGroups({ groups, deleteGroup }: Props) {
  const { t } = useTranslation('chat')

  return (
    <div className="virtual-list-participant">
      {groups && groups.length > 0 ? (
        <List>
          <VirtualList data={groups} itemKey="id" className="list">
            {(group: SearchGroupDTO) => (
              <List.Item>
                <List.Item.Meta
                  title={group.name}
                  description={t('Group', { ns: 'drives' })}
                />
                <Button
                  className="btn-danger-border"
                  onClick={() => deleteGroup(group.id)}
                >
                  <IoTrashOutline className="error-color" size="1em" />
                </Button>
              </List.Item>
            )}
          </VirtualList>
        </List>
      ) : (
        <p className="m-0">{t('No group added for the moment.', { ns: 'common' })}</p>
      )}
    </div>
  )
}

export default ChatGroups
