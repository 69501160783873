import React, { DragEvent, MouseEvent, useState } from 'react'
import {
  FileInterface,
  setFileToRename,
  setSelectedFilesFolders,
} from '../../../features/Drives/redux/drivesSlice'
import { AiOutlineArrowRight, AiOutlineEdit, AiOutlineEye } from 'react-icons/ai'
import { Button, Dropdown, Tooltip } from 'antd'
import {
  downloadEncryptedFile,
  formatBytes,
  localFormatDate,
  renderIcon,
  truncateText,
} from '../../../utils/Utils'
import dayjs from 'dayjs'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../../store'
import { BiDotsVerticalRounded } from 'react-icons/bi'
import { MenuProps } from 'antd/lib'
import { IoTrashOutline } from 'react-icons/io5'
import { useTranslation } from 'react-i18next'
import { BsArrowRight, BsDownload, BsFolderSymlink } from 'react-icons/bs'
import { ViewFile, setFileToView } from '../../Viewer/ViewerSlice'
import { compareFilesFolders } from './DriveListContent'
import { useDriveLoader } from '../../../utils/hooks/UseDriveLoader'
import { ItemType } from 'antd/es/menu/interface'
import DriveDateTooltip from './DriveDateTooltip'
import MoveDriveItem from './MoveDriveItem'
import { MdOutlineDriveFileMove } from 'react-icons/md'

interface Props {
  file: FileInterface
  handleRowClick: (select: FileInterface, event: MouseEvent) => void
  handleRightClick: (event: MouseEvent, itemsFilesOrFolders?: ItemType[]) => void
  setOpenDeleteFileModal: (open: boolean) => void
  setFileToDelete: (file: FileInterface) => void
  handleDragStart: (e: DragEvent<HTMLDivElement>) => void
  handleDragEnd: () => void
}

function FileRow({
  file,
  handleRowClick,
  handleRightClick,
  setOpenDeleteFileModal,
  setFileToDelete,
  handleDragStart,
  handleDragEnd,
}: Props) {
  const { t } = useTranslation('drives')
  const dispatch = useDispatch<AppDispatch>()

  const { fileRights, selectedDriveFolder, selectedFilesFolders, driveEncryptKey } =
    useDriveLoader()

  const toolTipMaxCharacters = 100

  const draggingOver = useSelector((state: RootState) => state.drive.draggingOver)

  const [openMoveFileModal, setOpenMoveFileModal] = useState(false)

  const fileItems: MenuProps['items'] = [
    {
      label: (
        <span className="d-flex d-flex-middle d-flex-center g-0_5rem">
          <AiOutlineEye size="1.2em" />
          {t('Open', { ns: 'common' })}
        </span>
      ),
      onClick: () => onFileOpen(file),
      key: `view_file_${file.id}`,
    },
    {
      label: (
        <span className="d-flex d-flex-middle d-flex-center g-0_5rem">
          <AiOutlineEdit size="1.2em" />
          {t('Rename')}
        </span>
      ),
      onClick: () => setRenameFile(file),
      key: `rename_file_${file.id}`,
    },
    {
      label: (
        <span className="d-flex d-flex-middle d-flex-center g-0_5rem">
          <BsDownload size="1.2em" />
          {t('Download')}
        </span>
      ),
      onClick: () =>
        driveEncryptKey && downloadEncryptedFile(file.id, driveEncryptKey, file.name),
      key: `download_file_${file.id}`,
    },
    {
      label: (
        <div className="d-flex d-flex-middle d-flex-center g-0_5rem">
          <BsArrowRight size="1.2em" />
          {t('Move', { ns: 'common' })}
        </div>
      ),
      key: `move_file_${file?.id}`,
      onClick: () => setOpenMoveFileModal(true),
    },

    {
      type: 'divider',
    },
    {
      label: (
        <span className="d-flex d-flex-middle d-flex-center g-0_5rem error-color">
          <IoTrashOutline size="1.2em" />
          {t('Delete')}
        </span>
      ),
      className: 'delete-btn',
      onClick: () => onDeleteFile(file),
      key: `delete_file_${file.id}`,
    },
  ]

  const fileItemsDefaultRights: MenuProps['items'] = [
    {
      label: (
        <span
          className="d-flex d-flex-middle d-flex-center g-0_5rem"
          onClick={() => onFileOpen(file)}
        >
          <AiOutlineEye size="1.2em" />
          {t('Open', { ns: 'common' })}
        </span>
      ),
      key: `view_file_${file?.id}`,
    },
    {
      label: (
        <span
          className="d-flex d-flex-middle d-flex-center g-0_5rem"
          onClick={() =>
            driveEncryptKey && downloadEncryptedFile(file.id, driveEncryptKey, file.name)
          }
        >
          <BsDownload size="1.2em" />
          {t('Download')}
        </span>
      ),
      key: `download_file_${file?.id}`,
    },
  ]

  const handleMouseDown = (event: MouseEvent) => {
    if (!selectedFilesFolders.includes(file) && !event.shiftKey && !event.ctrlKey) {
      dispatch(setSelectedFilesFolders([file]))
    }
  }

  /**
   * Opens the delete file modal and sets the file to delete.
   *
   * @param document - The file to be deleted.
   */
  function onDeleteFile(document: FileInterface) {
    setOpenDeleteFileModal(true)
    setFileToDelete(document)
  }

  /**
   * Sets up the modal to rename the file.
   *
   * @param document - The file to be renamed.
   */
  function setRenameFile(document?: FileInterface) {
    if (selectedDriveFolder && document) {
      dispatch(setFileToRename({ file: document }))
    }
  }

  /**
   * Opens the file for viewing.
   *
   * @param row - The file to be opened.
   */
  async function onFileOpen(row: FileInterface) {
    if (driveEncryptKey) {
      dispatch(
        setFileToView({
          file: {
            id: row.id,
            size: parseInt(row.size),
            mimeType: row.mimeType,
            name: row.name,
          } as ViewFile,
          encryptKey: driveEncryptKey,
        }),
      )
    }
  }

  const isSelected = selectedFilesFolders.some((item) => compareFilesFolders(item, file))

  return (
    <>
      <div
        className={`DriveListContent-table-row ${
          isSelected ? 'DriveListContent-table-row-selected' : ''
        } ${draggingOver && selectedFilesFolders.includes(file) ? 'file-dragging' : ''}`}
        draggable
        onDragStart={(e) => handleDragStart(e)}
        onDragEnd={handleDragEnd}
        onContextMenu={(e) =>
          handleRightClick(e, fileRights ? fileItems : fileItemsDefaultRights)
        }
        onClick={(e) => handleRowClick(file, e)}
        onMouseDown={(e) => handleMouseDown(e)}
        onDoubleClick={() => onFileOpen(file)}
      >
        <div className="DriveListContent-table-name-column">
          <div className="name">
            <span className="document-icon">
              <img className="file-icon" src={renderIcon(file.mimeType, file.name)} />
            </span>
            <Tooltip title={truncateText(file.name, toolTipMaxCharacters)}>
              <span className="document-name">{file.name}</span>
            </Tooltip>
          </div>
        </div>
        <div className="DriveListContent-table-owner-column">
          {file.creatorFullName ? (
            <Tooltip title={file.creatorEmail}>
              <span>{file.creatorFullName}</span>
            </Tooltip>
          ) : (
            <span>{file.creatorEmail}</span>
          )}
        </div>
        <div className="DriveListContent-table-date-column">
          <Tooltip
            title={
              <DriveDateTooltip createdAt={file.createdAt} updatedAt={file.updatedAt} />
            }
          >
            <span>
              {localFormatDate(dayjs(file.updatedAt ? file.updatedAt : file.createdAt))}
            </span>
          </Tooltip>
        </div>
        <div className="DriveListContent-table-size-column">
          {formatBytes(parseFloat(file.size))}
        </div>
        <div className="DriveListContent-table-actions-column">
          <div className="action-main-container">
            <div className="action-custom-container">
              <div className="action-hover-container">
                <div className="action-icons">
                  {fileRights && (
                    <div
                      className="action-rounded-icon hideOnLaptop"
                      onClick={() => setRenameFile(file)}
                    >
                      <AiOutlineEdit size="1.5em" />
                    </div>
                  )}
                  <div className="action-rounded-icon" onClick={() => onFileOpen(file)}>
                    <AiOutlineEye size="1.5em" />
                  </div>
                  <div
                    className="action-rounded-icon"
                    onClick={(e) =>
                      driveEncryptKey &&
                      downloadEncryptedFile(file.id, driveEncryptKey, file.name)
                    }
                  >
                    <BsDownload size="1.5em" />
                  </div>
                </div>
              </div>
            </div>
            <Dropdown
              menu={{
                items: fileRights ? fileItems : fileItemsDefaultRights,
              }}
              trigger={['click']}
            >
              <a className="action-more-container">
                <div className="action-more">
                  <BiDotsVerticalRounded size="1.5em" />
                </div>
              </a>
            </Dropdown>

            <div className="action-more-mobile">
              <Button type="primary" onClick={() => onFileOpen(file)}>
                <AiOutlineEye size="1.5em" />
              </Button>
              {fileRights && (
                <Button onClick={() => setRenameFile(file)}>
                  <AiOutlineEdit size="1.5em" />
                </Button>
              )}
              <Button
                onClick={() =>
                  driveEncryptKey &&
                  downloadEncryptedFile(file.id, driveEncryptKey, file.name)
                }
              >
                <BsDownload size="1.5em" />
              </Button>
              {fileRights && (
                <Button className="btn-danger-border" onClick={() => onDeleteFile(file)}>
                  <IoTrashOutline size="1.2em" className="error-color" />
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
      <MoveDriveItem
        openMoveFileModal={openMoveFileModal}
        setOpenMoveFileModal={setOpenMoveFileModal}
        file={file}
      />
    </>
  )
}

export default FileRow
