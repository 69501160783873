import { Col, DatePicker, Form, InputNumber, Modal, Radio, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import { DefaultOptionType } from 'antd/es/select'
import dayjs from 'dayjs'
import { Days, Periodicity } from './NewMeetingSlice'
import { Select } from 'antd/lib'
import { useTranslation } from 'react-i18next'
import { getDatePickerLocale } from './NewMeeting/NewMeetingForm'
import '../../index.scss'
import CustomCheckboxGroup from './CustomCheckboxGroup'

interface Props {
  personalizePeriodicity: Periodicity | undefined
  setPersonalizePeriodicity: (val: Periodicity | undefined) => void
  hidden?: boolean
  disabled?: boolean
  defaultDate: Date
}

function PeriodicityForm({
  personalizePeriodicity,
  setPersonalizePeriodicity,
  hidden,
  disabled,
  defaultDate,
}: Props) {
  const { t, i18n } = useTranslation('meetings')
  dayjs.locale(i18n.language)
  let datePickerLocale = getDatePickerLocale(i18n.language)

  const defaultByWeekDay: { value: Days; shortLabel: string; rank: number }[] = [
    { value: 'monday', shortLabel: t('Monday')[0].toUpperCase(), rank: 1 },
    { value: 'tuesday', shortLabel: t('Tuesday')[0].toUpperCase(), rank: 2 },
    { value: 'wednesday', shortLabel: t('Wednesday')[0].toUpperCase(), rank: 3 },
    { value: 'thursday', shortLabel: t('Thursday')[0].toUpperCase(), rank: 4 },
    { value: 'friday', shortLabel: t('Friday')[0].toUpperCase(), rank: 5 },
    { value: 'saturday', shortLabel: t('Saturday')[0].toUpperCase(), rank: 6 },
    { value: 'sunday', shortLabel: t('Sunday')[0].toUpperCase(), rank: 0 },
  ]

  const maxUntilValue = ({ startDate }: { startDate?: Date }) => undefined // dayjs(startDate).add(100, 'years').toDate()
  const UTCtoLocal = (date: Date) => {
    return dayjs(date)
      .utcOffset(2 * dayjs().utcOffset(), true)
      .toDate()
  }

  const getPeriodicity = (
    startDate: Date,
    mode: 'everyDay' | 'everyWeek' | 'everyMonth' | 'everyYear' | 'everyWeekDay',
  ): Periodicity => {
    switch (mode) {
      case 'everyDay':
        return {
          dtstart: UTCtoLocal(startDate),
          interval: 1,
          freq: 'day',
          until: maxUntilValue({ startDate }),
        }
      case 'everyWeek':
        return {
          dtstart: UTCtoLocal(startDate),
          interval: 1,
          freq: 'week',
          byweekday: defaultByWeekDay
            .filter((item) => item.rank == parseInt(dayjs(startDate).format('d')))
            .map((item) => item.value),
          until: maxUntilValue({ startDate }),
        }
      case 'everyMonth':
        return {
          dtstart: UTCtoLocal(startDate),
          interval: 1,
          freq: 'month',
          byweekday: defaultByWeekDay
            .filter((item) => item.rank == parseInt(dayjs(startDate).format('d')))
            .map((item) => item.value),
          bysetpos: [1],
          until: maxUntilValue({ startDate }),
        }
      case 'everyYear':
        return {
          dtstart: UTCtoLocal(startDate),
          interval: 1,
          freq: 'year',
          bymonth: [parseInt(dayjs(startDate).format('M'))],
          bymonthday: [parseInt(dayjs(startDate).format('D').toLowerCase())],
          until: maxUntilValue({ startDate }),
        }
      case 'everyWeekDay':
        return {
          dtstart: UTCtoLocal(startDate),
          interval: 1,
          freq: 'day',
          byweekday: defaultByWeekDay
            .map((day) => day.value)
            .filter((day) => day.toString() != 'saturday' && day.toString() != 'sunday'),
          until: maxUntilValue({ startDate }),
        }
    }
  }

  const [periodicityForm] = Form.useForm()
  const [periodicityModalForm] = Form.useForm()
  const [personalizePeriodicityModal, setPersonalizePeriodicityModal] =
    useState<boolean>(false)
  const [modalEndRadio, setModalEndRadio] = useState<string>('never')
  const [checkboxValues, setCheckboxValues] = useState<number[]>([])

  useEffect(() => {
    if (personalizePeriodicity != undefined) {
      periodicityModalForm.submit()
    }
  }, [defaultDate])

  useEffect(() => {
    periodicityForm.setFieldsValue(defaultPeriodicityFormValues)
    periodicityModalForm.setFieldsValue(defaultPeriodicityModalFormValues)
    setModalEndRadio(defaultPeriodicityModalFormValues.end)
  }, [personalizePeriodicity])

  const renderDropDownPeriodicity = () => {
    const dropDownSelect = (items: { value: string; wording: string }[]) => {
      return items.map((item) => {
        return (
          <Select.Option value={item.value} key={item.value}>
            <div className="d-flex d-flex-between d-flex-middle">
              <span className="mr-05rem">{item.wording}</span>
            </div>
          </Select.Option>
        )
      })
    }

    const onSelect = (value: string, option: DefaultOptionType | DefaultOptionType[]) => {
      if (value == 'personalize') {
        setPersonalizePeriodicityModal(true)
      }
    }

    return (
      <Select
        placeholder={t('Periodicity')}
        defaultOpen={false}
        onSelect={onSelect}
        className="w-100"
        onChange={periodicityForm.submit}
        disabled={disabled}
      >
        {dropDownSelect([
          { value: 'never', wording: t('Never') },
          { value: 'everyDay', wording: t('Every day') },
          {
            value: 'everyWeek',
            wording: t('EVERY_WEEK_ON', { weekday: dayjs(defaultDate).format('dddd') }),
          },
          {
            value: 'everyMonth',
            wording: t('EVERY_MONTH_ON', { weekday: dayjs(defaultDate).format('dddd') }),
          },
          {
            value: 'everyYear',
            wording: t('EVERY_YEAR_ON', {
              dayMonth: dayjs(defaultDate).format('D MMMM'),
            }),
          },
          {
            value: 'everyWeekDay',
            wording: t('Every day of the week (Monday to Friday)'),
          },
          { value: 'personalize', wording: t('Personalize...') },
        ])}
      </Select>
    )
  }

  const periodicitySubmit = () => {
    switch (periodicityForm.getFieldValue('periodicity')) {
      case 'never':
        setPersonalizePeriodicity(undefined)
        break
      case 'everyDay':
        setPersonalizePeriodicity(getPeriodicity(dayjs(defaultDate).toDate(), 'everyDay'))
        break
      case 'everyWeek':
        setPersonalizePeriodicity(
          getPeriodicity(dayjs(defaultDate).toDate(), 'everyWeek'),
        )
        break
      case 'everyMonth':
        setPersonalizePeriodicity(
          getPeriodicity(dayjs(defaultDate).toDate(), 'everyMonth'),
        )
        break
      case 'everyYear':
        setPersonalizePeriodicity(
          getPeriodicity(dayjs(defaultDate).toDate(), 'everyYear'),
        )
        break
      case 'everyWeekDay':
        setPersonalizePeriodicity(
          getPeriodicity(dayjs(defaultDate).toDate(), 'everyWeekDay'),
        )
        break
    }
  }

  // convert periodicity to form values
  const basicPeriodicityCompare = (
    periodicity1: Periodicity,
    periodicity2: Periodicity,
  ) => {
    const compareArray = (a: any[] | undefined, b: any[] | undefined) => {
      if (
        (a == undefined || a == null || a.length == 0) &&
        (b == undefined || b == null || b.length == 0)
      ) {
        return true
      }
      return JSON.stringify(a) == JSON.stringify(b)
    }

    const datePer1Until = new Date(periodicity1.until || 0)
    const datePer2Until = new Date(periodicity2.until || 0)

    return (
      periodicity1.interval == periodicity2.interval &&
      periodicity1.freq == periodicity2.freq &&
      datePer1Until.getFullYear() == datePer2Until.getFullYear() &&
      datePer1Until.getMonth() == datePer2Until.getMonth() &&
      datePer1Until.getDate() == datePer2Until.getDate() &&
      periodicity1.count == periodicity2.count &&
      compareArray(periodicity1.byweekday, periodicity2.byweekday) &&
      compareArray(periodicity1.bysetpos, periodicity2.bysetpos) &&
      compareArray(periodicity1.bymonth, periodicity2.bymonth) &&
      compareArray(periodicity1.bymonthday, periodicity2.bymonthday)
    )
  }

  const getDropDownValue = (periodicity: Periodicity | undefined) => {
    if (!periodicity) {
      return 'never'
    } else if (
      basicPeriodicityCompare(
        periodicity,
        getPeriodicity(dayjs(defaultDate).toDate(), 'everyDay'),
      )
    ) {
      return 'everyDay'
    } else if (
      basicPeriodicityCompare(
        periodicity,
        getPeriodicity(dayjs(defaultDate).toDate(), 'everyWeek'),
      )
    ) {
      return 'everyWeek'
    } else if (
      basicPeriodicityCompare(
        periodicity,
        getPeriodicity(dayjs(defaultDate).toDate(), 'everyMonth'),
      )
    ) {
      return 'everyMonth'
    } else if (
      basicPeriodicityCompare(
        periodicity,
        getPeriodicity(dayjs(defaultDate).toDate(), 'everyYear'),
      )
    ) {
      return 'everyYear'
    } else if (
      basicPeriodicityCompare(
        periodicity,
        getPeriodicity(dayjs(defaultDate).toDate(), 'everyWeekDay'),
      )
    ) {
      return 'everyWeekDay'
    } else {
      return 'personalize'
    }
  }

  const periodicityModalSubmit = () => {
    setPersonalizePeriodicityModal(false)
    const byweekday = periodicityModalForm.getFieldValue('byweekday')
    const end = periodicityModalForm.getFieldValue('end')
    setPersonalizePeriodicity({
      dtstart: UTCtoLocal(new Date(defaultDate) || new Date()),
      interval: parseInt(periodicityModalForm.getFieldValue('interval')),
      freq: periodicityModalForm.getFieldValue('freq'),
      byweekday: [
        ...defaultByWeekDay
          .filter((day) => byweekday.includes(day.value))
          .map((day) => day.value),
      ],
      until:
        end == 'never'
          ? maxUntilValue({})
          : end == 'until'
            ? periodicityModalForm.getFieldValue('untilValue')
            : undefined,
      count:
        end == 'count' ? periodicityModalForm.getFieldValue('countValue') : undefined,
    })
  }

  const onCancelPeriodicityModal = () => {
    setPersonalizePeriodicityModal(false)
    periodicityModalForm.resetFields()
    setModalEndRadio('never')
    periodicityForm.setFieldsValue(defaultPeriodicityFormValues)
  }

  const defaultPeriodicityFormValues = {
    periodicity: getDropDownValue(personalizePeriodicity),
  }

  const defaultPeriodicityModalFormValues = {
    interval: personalizePeriodicity?.interval || 1,
    freq: personalizePeriodicity?.freq || 'week',
    byweekday: personalizePeriodicity?.byweekday || [
      defaultByWeekDay.find((item) => item.rank == parseInt(dayjs().format('d')))?.value,
    ],
    end: personalizePeriodicity?.count
      ? 'count'
      : personalizePeriodicity?.until
        ? 'until'
        : 'never',
    untilValue: dayjs(personalizePeriodicity?.until) || dayjs(),
    countValue: personalizePeriodicity?.count || 1,
  }

  return (
    <>
      <Form
        form={periodicityForm}
        name="general"
        autoComplete="off"
        layout="vertical"
        onFinish={periodicitySubmit}
        hidden={hidden}
        initialValues={defaultPeriodicityFormValues}
      >
        <Form.Item label={t('Repeat')} style={{ marginBottom: '0px' }} name="periodicity">
          {renderDropDownPeriodicity()}
        </Form.Item>
      </Form>
      <Modal
        className="line-1"
        open={personalizePeriodicityModal}
        title={t('Custom recurrence')}
        onOk={periodicityModalForm.submit}
        onCancel={onCancelPeriodicityModal}
        okText={t('Validate')}
        cancelText={t('Back')}
        centered
      >
        <Form
          form={periodicityModalForm}
          initialValues={defaultPeriodicityModalFormValues}
          onFinish={periodicityModalSubmit}
          name="CustomFrequency"
          autoComplete="off"
          layout="vertical"
          scrollToFirstError={true}
        >
          <Row>
            <div className="layout-header-bottom">
              <h4 className="layout-list-header">{t('Frequency')}</h4>
            </div>
          </Row>
          <Row gutter={[24, 8]} className="d-flex d-flex-baseline">
            <Col className="d-flex-inherit">{t('Every')}</Col>
            <Col className="d-flex-inherit">
              <Form.Item
                style={{ width: '3rem' }}
                name="interval"
                className="mt-1rem mb-1rem"
                rules={[{ required: true, message: t('Please input a valid frequency') }]}
              >
                <InputNumber min={1} className="w-auto" />
              </Form.Item>
            </Col>
            <Col className="d-flex-auto">
              <Form.Item
                name="freq"
                className="mt-1rem mb-1rem w-100"
                rules={[{ required: true, message: t('Please input a valid frequency') }]}
              >
                <Select
                  placeholder={t('Periodicity')}
                  defaultOpen={false}
                  className="w-7rem"
                >
                  <Select.Option value="day">
                    <div className="d-flex d-flex-between d-flex-middle">
                      <span className="mr-05rem">{t('day')}</span>
                    </div>
                  </Select.Option>
                  <Select.Option value="week">
                    <div className="d-flex d-flex-between d-flex-middle">
                      <span className="mr-05rem">{t('week')}</span>
                    </div>
                  </Select.Option>
                  <Select.Option value="month">
                    <div className="d-flex d-flex-between d-flex-middle">
                      <span className="mr-05rem">{t('month')}</span>
                    </div>
                  </Select.Option>
                  <Select.Option value="year">
                    <div className="d-flex d-flex-between d-flex-middle">
                      <span className="mr-05rem">{t('year')}</span>
                    </div>
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[24, 8]} className="">
            <Col span={20}>
              <div className="layout-header-bottom">
                <h4 className="layout-list-header">{t('Repetition')}</h4>
              </div>
            </Col>
            <Col span={20}>
              <Form.Item
                name="byweekday"
                rules={[
                  { required: true, message: t('Please input a valid periodicity') },
                ]}
              >
                <CustomCheckboxGroup
                  value={checkboxValues}
                  onChange={(e) => setCheckboxValues(e as number[])}
                  entries={defaultByWeekDay.map((item) => {
                    return { label: item.shortLabel, value: item.value }
                  })}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[24, 8]}>
            <Col span={24}>
              <Row>
                <div className="layout-header-bottom">
                  <h4 className="layout-list-header">{t('End')}</h4>
                </div>
              </Row>
              <Form.Item name="end">
                <Radio.Group
                  className="radio-padding w-100"
                  value={modalEndRadio}
                  onChange={(e) => {
                    setModalEndRadio(e.target.value)
                  }}
                >
                  <Row>
                    <Col className="w-5rem">
                      <Radio value={'never'}>{t('Never')}</Radio>
                    </Col>
                  </Row>
                  <Row className="d-flex d-flex-middle d-flex-row w-100">
                    <Radio value={'until'} className="periodicity-radio">
                      {t('On')}
                    </Radio>

                    <Form.Item
                      name="untilValue"
                      className="mt-1rem mb-1rem d-flex-grow-1"
                    >
                      <DatePicker
                        disabled={modalEndRadio != 'until'}
                        className="w-100"
                        disabledDate={(current) =>
                          current.isBefore(dayjs().subtract(1, 'day').toString())
                        }
                        showSecond={false}
                        locale={datePickerLocale}
                        format="DD/MM/YYYY"
                        inputReadOnly
                      />
                    </Form.Item>
                  </Row>
                  <Row className="d-flex d-flex-middle d-flex-row w-100">
                    <Radio value={'count'} className="periodicity-radio">
                      {t('After')}
                    </Radio>

                    <Form.Item
                      name="countValue"
                      className="mt-1rem mb-1rem d-flex-grow-1"
                    >
                      <InputNumber
                        disabled={modalEndRadio != 'count'}
                        min={1}
                        max={1440}
                        addonAfter={t('occurrences')}
                      />
                    </Form.Item>
                  </Row>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  )
}

export default PeriodicityForm
