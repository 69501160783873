import React from 'react'
import { Modal } from 'antd'
import { AppDispatch, RootState } from '../../store'
import { useDispatch, useSelector } from 'react-redux'
import { deleteFolder, setFolderToDelete } from '../../features/Drives/redux/drivesSlice'
import { Trans, useTranslation } from 'react-i18next'
import { limitText } from '../../utils/Utils'

function DeleteFolderModal() {
  const { t } = useTranslation('drives')
  const dispatch = useDispatch<AppDispatch>()
  const selectedDriveFolder = useSelector(
    (state: RootState) => state.drive.selectedDriveFolder,
  )

  const folderToDelete = useSelector((state: RootState) => state.drive.folderToDelete)

  /**
   *
   */
  function confirmDeleteFolder() {
    if (folderToDelete && selectedDriveFolder) {
      dispatch(
        deleteFolder({
          driveId: selectedDriveFolder.driveId,
          folderId: folderToDelete.folder.id,
          mainFolder: folderToDelete.mainFolder,
        }),
      )
    }
  }

  return (
    <Modal
      centered
      open={folderToDelete != undefined}
      title={t('Delete folder')}
      onOk={confirmDeleteFolder}
      onCancel={() => dispatch(setFolderToDelete(undefined))}
      okText={t('Yes', { ns: 'common' })}
      cancelText={t('No', { ns: 'common' })}
    >
      <p>
        <Trans
          ns="drives"
          i18nKey="DELETE_FOLDER"
          shouldUnescape={true}
          values={{
            name: limitText(folderToDelete?.folder.name),
          }}
          components={{ bold: <strong /> }}
        />
      </p>
    </Modal>
  )
}

export default DeleteFolderModal
