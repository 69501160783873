import React, { useEffect } from 'react'
import { Button, Modal } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import ManageParticipants from './ManageParticipants/ManageParticipants'
import { AppDispatch, RootState } from '../../store'
import {
  addInvited,
  addParticipant,
  deleteParticipant,
  deleteInvited,
  updateParticipantRole,
  updateInvitedRole,
  idleUpdateRoomStatus,
  setRoomToModify,
  updateRoom,
} from '../../features/Meetings/MeetingsSlice'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { unescape } from 'html-escaper'
import './ModalManageParticipants.scss'

function ModalManageParticipants() {
  const { t } = useTranslation('meetings')
  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()
  const { roomId } = useParams()
  const email = useSelector((state: RootState) => state.auth.email)
  const roomToModify = useSelector((state: RootState) => state.rooms.roomToModify)
  const updateRoomStatus = useSelector((state: RootState) => state.rooms.updateRoomStatus)

  const disabled =
    !!roomToModify &&
    (roomToModify.archived ||
      updateRoomStatus === 'loading' ||
      (roomToModify.creator ? roomToModify.creator.email !== email : false))

  useEffect(() => {
    if (updateRoomStatus === 'success') {
      dispatch(setRoomToModify())
      navigate(`/room/${roomId}`)
    }

    return () => {
      dispatch(idleUpdateRoomStatus())
    }
  }, [updateRoomStatus])

  function handleParticipantsModalCancel() {
    dispatch(setRoomToModify())
  }

  return (
    <Modal
      centered
      className="modal-add-participant"
      title={
        <>
          {t('Manage meeting')} <strong>{unescape(roomToModify?.name || '')}</strong>
        </>
      }
      open={!!roomToModify}
      width={860}
      closable={false}
      onCancel={handleParticipantsModalCancel}
      afterClose={() => dispatch(idleUpdateRoomStatus())}
      footer={
        <div className="manage_room_modal-footer">
          <Button onClick={handleParticipantsModalCancel}>
            {t('Cancel', { ns: 'common' })}
          </Button>

          <Button
            type="primary"
            disabled={disabled}
            onClick={() => {
              if (roomToModify) {
                dispatch(updateRoom(roomToModify))
              }
            }}
          >
            {t('Save')}
          </Button>
        </div>
      }
    >
      <div className="participant-form">
        <ManageParticipants
          disabled={disabled}
          room={roomToModify}
          vertical={false}
          addParticipant={addParticipant}
          addInvited={addInvited}
          deleteParticipant={deleteParticipant}
          deleteInvited={deleteInvited}
          updateParticipantRole={updateParticipantRole}
          updateInvitedRole={updateInvitedRole}
        />
      </div>
    </Modal>
  )
}

export default ModalManageParticipants
