import { generateUniqueColor } from '../features/room/RoomChat/ChatLibrary'

/**
 *
 * @param name
 * @returns
 */
export function getInitials(name: string) {
  const splitted = name.split(/[ ]+/, 2)
  if (splitted.length == 0) {
    return '?'
  }
  if (splitted.length == 1) {
    return splitted[0].substring(0, 2).toUpperCase()
  }

  return `${splitted[0].substring(0, 1)}${splitted[1].substring(0, 1)}`.toUpperCase()
}

class DisplayName {
  private _fullName: string
  private _initials: string
  private _color: string

  constructor(id: string, fullName?: string) {
    if (fullName) {
      this._fullName = fullName
      this._initials = getInitials(fullName)
    } else {
      this._fullName = id
      this._initials = getInitials(id)
    }

    this._color = generateUniqueColor(id)
  }

  get fullName() {
    return this._fullName
  }

  get initials() {
    return this._initials
  }

  get color() {
    return this._color
  }
}

export default DisplayName
