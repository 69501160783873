export type RoomParticipantRole =
  | 'creator'
  | 'moderator'
  | 'proofreader'
  | 'document'
  | 'attendee'

export enum RoomType {
  Phone = 'phone',
  InPerson = 'in-person',
  Videoconferencing = 'videoconferencing',
}

export enum RoomParticipantRoleEnum {
  Creator = 'creator',
  Moderator = 'moderator',
  Proofreader = 'proofreader',
  Document = 'document',
  Attendee = 'attendee',
}

export interface RoomParticipant {
  email: string
  firstName?: string
  lastName?: string
  role: RoomParticipantRole
  color?: string
}

export interface RoomFile {
  uid: string
  filename: string
  mimeType?: string
  size?: number
}

export interface RoomInterface {
  id?: string
  name?: string
  color?: string
  type?: RoomType
  reference?: Array<string>
  startDate?: string | null
  duration?: number
  creator?: {
    email: string
    firstName: string
    lastName: string
  }
  creatorNote?: string
  role?: RoomParticipantRole
  archived?: boolean
  participants?: RoomParticipant[]
  invited?: RoomParticipant[]
  files?: RoomFile[]
  hasAccessFiles?: boolean
  createdAt?: string
  periodicRoomId?: string
  periodicity?: string
  decrypt_key?: string
}

export interface PendingRoomInterface extends RoomInterface {
  voteStatus?: {
    count: number
    voted: boolean
    dates: string[]
  }
}
