import React, { CSSProperties, useContext, useEffect, useState } from 'react'
import './EmojiPickerModal.scss'
import { WebSocketConnectionContext } from '../WebsocketConnection/WebSocketConnectionContext'
import { useTranslation, getI18n } from 'react-i18next'
import { getEmojiDataFromNative, init } from 'emoji-mart'
import Picker from '@emoji-mart/react'
import { AiOutlinePlus } from 'react-icons/ai'

interface Reaction {
  aliases: string[]
  id: string
  keywords: string[]
  name: string
  native: string
  shortcodes: string
  skin: number
  unified: string
}

interface Props {
  open: boolean
  setOpen: (open: boolean) => void
  messageId?: string
  chatId: string
  mousePosition: {
    x: number
    y: number
  }
}

export const EMOJI_STYLE = 'native'
const QUICK_REACTIONS: string[] = ['✅', '👍', '👎', '👀', '😂', '❤️']
const EXCEPT_REACTIONS: string[] = [] // ["gun", "hankey", "eggplant", "peach", "underage"]
const MODAL_WIDTH = 350
const MODAL_HEIGHT = 450

function EmojiPickerModal({ open, setOpen, messageId, chatId, mousePosition }: Props) {
  const ws = useContext(WebSocketConnectionContext)
  const { t } = useTranslation('chat')
  const lang = getI18n().language

  const [quickReactions, setQuickReactions] = useState<(Reaction | undefined)[]>(
    QUICK_REACTIONS.map((i) => undefined),
  )
  const [emojiData, setEmojiData] = useState<any | undefined>(undefined)
  const [modalCoordinates, setModalCoordinates] = useState<{
    top?: number
    bottom?: number
    left?: number
    right?: number
  }>({ top: 0, left: 0 })
  const [quickReactionsModal, setQuickReactionsModal] = useState<boolean>(true)

  const modalStyle: CSSProperties = {
    position: 'fixed',
    zIndex: 100000,
    top: modalCoordinates.top,
    bottom: modalCoordinates.bottom,
    left: modalCoordinates.left,
    right: modalCoordinates.right,
  }

  const pickerProps = {
    data: async () => {
      return await import(`../../../emoji_data/combined/data-${lang}.json`)
    },
    onEmojiSelect: (i: any) => {
      setEmojiData(i)
      setOpen(false)
    },
    locale: lang,
    set: EMOJI_STYLE,
    exceptEmojis: EXCEPT_REACTIONS,
  }
  const pickerComponent = Picker(pickerProps)

  const handleKeyPressed = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      setOpen(false)
    }
  }

  useEffect(() => {
    if (emojiData) {
      if (chatId && messageId) {
        ws?.sendMessageReactionAdded(chatId, messageId, emojiData.native)
      }
      setEmojiData(undefined)
    }
  }, [emojiData])

  useEffect(() => {
    const newPosition: { top?: number; bottom?: number; left?: number; right?: number } =
      {}
    if (mousePosition.x + MODAL_WIDTH > window.innerWidth) {
      newPosition.right = window.innerWidth - mousePosition.x
    } else {
      newPosition.left = mousePosition.x
    }
    if (mousePosition.y + MODAL_HEIGHT > window.innerHeight) {
      newPosition.bottom = window.innerHeight - mousePosition.y
    } else {
      newPosition.top = mousePosition.y
    }

    setModalCoordinates(newPosition)
  }, [mousePosition])

  useEffect(() => {
    if (!open) {
      setQuickReactionsModal(true)
    }
  }, [open])

  useEffect(() => {
    QUICK_REACTIONS.forEach(async (reaction, index) => {
      const emojiData = await getEmojiDataFromNative(reaction)
      setQuickReactions((prev) => {
        const newQuickReactions = [...prev]
        newQuickReactions[index] = emojiData
        return newQuickReactions
      })
    })
    window.addEventListener('keydown', handleKeyPressed)
    return () => {
      window.removeEventListener('keydown', handleKeyPressed)
    }
  }, [])

  return (
    <div
      className={open ? 'emoji_picker_container' : 'z-index--1'}
      onClick={(e) => setOpen(false)}
    >
      <div
        id="emoji-picker"
        className="emoji_picker"
        style={modalStyle}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={open && !quickReactionsModal ? '' : 'd-none'}>
          {pickerComponent}
        </div>

        <div
          className={open && quickReactionsModal ? 'quick_reaction_container' : 'd-none'}
        >
          {!quickReactions.some((i) => i == undefined) &&
            quickReactions.map((reaction) => {
              return (
                <div
                  key={reaction?.unified || ''}
                  className="quick_reaction_item quick_reaction_item--emoji"
                  onClick={() => {
                    setEmojiData(reaction)
                    setOpen(false)
                  }}
                >
                  {/* @ts-expect-error Server Component */}
                  <em-emoji
                    shortcodes={reaction?.shortcodes}
                    size="1.5em"
                    set={EMOJI_STYLE}
                  />
                </div>
              )
            })}

          <div
            key={'add_reaction'}
            className="quick_reaction_item"
            onClick={() => {
              setQuickReactionsModal(false)
            }}
          >
            <AiOutlinePlus size="2em" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default EmojiPickerModal
