import { Avatar, Button, Popover } from 'antd'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { AppDispatch, RootState } from '../../store'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { logout } from '../../features/auth/redux/authSlice'
import { FiPower } from 'react-icons/fi'
import { LanguageSwitch } from '../LanguageSwitch'
import { attemptCheckPersonalizationAvailable } from '../../features/Personalization/PersonalizationSlice'
import './HeaderMenuAccount.scss'
import { getInitials } from '../../utils/DisplayName'
import NotificationWidget from '../NotificationWidget/NotificationWidget'

function HeaderMenuAccount() {
  const { t, i18n } = useTranslation('layout')
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()

  const organisationName = useSelector(
    (state: RootState) => state.organisation.organisation?.name,
  )
  const auth = useSelector((state: RootState) => state.auth)

  /**
   *
   */
  function logoutHandler() {
    dispatch(logout())
    navigate('/login')
  }

  const content = auth.lastName && auth.firstName && (
    <div className="menu-account">
      <Avatar
        className="avatar"
        icon={auth.lastName && getInitials(auth.firstName.concat(' ', auth.lastName))}
      />
      <div className="user">
        <span className="email">{auth.email}</span>
        {organisationName}
      </div>
      <span
        className="underline cursor-pointer hover-remove-underline"
        onClick={() => navigate('/account')}
      >
        {t('Manage my account')}
      </span>
      <LanguageSwitch />
      <Button
        className="logout-button btn-secondary d-flex d-flex-middle g-0_5rem"
        type="default"
        onClick={logoutHandler}
      >
        <FiPower />
        <span>{t('Log out')}</span>
      </Button>
    </div>
  )

  return (
    <>
      {auth.lastName && auth.firstName && (
        <>
          <NotificationWidget />

          <Popover
            content={content}
            trigger="click"
            color="#f6f6f6"
            placement="bottomRight"
            arrow={{ pointAtCenter: true }}
            align={{ offset: [21, 7] }}
          >
            <Avatar
              className="avatar"
              icon={
                auth.lastName && getInitials(auth.firstName.concat(' ', auth.lastName))
              }
            />
          </Popover>
        </>
      )}
    </>
  )
}

export default HeaderMenuAccount
