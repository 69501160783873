import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import Layout from '../../../components/Layout/Layout'
import { Divider } from 'antd'
import { ENV_CONSTANTS } from '../../../utils/Utils'
import { FiArrowLeftCircle } from 'react-icons/fi'
import { Link } from 'react-router-dom'

function Network() {
  const { i18n, t } = useTranslation('faq')

  function renderDevicesNetwork() {
    return (
      <div className="Faq">
        <div className="d-flex d-flex-middle mb-1rem">
          <Link to="/faq" className="d-flex d-flex-middle">
            <FiArrowLeftCircle size="2rem" strokeWidth={1} />
            <span className="ml-05rem">{t('Back to FAQ', { ns: 'faq' })}</span>
          </Link>
        </div>
        <h1>{t('Prepare your network for meetings')}</h1>
        <Divider />
        <p className="text-color">
          <i>{t('This article is for administrators.')}</i>
        </p>
        <p>
          <Trans
            i18nKey="INTRO_NETWORK_SETUP"
            ns="faq"
            values={{
              applicationName: ENV_CONSTANTS['DEFAULT_APPLICATION_NAME'],
            }}
          />
        </p>
        <p>{t('You should:')}</p>
        <ul>
          <li>
            <Trans
              i18nKey="NETWORK_SETUP_1"
              ns="faq"
              values={{
                applicationName: ENV_CONSTANTS['DEFAULT_APPLICATION_NAME'],
              }}
            />
          </li>
          <li>
            {t(
              'Avoid proxies, packet inspection, protocol analyzers, and quality of service (QoS).',
            )}
          </li>
          <li>{t('Measure and optimize latency, bandwidth, and your Wi-Fi network.')}</li>
        </ul>
        <Divider />
        <p>
          <b>{t('Set up your network')}</b>
        </p>

        <p className="text-color">
          <b>{t('Step 1: Set up outbound ports for media traffic')}</b>
        </p>
        <p>
          {t(
            'Update your firewall to allow media traffic to flow to and from your organization:',
          )}
        </p>
        <ul>
          <li>
            <Trans
              i18nKey="NETWORK_SETUP_2"
              ns="faq"
              values={{
                ports: ENV_CONSTANTS['MEDIA_PORTS'],
              }}
            />
            <ul>
              <li>
                <Trans
                  i18nKey={'NETWORK_SETUP_3'}
                  ns="faq"
                  values={{
                    applicationName: ENV_CONSTANTS['DEFAULT_APPLICATION_NAME'],
                  }}
                />
              </li>
            </ul>
          </li>
          <li>
            {t('For web traffic and user authentication, use outbound TCP port 443.')}
          </li>
        </ul>
        <p>
          {t(
            'The ports are allowed without any IP limitation. If UDP ports are blocked, TCP will be used. However, sustained use of TCP or proxied TCP can degrade overall meeting quality.',
          )}
        </p>

        <p className="text-color">
          <b>{t('Step 2: Allow access to uniform resource identifiers (URIs)')}</b>
        </p>
        <p>
          <Trans
            i18nKey="NETWORK_SETUP_4"
            ns="faq"
            values={{
              applicationName: ENV_CONSTANTS['DEFAULT_APPLICATION_NAME'],
            }}
          />
          <br />
          {t(
            'If there are restrictions or filtering policies for users on your network, give network access to the uniform resource identifier (URI) patterns below on this page using port 443.',
          )}
        </p>
        <p>
          {t('Domain and IP address for API endpoint connectivity and media transfer:')}
        </p>
        <ul>
          <li>
            <span className="text-color">
              {ENV_CONSTANTS['DOMAINS']['MEDIA']['HOST']}
            </span>
          </li>
          <li>{ENV_CONSTANTS['DOMAINS']['MEDIA']['IP']}</li>
        </ul>

        <p className="text-color">
          <b>{t('Step 3: Review bandwidth requirements')}</b>
        </p>
        <p>
          {t(
            'Your network should have enough bandwidth for concurrent HD video meetings, plus additional bandwidth for other needs, such as live streaming. Number of participants, screensharing, and other factors also affect bandwidth usage.',
          )}
          <br />
          <Trans
            i18nKey="NETWORK_SETUP_5"
            ns="faq"
            values={{
              applicationName: ENV_CONSTANTS['DEFAULT_APPLICATION_NAME'],
            }}
          />
        </p>
      </div>
    )
  }

  return <Layout content={renderDevicesNetwork()} footer={false} />
}

export default Network
