import { useEffect, useRef } from 'react'
import { WebsocketConnection } from './WebsocketConnection'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../../store'
import { ChatWebsocketConnection } from './AppWebsocketConnection'

export default function useWebsocketConnection(
  jwt: string,
  email: string,
  squadId: string,
  chatMeeting: boolean,
) {
  const dispatch = useDispatch<AppDispatch>()
  const wsRef = useRef<ChatWebsocketConnection | undefined>()
  const chatConnected = useSelector((state: RootState) => state.chatWebsocket.connected)

  useEffect(() => {
    wsRef.current = new ChatWebsocketConnection(dispatch, jwt, email, squadId)
    wsRef.current.connect()
    const disconnectable = wsRef.current

    return () => {
      disconnectable.disconnect()
    }
  }, [])

  useEffect(() => {
    if (chatConnected && wsRef.current) {
      wsRef.current.sendGetSquad(squadId)
      wsRef.current.sendListChats(squadId, chatMeeting)
    }
  }, [chatConnected])

  return wsRef
}
