import React, { useRef } from 'react'
import {
  idleRenameDriveFolderStatus,
  renameDriveFolder,
  setFolderToRename,
} from '../../features/Drives/redux/drivesSlice'
import { FormInstance } from 'antd/lib'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../store'
import { Form, Input, InputRef, Modal } from 'antd'
import { useAttemptsListener } from 'auxasphere-react-kit'
import { useToastContext } from '../Toast/ToastContext'

interface Props {
  renameFolderForm: FormInstance
}

function RenameFolderModal({ renameFolderForm }: Props) {
  const { t } = useTranslation('drives')
  const dispatch = useDispatch<AppDispatch>()
  const selectedDriveFolder = useSelector(
    (state: RootState) => state.drive.selectedDriveFolder,
  )
  const renameDriveFolderStatus = useSelector(
    (state: RootState) => state.drive.renameDriveFolderStatus,
  )

  const folderToRename = useSelector((state: RootState) => state.drive.folderToRename)

  const inputRef = useRef<InputRef>(null)
  const { ToastOpen } = useToastContext()

  useAttemptsListener([
    [
      renameDriveFolderStatus,
      {
        success: () => {
          ToastOpen({
            message: t('Folder was successfully renamed.'),
            type: 'success',
          })
          resetRenameFolderModal()
        },
        NOT_ENOUGH_FOLDER_RIGHT: () => {
          ToastOpen({
            message: t(
              'You do not have the necessary rights to rename folders in this project.',
            ),
            type: 'error',
          })
          resetRenameFolderModal()
        },
        FOLDER_ALREADY_EXIST: () => {
          ToastOpen({
            message: t('Unable to rename the folder because this name already exists.'),
            type: 'error',
          })
        },
        FETCH_ERROR: () => {
          ToastOpen({
            message: t('The drive service is not available at the moment.'),
            type: 'error',
            duration: 50,
          })
        },
        unknown_error: () => {
          ToastOpen({
            message: t('Error renaming folder.'),
            type: 'error',
          })
        },
      },
      () => dispatch(idleRenameDriveFolderStatus()),
    ],
  ])

  /**
   * Reset the Rename Folder modal state.
   */
  function resetRenameFolderModal() {
    renameFolderForm.resetFields()
    dispatch(setFolderToRename(undefined))
  }

  /**
   * Handle the close event of the Rename Folder modal.
   */
  function onModalRenameFolderClose() {
    resetRenameFolderModal()
  }

  /**
   * Perform the folder renaming action.
   */
  function onRenameFolder() {
    if (selectedDriveFolder && folderToRename) {
      dispatch(
        renameDriveFolder({
          name: renameFolderForm.getFieldsValue().name,
          id: folderToRename.id,
          driveId: selectedDriveFolder?.driveId,
        }),
      )
    }
  }

  return (
    <Modal
      centered
      open={folderToRename != undefined}
      title={t('Rename folder')}
      onOk={renameFolderForm.submit}
      onCancel={onModalRenameFolderClose}
      okText={t('Validate', { ns: 'common' })}
      cancelText={t('Cancel', { ns: 'common' })}
      afterOpenChange={(open) => open && inputRef.current?.focus({ cursor: 'all' })}
    >
      <div className="modal-content-container">
        <Form
          name="renameFolderForm"
          layout="vertical"
          autoComplete="off"
          form={renameFolderForm}
          onFinish={onRenameFolder}
          initialValues={{
            name: folderToRename?.name,
          }}
        >
          <Form.Item
            style={{ marginBottom: '0px' }}
            name="name"
            label={t('Folder name')}
            rules={[{ required: true, message: t('Please input folder name') }]}
          >
            {/* <Input /> */}
            <Input
              ref={inputRef}
              onMouseEnter={() => inputRef.current?.focus({ cursor: 'end' })}
            />
          </Form.Item>
        </Form>
      </div>
    </Modal>
  )
}

export default RenameFolderModal
