import { Tooltip, Upload, UploadProps } from 'antd'
import { UploadFile } from 'antd/es/upload/interface'
import _ from 'lodash'
import React, {
  type DragEvent as DragEventInterface,
  Ref,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { FaFileUpload } from 'react-icons/fa'
import OutsideClickHandler from '../../../utils/hooks/OutsideClickHandler'
import useEventListener from '../../../utils/hooks/useEventListener'
import './FileUploadDialog.scss'
import { Chat } from './chatsSlice'
import { UploadRef } from 'antd/es/upload/Upload'
import RcUpload from 'rc-upload'
import { renderIcon } from '../../../utils/Utils'
import { IoCloseOutline } from 'react-icons/io5'
import { useToastContext } from '../../Toast/ToastContext'
const { Dragger } = Upload
const CHAT_MAX_FILES_UPLOAD = 12

export interface FileUploadInProgressInterface {
  filesInProgress: UploadFile<any>[] | undefined
  setFilesInprogress: React.Dispatch<React.SetStateAction<UploadFile<any>[] | undefined>>
}
interface Props extends FileUploadInProgressInterface {
  chat: Chat
}

interface ExtendedUploadRef<T = any> extends Omit<UploadRef<T>, 'upload'> {
  upload: Omit<RcUpload, 'uploader'> & {
    uploader: any
  }
}
export type UploadMethod = 'dragger' | 'button'
export interface FileUploadInterface {
  openDeviceFolder: (type: UploadMethod) => void
}

const FileUploadDialog = forwardRef(
  (
    { chat, filesInProgress, setFilesInprogress }: Props,
    ref: Ref<FileUploadInterface>,
  ) => {
    const uploadRef = useRef<ExtendedUploadRef<any> | null>(null)

    const [isUploadDialogPresented, setIsUploadDialogPresented] = useState<boolean>(false)
    useImperativeHandle(ref, () => ({
      openDeviceFolder: (type) => {
        type === 'dragger'
          ? setIsUploadDialogPresented(true)
          : uploadRef.current?.upload?.uploader.fileInput.click()
      },
    }))

    const { t } = useTranslation('chat')
    const { ToastOpenOnce } = useToastContext()

    function handlePasteFiles(e: ClipboardEvent) {
      if (isUploadDialogPresented) return

      const items = e.clipboardData?.items

      if (!items) return
      const arrItems = Array.from(items)
      if (arrItems.every((item) => item.kind !== 'file')) return
      e.preventDefault() //to not paste file path if focused in input
      const fileList = new DataTransfer()
      arrItems.forEach((item) => {
        const file = item.getAsFile()
        if (!file) return
        fileList.items.add(file)
      })

      if (fileList.items.length > 0) {
        const dropEvent = new DragEvent('drop', {
          dataTransfer: fileList,
          bubbles: true,
          cancelable: true,
        })
        uploadRef.current?.upload?.uploader.fileInput.dispatchEvent(dropEvent)
      }
    }

    useEventListener('paste', handlePasteFiles)

    function handleDragLeave(e: DragEventInterface<HTMLDivElement>) {
      e.preventDefault()
      cancelUpload()
    }

    function cancelUpload() {
      setIsUploadDialogPresented(false)
    }

    function onChange(fileList: UploadFile<any>[]) {
      setFilesInprogress((prev) => {
        const mergedFiles = _.mergeWith(
          _.keyBy(prev, 'uid'),
          _.keyBy(fileList, 'uid'),
          // (obj1, obj2) => ({ ...obj1, ...obj2 }),
        )
        const finalMergedArray = _.values(mergedFiles)
        return finalMergedArray
      })
    }

    useEffect(() => {
      if (filesInProgress?.every((file) => file.status === 'done'))
        setIsUploadDialogPresented(false)
    }, [filesInProgress])

    function handleBeforeUpload(file: UploadFile<any>, fileList: UploadFile<any>[]) {
      if (file.type && (!file.type.includes('image') || file.type.includes('svg'))) {
        file.thumbUrl = renderIcon(file.type || 'default', file.name)
      }
      if (file.type === '') {
        // Warning for files with no type, it will block them
        ToastOpenOnce({
          style: { paddingInlineEnd: '0px' },
          message: t(
            'Type not supported, try importing a .zip version of this folder or the files individually.',
          ),
          type: 'error',
          key: 'file_type_error',
        })
        setIsUploadDialogPresented(false)
        return Upload.LIST_IGNORE
      }
      if (fileList.length + (filesInProgress?.length || 0) > CHAT_MAX_FILES_UPLOAD) {
        ToastOpenOnce({
          message:
            t('You can upload a maximum of') +
            ` ${CHAT_MAX_FILES_UPLOAD}  ` +
            t('files simultaneously, some files have been removed.'),
          type: 'warning',
          key: 'max_files_warning',
        })
      }
      return true
    }

    const draggerProps: UploadProps = {
      // directory: true,
      name: 'file',
      multiple: true,
      action: `${process.env.REACT_APP_FILE_ENCRYPTOR_V2_BASE_URL}/${chat.fileEncryptionKey}`,
      onChange: (info) => onChange(info.fileList),
      // onDrop(e) {
      //   console.log('Dropped files', e.dataTransfer.files)
      // },
      showUploadList: false,
      // openFileDialogOnClick: true,
      fileList: filesInProgress,
    }
    return (
      <div
        className="chat_file_upload_overlay"
        hidden={
          !isUploadDialogPresented ||
          (filesInProgress && !filesInProgress?.every((file) => file.status === 'done'))
        }
        onDragLeave={handleDragLeave}
      >
        <OutsideClickHandler onOutsideClick={cancelUpload} className="chat_file_upload">
          {/* TODO: review ref */}
          <span className="close_dragger_container">
            <Tooltip title={t('Close', { ns: 'common' })} placement="top">
              <div
                className="action-rounded-icon"
                onClick={() => setIsUploadDialogPresented(false)}
              >
                <IoCloseOutline size="1.5em" className="close_dragger" />
              </div>
            </Tooltip>
          </span>
          {
            <Dragger
              className="chat_file_upload_dragger"
              {...draggerProps}
              ref={uploadRef as React.RefObject<UploadRef<any>>}
              maxCount={CHAT_MAX_FILES_UPLOAD}
              beforeUpload={handleBeforeUpload}
            >
              <div className="chat_file_upload_middle">
                <p className="ant-upload-drag-icon">
                  <FaFileUpload />
                </p>
                <p className="ant-upload-text">{t('Drag file to this area to upload')}</p>
              </div>
            </Dragger>
          }
        </OutsideClickHandler>
      </div>
    )
  },
)

FileUploadDialog.displayName = 'FileUploadDialog'
export default FileUploadDialog
