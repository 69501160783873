import React, { Ref, forwardRef, useImperativeHandle, useRef, useState } from 'react'
import { createSelector } from '@reduxjs/toolkit'
import { ColorPicker, Form, Input, InputRef, Row, Spin, Tag } from 'antd'
import { Color } from 'antd/es/color-picker'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import AddUserGroup from './AddUserGroup/AddUserGroup'
import { GroupParticipant } from './redux/groupsSlice'

export interface GroupFormValues {
  name: string
  color: string
  participants: string[]
}

export interface ChangedParticipants {
  removingParticipants: string[]
  addingParticipants: string[]
}
interface Props {
  participantsToBeDisplayed: GroupParticipant[]
  onGroupSubmit: (
    values: GroupFormValues,
    changedParticipants: ChangedParticipants,
  ) => void
}

export interface GroupFormInterface {
  resetFields: () => void
  submit: () => void
}
const GroupForm = forwardRef(
  ({ participantsToBeDisplayed, onGroupSubmit }: Props, ref: Ref<GroupFormInterface>) => {
    useImperativeHandle(ref, () => ({
      resetFields: groupForm.resetFields,
      submit: groupForm.submit,
    }))
    const [groupForm] = Form.useForm<GroupFormValues>()

    const readonly = useSelector(
      (state: RootState) => state.groups.groupToModify?.readonly,
    )

    const { t } = useTranslation('groups')

    function handleParticipantsChange(
      selectedParticipantKeys: React.Key[],
      selectedParticipants: GroupParticipant[],
    ) {
      groupForm.setFieldValue('participants', selectedParticipantKeys)
    }

    const selectAuth = (state: RootState) => state.auth
    const selectGroupToModify = (state: RootState) => state.groups.groupToModify
    const selectedParticipants = Form.useWatch('participants', groupForm)
    const [changedParticipants, setChangedParticipants] = useState<ChangedParticipants>({
      removingParticipants: [],
      addingParticipants: [],
    })

    const groupToModify = useSelector((state: RootState) => state.groups.groupToModify)

    const defaultSelectedParticipantsSelector = createSelector(
      [selectGroupToModify, selectAuth],
      (groupToModify, auth) => {
        if (groupToModify?.group.participants)
          return groupToModify?.group.participants?.map(
            (participant) => participant.email,
          )
        else if (auth.email) return [auth.email]
        else return []
      },
    )

    const defaultSelectedParticipants = useSelector(defaultSelectedParticipantsSelector)
    const groupNameInputRef = useRef<InputRef>(null)
    groupNameInputRef.current?.focus()

    return (
      <>
        <Form
          className="m-1em"
          name="basic"
          layout="vertical"
          autoComplete="off"
          form={groupForm}
          onValuesChange={(changedValues, values) => {
            if ('participants' in changedValues) {
              const addingParticipants =
                _.difference(values.participants, defaultSelectedParticipants) || []
              const removingParticipants =
                _.difference(defaultSelectedParticipants, values.participants) || []
              setChangedParticipants({ removingParticipants, addingParticipants })
            }
          }}
          onFinish={(values) => onGroupSubmit(values, changedParticipants)}
          initialValues={
            groupToModify?.group
              ? groupToModify.group
              : {
                  participants: defaultSelectedParticipants,
                  color: '#224160',
                }
          }
        >
          <Row>
            <Form.Item
              style={{ marginBottom: '0px', marginRight: '1em', flex: 1 }}
              name="name"
              label={t('Group name')}
              rules={[{ required: true, message: t('Please input group name') }]}
            >
              <Input disabled={readonly} ref={groupNameInputRef} />
            </Form.Item>

            <Form.Item
              label={t('Color', { ns: 'common' })}
              name="color"
              normalize={(color: Color) => color.toHexString()}
            >
              <ColorPicker format="hex" disabled={readonly} />
            </Form.Item>
          </Row>

          <Form.Item
            className="mt-1rem"
            name="participants"
            label={`${t('Users', { ns: 'room' })} ${
              selectedParticipants ? ': ' + selectedParticipants.length : ''
            }`}
            // label={getDetailedParticipantsLabel()}
          >
            {!groupToModify || groupToModify.group.participants ? ( //be careful with defaultSelectedParticipants in child AddUserGroup
              <AddUserGroup
                participantsToBeDisplayed={participantsToBeDisplayed}
                defaultSelectedParticipants={defaultSelectedParticipants}
                //TODO: as array to be used later (group participant rights, maybe restrict to admin remove another admin)
                onChange={handleParticipantsChange}
              />
            ) : (
              <Spin />
            )}
          </Form.Item>
        </Form>
      </>
    )
  },
)

GroupForm.displayName = 'GroupForm'
export default GroupForm
