import React, { MutableRefObject, useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import { useIdleTimer } from 'react-idle-timer'
import { callLogoFilename } from '../../assets/personnalization'
import { Dropdown, MenuProps, Spin, Tooltip } from 'antd'
import { TbScreenShare, TbScreenShareOff } from 'react-icons/tb'
import { AppDispatch, RootState } from '../../store'
import { useDispatch, useSelector } from 'react-redux'
import RoomClient from '../../features/room/RoomClient'
import { ProducerModel } from '../../features/room/redux/model'
import { BsFilePerson, BsFillExclamationCircleFill } from 'react-icons/bs'
import { GoFile } from 'react-icons/go'
import { IoChatboxEllipsesOutline, IoSettingsOutline } from 'react-icons/io5'
import { useNavigate, useParams } from 'react-router-dom'
import { ImPhoneHangUp } from 'react-icons/im'
import { MdKeyboardDoubleArrowDown, MdKeyboardDoubleArrowUp } from 'react-icons/md'
import { useTranslation } from 'react-i18next'
import { FiMoreHorizontal } from 'react-icons/fi'
import ModalConfirmation, { ModalConfirmationInterface } from '../Modal/ModalConfirmation'
import useEventListener from '../../utils/hooks/useEventListener'
import { AiOutlineFullscreen, AiOutlineFullscreenExit } from 'react-icons/ai'
import CallControlsWebcam from './CallControlsWebcam'
import CallControlsMic from './CallControlsMic'
import packageInfo from '../../../package.json'
import { toggleFullScreen } from '../../redux/appSlice'
import DateTime from '../Time'
import { LOGO } from '../../assets/logos'
import { createSelector } from '@reduxjs/toolkit'

interface Props {
  roomClient: MutableRefObject<RoomClient>
  documentDrawerOpen: boolean
  messagesDrawerOpen: boolean
  participantsDrawerOpen: boolean
  settingDrawerOpen: boolean
  encryptKey?: string
  setOpenDrawerDocuments: () => void
  setOpenDrawerMessages: () => void
  setOpenDrawerParticipants: () => void
  setOpenDrawerSettings: () => void
}

function CallControls({
  roomClient,
  documentDrawerOpen,
  messagesDrawerOpen,
  participantsDrawerOpen,
  settingDrawerOpen,
  encryptKey,
  setOpenDrawerDocuments,
  setOpenDrawerMessages,
  setOpenDrawerParticipants,
  setOpenDrawerSettings,
}: Props) {
  const navigate = useNavigate()
  const { t } = useTranslation('room')
  const inviteToken = useSelector((state: RootState) => state.invite.roomToken)
  const isPhone = useSelector((state: RootState) => state.app.isPhone)
  const organization = useSelector((state: RootState) => state.organisation.organisation)
  const room = useSelector((state: RootState) => state.room)
  const [hideCallControls, setHideCallControls] = useState(false)
  const [hideOnMouseStop, setHideOnMouseStop] = useState(false)
  const bokehEffectActive = useSelector((state: RootState) => state.me.bokehEffectActive)
  const shareInProgress = useSelector((state: RootState) => state.me.shareInProgress)
  const promotedFullScreen = useSelector(
    (state: RootState) => state.room.promotedFullScreen,
  )
  const [isMobile, setIsMobile] = useState(false)
  const [dropdownOpened, setDropdownOpened] = useState(false)
  const [tooltipVisible, setTooltipVisible] = useState(true)
  const email = useSelector((state: RootState) => state.auth.email)
  const dispatch = useDispatch<AppDispatch>()
  const fullscreen = useSelector((state: RootState) => state.app.fullscreen)

  const selectChatsDetails = (state: RootState) => state.chats.chatsDetails
  const newMessageSelector = createSelector(selectChatsDetails, (chatsDetails) => {
    let newMessage = false
    Object.values(chatsDetails).forEach((chat) => {
      if (chat.unreadMessagesCount > 0) newMessage = true
    })
    return newMessage
  })
  const newMessage = useSelector(newMessageSelector)

  const shareProducer: ProducerModel | undefined = useSelector((state: RootState) =>
    Object.values(state.producers.producers).find(
      (producer) => producer.type === 'share',
    ),
  )

  let shareState = 'off'
  if (shareProducer) {
    shareState = 'on'
  }

  const items: MenuProps['items'] = [
    {
      key: 'settings',
      label: renderSettings(),
      className: 'call-controls-dropdown',
    },
    {
      key: 'messages',
      label: renderMessages(),
      className: 'call-controls-dropdown',
    },
    {
      key: 'documents',
      label: renderDocuments(),
      className: !encryptKey ? 'display-none' : 'call-controls-dropdown',
    },
    {
      key: 'participants',
      label: renderParticipants(),
      className: room.role != 'creator' ? 'display-none' : '',
    },
  ]

  useEffect(() => {
    updateSize()
  }, [])
  useEventListener('resize', updateSize)

  useIdleTimer({
    onIdle: () => {
      if (!hideCallControls) {
        setHideOnMouseStop(true)
      }
    },
    onAction: () => {
      setHideOnMouseStop(false)
    },
    timeout: 3_000,
    events: ['mousemove'],
  })

  function updateSize() {
    setIsMobile(window.innerWidth < 768)
  }

  function disableShare() {
    if (!shareInProgress) {
      roomClient.current.disableShare()
    }
  }

  function enableShare() {
    if (!shareInProgress) {
      roomClient.current.enableShare()
    }
  }

  /**
   *
   */
  function renderScreenShareControl() {
    if (shareState === 'on') {
      return (
        <Tooltip title={t('Stop sharing screen')}>
          <div className="call-control call-control--active" onClick={disableShare}>
            {shareInProgress ? (
              <Spin />
            ) : (
              <TbScreenShareOff size="2rem" color="#ffffff" strokeWidth={1} />
            )}
          </div>
        </Tooltip>
      )
    }

    if (shareState === 'off') {
      return (
        <Tooltip title={t('Share screen')}>
          <div className="call-control call-control--inactive" onClick={enableShare}>
            {shareInProgress ? (
              <Spin />
            ) : (
              <TbScreenShare size="2rem" color="#ffffff" strokeWidth={1} />
            )}
          </div>
        </Tooltip>
      )
    }
  }

  /**
   * Render Blur control.
   * @returns
   */
  function renderBlurCamera() {
    return (
      <>
        {bokehEffectActive ? (
          <Tooltip title={t('Stop blurring background')}>
            <div
              className="call-control call-control--active"
              onClick={() => roomClient.current.toggleBokehEffect(false)}
            >
              {<BsFilePerson size="2rem" color="#ffffff" />}
            </div>
          </Tooltip>
        ) : (
          <Tooltip title={t('Blur background')}>
            <div
              className="call-control call-control--inactive"
              onClick={() => roomClient.current.toggleBokehEffect(true)}
            >
              {<BsFilePerson size="2rem" color="#ffffff" />}
            </div>
          </Tooltip>
        )}
      </>
    )
  }

  function toggleDrawerMessages() {
    setTooltipVisible(false)
    setDropdownOpened(false)
    setOpenDrawerMessages()
    setTimeout(() => setTooltipVisible(true), 100) // to reset the tooltip pause
  }

  function renderMessages() {
    return (
      <Tooltip title={t('Messages')}>
        <div
          className={classNames({
            'call-control': !isMobile,
            'call-control-dropdown': isMobile,
            'call-control--active': messagesDrawerOpen,
          })}
          onClick={toggleDrawerMessages}
        >
          <IoChatboxEllipsesOutline size="2rem" color="#ffffff" />

          {newMessage && (
            <BsFillExclamationCircleFill
              fill="#f50"
              className="call-control-attention"
              strokeWidth={1}
            />
          )}
        </div>
      </Tooltip>
    )
  }

  function toggleDrawerDocuments() {
    setTooltipVisible(false)
    setDropdownOpened(false)
    setOpenDrawerDocuments()
    setTimeout(() => setTooltipVisible(true), 100) // to reset the tooltip pause
  }

  function renderDocuments() {
    if (encryptKey) {
      return (
        <Tooltip title={t('Documents', { ns: 'common' })}>
          <div
            className={classNames({
              'call-control': !isMobile,
              'call-control-dropdown': isMobile,
              'call-control--active': documentDrawerOpen,
            })}
            onClick={toggleDrawerDocuments}
          >
            <GoFile size="2rem" color="#ffffff" />
          </div>
        </Tooltip>
      )
    }
  }

  function toggleDrawerParticipants() {
    setTooltipVisible(false)
    setDropdownOpened(false)
    setOpenDrawerParticipants()
    setTimeout(() => setTooltipVisible(true), 100) // to reset the tooltip pause
  }

  function renderParticipants() {
    if (room.role === 'creator') {
      return room.waitingInvited.length > 0 ? (
        <Tooltip
          title={<strong>{t('Waiting participants')}</strong>}
          open={
            tooltipVisible &&
            room.waitingInvited.length > 0 &&
            !hideCallControls &&
            !hideOnMouseStop
          }
          color="#e12727"
        >
          <div
            className={classNames({
              'call-control': !isMobile,
              'call-control-dropdown': isMobile,
              'call-control--active': participantsDrawerOpen,
            })}
            onClick={toggleDrawerParticipants}
          >
            {isMobile ? (
              <img className="users" src="/images/users_blue.svg" alt="users" />
            ) : (
              <img className="users" src="/images/users.svg" alt="users" />
            )}
            {room.waitingInvited.length > 0 && (
              <BsFillExclamationCircleFill
                fill="#f50"
                className="call-control-attention"
                strokeWidth={1}
              />
            )}
          </div>
        </Tooltip>
      ) : (
        <Tooltip title={t('Participants')}>
          <div
            className={classNames({
              'call-control': !isMobile,
              'call-control-dropdown': isMobile,
              'call-control--active': participantsDrawerOpen,
            })}
            onClick={toggleDrawerParticipants}
          >
            {isMobile ? (
              <img className="users" src="/images/users_blue.svg" alt="users" />
            ) : (
              <img className="users" src="/images/users.svg" alt="users" />
            )}
            {room.waitingInvited.length > 0 && (
              <BsFillExclamationCircleFill
                fill="#f50"
                className="call-control-attention"
                strokeWidth={1}
              />
            )}
          </div>
        </Tooltip>
      )
    }
  }

  function toggleDrawerSettings() {
    setTooltipVisible(false)
    setDropdownOpened(false)
    setOpenDrawerSettings()
    setTimeout(() => setTooltipVisible(true), 100) // to reset the tooltip pause
  }

  function renderSettings() {
    return (
      <Tooltip title={t('Settings')}>
        <div
          className={classNames({
            'call-control': !isMobile,
            'call-control-dropdown': isMobile,
            'call-control--active': settingDrawerOpen,
          })}
          onClick={toggleDrawerSettings}
        >
          <IoSettingsOutline size="2rem" color="#ffffff" />
        </div>
      </Tooltip>
    )
  }

  function renderFullscreen() {
    return (
      <div
        className={classNames({
          'call-control': !isMobile,
        })}
        onClick={() => dispatch(toggleFullScreen())}
      >
        {fullscreen ? (
          <Tooltip title={t('Exit full screen')}>
            <AiOutlineFullscreenExit size="2rem" color="#ffffff" />
          </Tooltip>
        ) : (
          <Tooltip title={t('Full screen')}>
            <AiOutlineFullscreen size="2rem" color="#ffffff" />
          </Tooltip>
        )}
      </div>
    )
  }

  function renderMenu() {
    if (email) {
      return (
        <Tooltip title={t('Menu', { ns: 'layout' })}>
          <a
            className={classNames({
              'call-control-white': true,
              'call-control': !isMobile,
            })}
            href="/applications"
            target="_blank"
          >
            <img src={LOGO.menu} />
          </a>
        </Tooltip>
      )
    }
  }

  return (
    <>
      <div
        className={classNames({
          'banner-container': !promotedFullScreen,
          'banner-container--drawer-opened':
            documentDrawerOpen ||
            messagesDrawerOpen ||
            participantsDrawerOpen ||
            settingDrawerOpen,
        })}
        hidden={hideCallControls || hideOnMouseStop}
      >
        {!promotedFullScreen && (
          <div className="banner-content">
            <Tooltip
              title={`${t('Version:', { ns: 'common' })} ${packageInfo.version}`}
              placement="bottom"
            >
              <img className="logo-image" src={`/logo/${callLogoFilename}`} alt="logo" />
            </Tooltip>
            <DateTime />
          </div>
        )}
      </div>
      <div
        className="call-controls-container"
        hidden={hideCallControls || hideOnMouseStop}
      >
        <div className="call-controls">
          <div className="call-controls--left">
            <CallControlsMic roomClient={roomClient} />
            <CallControlsWebcam roomClient={roomClient} />
            {renderBlurCamera()}
          </div>
          <div className="call-controls--middle">
            {!isPhone && renderScreenShareControl()}
            {isMobile ? (
              <Dropdown
                menu={{ items }}
                placement="top"
                open={dropdownOpened}
                className="call-controls-dropdown"
              >
                <div
                  className={classNames({
                    'call-control': true,
                  })}
                  onClick={() => setDropdownOpened(!dropdownOpened)}
                >
                  <FiMoreHorizontal size="2rem" color="#ffffff" />
                </div>
              </Dropdown>
            ) : (
              <>
                {renderDocuments()}
                {renderMessages()}
                {renderMenu()}
                {renderParticipants()}
                {renderSettings()}
                {renderFullscreen()}
              </>
            )}
          </div>
          <div className="call-controls--right">
            <Tooltip title={t('Hang up')}>
              <div
                className="call-control call-control--hangup"
                onClick={() => {
                  if (!organization) navigate(`/invite/meeting/${inviteToken}`)
                  else navigate('/upcoming-meetings')
                }}
              >
                <ImPhoneHangUp size="2rem" color="#ffffff" />
              </div>
            </Tooltip>
          </div>
          <Tooltip title={t('Hide call controls')}>
            <div className="hide-call-controls" onClick={() => setHideCallControls(true)}>
              <MdKeyboardDoubleArrowDown size="1rem" color="#ffffff" />
            </div>
          </Tooltip>
        </div>
      </div>
      {hideCallControls &&
        (room.waitingInvited.length > 0 ? (
          <Tooltip
            title={<strong>{t('Waiting participants')}</strong>}
            open={true}
            color="#e12727"
          >
            <div
              className="show-call-controls"
              hidden={!hideCallControls}
              onClick={() => setHideCallControls(false)}
            >
              <div className="d-flex d-flex-center d-flex-middle">
                <MdKeyboardDoubleArrowUp size="1rem" color="#ffffff" />
                {room.waitingInvited.length > 0 && (
                  <BsFillExclamationCircleFill
                    fill="#f50"
                    className="call-control-attention"
                    strokeWidth={1}
                  />
                )}
              </div>
            </div>
          </Tooltip>
        ) : (
          <div
            className="show-call-controls"
            hidden={!hideCallControls}
            onClick={() => setHideCallControls(false)}
          >
            <div className="d-flex d-flex-center d-flex-middle">
              <MdKeyboardDoubleArrowUp size="1rem" color="#ffffff" />
              {room.waitingInvited.length > 0 && (
                <BsFillExclamationCircleFill
                  fill="#f50"
                  className="call-control-attention"
                  strokeWidth={1}
                />
              )}
            </div>
          </div>
        ))}
    </>
  )
}

export default CallControls
