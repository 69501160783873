import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { FetchStatus } from '../../utils/FetchStatus'
import {
  Notification,
  attemptDeleteNotification,
  attemptMarkNotificationSeen,
} from '../../components/NotificationWidget/liveWidgetSlice'

interface State {
  notifications?: Notification[]
  totalCount?: number
  currentPage?: number
  pageSize?: number
  attemptFetchNotificationsStatus: FetchStatus
}

export const initialState: State = {
  attemptFetchNotificationsStatus: 'idle',
  currentPage: 1,
}

interface AttemptFetchNotificationsResponse {
  notifications: Notification[]
  totalCount: number
  pageSize: number
}

export const attemptFetchNotifications = createAsyncThunk(
  'notification/attemptFetchNotifications',
  async (payload, { getState }) => {
    const state = getState() as any
    const jwt = state.auth.jwt
    const page = state.notifications.currentPage

    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/notification/persisted/chunk/${page - 1}`,
      {
        method: 'GET',
        headers: {
          Authorization: `${jwt}`,
        },
      },
    )

    const body = await response.json()

    return {
      notifications: body.notifications,
      totalCount: body.totalCount,
      pageSize: body.pageSize,
    }
  },
)

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setCurrentPage: (state, { payload }: PayloadAction<number>) => {
      state.currentPage = payload
    },
    idleAttemptFetchNotificationsStatus: (state) => {
      state.attemptFetchNotificationsStatus = 'idle'
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(attemptFetchNotifications.pending, (state) => {
        state.attemptFetchNotificationsStatus = 'loading'
      })
      .addCase(
        attemptFetchNotifications.fulfilled,
        (state, { payload }: PayloadAction<AttemptFetchNotificationsResponse>) => {
          state.attemptFetchNotificationsStatus = 'success'
          state.notifications = payload.notifications
          state.totalCount = payload.totalCount
          state.pageSize = payload.pageSize
        },
      )
      .addCase(attemptFetchNotifications.rejected, (state) => {
        state.notifications = []
        state.attemptFetchNotificationsStatus = 'error'
        state.currentPage = 1
        state.totalCount = undefined
      })
      .addCase(
        attemptMarkNotificationSeen.fulfilled,
        (state, { payload }: PayloadAction<Notification>) => {
          for (const n of state.notifications || []) {
            if (n.id === payload.id) {
              n.unseenCount = 0
            }
          }
        },
      )
      .addCase(
        attemptDeleteNotification.fulfilled,
        (state, { payload }: PayloadAction<Notification>) => {
          if (state.notifications) {
            state.notifications = state.notifications.filter((n) => n.id !== payload.id)
          }
        },
      )
  },
})

export const { setCurrentPage, idleAttemptFetchNotificationsStatus } =
  notificationsSlice.actions

export default notificationsSlice.reducer
