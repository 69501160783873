import React from 'react'
import { DataNode } from 'antd/lib/tree'
import { useTranslation } from 'react-i18next'
export default function useDatesTreeOptions(datesTreeChildren: DataNode[]) {
  const MAX_DATES_ARRAY = 10
  const numberOfDatesDisplayed = 1
  const { t } = useTranslation('meetings')
  const tree: DataNode[] = [
    {
      title: t('Selected dates'),
      key: 'parentTree',
      children:
        datesTreeChildren.length > MAX_DATES_ARRAY - 1
          ? [...datesTreeChildren]
          : [
              ...datesTreeChildren,
              ...(datesTreeChildren.length >= numberOfDatesDisplayed
                ? []
                : new Array(numberOfDatesDisplayed - datesTreeChildren.length)
                    .fill(0)
                    .map((_, index) => {
                      return {
                        title: () => {
                          return (
                            <span className="tree-dates placeholder">
                              <i>{t('Add a date above')}</i>
                            </span>
                          )
                        },
                        key: index,
                      }
                    })),
            ],
    },
  ]

  return { tree }
}
