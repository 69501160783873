import React from 'react'
import { Col, Input, Row, Select, Form } from 'antd'
import { useTranslation } from 'react-i18next'
import SelectLanguage from '../../components/Select/SelectLanguage'

function AccountGeneral() {
  const { i18n, t } = useTranslation(['account', 'reinitpassword'])

  return (
    <Row gutter={[24, 8]}>
      <Col span={24}>
        <Form.Item label={t('Email')} name="email" rules={[{ required: true }]}>
          <Input disabled />
        </Form.Item>
      </Col>
      <Col span={24} sm={12}>
        <Form.Item
          label={t('First name')}
          name="firstName"
          rules={[{ required: true, message: t('Please input your first name') }]}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col span={24} sm={12}>
        <Form.Item
          label={t('Last name')}
          name="lastName"
          rules={[{ required: true, message: t('Please input your last name') }]}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col span={24} sm={12}>
        <Form.Item label={t('Phone')} name="tel">
          <Input />
        </Form.Item>
      </Col>
      <Col span={24} sm={12}>
        <Form.Item
          label={t('Interface languages', { ns: 'room' })}
          name="lang"
          rules={[
            { required: true, message: t('Please select your preferred language') },
          ]}
        >
          <SelectLanguage
            className="select-language same-as-input"
            onChange={(key) => i18n.changeLanguage(key)}
          />
        </Form.Item>
      </Col>
    </Row>
  )
}

export default AccountGeneral
