import React from 'react'
import { Modal } from 'antd'
import { Trans, useTranslation } from 'react-i18next'
import {
  deleteRoom as fetchDeleteRoom,
  deleteGeneratedRoom as fetchDeleteGeneratedRoom,
  setRoomToDelete,
  deletePeriodicRoom,
} from '../../features/Meetings/MeetingsSlice'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../store'
import { limitText } from '../../utils/Utils'
import { unescape } from 'html-escaper'

export default function ModalDeleteMeeting() {
  const { t } = useTranslation('meetings')
  const dispatch = useDispatch<AppDispatch>()
  const email = useSelector((state: RootState) => state.auth.email)
  const roomToDelete = useSelector((state: RootState) => state.rooms.roomToDelete)
  const deleteRoomStatus = useSelector((state: RootState) => state.rooms.deleteRoomStatus)
  const deleteGeneratedRoomStatus = useSelector(
    (state: RootState) => state.rooms.deleteGeneratedRoomStatus,
  )
  const deletePeriodicRoomStatus = useSelector(
    (state: RootState) => state.rooms.deletePeriodicRoomStatus,
  )
  const isCancel =
    roomToDelete?.room?.creator?.email === email && !roomToDelete?.room?.archived

  const nbParticipants =
    (roomToDelete?.room?.participants?.length || 0) +
    (roomToDelete?.room?.invited?.length || 0) +
    (roomToDelete?.room?.creator ? 1 : 0)
  function confirmedDeleteRoom() {
    if (roomToDelete?.room?.periodicRoomId) {
      dispatch(
        fetchDeleteGeneratedRoom({
          periodicRoomId: roomToDelete?.room?.periodicRoomId || '',
          date: roomToDelete?.room?.startDate || '',
          id: roomToDelete?.room?.id || '',
        }),
      )
    } else if (roomToDelete?.room?.periodicity) {
      dispatch(deletePeriodicRoom({ roomId: roomToDelete.room.id || '' }))
    } else {
      dispatch(
        fetchDeleteRoom({
          roomId: roomToDelete?.room?.id || '',
          pending: roomToDelete?.pending || false,
        }),
      )
    }
  }

  return (
    <Modal
      centered
      title={isCancel ? t('Cancel meeting') : t('Leave the meeting')}
      open={!!roomToDelete?.room}
      onOk={confirmedDeleteRoom}
      okText={t('Yes', { ns: 'common' })}
      confirmLoading={
        deleteRoomStatus === 'loading' ||
        deleteGeneratedRoomStatus === 'loading' ||
        deletePeriodicRoomStatus === 'loading'
      }
      onCancel={() => dispatch(setRoomToDelete({ room: undefined, pending: false }))}
      cancelText={t('No', { ns: 'common' })}
    >
      <p>
        <Trans
          i18nKey={isCancel ? 'CANCEL_MEETING' : 'LEAVE_MEETING'}
          shouldUnescape={true}
          ns="meetings"
          values={{
            meetingTitle: unescape(limitText(roomToDelete?.room?.name)),
          }}
          components={{ bold: <strong /> }}
        />
      </p>
      {isCancel && nbParticipants > 0 && (
        <p>
          <Trans
            i18nKey="MEETING_PARTICIPANTS"
            ns="meetings"
            values={{ nbParticipants }}
            components={{ bold: <strong /> }}
          />
        </p>
      )}
    </Modal>
  )
}
