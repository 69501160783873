const url = process.env.REACT_APP_LOG_URL
const dataset = process.env.REACT_APP_LOG_DATASET
type Level = 'info' | 'error'

function send(message: string, level: Level) {
  /*
  if (url && dataset) {
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        message,
        data_stream: {
          type: 'logs',
          dataset,
          namespace: level,
        },
      }),
    })
  }
*/
}

class Logger {
  info(message: string) {
    send(message, 'info')
  }

  error(message: string) {
    send(message, 'error')
  }
}

const logger = new Logger()

export default logger
