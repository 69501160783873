import React from 'react'
import { ChatReaction } from './chatsSlice'
import { Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'

interface Props {
  ownEmail: string
  reactions: ChatReaction[]
  style: 'all' | 'native' | 'apple' | 'facebook' | 'google' | 'twitter'
  addReaction: (emoji: string) => void
  removeReaction: (reactionId: string) => void
}

export default function ChatReactionsBubbles({
  ownEmail,
  reactions,
  style,
  addReaction,
  removeReaction,
}: Props) {
  const { t } = useTranslation('chat')
  const MAX_NAME_TO_DISPLAY = 5

  const buildToolTipMessage = (reaction: ChatReaction) => {
    let listUsers = reaction.users.filter((user) => user.email !== ownEmail)
    const userReacts = reaction.users.find((user) => user.email === ownEmail)

    if (userReacts) {
      listUsers.push(userReacts)
    }

    const names = listUsers.map((user) =>
      user.email === ownEmail
        ? t('You', { ns: 'room' })
        : `${user.firstName} ${user.lastName[0].toUpperCase()}.`,
    )

    if (names.length === 0) {
      return ''
    }

    if (names.length === 1) {
      return names[0] === t('You', { ns: 'room' })
        ? t('You reacted')
        : t('REACTION_TOOLTIP', { name: names[0] })
    }

    if (names.length <= MAX_NAME_TO_DISPLAY) {
      return t('REACTION_TOOLTIP_MULTIPLE', {
        listName: names.slice(0, -1).join(', '),
        remainingName: names.slice(-1),
      })
    }

    return t('REACTION_TOOLTIP_MULTIPLE', {
      listName: names.slice(-1),
      remainingName: t('others'),
      interpolation: { escapeValue: false },
    })
  }

  return (
    <>
      {[...reactions].map((reaction) => {
        var clickFunction = () => {}
        if (reaction.userReactionId) {
          clickFunction = () => removeReaction(reaction.userReactionId || '')
        } else {
          clickFunction = () => addReaction(reaction.reaction)
        }

        return (
          <Tooltip key={reaction.reaction} title={buildToolTipMessage(reaction)}>
            <div
              className={
                'chat_message_reaction_bubble' +
                (reaction.userReactionId ? ' chat_message_reaction_bubble_active' : '')
              }
              onClick={clickFunction}
            >
              {/* @ts-expect-error Server Component */}
              <em-emoji
                native={reaction.reaction}
                size="15px"
                set={style}
                class="emoji-icone"
              />
              <div className="reaction_count">{reaction.users.length}</div>
            </div>
          </Tooltip>
        )
      })}
    </>
  )
}
