import React, { useEffect, useRef, useState } from 'react'
import { Form, InputRef, Modal } from 'antd'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../store'
import {
  idleFavoriteToModify,
  idleUpdateFavoriteStatus,
  updateFavorite,
} from './redux/applicationsSlice'
import FavoriteForm from './FavoriteForm'
import { useAttemptsListener } from 'auxasphere-react-kit'
import { useToastContext } from '../../components/Toast/ToastContext'

function UpdateFavoriteModal() {
  const { t } = useTranslation('applications')
  const dispatch = useDispatch<AppDispatch>()
  const [updateFavoriteForm] = Form.useForm()
  const [iconUploaded, setIconUploaded] = useState<string | undefined>()
  const inputRef = useRef<InputRef>(null)
  const { ToastOpen } = useToastContext()

  const favoriteToModify = useSelector(
    (state: RootState) => state.applications.favoriteToModify,
  )

  const updateFavoriteStatus = useSelector(
    (state: RootState) => state.applications.updateFavoriteStatus,
  )

  useAttemptsListener(
    [
      [
        updateFavoriteStatus,
        {
          success: () => {
            ToastOpen({
              message: t('Bookmark was successfully updated.'),
              type: 'success',
            })
            clear()
          },
          URL_ALREADY_EXIST: () => {
            ToastOpen({
              message: t("Bookmark's url already exists."),
              type: 'error',
            })
          },

          NAME_ALREADY_EXIST: () => {
            ToastOpen({
              message: t("Bookmark's name already exists."),
              type: 'error',
            })
          },
          unknown_error: () => {
            ToastOpen({
              message: t('Error updating favorite..'),
              type: 'error',
            })
          },
        },
      ],
    ],
    () => dispatch(idleUpdateFavoriteStatus()),
  )

  useEffect(() => {
    updateFavoriteForm.setFieldValue('name', favoriteToModify?.name)
    updateFavoriteForm.setFieldValue('url', favoriteToModify?.url)
    setIconUploaded(favoriteToModify?.icon)
  }, [favoriteToModify])

  /**
   *
   */
  function clear() {
    dispatch(idleFavoriteToModify())
    updateFavoriteForm.resetFields()
    setIconUploaded(undefined)
  }

  /**
   *
   */
  function onFavoriteSubmit() {
    if (favoriteToModify) {
      dispatch(
        updateFavorite({
          id: favoriteToModify.id,
          name: updateFavoriteForm.getFieldsValue().name,
          url: updateFavoriteForm.getFieldsValue().url,
          icon: iconUploaded,
        }),
      )
    }
  }

  return (
    <Modal
      centered
      open={favoriteToModify != undefined}
      title={t('Update bookmark')}
      onOk={updateFavoriteForm.submit}
      onCancel={clear}
      okText={t('Validate', { ns: 'common' })}
      cancelText={t('Cancel', { ns: 'common' })}
      afterOpenChange={(open) => open && inputRef.current?.focus()}
      maskClosable={false}
      confirmLoading={updateFavoriteStatus === 'loading'}
    >
      <div className="modal-content-container">
        <FavoriteForm
          form={updateFavoriteForm}
          onSubmit={onFavoriteSubmit}
          inputFocusRef={inputRef}
          iconUploaded={iconUploaded}
          setIconUploaded={setIconUploaded}
        />
      </div>
    </Modal>
  )
}

export default UpdateFavoriteModal
