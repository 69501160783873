import React, { MutableRefObject, useEffect, useRef, useState } from 'react'
import { Alert, Button, Col, Row, Select, Spin, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import './room-devices-configuration.scss'
import RoomClient from '../RoomClient'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../../store'
import { BsFilePerson, BsFilePersonFill } from 'react-icons/bs'
import { TbVideo, TbVideoOff, TbMicrophone, TbMicrophoneOff } from 'react-icons/tb'
import { IoStopSharp } from 'react-icons/io5'
import classnames from 'classnames'
import hark from 'hark'
import dayjs from 'dayjs'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import { HiOutlineSpeakerWave } from 'react-icons/hi2'
import { FiRefreshCcw } from 'react-icons/fi'
import VolumeProgress, { VolumeProgressInterface } from '../VolumeLevel/VolumeProgress'
import usePeripheralsSelector from '../../../utils/hooks/usePeripheralsSelector'
import { setSpeakerInProgress } from '../redux/meSlice'
import DeviceNotReadyHandler from '../../../components/DeviceNotReadyHandler'
import AntechamberControls from '../Antechamber/AntechamberControls'
interface Props {
  roomClient: MutableRefObject<RoomClient>
}
interface AudioContextExtended extends AudioContext {
  setSinkId?(sinkId: string | undefined): Promise<void>
}

function RoomDevicesConfigurations({ roomClient }: Props) {
  const { t, i18n } = useTranslation('roomDevicesConfiguration')
  const room = useSelector((state: RootState) => state.room)
  const me = useSelector((state: RootState) => state.me)
  const bokehEffectActive = useSelector((state: RootState) => state.me.bokehEffectActive)
  const bokehEffectInProgress = useSelector(
    (state: RootState) => state.me.bokehEffectInProgress,
  )
  const dispatch = useDispatch<AppDispatch>()
  const videoRef = useRef<HTMLVideoElement>(null)
  const canvasRef = useRef<HTMLCanvasElement>(null)

  const audioStream = useRef<MediaStream>(new MediaStream())
  const sourceNode = useRef<MediaStreamAudioSourceNode>()
  const audioContext = useRef<AudioContextExtended>(new AudioContext())
  const volumeProgressRef = useRef<VolumeProgressInterface>(null)
  const microphoneWidthRef = useRef<HTMLDivElement>(null)
  const [microTesting, setMicroTesting] = useState<boolean>(false)

  const webCamNotReadyMessages = {
    ASKING_PERMISSIONS: t('WEBCAM_STATUS_ASKING_PERMISSIONS'),
    FAILED: t('WEBCAM_STATUS_FAILED'),
    NOT_ALLOWED: t('WEBCAM_STATUS_NOT_ALLOWED'),
    NOT_FOUND: t('WEBCAM_STATUS_NOT_FOUND'),
    STOPPED: t('WEBCAM_STATUS_STOPPED'),
    STARTED: t('WEBCAM_STATUS_STARTED'),
  }

  const micNotReadyMessages = {
    ASKING_PERMISSIONS: t('MIC_STATUS_ASKING_PERMISSIONS'),
    FAILED: t('MIC_STATUS_FAILED'),
    NOT_ALLOWED: t('MIC_STATUS_NOT_ALLOWED'),
    NOT_FOUND: t('MIC_STATUS_NOT_FOUND'),
    STOPPED: t('MIC_STATUS_STOPPED'),
    STARTED: t('MIC_STATUS_STARTED'),
  }

  dayjs.extend(LocalizedFormat)

  useEffect(() => {
    refreshDevices()
    roomClient.current.setOutputCanvasRef(canvasRef, videoRef)

    return () => {
      roomClient.current.stopWebcamDevice()
      roomClient.current.stopMicDevice()
      roomClient.current.setOutputCanvasRef(undefined, undefined)
      audioContext.current?.close()
      for (const t of audioStream.current.getAudioTracks()) {
        t.stop()
        audioStream.current.removeTrack(t)
      }
    }
  }, [])

  const onMicChangenged = () => {
    if (roomClient.current._localAudioTrack) {
      setAudioTrack(roomClient.current._localAudioTrack)
      const speechEvents = hark(audioStream.current)
      // eslint-disable-next-line no-unused-vars
      speechEvents.on('volume_change', (dBs, threshold) => {
        volumeProgressRef.current?.setVolume(parseInt(dBs.toFixed(2)))
      })

      if (microTesting) {
        sourceNode.current?.disconnect()
        sourceNode.current = audioContext.current.createMediaStreamSource(
          audioStream.current,
        )
        sourceNode.current?.connect(audioContext.current.destination)
      }
    } else {
      setAudioTrack(undefined)
    }
  }

  const onSpeakersChanged = () => {
    if (!audioContext.current) {
      audioContext.current = new AudioContext()
      audioContext.current.setSinkId && audioContext.current.setSinkId(room.speakerId)
    }
  }

  usePeripheralsSelector(room, onMicChangenged, onSpeakersChanged, () => {
    const videoElem = videoRef.current
    if (videoElem) {
      videoElem.srcObject = null
    }
  })

  /**
   * Set tracks.
   * @param audioTrack
   * @param videoTrack
   * @returns
   */
  const setAudioTrack = (audioTrack?: MediaStreamTrack) => {
    console.log('setAudioTrack', audioTrack)
    if (audioTrack) {
      audioStream.current = new MediaStream()
      audioStream.current.addTrack(audioTrack)
    } else {
      for (const t of audioStream.current.getAudioTracks()) {
        t.stop()
        audioStream.current.removeTrack(t)
      }
    }
  }

  /**
   * Set tracks.
   * @param audioTrack
   * @param videoTrack
   * @returns
   */
  const setVideoTrack = (videoTrack?: MediaStreamTrack) => {
    console.log('setVideoTrack', videoTrack)

    const videoElem = videoRef.current
    if (videoElem) {
      if (videoTrack) {
        const stream = new MediaStream()
        stream.addTrack(videoTrack)

        videoElem.srcObject = stream
        videoElem.oncanplay = () => {}
        videoElem.onplay = () => {}
        videoElem.onpause = () => {}

        videoElem
          .play()
          .catch((error) =>
            console.error('Antechamber: videoElem.play() failed:%o', error),
          )
      } else {
        videoElem.srcObject = null
      }
    }
  }

  function changeCamera(value: string) {
    if (value && value != room.webcam) {
      roomClient.current.changeWebcamDevice(value)
    }
  }

  function changeMic(value: string) {
    if (value && value != room.mic) {
      roomClient.current.changeMicDevice(value)
    }
  }

  function changeSpeaker(value: string) {
    if (value && value != room.speakerId) {
      roomClient.current.changeSpeaker(value)
    }
  }

  function toggleWebcamActive() {
    if (me.webcamStatus === 'STARTED') {
      if (videoRef.current) {
        videoRef.current.srcObject = null
      }
      roomClient.current.stopWebcamDevice()
    } else {
      roomClient.current.startWebcamDevice(bokehEffectActive)
    }
  }

  function toggleBokehEffect() {
    roomClient.current.toggleBokehEffect(!bokehEffectActive)
  }

  function toggleMicActive() {
    if (me.micStatus === 'STARTED') {
      roomClient.current.stopMicDevice()
    } else {
      roomClient.current.startMicDevice()
    }
  }

  function refreshDevices() {
    dispatch(setSpeakerInProgress(true))
    const startWebcamDevicePromise =
      roomClient.current.startWebcamDevice(bokehEffectActive)
    const startMicDevicePromise = roomClient.current.startMicDevice()
    Promise.all([startWebcamDevicePromise, startMicDevicePromise])
      .then(() => roomClient.current.updateSpeakerDevices())
      .finally(() => dispatch(setSpeakerInProgress(false)))
  }

  const testMicrophone = async () => {
    try {
      setMicroTesting(true)
      audioContext.current = new AudioContext()
      sourceNode.current = audioContext.current.createMediaStreamSource(
        audioStream.current,
      )
      sourceNode.current?.connect(audioContext.current.destination)
    } catch (error) {
      console.error(error)
    }
  }

  const stopTestMicrophone = async () => {
    try {
      setMicroTesting(false)
      sourceNode.current?.disconnect()
    } catch (error) {
      console.error(error)
    }
  }

  const testSpeaker = async () => {
    try {
      const audio: any = new Audio(
        i18n.language === 'fr' ? '/audioTestFR.mp3' : '/audioTestEN.mp3',
      )
      if (audio.setSinkId && room.speakerId) {
        audio.setSinkId(room.speakerId)
        audio.loop = false
        audio.play()
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      <h4 className="devices-configuration-label">{t('Configuration')}</h4>
      <hr />
      <Row align="middle">
        <Col span={24} md={12} className="d-flex d-flex-center">
          <div className="devices-configuration-video-container">
            <canvas
              className={classnames({
                'devices-configuration-canvas': true,
                'd-none': !bokehEffectActive || !me.webcamActive,
              })}
              ref={canvasRef}
            />
            <video
              className={classnames({
                'devices-configuration-video': true,
                'd-none':
                  bokehEffectActive ||
                  !me.webcamActive ||
                  me.webcamStatus === 'NOT_ALLOWED' ||
                  me.webcamStatus === 'NOT_FOUND' ||
                  me.webcamStatus === 'FAILED',
              })}
              ref={videoRef}
              autoPlay
              playsInline
              muted
              controls={false}
            />
            <div
              className={classnames({
                'devices-configuration-empty-video': true,
                'd-none':
                  me.webcamStatus === 'STARTED' ||
                  me.webcamStatus === 'ASKING_PERMISSIONS',
              })}
            ></div>

            {bokehEffectActive && bokehEffectInProgress && (
              <div className="devices-configuration-bokehEffectInProgress">
                <Spin size="large" />
              </div>
            )}
            <div className="devices-configuration-webcam-container">
              <AntechamberControls
                deviceStatus={me.micStatus}
                messages={micNotReadyMessages}
                on={<TbMicrophone size="2rem" strokeWidth={1} color="#FFFFFF" />}
                off={<TbMicrophoneOff size="2rem" strokeWidth={1} color="#FFFFFF" />}
                onClick={toggleMicActive}
              />
              <AntechamberControls
                deviceStatus={me.webcamStatus}
                messages={webCamNotReadyMessages}
                on={<TbVideo size="2rem" strokeWidth={1} color="#FFFFFF" />}
                off={<TbVideoOff size="2rem" strokeWidth={1} color="#FFFFFF" />}
                onClick={toggleWebcamActive}
              />
              <div
                className={classnames(
                  'media-control-container',
                  { 'control-off': !bokehEffectActive },
                  { 'control-disabled': room.webcams.length <= 0 },
                )}
                onClick={room.webcams.length > 0 ? toggleBokehEffect : undefined}
              >
                <Tooltip
                  title={
                    bokehEffectActive
                      ? t(
                          'You have activated background blur. Click on the icon to deactivate it.',
                        )
                      : t(
                          'Background blur is deactivated. Click on the icon to activate it.',
                        )
                  }
                >
                  {bokehEffectActive && room.webcams.length > 0 ? (
                    <BsFilePersonFill color="#FFFFFF" />
                  ) : (
                    <BsFilePerson color="#FFFFFF" />
                  )}
                </Tooltip>
              </div>
            </div>

            {/* <div className="volume-level-container">
              <VolumeLevel ref={VolumeLevelComponentRef} />
            </div> */}
          </div>
        </Col>

        <Col span={24} md={12}>
          <div className="devices-configuration-mediasettings-container">
            <div className="devices-configuration-mediasettings-row">
              <div className="devices-configuration-mediasettings-title">
                {t('Camera', { ns: 'common' })}
              </div>
              <DeviceNotReadyHandler
                deviceStatus={me.webcamStatus}
                messages={webCamNotReadyMessages}
                startedCallback={() => setVideoTrack(roomClient.current.localVideoTrack)}
                notFoundCallback={() => {
                  if (videoRef.current) {
                    videoRef.current.src = ''
                  }
                }}
              >
                <Row>
                  <Col span={20}>
                    <Select
                      value={room.webcam}
                      style={{ width: '100%' }}
                      onChange={changeCamera}
                      options={room.webcams}
                    />
                  </Col>
                </Row>
              </DeviceNotReadyHandler>
            </div>

            <div className="devices-configuration-mediasettings-row">
              <div className="mt-1rem devices-configuration-mediasettings-title">
                {t('Microphone', { ns: 'common' })}
              </div>
              <DeviceNotReadyHandler
                deviceStatus={me.micStatus}
                messages={micNotReadyMessages}
              >
                <Row ref={microphoneWidthRef}>
                  <Col span={20}>
                    <Select
                      value={room.mic}
                      style={{ width: '100%' }}
                      onChange={changeMic}
                      options={room.mics}
                    />
                  </Col>
                  <Col
                    span={4}
                    className="d-flex d-flex-middle d-flex-right"
                    hidden={microTesting || !audioContext.current}
                  >
                    <Tooltip title={t('Test micro')}>
                      <Button
                        className="test-btn btn-secondary"
                        onClick={testMicrophone}
                        icon={<TbMicrophone size="1.7em" />}
                      />
                    </Tooltip>
                  </Col>
                  <Col
                    span={4}
                    className="d-flex d-flex-middle d-flex-right"
                    hidden={!microTesting}
                  >
                    <Tooltip title={t('Stop test')}>
                      <Button
                        className="test-btn btn-secondary stop-btn"
                        onClick={stopTestMicrophone}
                        icon={<IoStopSharp size="1.7em" />}
                      />
                    </Tooltip>
                  </Col>
                </Row>
                {microTesting && (
                  <VolumeProgress
                    ref={volumeProgressRef}
                    width={microphoneWidthRef.current?.offsetWidth}
                  />
                )}
              </DeviceNotReadyHandler>
            </div>

            <div className="devices-configuration-mediasettings-row">
              <div className="mt-1rem devices-configuration-mediasettings-title">
                {t('Speaker', { ns: 'common' })}
              </div>
              {me.speakerInProgress ? (
                <Alert
                  message={t('Please wait. Device detection in progress.')}
                  type="warning"
                  icon={<Spin />}
                  showIcon={true}
                />
              ) : room.speakers && room.speakers.length > 0 ? (
                <Row>
                  <Col span={20}>
                    <Select
                      value={room.speakerId}
                      style={{ width: '100%' }}
                      onChange={changeSpeaker}
                      options={room.speakers}
                    />
                  </Col>
                  <Col span={4} className="d-flex d-flex-middle d-flex-right">
                    {room.speakers.length > 0 && !microTesting && (
                      <Tooltip title={t('Test speaker')}>
                        <Button
                          className="test-btn btn-secondary"
                          onClick={testSpeaker}
                          disabled={microTesting}
                          icon={<HiOutlineSpeakerWave size="1.8em" />}
                        />
                      </Tooltip>
                    )}
                  </Col>
                </Row>
              ) : (
                <Alert message={t('SPEAKERS_STATUS_NOT_FOUND')} type="error" />
              )}
            </div>

            <div className="devices-configuration-mediasettings-row mt-2rem mb-1rem text-center">
              <Button
                className="d-inline"
                type="primary"
                size="small"
                shape="round"
                onClick={refreshDevices}
                disabled={
                  me.webcamStatus === 'ASKING_PERMISSIONS' ||
                  me.micStatus === 'ASKING_PERMISSIONS'
                }
              >
                {t('Refresh devices', { ns: 'common' })}
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default RoomDevicesConfigurations
