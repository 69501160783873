import React, { MutableRefObject } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import { useTranslation } from 'react-i18next'
import { ProducerModel } from '../../features/room/redux/model'
import { Spin, Tooltip } from 'antd'
import RoomClient from '../../features/room/RoomClient'
import { TbVideo, TbVideoOff } from 'react-icons/tb'
import { BsCameraVideoOff } from 'react-icons/bs'

interface Props {
  roomClient: MutableRefObject<RoomClient>
}

function CallControlsWebcam({ roomClient }: Props) {
  const canSendWebcam = useSelector((state: RootState) => state.me.canSendWebcam)
  const webcamStatus = useSelector((state: RootState) => state.me.webcamStatus)
  const bokehEffectActive = useSelector((state: RootState) => state.me.bokehEffectActive)
  const { t } = useTranslation('roomDevicesConfiguration')
  const videoProducer: ProducerModel | undefined = useSelector((state: RootState) =>
    Object.values(state.producers.producers).find(
      (producer) => producer.type === 'front' || producer.type === 'back',
    ),
  )

  function disableWebcam() {
    roomClient.current.disableWebcam()
  }

  function enableWebcam() {
    roomClient.current.enableWebcam(bokehEffectActive)
  }

  let webcamState = 'off'
  if (!canSendWebcam) {
    webcamState = 'unsupported'
  } else if (videoProducer) {
    webcamState = 'on'
  }

  let tooltip
  switch (webcamStatus) {
    case 'ASKING_PERMISSIONS':
      tooltip = t('WEBCAM_STATUS_ASKING_PERMISSIONS')
      break

    case 'FAILED':
      tooltip = t('WEBCAM_STATUS_FAILED')
      break

    case 'NOT_ALLOWED':
      tooltip = (
        <div>
          {t('WEBCAM_STATUS_NOT_ALLOWED')}{' '}
          <a href="/faq/devices" target="_blank">
            {t('Please visit our FAQ.', { ns: 'common' })}
          </a>
        </div>
      )
      break

    case 'NOT_FOUND':
      tooltip = t('WEBCAM_STATUS_NOT_FOUND')
      break

    case 'STOPPED':
      tooltip = t('WEBCAM_STATUS_STOPPED')
      break

    case 'STARTED':
      tooltip = t('WEBCAM_STATUS_STARTED')
      break
  }

  switch (webcamState) {
    case 'on':
      return (
        <>
          <Tooltip title={tooltip}>
            <div className="call-control call-control--active" onClick={disableWebcam}>
              {webcamStatus !== 'STARTED' ? (
                <Spin />
              ) : (
                <TbVideo size="2rem" color="#ffffff" strokeWidth={1} />
              )}
            </div>
          </Tooltip>
        </>
      )

    case 'off':
      return (
        <Tooltip title={tooltip}>
          <div className="call-control call-control--inactive" onClick={enableWebcam}>
            <TbVideoOff size="2rem" color="#ffffff" strokeWidth={1} />
          </div>
        </Tooltip>
      )
  }

  return (
    <Tooltip title={tooltip}>
      <div className="call-control" onClick={enableWebcam}>
        <BsCameraVideoOff size="2rem" color="#777777" strokeWidth={1} />
      </div>
    </Tooltip>
  )
}

export default CallControlsWebcam
