import React from 'react'
import { Button, Divider, Tooltip } from 'antd'
import { IoCheckmarkCircleOutline, IoTrashOutline } from 'react-icons/io5'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../../store'
import {
  attemptDeleteAllNotifications,
  attemptMarkAllNotificationsSeen,
} from '../../components/NotificationWidget/liveWidgetSlice'
import { Notification } from '../../components/NotificationWidget/liveWidgetSlice'

interface Props {
  title: string
  notifications: Notification[] | undefined
  setNotificationsDisplayed: (notifications: Notification[] | undefined) => void
}

export default function NotificationsHeader({
  title,
  notifications,
  setNotificationsDisplayed,
}: Props) {
  const { t } = useTranslation('notification')
  const dispatch = useDispatch<AppDispatch>()

  const handleMarkAllAsRead = () => {
    dispatch(attemptMarkAllNotificationsSeen())
    setNotificationsDisplayed(notifications?.map((n) => ({ ...n, unseenCount: 0 })))
  }

  const handleDeleteAll = () => {
    dispatch(attemptDeleteAllNotifications())
    setNotificationsDisplayed([])
  }

  return (
    <>
      <div className="layout-header-bottom">
        <h4 className="layout-list-header">{title}</h4>
        {title.includes(t('All your notifications')) && (
          <div className="d-flex d-flex-end">
            <div
              className="notification_header_actions_item"
              onClick={handleMarkAllAsRead}
            >
              <IoCheckmarkCircleOutline className="notification_widget_content_action" />
              <div className="notification_header_actions_text">
                {t('Mark all as read')}
              </div>
            </div>
            <Divider className="notification_menu_divider" type="vertical" plain />
            <div className="notification_header_actions_item" onClick={handleDeleteAll}>
              <IoTrashOutline className="notification_widget_content_action" />
              <div className="notification_header_actions_text">{t('Delete all')}</div>
            </div>
          </div>
        )}
      </div>

      <Divider />
    </>
  )
}
