import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../../../store'
import { CallWebsocketConnection } from './CallWebsocketConnection'

interface Props {
  peerEmail?: string
}

export default function useCallWebsocketConnection({ peerEmail }: Props) {
  const dispatch = useDispatch<AppDispatch>()
  const wsRef = useRef<CallWebsocketConnection | undefined>()
  const { jwt, email } = useSelector((state: RootState) => state.auth)

  useEffect(() => {
    if (jwt && email && peerEmail) {
      wsRef.current = new CallWebsocketConnection(dispatch, jwt, email, peerEmail)
      wsRef.current.connect()

      return () => {
        console.log('call websocket disconnected')
        wsRef.current?.disconnect(true)
        wsRef.current = undefined
      }
    }
  }, [])

  return wsRef
}
