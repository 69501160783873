import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import './faq.scss'
import { Collapse } from 'antd'
import Layout from '../../components/Layout/Layout'
import { ENV_CONSTANTS } from '../../utils/Utils'
import { CollapseProps } from 'antd/lib'
import { TbMicrophone, TbVideo } from 'react-icons/tb'
import { MdCellWifi } from 'react-icons/md'

function FAQ() {
  const { t } = useTranslation('faq')

  /**
   *
   * @returns
   */
  function renderFaqNetworkChild() {
    return (
      <>
        <p>
          <Trans
            i18nKey="CALL_NETWORK_ISSUE_DESC"
            shouldUnescape={true}
            ns="faq"
            values={{
              applicationName: ENV_CONSTANTS['DEFAULT_APPLICATION_NAME'],
            }}
          />
        </p>
        <p>
          <span>{t('For further information, please see the ')}</span>
          <a href="/faq/network">{t('network configuration page')}</a>.
        </p>
      </>
    )
  }

  /**
   *
   * @returns
   */
  function renderFaqDevicesChild() {
    return (
      <>
        <p>
          {t(
            'Please ensure that your device is equipped with a camera and microphone and these have the necessary authorisations in the browser.',
          )}
        </p>
        <p>
          <span>{t('For further information, please see the ')}</span>
          <a href="/faq/devices">{t('devices configuration page')}</a>.
        </p>
      </>
    )
  }

  const items: CollapseProps['items'] = [
    {
      key: 'network',
      label: t('I have connection issue during call.'),
      children: renderFaqNetworkChild(),
      extra: (
        <div className="d-flex">
          <MdCellWifi size="1.5em" />
        </div>
      ),
    },
    {
      key: 'devices',
      label: t('I have a problem with my devices when I join a meeting.'),
      children: renderFaqDevicesChild(),
      extra: (
        <div className="d-flex">
          <TbVideo size="1.5em" />
          <TbMicrophone size="1.5em" />
        </div>
      ),
    },
  ]

  function renderFaqContent() {
    return (
      <div className="Faq">
        <div className="Faq-container">
          <img src="/images/faq.svg" className="main-image" />
          <div className="d-flex d-flex-center d-flex-middle d-flex-column g-1rem">
            <h1>{t('Frequently asked questions')}</h1>
            <Collapse items={items} className="main-collapse" accordion />
          </div>
        </div>
      </div>
    )
  }

  return <Layout content={renderFaqContent()} footer={false} />
}

export default FAQ
