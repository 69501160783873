import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface CollapseState {
  root: boolean
  meetings: boolean
  organisation: boolean
}
interface State {
  isMenuCollapsed: CollapseState
}

export const initialState: State = {
  isMenuCollapsed: { root: false, meetings: false, organisation: false },
}

const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    setMenuCollapsed: (state, { payload }: PayloadAction<CollapseState>) => {
      state.isMenuCollapsed = payload
    },
  },
})

export default layoutSlice.reducer

export const { setMenuCollapsed } = layoutSlice.actions
