import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import ApiError from '../../utils/errors/ApiError'
import { FetchStatus } from '../../utils/FetchStatus'
import { setNewFeaturesSeen } from '../auth/redux/authSlice'

interface State {
  updateNewFeaturesSeenStatus?: FetchStatus
}

export const initialState: State = {}

export enum NewFeaturePages {
  MEETING = 'MEETING',
  PLANNING = 'PLANNING',
  DRIVE = 'DRIVE',
  TEAM = 'TEAM',
  HOME = 'HOME',
}

/**
 * Sign up user.
 */
export const updateNewFeaturesSeen = createAsyncThunk(
  'newFeaturesSeen/updateNewFeaturesSeenStatus',
  async (
    payload: {
      newFeaturePageSeen: NewFeaturePages
    },
    { getState, dispatch },
  ) => {
    const { auth } = getState() as { auth: { jwt: string } }
    const response = await fetch(
      process.env.REACT_APP_BASE_USERS_URL + '/new_features_seen/update',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${auth.jwt}`,
        },
        body: JSON.stringify(payload),
      },
    )

    if (!response.ok) {
      const reason = (await response.json()).reason || 'unknown_error'
      throw new ApiError(reason)
    }

    dispatch(setNewFeaturesSeen(payload.newFeaturePageSeen))
    return await response.json()
  },
)

export const newFeaturesSeenSlice = createSlice({
  name: 'newFeaturesSeen',
  initialState,
  reducers: {},
  extraReducers: {
    [updateNewFeaturesSeen.pending.type]: (state) => {
      state.updateNewFeaturesSeenStatus = 'loading'
    },
    [updateNewFeaturesSeen.fulfilled.type]: (state, action) => {
      state.updateNewFeaturesSeenStatus = 'success'
    },
    [updateNewFeaturesSeen.rejected.type]: (state, action) => {
      const error = action.error
      state.updateNewFeaturesSeenStatus =
        error.name === 'ApiError' ? error.message : 'unknown_error'
    },
  },
})

export const {} = newFeaturesSeenSlice.actions

export default newFeaturesSeenSlice.reducer
