import { Modal } from 'antd'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

interface Props {
  openNotarisation: boolean
  setOpenNotarisation: (open: boolean) => void
}

function NotarisationModal({ openNotarisation, setOpenNotarisation }: Props) {
  const { t } = useTranslation('layout')

  return (
    <Modal
      title={t('Notarisation')}
      open={openNotarisation}
      onCancel={() => setOpenNotarisation(false)}
      cancelText={t('Close', { ns: 'common' })}
      centered
      okButtonProps={{ style: { display: 'none' } }}
      className="large-modal"
    >
      <p>
        <Trans ns="layout" i18nKey="NOTARISATION_1" />
      </p>
    </Modal>
  )
}

export default NotarisationModal
