import { createSlice } from '@reduxjs/toolkit'
import { setRoomState } from './roomSlice'

interface DataProducer {
  id: string
  sctpStreamParameters: object
  label: string
  protocol: string
}

interface DataProducers {
  [id: string]: DataProducer
}

interface State {
  producers: DataProducers
}

export const initialState: State = {
  producers: {},
}

const dataProducersSlice = createSlice({
  initialState,
  name: 'dataProducers',
  reducers: {
    addDataProducer: (state, action: { payload: DataProducer }) => {
      const producer = action.payload
      state.producers[producer.id] = producer
    },

    removeDataProducer: (state, action: { payload: string }) => {
      delete state.producers[action.payload]
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setRoomState, (state, action: { payload: string }) => {
      if (action.payload === 'closed') {
        return initialState
      }
    })
  },
})

export default dataProducersSlice.reducer

export const { addDataProducer, removeDataProducer } = dataProducersSlice.actions
