import React from 'react'
import { Col, Row, Select, Tag, Tooltip } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../../store'
import {
  RoomInterface,
  RoomParticipant,
  RoomParticipantRole,
} from '../../../features/room/RoomInterface'
import { useTranslation } from 'react-i18next'
import AddParticipantForm from './AddParticipantForm'
import './ManageParticipants.scss'
import '../.././../features/Meetings/NewMeeting/new-meeting.scss'
import { BiInfoCircle } from 'react-icons/bi'
import {
  attemptUpdateInvited,
  attemptUpdateParticipant,
} from '../../../features/room/redux/roomSlice'
import { LabeledValue } from 'antd/es/select'

interface AddRoomParticipantFormProps {
  room?: RoomInterface
  vertical: boolean
  disabled: boolean
  addParticipant: (payload: RoomParticipant) => any
  addInvited: (values: any) => any
  deleteParticipant: (payload: string) => any
  deleteInvited: (values: string) => any
  updateParticipantRole: (payload: string) => any
  updateInvitedRole: (payload: string) => any
}

function ManageParticipants({
  room,
  vertical,
  disabled,
  addParticipant,
  addInvited,
  deleteParticipant,
  deleteInvited,
  updateParticipantRole,
  updateInvitedRole,
}: AddRoomParticipantFormProps) {
  const { t } = useTranslation('meetings')
  const dispatch = useDispatch<AppDispatch>()
  const auth = useSelector((state: RootState) => state.auth)

  const moderatorSelect = [
    // Only for display purpose, will be obsolete when moderator role will be effective
    {
      value: 'Creator',
      info: t('Creator'),
      label: (
        <div className="label-container">
          <p className="select-items">{t('Creator')}</p>
          <Tooltip
            title={t('Access to documents before, during and after the meeting', {
              ns: 'common',
            })}
            className="cursor-pointer"
          >
            {' '}
            <BiInfoCircle size="1em" className="info-color" />
          </Tooltip>
        </div>
      ),
    },
  ]

  const formatDefaultValue = (participantMail: string, role: RoomParticipantRole) => {
    return participantMail + ' ' + role
  }

  const strSplit = (str: string) => {
    const [email, newRole] = str.split(' ')
    return { email, newRole }
  }
  /**
   *
   * @param participant email of the participant
   * @returns select options as an array
   */
  const valuesCreator = (participant: string | undefined) => {
    if (participant) {
      const rolesTable = [
        // {
        //   value: participant + ' moderator',
        //   label: (
        //     <div className="label-container">
        //       <p className="select-items">{t('Moderator')}</p>
        //       <Tooltip
        //         title={t('Access to documents before, during and after the meeting', {
        //           ns: 'common',
        //         })}
        //         className="cursor-pointer"
        //       >
        //         {' '}
        //         <BiInfoCircle size="1em" className="info-color" />
        //       </Tooltip>
        //     </div>
        //   ),
        // },
        {
          value: participant + ' proofreader',
          info: t('Proofreader'),
          label: (
            <div className="label-container">
              <p className="select-items">{t('Proofreader')}</p>
              <Tooltip
                title={t('Access to documents before, during and after the meeting', {
                  ns: 'common',
                })}
                className="cursor-pointer"
              >
                {' '}
                <BiInfoCircle size="1em" className="info-color" />
              </Tooltip>
            </div>
          ),
        },
        {
          value: participant + ' document',
          info: t('Document'),
          label: (
            <div className="label-container">
              <p className="select-items">{t('Document')}</p>
              <Tooltip
                title={t('Access to documents during the meeting', {
                  ns: 'common',
                })}
                className="cursor-pointer"
              >
                {' '}
                <BiInfoCircle size="1em" className="info-color" />
              </Tooltip>
            </div>
          ),
        },
        {
          value: participant + ' attendee',
          info: t('Attendee'),
          label: (
            <div className="label-container">
              <p className="select-items">{t('Attendee')}</p>
              <Tooltip
                title={t('No access to documents', {
                  ns: 'common',
                })}
                className="cursor-pointer"
              >
                {' '}
                <BiInfoCircle size="1em" className="info-color" />
              </Tooltip>
            </div>
          ),
        },
      ]
      return rolesTable
    }
  }

  const dispatchForForm = (value: string | number | LabeledValue) =>
    dispatch(updateParticipantRole(value as string))
  const dispatchForCall = (value: string | number | LabeledValue) =>
    dispatch(attemptUpdateParticipant(strSplit(value as string)))
  const dispatchInvitedForForm = (value: string | number | LabeledValue) =>
    dispatch(updateInvitedRole(value as string))
  const dispatchInvitedForCall = (value: string | number | LabeledValue) =>
    dispatch(attemptUpdateInvited(strSplit(value as string)))

  return (
    <>
      <Row
        gutter={[0, 8]}
        align="bottom"
        className="form-meeting-container manage-participant"
      >
        <Col span={24}>
          {!disabled && (
            <AddParticipantForm
              room={room}
              vertical={vertical}
              addParticipant={addParticipant}
              addInvited={addInvited}
            />
          )}
        </Col>

        <Col span={24}>
          <Row className="mt-05rem" align={'top'} gutter={[0, 0]}>
            <Col span={12}>
              <h4 className="uppercase">{t('Creator')}</h4>
              <i>{t('Meeting creator')}</i>
            </Col>
            <Col span={12}>
              {' '}
              <h4 className="uppercase">{t('Role', { ns: 'common' })}</h4>
              <i>{t('Change participant role')}</i>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row className="mt-05rem" align={'top'} gutter={[0, 0]}>
            <Col span={12}>
              <Tag closable={false} className="participant-tag">
                <Tooltip title={room?.creator?.email}>
                  <span className="participant-info">{`${room?.creator?.firstName} ${room?.creator?.lastName} 
                    <${room?.creator?.email}>`}</span>
                </Tooltip>
              </Tag>
            </Col>
            <Col span={11}>
              <Select
                className="select-role"
                options={moderatorSelect}
                defaultValue={'Creator'}
                optionLabelProp="info"
                disabled={true}
              />
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <h4 className="uppercase mt-05rem">{t('In-house participants')}</h4>
          <div className="participant-help-text">
            <i>{t('Members of your organization')}</i>
          </div>

          {room?.participants?.map((participant) => {
            return (
              <div key={participant.email}>
                <Row className="mt-05rem" align={'top'} gutter={[0, 0]}>
                  <Col span={12}>
                    <Tag
                      closable={!disabled}
                      onClose={(e) => {
                        e.preventDefault()
                        dispatch(deleteParticipant(participant.email))
                      }}
                      className="participant-tag"
                    >
                      <Tooltip title={participant.email}>
                        <span className="participant-info">{`${participant.firstName} ${participant.lastName} <${participant.email}>`}</span>
                      </Tooltip>
                    </Tag>
                  </Col>
                  <Col span={11}>
                    <Select
                      className="select-role"
                      options={valuesCreator(participant.email)}
                      defaultValue={formatDefaultValue(
                        participant.email,
                        participant.role,
                      )}
                      onSelect={vertical ? dispatchForCall : dispatchForForm}
                      optionLabelProp="info"
                      disabled={disabled}
                    />
                  </Col>
                </Row>
              </div>
            )
          })}
        </Col>
        <Col span={24}>
          <h4 className="uppercase mt-05rem">{t('Third-party participants')}</h4>
          <div className="participant-help-text">
            <i>{t('External people invited by email')}</i>
          </div>
          <div className="mt-05rem">
            {room?.invited?.map((invitedUser) => {
              return (
                <div key={invitedUser.email}>
                  <Row className="mt-05rem" align={'top'} gutter={[0, 0]}>
                    <Col span={12}>
                      <Tag
                        closable={!disabled}
                        onClose={(e) => {
                          e.preventDefault()
                          dispatch(deleteInvited(invitedUser.email))
                        }}
                        className="participant-tag"
                      >
                        <Tooltip title={invitedUser.email}>
                          <span className="participant-info">{invitedUser.email}</span>
                        </Tooltip>
                      </Tag>
                    </Col>
                    <Col span={11}>
                      <Select
                        className="select-role"
                        options={valuesCreator(invitedUser.email)}
                        defaultValue={formatDefaultValue(
                          invitedUser.email,
                          invitedUser.role,
                        )}
                        onSelect={
                          vertical ? dispatchInvitedForCall : dispatchInvitedForForm
                        }
                        optionLabelProp="info"
                        disabled={disabled}
                      />
                    </Col>
                  </Row>
                </div>
              )
            })}
          </div>
        </Col>
      </Row>
    </>
  )
}

export default ManageParticipants
