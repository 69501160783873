import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../store'
import { PersonalizationUploaderInterface } from './usePersonalizationUploader'
import { backgroundImageFilename } from '../../assets/personnalization'
import { FormInstance } from 'antd'
import { unescape } from 'html-escaper'

export default function usePersonalizationStyles(
  authBackgroundUploaded: PersonalizationUploaderInterface['authBackgroundUploaded'],
  authLogoUploadedFileName: PersonalizationUploaderInterface['authLogoUploadedFileName'],
  form: FormInstance<any>,
) {
  const values = useSelector((state: RootState) => state.personalization.values)

  const [primaryColor, setPrimaryColor] = useState<string | undefined>()
  const [primaryColorText, setPrimaryColorText] = useState<string | undefined>()
  const [secondaryColor, setSecondaryColor] = useState<string | undefined>()
  const [secondaryColorText, setSecondaryColorText] = useState<string | undefined>()
  const [tertiaryColor, setTertiaryColor] = useState<string | undefined>()
  const [tertiaryColorText, setTertiaryColorText] = useState<string | undefined>()

  useEffect(() => {
    document.documentElement.style.setProperty('--color-primary', primaryColor || '')
    document.documentElement.style.setProperty(
      '--color-primary-lighten',
      primaryColor || '',
    )
    document.documentElement.style.setProperty(
      '--color-primary-text',
      primaryColorText || '',
    )
    document.documentElement.style.setProperty('--color-secondary', secondaryColor || '')
    document.documentElement.style.setProperty(
      '--color-secondary-lighten',
      secondaryColor || '#000000',
    )
    document.documentElement.style.setProperty(
      '--color-secondary-lighter',
      secondaryColor || '#000000',
    )
    document.documentElement.style.setProperty(
      '--color-secondary-text',
      secondaryColorText || '',
    )
    document.documentElement.style.setProperty('--color-tertiary', tertiaryColor || '')
    document.documentElement.style.setProperty(
      '--color-tertiary-lighten',
      tertiaryColor || '',
    )
    document.documentElement.style.setProperty(
      '--color-tertiary-text',
      tertiaryColorText || '',
    )
    document.documentElement.style.setProperty(
      '--background-image',
      authBackgroundUploaded
        ? `url('${authBackgroundUploaded}')`
        : `url(/images/${backgroundImageFilename})`,
    )
  }, [
    primaryColor,
    primaryColorText,
    secondaryColor,
    secondaryColorText,
    tertiaryColor,
    tertiaryColorText,
    authBackgroundUploaded,
  ])

  useEffect(() => {
    form.setFieldValue('title', unescape(values?.title || 'Auxamed'))

    form.setFieldValue('mainLogo', authLogoUploadedFileName || '')

    form.setFieldValue('primaryColor', values?.primaryColor || '#224160')
    setPrimaryColor(values?.primaryColor || '#224160')

    form.setFieldValue('primaryColorText', values?.primaryColorText || '#FFFFFF')
    setPrimaryColorText(values?.primaryColorText || '#FFFFFF')

    form.setFieldValue('secondaryColor', values?.secondaryColor || '#1591ff')
    setSecondaryColor(values?.secondaryColor || '#1591ff')

    form.setFieldValue('secondaryColorText', values?.secondaryColorText || '#FFFFFF')
    setSecondaryColorText(values?.secondaryColorText || '#FFFFFF')

    form.setFieldValue('tertiaryColor', values?.tertiaryColor || '#a8bbcc')
    setTertiaryColor(values?.tertiaryColor || '#a8bbcc')

    form.setFieldValue('tertiaryColorText', values?.tertiaryColorText || '#FFFFFF')
    setTertiaryColorText(values?.tertiaryColorText || '#FFFFFF')
  }, [values])

  return {
    primaryColor,
    setPrimaryColor,
    primaryColorText,
    setPrimaryColorText,
    secondaryColor,
    setSecondaryColor,
    secondaryColorText,
    setSecondaryColorText,
    tertiaryColor,
    setTertiaryColor,
    tertiaryColorText,
    setTertiaryColorText,
  }
}
