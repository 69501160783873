import { createSlice } from '@reduxjs/toolkit'
import {
  websocketPeerStatusAction,
  websocketSquadAction,
  websocketSquadNotExistAction,
} from '../WebsocketConnection/AppWebsocketConnection'

export interface Squad {
  id: string
  title: string
}

type PeerStatus = 'ONLINE' | 'OFFLINE'

interface State {
  squads: { [id: string]: Squad }
  peersStatuses: { [squadId: string]: { [peerId: string]: PeerStatus } }
  missingSquads: string[]
}

export const initialState: State = {
  squads: {},
  peersStatuses: {},
  missingSquads: [],
}

export const squadsSlice = createSlice({
  name: 'squads',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(websocketSquadAction, (state, { payload }) => {
        state.squads[payload.squad.id] = payload.squad
        state.peersStatuses[payload.squad.id] = {}
        if (payload.peersOnline) {
          for (const p of payload.peersOnline) {
            state.peersStatuses[payload.squad.id][p] = 'ONLINE'
          }
        }
      })

      .addCase(websocketPeerStatusAction, (state, { payload }) => {
        if (!state.peersStatuses[payload.squadId]) {
          state.peersStatuses[payload.squadId] = {}
        }
        state.peersStatuses[payload.squadId][payload.peer] = payload.status
      })

      .addCase(websocketSquadNotExistAction, (state, { payload }) => {
        if (!state.missingSquads.includes(payload.squadId)) {
          state.missingSquads.push(payload.squadId)
        }
      })
  },
})

export const {} = squadsSlice.actions

export default squadsSlice.reducer
