import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useAttemptsListener } from 'auxasphere-react-kit'
import { AppDispatch, RootState } from '../../../store'
import {
  fetchRooms,
  idleCreateRoomStatus,
  idleDeleteRoomStatus,
  roomsCount,
  RoomToModify,
  setRoomToDelete,
  setRoomToModify,
} from '../MeetingsSlice'
import { PendingRoomInterface } from '../../room/RoomInterface'
import Layout from '../../../components/Layout/Layout'
import RoomManage from '../../../components/RoomManage/RoomManage'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import MeetingVote from '../MeetingVote/MeetingVote'
import SectionHeader from '../../../components/SectionHeader'
import '../meetings.scss'
import ModalDeleteMeeting from '../../../components/Modal/ModalDeleteMeeting'
import PendingMeetingTable from './PendingMeetingTable'
import MeetingsMenu from '../MeetingsMenu'
import { useToastContext } from '../../../components/Toast/ToastContext'

function PendingMeetings() {
  const { t } = useTranslation('meetings')
  const { roomId, page } = useParams()
  const dispatch = useDispatch<AppDispatch>()
  const { ToastOpen } = useToastContext()
  const navigate = useNavigate()
  const pending = useSelector((state: RootState) => state.rooms.rooms.pending)
  const createRoomStatus = useSelector((state: RootState) => state.rooms.createRoomStatus)
  const updatePlanRoomStatus = useSelector(
    (state: RootState) => state.rooms.updatePlanRoomStatus,
  )
  const updateRoomStatus = useSelector((state: RootState) => state.rooms.updateRoomStatus)
  const deleteRoomStatus = useSelector((state: RootState) => state.rooms.deleteRoomStatus)
  dayjs.extend(duration)
  const [meetingVoteRoom, setMeetingVoteRoom] = useState<
    PendingRoomInterface | undefined
  >(undefined)
  const roomModify = useSelector((state: RootState) => state.rooms.roomToModify)
  const setRoomModify = (room: RoomToModify | undefined): any =>
    dispatch(setRoomToModify(room))
  const [pendingMeetingPage, setPendingMeetingPage] = useState<number>(1)

  useAttemptsListener(
    [
      [
        updateRoomStatus,
        {
          success: () => {
            dispatch(setRoomToModify(undefined))
            navigate('/upcoming-meetings')
          },
        },
      ],
      [
        createRoomStatus,
        {
          error: () =>
            ToastOpen({
              message: t('Error creating meeting.'),
              type: 'error',
            }),
        },
      ],
      [
        updatePlanRoomStatus,
        {
          success: () => {
            dispatch(fetchRooms({ status: 'pending' }))
          },
        },
      ],
      [
        deleteRoomStatus,
        {
          success: () => {
            dispatch(setRoomToDelete({ room: undefined, pending: false }))
            ToastOpen({
              message: t('Meeting was successfully deleted.'),
              type: 'success',
            })
            dispatch(fetchRooms({ status: 'pending' }))
            dispatch(roomsCount({}))
          },
          error: () =>
            ToastOpen({
              message: t('Error deleting meeting.'),
              type: 'error',
            }),
        },
      ],
      /* Redirection */
      [
        pending.fetchStatus,
        {
          success: () => {
            if (roomId && page === 'vote') {
              const room = (pending.rooms || []).find((room) => room.id === roomId)
              if (room) {
                setMeetingVoteRoom(room)
              } else {
                ToastOpen({
                  message: t('Preferences area not found.'),
                  description: t(
                    'The meeting has already been scheduled or has been cancelled.',
                  ),
                  type: 'error',
                })
              }
            }
          },
        },
      ],
    ],
    () => {
      dispatch(idleCreateRoomStatus())
      dispatch(idleDeleteRoomStatus())
    },
    () => {
      dispatch(fetchRooms({ status: 'pending' }))
    },
  )

  /**
   *
   */
  function onCloseMeetingVote() {
    navigate('/pending-meetings')
    setMeetingVoteRoom(undefined)
  }

  /**
   *
   */
  function onCloseRoomManage() {
    navigate('/pending-meetings')
    setRoomModify(undefined)
  }

  function renderPendingMeetingsMenu() {
    return <MeetingsMenu />
  }

  function renderPendingMeetingsContent() {
    if (meetingVoteRoom && !roomModify) {
      return (
        <div className="ml-1rem">
          <MeetingVote
            room={meetingVoteRoom}
            close={onCloseMeetingVote}
            setRoomModify={setRoomModify}
          />
        </div>
      )
    }

    if (roomModify && !meetingVoteRoom) {
      return <RoomManage room={roomModify} close={onCloseRoomManage} />
    }

    if (!meetingVoteRoom && !roomModify) {
      return (
        <>
          <SectionHeader title={t('Your meetings awaiting validation')} />
          <PendingMeetingTable
            data={pending || []}
            pagination={{ page: pendingMeetingPage, setPendingMeetingPage }}
            setMeetingVoteRoom={setMeetingVoteRoom}
            setRoomModify={setRoomModify}
          />
        </>
      )
    }
  }

  return (
    <>
      <Layout
        title="Meetings"
        headerTitle={<h1 className="title">{t('Meetings')}</h1>}
        menu={renderPendingMeetingsMenu()}
        content={renderPendingMeetingsContent()}
      />
      <ModalDeleteMeeting />
    </>
  )
}

export default PendingMeetings
