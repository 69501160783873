import React from 'react'
import './UploadFileList.scss'
import { Button, Card, Progress, UploadFile } from 'antd'
import { useTranslation } from 'react-i18next'
import { formatBytes, renderIcon } from '../../utils/Utils'
import {
  UploadedFile,
  abordUngoingUploadRequest,
} from '../../features/Drives/redux/drivesSlice'
import { AiOutlineClose } from 'react-icons/ai'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../../store'
import { MdCancel } from 'react-icons/md'
import { Tooltip } from 'antd/lib'

interface Props {
  files: UploadedFile[]
}

function UploadFilesList({ files }: Props) {
  const { t } = useTranslation('drives')

  return (
    <Card className="upload-files-list" title={t('Uploading files')}>
      <div className="upload-files-list-container">
        {files.map((file, index) => (
          <div key={index} className="upload-file-item">
            <div className="upload-file-item-progress">
              <div className="file-name-container">
                <div className="d-flex g-0_2rem d-flex-middle">
                  <img
                    src={renderIcon(file.mimeType || '', file.name)}
                    className="file-icon"
                  />
                  <p className="file-name">{file.name}</p>
                </div>
                <p className="file-name">{formatBytes(file.size || 0)}</p>
              </div>
              <div className="d-flex">
                <Progress percent={Math.round(file.percent || 0)} />
                {file.status == 'uploading' && (
                  <Tooltip title={t('Abort upload')}>
                    <div
                      className="d-flex d-flex-center d-flex-column d-flex-middle upload-file-item-btn hover-error-color"
                      onClick={() => {
                        abordUngoingUploadRequest(file.name)
                      }}
                    >
                      <MdCancel size="1rem" />
                    </div>
                  </Tooltip>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </Card>
  )
}

export default UploadFilesList
