import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FetchStatus } from '../../../utils/FetchStatus'
import { OrganizationRole } from '../../Organisation/OrganizationInterface'

export interface GroupParticipant {
  email: string
  firstName: string
  lastName: string
  organizationRole: OrganizationRole
}

export interface Group {
  id: string
  name: string
  creatorEmail: string
  creatorFirstName: string
  creatorLastName: string
  creatorOrganizationRole: OrganizationRole
  color: string
  createdAt: string
  participantsCount: number
  updatedAt: string
  participants: GroupParticipant[] | undefined
}

interface State {
  createGroupStatus: FetchStatus
  fetchGroupsStatus: FetchStatus
  fetchGroupParticipantsStatus: FetchStatus
  deleteGroupStatus: FetchStatus
  updateGroupStatus: FetchStatus
  fetchGroupsError?: string
  groups?: Group[]
  groupToModify?: {
    group: Group
    readonly: boolean
  }
}

export const initialState: State = {
  createGroupStatus: 'idle',
  fetchGroupsStatus: 'idle',
  fetchGroupParticipantsStatus: 'idle',
  deleteGroupStatus: 'idle',
  updateGroupStatus: 'idle',
}

export const createGroup = createAsyncThunk(
  'groups/createGroupStatus',
  async (
    payload: {
      name: string
      participants: string[] | []
      color: string
    },
    { getState },
  ) => {
    const { auth, organisation } = getState() as {
      auth: { jwt: string }
      organisation: { organisation: { id: string } }
    }
    const organizationId = organisation.organisation.id

    const response = await fetch(
      `${process.env.REACT_APP_BASE_USERS_URL}/organization/${organizationId}/group/create`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${auth.jwt}`,
        },
        body: JSON.stringify(payload),
      },
    )

    if (!response.ok) {
      throw new Error('Could not create group')
    }
  },
)

//TODO: customize request to get groups for user?
export const fetchGroups = createAsyncThunk(
  'groups/fetchGroupsStatus',
  async (payload, { getState }) => {
    const { auth, organisation } = getState() as {
      auth: { jwt: string }
      organisation: { organisation: { id: string } }
    }
    const organizationId = organisation.organisation.id

    const response = await fetch(
      `${process.env.REACT_APP_BASE_USERS_URL}/organization/${organizationId}/group/list`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${auth.jwt}`,
        },
      },
    )

    if (!response.ok) {
      throw new Error('Could not fetch groups')
    }

    return {
      groups: await response.json(),
    }
  },
)

export const fetchGroupParticipants = createAsyncThunk(
  'groups/fetchGroupParticipantsStatus',
  async (payload: { organizationId: string; groupId: string }, { getState }) => {
    const { organizationId, groupId } = payload
    const { auth } = getState() as { auth: { jwt: string } }

    const response = await fetch(
      `${process.env.REACT_APP_BASE_USERS_URL}/organization/${organizationId}/group/${groupId}/participants`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${auth.jwt}`,
        },
      },
    )

    if (!response.ok) {
      throw new Error('Could not fetch groups')
    }
    const data = await response.json()
    return {
      participants: data,
      groupId,
    }
  },
)

export const deleteGroup = createAsyncThunk(
  'groups/deleteGroupStatus',
  async ({ groupId }: { groupId?: string }, { getState }) => {
    const { auth, organisation } = getState() as {
      auth: { jwt: string }
      organisation: { organisation: { id: string } }
    }
    const organizationId = organisation.organisation.id

    const response = await fetch(
      `${process.env.REACT_APP_BASE_USERS_URL}/organization/${organizationId}/group/delete`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: auth.jwt,
        },
        body: JSON.stringify({ groupId }),
      },
    )

    if (!response.ok) {
      throw new Error('Could not delete group')
    }
  },
)

export const updateGroup = createAsyncThunk(
  'groups/updateGroupStatus',
  async (
    payload: {
      groupId: string
      name: string
      color: string
      removingParticipants: string[]
      addingParticipants: string[]
    },
    { getState },
  ) => {
    const { auth, organisation } = getState() as {
      auth: { jwt: string }
      organisation: { organisation: { id: string } }
    }
    const organizationId = organisation.organisation.id
    const response = await fetch(
      `${process.env.REACT_APP_BASE_USERS_URL}/organization/${organizationId}/group/modify`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${auth.jwt}`,
        },
        body: JSON.stringify(payload),
      },
    )

    if (!response.ok) {
      throw new Error('Could not update group')
    }
  },
)

const groupsSlice = createSlice({
  name: 'groups',
  initialState,
  reducers: {
    idleCreateGroupStatus: (state) => {
      state.createGroupStatus = 'idle'
    },
    idleFetchGroupsStatus: (state) => {
      state.fetchGroupsStatus = 'idle'
    },
    idleFetchGroupParticipantsStatus: (state) => {
      state.fetchGroupParticipantsStatus = 'idle'
    },
    idleDeleteGroupStatus: (state) => {
      state.deleteGroupStatus = 'idle'
    },
    idleUpdateGroupStatus: (state) => {
      state.updateGroupStatus = 'idle'
    },
    setGroupToModify: (
      state,
      action: PayloadAction<{ group: Group; readonly: boolean } | undefined>,
    ) => {
      state.groupToModify = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createGroup.pending, (state, action) => {
        state.createGroupStatus = 'loading'
      })
      .addCase(createGroup.fulfilled, (state, action) => {
        state.createGroupStatus = 'success'
      })
      .addCase(createGroup.rejected, (state, action) => {
        const error = action.error
        state.createGroupStatus =
          error.name === 'ApiError' && error.message ? error.message : 'unknown_error'
      })
      .addCase(fetchGroups.pending, (state, action) => {
        state.fetchGroupsStatus = 'loading'
      })
      .addCase(
        fetchGroups.fulfilled,
        (state, { payload }: PayloadAction<{ groups: Group[] }>) => {
          state.fetchGroupsStatus = 'success'
          state.groups = payload.groups
        },
      )

      .addCase(fetchGroups.rejected, (state, action) => {
        const error = action.error
        state.fetchGroupsStatus = 'error'
        state.fetchGroupsError = error.message ?? 'unknown_error'
      })

      .addCase(fetchGroupParticipants.pending, (state, action) => {
        state.fetchGroupParticipantsStatus = 'loading'
      })
      .addCase(
        fetchGroupParticipants.fulfilled,
        (
          state,
          {
            payload,
          }: PayloadAction<{ participants: GroupParticipant[]; groupId: string }>,
        ) => {
          state.fetchGroupParticipantsStatus = 'success'
          const { participants, groupId } = payload

          const targetGroup = state.groups?.find((group) => group.id === groupId)
          if (targetGroup) targetGroup.participants = participants
          if (state.groupToModify?.group.id === groupId)
            state.groupToModify.group.participants = participants //TODO: shouldn't be here, refactor this part
        },
      )
      .addCase(fetchGroupParticipants.rejected, (state, action) => {
        state.fetchGroupParticipantsStatus = 'error'
      })
      .addCase(deleteGroup.pending, (state, action) => {
        state.deleteGroupStatus = 'loading'
      })
      .addCase(deleteGroup.fulfilled, (state, action) => {
        state.deleteGroupStatus = 'success'
      })
      .addCase(deleteGroup.rejected, (state, action) => {
        const error = action.error
        state.deleteGroupStatus =
          error.name === 'ApiError' && error.message ? error.message : 'unknown_error'
      })

      .addCase(updateGroup.pending, (state, action) => {
        state.updateGroupStatus = 'loading'
      })
      .addCase(updateGroup.fulfilled, (state, action) => {
        state.updateGroupStatus = 'success'
      })
      .addCase(updateGroup.rejected, (state, action) => {
        const error = action.error
        state.updateGroupStatus =
          error.name === 'ApiError' && error.message ? error.message : 'unknown_error'
      })
  },
})

export default groupsSlice.reducer

export const {
  idleCreateGroupStatus,
  idleFetchGroupsStatus,
  idleFetchGroupParticipantsStatus,
  idleDeleteGroupStatus,
  idleUpdateGroupStatus,
  setGroupToModify,
} = groupsSlice.actions
