import React, { useRef, useState } from 'react'
import { Alert } from 'antd'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import { useTranslation } from 'react-i18next'
import './Preview.scss'
import AuthPreview from './Views/AuthPreview'
import { AiTwotoneEdit } from 'react-icons/ai'
import './Views/views.scss'
import LayoutPreview from './Views/LayoutPreview'
import AntechamberPreview from './Views/AntechamberPreview'
import CallPreview from './Views/CallPreview'
import DesignerTools, { DesignerToolsInterface } from './DesignerTools'
import usePersonalizationUploader from '../../utils/hooks/usePersonalizationUploader'

function PersonalizationPreview() {
  const { t } = useTranslation(['common', 'personalization'])
  const buildInProgress = useSelector(
    (state: RootState) => state.personalization.buildInProgress,
  )
  const [displayedView, setDisplayedView] = useState<
    'auth' | 'layout' | 'antechamber' | 'call'
  >('auth')

  const personalizationUploader = usePersonalizationUploader()
  const {
    authLogoUploaded,
    headerLogoUploaded,
    headerLogoMobileUploaded,
    callLogoUploaded,
  } = personalizationUploader
  const DesignerToolsRef = useRef<DesignerToolsInterface>(null)

  function DisplayedView() {
    if (displayedView === 'layout')
      return (
        <LayoutPreview logo={headerLogoUploaded} logoMobile={headerLogoMobileUploaded} />
      )
    else if (displayedView === 'antechamber')
      return <AntechamberPreview logo={callLogoUploaded} />
    else if (displayedView === 'call') return <CallPreview logo={callLogoUploaded} />
    //default : (displayedView === 'auth')
    else return <AuthPreview logo={authLogoUploaded} />
  }

  return (
    <>
      <AiTwotoneEdit
        size="2.5rem"
        color="#FFFFFF"
        className="fixed-custom-button"
        onClick={() => DesignerToolsRef.current?.toggleShowState()}
      />
      <div className="personnalization-ribon">
        {t('Personalization', { ns: 'layout' })}
      </div>
      {buildInProgress && (
        <Alert
          className="build-in-progress-alert"
          message={t(
            'Build in progress. It usually takes up to 5 minutes. Please, wait before submitting any other changes.',
            { ns: 'personalization' },
          )}
          type="warning"
        />
      )}
      <div
        className="preview"
        onClick={() => console.log('DesignerToolsRef.current?.test:')}
      >
        <DisplayedView />
        <DesignerTools
          ref={DesignerToolsRef}
          setDisplayedView={setDisplayedView}
          personalizationUploader={personalizationUploader}
        />
      </div>
    </>
  )
}

export default PersonalizationPreview
