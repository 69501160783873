import React, { useEffect } from 'react'
import { RootState } from '../../store'

/**
 * @param onWebCamChangenged
 * @param onMicChangenged
 * @param onSpeakersChanged
 * @returns
 */
export default function usePeripheralsSelector(
  room: RootState['room'],
  onMicChangenged: () => void,
  onSpeakersChanged: () => void,
  onReturn: () => void,
) {
  useEffect(onMicChangenged, [room.mic])
  useEffect(onSpeakersChanged, [room.speakerId])
  useEffect(() => {
    return onReturn
  }, [])
}
