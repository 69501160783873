import React from 'react'
import { Form, FormInstance, Input, Row, Col, InputRef } from 'antd'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { DriveRole } from '../../../features/Drives/redux/drivesSlice'
import { RootState } from '../../../store'
import './DriveForm.scss'
import DriveFormAddParticipant from './DriveFormAddParticipant'
interface Props {
  driveForm: FormInstance
  onDriveSubmit: () => void
  newDrive: boolean
  inputFocusRef?: React.RefObject<InputRef>
}

function DriveForm({ driveForm, onDriveSubmit, newDrive, inputFocusRef }: Props) {
  const { t } = useTranslation('drives')
  const driveToModify = useSelector((state: RootState) => state.drive.driveToModify)
  const isAdmin = useSelector((state: RootState) => state.drive.driveModifyForm.isAdmin)

  return (
    <>
      <Form
        name="driveForm"
        layout="vertical"
        autoComplete="off"
        form={driveForm}
        onFinish={onDriveSubmit}
        className="DriveForm"
        initialValues={{
          role: DriveRole.READ_ONLY,
        }}
      >
        <Form.Item
          name="name"
          className="drive-name"
          label={t('Drive name')}
          rules={[{ required: true, message: t('Please input drive name.') }]}
        >
          <Input disabled={driveToModify && !isAdmin} ref={inputFocusRef} />
        </Form.Item>
        <Row>
          <Col span={24}>
            <DriveFormAddParticipant driveForm={driveForm} newDrive={newDrive} />
          </Col>
        </Row>
      </Form>
    </>
  )
}

export default DriveForm
