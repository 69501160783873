import React from 'react'
import DrivesSearch from '../DrivesSearch'
import './WelcomeDrives.scss'
import DrivesList from '../DrivesList/DrivesList'
import { FolderInterface } from '../../../features/Drives/redux/drivesSlice'

interface Props {
  adminRootFolders: FolderInterface[]
  sharedRootFolders: FolderInterface[]
}

function WelcomeDrives({ adminRootFolders, sharedRootFolders }: Props) {
  return (
    <div className="WelcomeDrives">
      <div className="search-container">
        <DrivesSearch />
      </div>
      <div className="drives-container">
        <DrivesList
          adminRootFolders={adminRootFolders}
          sharedRootFolders={sharedRootFolders}
        />
      </div>
    </div>
  )
}

export default WelcomeDrives
