import React, { useRef } from 'react'
import { Button, Divider } from 'antd'
import Layout from '../../components/Layout/Layout'
import { Trans, useTranslation } from 'react-i18next'
import './notarisation.scss'
import NotarisationTable from './NotarisationTable'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import sanitizeHtml from 'sanitize-html'
import { allowedTagsObject } from '../../utils/Utils'

function Notarisation() {
  const { t } = useTranslation('notarisation')
  const { roomId } = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const name = searchParams.get('name') || ''
  const sanitizedName = useRef<string>()
  sanitizedName.current = sanitizeHtml(name, allowedTagsObject)

  function goBack() {
    navigate(
      location.pathname.includes('archived-mettings')
        ? '/archived-meetings'
        : '/upcoming-meetings',
    )
  }

  function renderNotarisationContent() {
    if (roomId) {
      return (
        <>
          <div className="layout-header">
            <h1>{t('Notarisation')}</h1>
            <div className="layout-header-bottom">
              <h4 className="layout-list-header">
                <Trans
                  i18nKey={'MEETING_NAME'}
                  shouldUnescape={true}
                  ns="notarisation"
                  values={{ name: sanitizedName.current }}
                />
              </h4>
              <Button type="primary" onClick={goBack}>
                <span className="hideOnMobile">{t('Close', { ns: 'common' })}</span>
              </Button>
            </div>
            <Divider />
          </div>
          <NotarisationTable roomId={roomId} />
        </>
      )
    }
  }

  return <Layout content={renderNotarisationContent()} footer={false} />
}

export default Notarisation
