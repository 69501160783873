import React from 'react'
import { Tooltip } from 'antd'
import { Notification } from '../liveWidgetSlice'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../../../store'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { setCurrentChatId } from '../../ChatComponent/Chat/chatsSlice'
import sanitizeHtml from 'sanitize-html'
import NotificationItem from './NotificationItem'
import { LOGO } from '../../../assets/logos'
import { limitText, renderIcon } from '../../../utils/Utils'
import { ViewFile } from '../../Viewer/ViewerSlice'
import classNames from 'classnames'

interface Props {
  notification: Notification
  isRowTable?: boolean
}

export default function ChatMessageNotificationItem({ notification, isRowTable }: Props) {
  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()

  let files: ViewFile[] | undefined
  if (notification.misc.files) {
    try {
      files = JSON.parse(notification.misc.files) as ViewFile[]
      files = Array.isArray(files) ? files : undefined
    } catch (e) {}
  }

  return (
    <NotificationItem
      notification={notification}
      icon={
        <img src={LOGO.logo_auxateam} className="notification_widget_content_item_icon" />
      }
      onClick={() => {
        dispatch(setCurrentChatId(notification.misc.chatId))
        navigate('/messages')
      }}
      isRowTable={isRowTable}
    >
      <div
        className={classNames({
          notification_chat_container: true,
          notification_table_message_column_content_chat: isRowTable,
        })}
      >
        {notification.misc.senderFirstName &&
          notification.misc.senderLastName &&
          notification.misc.senderEmail && (
            <Tooltip title={notification.misc.senderEmail}>
              <div>
                {notification.misc.senderFirstName} {notification.misc.senderLastName}:{' '}
              </div>
            </Tooltip>
          )}

        {notification.misc.text && (
          <Tooltip
            title={
              notification.misc.text.length > 50
                ? limitText(
                    sanitizeHtml(notification.misc.text, {
                      allowedTags: [''],
                    }),
                    200,
                  )
                : ''
            }
            overlayClassName="notification_tooltip"
          >
            <div
              className="notification_chat_text"
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(notification.misc.text, {
                  allowedTags: [''],
                }),
              }}
            />
          </Tooltip>
        )}

        {files && files[0] && files[0].mimeType && files[0].name ? (
          <Tooltip title={files[0].name}>
            <div className="notification_chat_file">
              <img
                src={renderIcon(files[0].mimeType, files[0].name)}
                className="file-icon"
              />
              {files[0].name}
            </div>
          </Tooltip>
        ) : (
          <></>
        )}
      </div>
    </NotificationItem>
  )
}
