import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState, store } from '../../../store'
import './Call.scss'
import { addListener } from '@reduxjs/toolkit'
import { Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import { ImPhoneHangUp } from 'react-icons/im'
import CallControlsMic from './CallControlsMic'
import CallControlsWebcam from './CallControlsWebcam'
import { setCalling } from '../Chat/chatsSlice'
import { useToastContext } from '../../Toast/ToastContext'
import { ChatWebsocketConnection } from '../WebsocketConnection/AppWebsocketConnection'
import useCallWebsocketConnection from '../WebsocketConnection/call/useCallWebsocketConnection'
import { websocketWebRTCSignalingAction } from '../WebsocketConnection/call/CallWebsocketConnection'

let ICE_SERVERS: RTCIceServer[] = []
try {
  ICE_SERVERS = JSON.parse(process.env.REACT_APP_ICE_SERVERS || '')
  console.log(ICE_SERVERS)
} catch (e) {
  console.error(
    'Could not parse REACT_APP_ICE_SERVERS: ' + process.env.REACT_APP_ICE_SERVERS,
  )
}

interface CallProps {
  wsRef: React.MutableRefObject<ChatWebsocketConnection | undefined>
  currentSquadId: string
  currentChatId: string
}

export default function Call({ wsRef, currentSquadId, currentChatId }: CallProps) {
  const { t } = useTranslation('ChatCall')
  const { ToastOpen, ToastDestroy } = useToastContext()
  const selectChat = (state: RootState) =>
    (state.chats.chats[currentSquadId] || []).find((c) => c.id === currentChatId)
  const chat = useSelector(selectChat)
  const pc = useRef<RTCPeerConnection | undefined>()
  const localVideo = useRef<HTMLVideoElement>(null)
  const remoteVideo = useRef<HTMLVideoElement>(null)
  const [localStream, setLocalStream] = useState<MediaStream | undefined>()
  const [remoteStream, setRemoteStream] = useState<MediaStream | undefined>()
  const makingOffer = useRef(false)
  const dispatch = useDispatch<AppDispatch>()
  const [cameraActive, setCameraActive] = useState(true)
  const [micActive, setMicActive] = useState(true)
  const [peerCameraActive, setPeerCameraActive] = useState(false)
  const [peerMicActive, setPeerMicActive] = useState(false)

  const email = useSelector((state: RootState) => state.auth.email || '')
  const peerEmail = chat?.peer?.email || ''
  const polite = peerEmail < email
  const callWs = useCallWebsocketConnection({ peerEmail })
  const wsConnected = useSelector((state: RootState) => state.chatWebsocket.connected)
  const wsCallConnected = useSelector((state: RootState) => state.callWebsocket.connected)

  useEffect(() => {
    if (!wsConnected) {
      console.error('wsConnected not connected')
      return
    }

    if (!wsCallConnected) {
      console.error('callWsConnected not connected')
      return
    }
    callWs?.current?.sendWebRTCSignaling(peerEmail, JSON.stringify({ connected: true }))
    wsRef?.current?.sendStartDial(peerEmail)

    const unsubscribeFromWebRTCSignalingAction = store.dispatch(
      addListener({
        actionCreator: websocketWebRTCSignalingAction,
        effect: (action, listenerApi) => {
          if (peerEmail !== action.payload.from) {
            return
          }

          const data = JSON.parse(action.payload.data)
          onSignaling(data)
        },
      }),
    )

    pc.current = new RTCPeerConnection({
      iceServers: ICE_SERVERS,
    })

    pc.current.ontrack = ({ track, streams }) => {
      console.log('!!!!!!!!!!! { track, streams }')
      track.onunmute = () => {
        setRemoteStream(streams[0])
        if (remoteVideo.current) {
          remoteVideo.current.srcObject = streams[0]
        }

        if (track.kind === 'audio') {
          setPeerMicActive(true)
        } else if (track.kind === 'video') {
          setPeerCameraActive(true)
        }
      }
    }

    pc.current.onnegotiationneeded = async () => {
      console.log('!!! onnegotiationneeded')
      if (!pc.current) {
        console.error('!!! onnegotiationneeded pc.current IS NULL')
        return
      }

      try {
        makingOffer.current = true
        await pc.current.setLocalDescription()
        callWs?.current?.sendWebRTCSignaling(
          peerEmail,
          JSON.stringify({ description: pc.current.localDescription }),
        )
        console.log('DDD SENT', { description: pc.current.localDescription })
      } catch (err) {
        console.error(err)
      } finally {
        makingOffer.current = false
      }
    }

    pc.current.onicecandidate = ({ candidate }) => {
      console.log('!!! onicecandidate')
      callWs?.current?.sendWebRTCSignaling(peerEmail, JSON.stringify({ candidate }))
    }

    pc.current.oniceconnectionstatechange = () => {
      console.log('!!! oniceconnectionstatechange', pc.current?.iceConnectionState)
      if (pc.current?.iceConnectionState === 'failed') {
        pc.current.restartIce()
      }
    }

    startUserMedia()

    return () => {
      unsubscribeFromWebRTCSignalingAction()
      if (pc.current) {
        pc.current.close()
        pc.current = undefined
      }
    }
  }, [wsCallConnected])

  useEffect(() => {
    if (localVideo.current && localStream) {
      localVideo.current.srcObject = localStream
    }

    return () => {
      if (localStream) {
        localStream.getTracks().forEach((track) => track.stop())
      }
      if (localVideo.current) {
        localVideo.current.srcObject = null
      }
    }
  }, [localStream])

  useEffect(() => {
    if (pc.current && localStream) {
      for (const track of localStream.getTracks()) {
        console.log('??? ADD TRACK')
        pc.current?.addTrack(track, localStream)
      }
    }
  }, [localStream])

  function hangup() {
    callWs?.current?.sendWebRTCSignaling(peerEmail, JSON.stringify({ hangup: true }))
    dispatch(setCalling(false))
  }

  async function onSignaling(data: any) {
    const { connected, hangup, description, candidate, cameraStatus, micStatus } = data

    if (!pc.current) {
      console.error('!!! onSignaling pc.current IS UNDEFINED')
      return
    }

    try {
      if (connected) {
      } else if (hangup) {
        ToastOpen({
          type: 'info',
          message: t('Call ended. Your peer has just hang up'),
        })

        dispatch(setCalling(false))
      } else if (micStatus) {
        setPeerMicActive(micStatus === 'active')
      } else if (cameraStatus) {
        setPeerCameraActive(cameraStatus === 'active')
      } else if (description) {
        const offerCollision =
          description.type === 'offer' &&
          (makingOffer.current || pc.current.signalingState !== 'stable')

        /*
        const ignoreOffer = !polite && offerCollision
        if (ignoreOffer) {
          console.log('IIIIIIII ' + ignoreOffer)
          return
        }*/

        await pc.current.setRemoteDescription(description)
        if (description.type === 'offer') {
          await pc.current.setLocalDescription()
          callWs?.current?.sendWebRTCSignaling(
            peerEmail,
            JSON.stringify({ description: pc.current.localDescription }),
          )
        }
      } else if (candidate) {
        await pc.current.addIceCandidate(candidate)
      }
    } catch (e) {
      console.error('onSignaling: ', e)
    }
  }

  async function startUserMedia() {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: true,
      })
      setLocalStream(stream)
    } catch (err) {
      console.error(err)
    }
  }

  function toggleMic() {
    const newMicActive = !micActive
    setMicActive(newMicActive)
    const audioTracks = localStream?.getAudioTracks() || []
    for (const track of audioTracks) {
      track.enabled = newMicActive
    }

    callWs?.current?.sendWebRTCSignaling(
      peerEmail,
      JSON.stringify({ micStatus: newMicActive ? 'active' : 'disabled' }),
    )
  }

  function toggleCamera() {
    const newCameraActive = !cameraActive
    setCameraActive(newCameraActive)
    const videoTracks = localStream?.getVideoTracks() || []
    for (const track of videoTracks) {
      track.enabled = newCameraActive
    }

    callWs?.current?.sendWebRTCSignaling(
      peerEmail,
      JSON.stringify({ cameraStatus: newCameraActive ? 'active' : 'disabled' }),
    )
  }

  if (!chat?.peerToPeer || !chat?.peer) {
    return <></>
  }

  return (
    <div className="Call">
      <video
        className="Call__local_video"
        ref={localVideo}
        autoPlay
        playsInline
        muted
        controls={false}
      />
      <video
        className="Call__remote_video"
        ref={remoteVideo}
        autoPlay
        playsInline
        muted
        controls={false}
      />

      <div className="Call__controls-container">
        <div className="call-controls">
          <div className="call-controls--left">
            <CallControlsMic active={micActive} toggle={toggleMic} />
            <CallControlsWebcam active={cameraActive} toggle={toggleCamera} />
          </div>
          <div className="call-controls--middle"></div>
          <div className="call-controls--right">
            <Tooltip title={t('Hang up')}>
              <div className="call-control call-control--hangup" onClick={hangup}>
                <ImPhoneHangUp size="2rem" color="#ffffff" />
              </div>
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  )
}
