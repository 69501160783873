import ApiError from '../../utils/errors/ApiError'
import { OrganizationRole } from './OrganizationInterface'

export interface SearchEntitiesDTO {
  users: SearchUserDTO[]
  groups: SearchGroupDTO[]
}

export interface SearchUsersAndInvitesDTO {
  users: SearchUserDTO[]
  invites: SearchUserDTO[]
}

export interface SearchUserDTO {
  email: string
  firstName?: string
  lastName?: string
  organizationRole?: OrganizationRole
}

export interface SearchGroupDTO {
  id: string
  name: string
}

interface SearchEntitiesArgs {
  jwt: string
  q: string
  organisationId: string
  excludeEmails: string[]
  excludeGroupIds: string[]
}

export async function searchEntities({
  jwt,
  q,
  organisationId,
  excludeEmails,
  excludeGroupIds,
}: SearchEntitiesArgs): Promise<SearchEntitiesDTO> {
  const response = await fetch(
    `${process.env.REACT_APP_API_BASE_URL}/users/organization/${organisationId}/entities/search`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${jwt}`,
      },
      body: JSON.stringify({ q, excludeEmails, excludeGroupIds }),
    },
  )

  const result = await response.json()

  if (!response.ok) {
    throw new ApiError(response.status.toString())
  }

  return result
}

export async function searchUsersAndInvites(
  jwt: string,
  q: string,
  organizationId: string,
  service: string,
  excludeEmails: string[],
): Promise<SearchUsersAndInvitesDTO> {
  const response = await fetch(
    `${process.env.REACT_APP_API_BASE_URL}/users/organization/${organizationId}/usersAndInvites/search`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${jwt}`,
      },
      body: JSON.stringify({ q, service, excludeEmails }),
    },
  )

  if (!response.ok) {
    throw new ApiError(response.status.toString())
  }

  return await response.json()
}

export async function searchUsers(
  jwt: string,
  q: string,
  excludeEmails: string[],
): Promise<SearchUserDTO[]> {
  const response = await fetch(
    `${process.env.REACT_APP_API_BASE_URL}/users/user/search`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${jwt}`,
      },
      body: JSON.stringify({ q, excludeEmails }),
    },
  )

  if (!response.ok) {
    throw new ApiError(response.status.toString())
  }

  return await response.json()
}

export async function searchInvite(
  jwt: string,
  q: string,
  service: string,
  entity: string,
  excludeEmails: string[],
): Promise<SearchUserDTO[]> {
  const response = await fetch(
    `${process.env.REACT_APP_API_BASE_URL}/users/invite/search`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${jwt}`,
      },
      body: JSON.stringify({ q, service, entity, excludeEmails }),
    },
  )

  if (!response.ok) {
    throw new ApiError(response.status.toString())
  }

  return await response.json()
}
