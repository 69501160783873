import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import dayjs, { ManipulateType, OpUnitType } from 'dayjs'
import { Button, Radio, RadioChangeEvent } from 'antd'
import ButtonGroup from 'antd/es/button/button-group'
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs'
import './calendar.scss'
import { NavigateAction, View } from 'react-big-calendar'
import { AppDispatch } from '../../store'
import { useDispatch } from 'react-redux'
import { buildCalendarUrl } from './CalendarService'
import { useNavigate } from 'react-router-dom'

interface Props {
  currentView: View
  currentDate: string
  setCurrentDate: (view: string) => void
}

function CustomToolbarCalendar({ currentView, setCurrentDate, currentDate }: Props) {
  const dispatch = useDispatch<AppDispatch>()
  const { t } = useTranslation('calendar')
  const navigate = useNavigate()

  const getViewLabel = () => {
    switch (currentView) {
      case 'day':
        return dayjs(currentDate).format('dddd D MMMM YYYY')
      case 'week':
        const startOfWeek = dayjs(currentDate).startOf('week').format('D MMMM')
        const endOfWeek = dayjs(currentDate).endOf('week').format('D MMMM YYYY')
        return `${startOfWeek} - ${endOfWeek}`
      case 'month':
        return dayjs(currentDate).format('MMMM YYYY')
      default:
        return ''
    }
  }

  /**
   *
   */
  function changeDate(direction: 'PREV' | 'TODAY' | 'NEXT') {
    let newDate
    if (direction === 'PREV') {
      newDate = dayjs(currentDate)
        .subtract(1, currentView as ManipulateType)
        .toString()
    } else if (direction === 'NEXT') {
      newDate = dayjs(currentDate)
        .add(1, currentView as ManipulateType)
        .toString()
    } else {
      newDate = dayjs().toString()
    }
    setCurrentDate(newDate)
    navigate(buildCalendarUrl(newDate, currentView as OpUnitType))
  }

  /**
   * Event handler for changing the calendar view.
   */
  const handleChangeView = useCallback(
    (event: RadioChangeEvent) => {
      navigate(buildCalendarUrl(currentDate.toString(), event.target.value))
    },
    [dispatch],
  )

  return (
    <div className="toolbar">
      <div className="mb-05rem toolbar-desktop">
        <div className="d-flex d-flex-middle g-1rem">
          <ButtonGroup className="calendar-navigation-group">
            <Button
              type="primary"
              size="small"
              onClick={() => changeDate('PREV')}
              icon={<BsChevronLeft />}
            ></Button>
            <Button type="primary" size="small" onClick={() => changeDate('TODAY')}>
              {t('Today')}
            </Button>
            <Button
              type="primary"
              size="small"
              onClick={() => changeDate('NEXT')}
              icon={<BsChevronRight />}
            ></Button>
          </ButtonGroup>
        </div>

        <strong>{getViewLabel()}</strong>

        <Radio.Group
          value={currentView}
          onChange={(e: RadioChangeEvent) => handleChangeView(e)}
        >
          <Radio.Button value="day">{t('Day')}</Radio.Button>
          <Radio.Button value="week">{t('Week')}</Radio.Button>
          <Radio.Button value="month">{t('Month')}</Radio.Button>
        </Radio.Group>
      </div>

      <div className="mb-05rem toolbar-mobile">
        <strong>{getViewLabel()}</strong>
        <div className="change-date">
          <div className="change-date">
            <ButtonGroup className="calendar-navigation-group">
              <Button
                type="primary"
                size="small"
                onClick={() => changeDate('PREV')}
                icon={<BsChevronLeft />}
              ></Button>
              <Button type="primary" size="small" onClick={() => changeDate('TODAY')}>
                {t('Today')}
              </Button>
              <Button
                type="primary"
                size="small"
                onClick={() => changeDate('NEXT')}
                icon={<BsChevronRight />}
              ></Button>
            </ButtonGroup>
          </div>
          <Radio.Group
            value={currentView}
            onChange={(e: RadioChangeEvent) => handleChangeView(e)}
          >
            <Radio.Button value="day">{t('Day')}</Radio.Button>
            <Radio.Button value="week">{t('Week')}</Radio.Button>
            <Radio.Button value="month">{t('Month')}</Radio.Button>
          </Radio.Group>
        </div>
      </div>
    </div>
  )
}

export default CustomToolbarCalendar
