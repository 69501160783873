function getMaxArea(
  lines: number,
  elements: number,
  width: number,
  height: number,
): { area: number; elementWidth: number; elementHeight: number } {
  const elementsInLine = Math.ceil(elements / lines)
  const maxHeight = height / lines
  const maxWidth = width / elementsInLine

  let elementWidth
  let elementHeight

  if (maxWidth / maxHeight > 4 / 3) {
    elementWidth = (maxHeight * 4) / 3
    elementHeight = maxHeight
  } else {
    elementWidth = maxWidth
    elementHeight = (maxWidth / 4) * 3
  }

  return { area: elementWidth * elementHeight * elements, elementWidth, elementHeight }
}

/**
 * Get lines number to cover maximum area.
 * @param elements
 * @param width
 * @param height
 * @returns
 */
export function getMaxAreaLines(
  elements: number,
  width: number,
  height: number,
): { lines: number; elementWidth: number; elementHeight: number } {
  let maxArealLines = 1
  let maxElementWidth = 0
  let maxElementHeight = 0
  let maxArea = -1

  for (let lines = 1; lines <= elements; lines++) {
    const { area, elementWidth, elementHeight } = getMaxArea(
      lines,
      elements,
      width,
      height,
    )
    if (area > maxArea) {
      maxArea = area
      maxArealLines = lines
      maxElementWidth = elementWidth
      maxElementHeight = elementHeight
    }
  }

  return {
    lines: maxArealLines,
    elementWidth: maxElementWidth,
    elementHeight: maxElementHeight,
  }
}
