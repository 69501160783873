import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { Dayjs } from 'dayjs'
import { FetchStatus } from '../../../utils/FetchStatus'
import { stat } from 'fs'

interface State {
  newMeeting?: {
    meetingStartDate?: Dayjs
    meetingDuration?: number
  }
  updateMeetingDrawerOpen: boolean
  moveMeetingModalOpen: boolean
  updateColorStatus: FetchStatus
  reducedFetchMeetingsStatus: FetchStatus
  extendedFetchMeetingsStatus: FetchStatus
  calendarMenuEvents: CalendarMenuEvent[]
}

export interface CalendarMenuEvent {
  id: string
  start: string
  meetingsType: string
}

export enum MeetingType {
  upcoming = 'upcoming',
  archived = 'archived',
  pending = 'pending',
}

export const initialState: State = {
  updateMeetingDrawerOpen: false,
  moveMeetingModalOpen: false,
  updateColorStatus: 'idle',
  reducedFetchMeetingsStatus: 'idle',
  extendedFetchMeetingsStatus: 'idle',
  calendarMenuEvents: [],
}

export const updateColor = createAsyncThunk(
  'calendar/updateColorStatus',
  async (payload: { color: string; meetingId: string }, { getState }) => {
    const { auth } = getState() as { auth: { jwt: string } }

    const response = await fetch(
      process.env.REACT_APP_MEDIA_BASE_URL + '/room/update-color-planning',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${auth.jwt}`,
        },
        body: JSON.stringify(payload),
      },
    )

    if (!response.ok) {
      throw new Error('Could not update color')
    }
  },
)

export const reducedFetchMeetings = createAsyncThunk(
  'calendar/reducedFetchMeetingsStatus',
  async (payload: { minDate: string; maxDate: string }, { getState }) => {
    const { auth } = getState() as { auth: { jwt: string } }

    const response = await fetch(
      process.env.REACT_APP_MEDIA_BASE_URL + '/room/reduced-list',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${auth.jwt}`,
        },
        body: JSON.stringify(payload),
      },
    )

    if (!response.ok) {
      throw new Error('Could not fetch meetings')
    }

    return {
      events: await response.json(),
    }
  },
)

export const calendarSlice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    setNewMeeting: (
      state,
      {
        payload,
      }: PayloadAction<{ meetingStartDate: Dayjs; meetingDuration: number } | undefined>,
    ) => {
      state.newMeeting = payload
    },
    setUpdateMeetingDrawerOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.updateMeetingDrawerOpen = payload
    },
    setMoveMeetingModalOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.moveMeetingModalOpen = payload
    },
    idleUpdateColorStatus: (state) => {
      state.updateColorStatus = 'idle'
    },
    setCalendarMenuEvents: (state, { payload }: PayloadAction<CalendarMenuEvent[]>) => {
      state.calendarMenuEvents = payload
    },
    pushCalendarMenuEvents: (state, { payload }: PayloadAction<CalendarMenuEvent>) => {
      state.calendarMenuEvents.push(payload)
    },
    popCalendarMenuEvents: (state, { payload }: PayloadAction<string>) => {
      const indexToDelete = state.calendarMenuEvents.findIndex(
        (event) => (event.start = payload),
      )
      state.calendarMenuEvents.splice(indexToDelete, 1)
    },
  },
  extraReducers: {
    [updateColor.pending.type]: (state, action) => {
      state.updateColorStatus = 'loading'
    },
    [updateColor.fulfilled.type]: (state, action: PayloadAction<{}>) => {
      state.updateColorStatus = 'success'
    },
    [updateColor.rejected.type]: (state, action) => {
      const error = action.error
      state.updateColorStatus = 'error'
      state.updateColorStatus = error.message ?? 'unknown_error'
    },
    [reducedFetchMeetings.pending.type]: (state, action) => {
      state.reducedFetchMeetingsStatus = 'loading'
    },
    [reducedFetchMeetings.fulfilled.type]: (
      state,
      action: PayloadAction<{ events: CalendarMenuEvent[] }>,
    ) => {
      state.reducedFetchMeetingsStatus = 'success'
      state.calendarMenuEvents = action.payload.events
    },
    [reducedFetchMeetings.rejected.type]: (state, action) => {
      const error = action.error
      state.reducedFetchMeetingsStatus = 'error'
      state.reducedFetchMeetingsStatus = error.message ?? 'unknown_error'
    },
  },
})

export const {
  setNewMeeting,
  setUpdateMeetingDrawerOpen,
  setMoveMeetingModalOpen,
  idleUpdateColorStatus,
  setCalendarMenuEvents,
  pushCalendarMenuEvents,
  popCalendarMenuEvents,
} = calendarSlice.actions

export default calendarSlice.reducer
