import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { FetchStatus } from '../../../utils/FetchStatus'
import { setJWT } from '../../auth/redux/authSlice'
import ApiError from '../../../utils/errors/ApiError'

interface State {
  attemptModifyStatus: FetchStatus
  attemptModifyError?: string
  notification?: { key: string; checked: boolean }[]
  IsDesktopNotificationEnabled?: boolean
}

export const initialState: State = {
  attemptModifyStatus: 'idle',
  notification: [],
  IsDesktopNotificationEnabled: false,
}

/**
 * Auth user with email and password.
 */
export const attemptModify = createAsyncThunk(
  'auth/attemptModify',
  async (
    payload: {
      firstName: string
      lastName: string
      tel?: string
      newPassword?: string
      oldPassword?: string
      lang: string
      notification: { key: string; checked: boolean }[]
    },
    { getState, dispatch },
  ) => {
    const { auth } = getState() as { auth: { jwt: string } }

    const responseUser = await fetch(
      process.env.REACT_APP_BASE_USERS_URL + '/user/modify',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${auth.jwt}`,
        },
        body: JSON.stringify({ ...payload, notification: undefined }),
      },
    )
    const bodyUser = await responseUser.json()

    const responseNotif = await fetch(
      process.env.REACT_APP_MEDIA_BASE_URL + '/preferences/modify',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${auth.jwt}`,
        },
        body: JSON.stringify({ notification: payload.notification }),
      },
    )

    if (!responseUser.ok || !responseNotif.ok) {
      const reason = bodyUser.reason || 'unknown_error'
      throw new ApiError(reason)
    }

    dispatch(setJWT(bodyUser.token))
    return bodyUser
  },
)

export const updateLanguage = createAsyncThunk(
  'account/updateLanguage',
  async (payload: { lang: string }, { getState, dispatch }) => {
    const { auth } = getState() as { auth: { jwt: string } }
    if (!auth.jwt) return //to not show error notification on login page

    const response = await fetch(process.env.REACT_APP_BASE_USERS_URL + '/user/modify', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${auth.jwt}`,
      },
      body: JSON.stringify(payload),
    })

    const body = await response.json()
    if (!response.ok) {
      const reason = body.reason || 'unknown_error'
      throw new ApiError(reason)
    }
    dispatch(setJWT(body.token))
    return body
  },
)

export const fetchPreferences = createAsyncThunk(
  'auth/fetchPreferences',
  async (payload: {}, { getState, dispatch }) => {
    const { auth } = getState() as { auth: { jwt: string } }

    const response = await fetch(
      process.env.REACT_APP_MEDIA_BASE_URL + '/preferences/fetch-preferences',
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${auth.jwt}`,
        },
      },
    )

    const body = await response.json()
    if (!response.ok) {
      throw new Error('Could not fetch preferences')
    }

    return body
  },
)

export const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    idleAttemptModifyStatus: (state) => {
      state.attemptModifyStatus = 'idle'
      state.attemptModifyError = undefined
    },
    setDesktopNotification: (state, { payload }: PayloadAction<{ status?: boolean }>) => {
      state.IsDesktopNotificationEnabled = payload.status
    },
  },
  extraReducers: {
    [attemptModify.pending.type]: (state) => {
      state.attemptModifyStatus = 'loading'
    },
    [attemptModify.fulfilled.type]: (state, action) => {
      state.attemptModifyStatus = 'success'
    },
    [attemptModify.rejected.type]: (state, action) => {
      const error = action.error
      state.attemptModifyStatus =
        error.name === 'ApiError' ? error.message : 'unknown_error'
    },

    [fetchPreferences.fulfilled.type]: (
      state,
      action: PayloadAction<{
        _id: any
        notification: { key: string; checked: boolean }[]
      }>,
    ) => {
      state.notification = action.payload.notification
    },
  },
})

export const { idleAttemptModifyStatus, setDesktopNotification } = accountSlice.actions

export default accountSlice.reducer
