import React, { useEffect, useState } from 'react'
import classnames from 'classnames'
import { ConsumerModel, ProducerModel, ScoreModel } from './redux/model'
import './PeerViewInfo.scss'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'

interface Props {
  audioProducer?: ProducerModel
  audioConsumer?: ConsumerModel
  videoProducer?: ProducerModel
  videoConsumer?: ConsumerModel
  videoResolutionWidth?: number
  videoResolutionHeight?: number
  onChangeMaxSendingSpatialLayer?: (layer: number) => void
  onChangeVideoPreferredLayers?: (
    preferredSpatialLayer: number,
    preferredTemporalLayer: number,
  ) => void
  onChangeVideoPriority?: (priority: number) => void
  onRequestKeyFrame?: () => void
  isMe: boolean
}

function PeerViewInfo({
  audioProducer,
  audioConsumer,
  videoProducer,
  videoConsumer,
  videoResolutionWidth,
  videoResolutionHeight,
  onChangeMaxSendingSpatialLayer,
  onChangeVideoPreferredLayers,
  onChangeVideoPriority,
  onRequestKeyFrame,
  isMe,
}: Props) {
  const visible = useSelector((state: RootState) => state.room.showInfo)
  const [maxSpatialLayer, setMaxSpatialLayer] = useState<number>()
  const videoMultiLayer = videoConsumer && videoConsumer.type !== 'simple'

  useEffect(() => {
    if (isMe && videoProducer?.rtpParameters && maxSpatialLayer === undefined) {
      setMaxSpatialLayer(videoProducer?.rtpParameters.encodings.length - 1)
    } else if (isMe && !videoProducer?.rtpParameters && maxSpatialLayer !== undefined) {
      setMaxSpatialLayer(undefined)
    }
  })

  return (
    <div
      className={classnames({
        PeerViewInfo: true,
        'd-none': !visible,
      })}
    >
      {(audioProducer || audioConsumer) && (
        <>
          <h4>audio</h4>

          {audioProducer && (
            <>
              <p>id: {audioProducer.id}</p>
              <p>codec: {audioProducer.codec}</p>
              <ProducerScore score={audioProducer.score} />
            </>
          )}

          {audioConsumer && (
            <>
              <p>id: {audioConsumer.id}</p>
              <p>codec: {audioConsumer.codec}</p>
              <p>priority: {audioConsumer.priority}</p>
              <ConsumerScore score={audioConsumer.score} />
            </>
          )}
        </>
      )}

      {(videoProducer || videoConsumer) && (
        <>
          <h4>video</h4>

          {videoProducer && (
            <>
              <p>id: {videoProducer.id}</p>
              <p>codec: {videoProducer.codec}</p>
              {videoProducer.rtpParameters.encodings.length > 1 &&
                maxSpatialLayer !== undefined && (
                  <p>
                    max spatial layer: {maxSpatialLayer > -1 ? maxSpatialLayer : 'none'}
                    {onChangeMaxSendingSpatialLayer && (
                      <>
                        <span
                          className={classnames({
                            changeParam: true,
                            disabled: maxSpatialLayer < 0,
                          })}
                          onClick={(event) => {
                            event.stopPropagation()
                            if (maxSpatialLayer < 0) {
                              return
                            }
                            const newMaxSpatialLayer = maxSpatialLayer - 1

                            onChangeMaxSendingSpatialLayer(newMaxSpatialLayer)
                            setMaxSpatialLayer(newMaxSpatialLayer)
                          }}
                        >
                          [ down ]
                        </span>

                        <span
                          className={classnames({
                            changeParam: true,
                            disabled:
                              maxSpatialLayer >=
                              videoProducer.rtpParameters.encodings.length - 1,
                          })}
                          onClick={(event) => {
                            event.stopPropagation()
                            if (
                              maxSpatialLayer >=
                              videoProducer.rtpParameters.encodings.length - 1
                            ) {
                              return
                            }

                            const newMaxSpatialLayer = maxSpatialLayer + 1

                            onChangeMaxSendingSpatialLayer(newMaxSpatialLayer)
                            setMaxSpatialLayer(newMaxSpatialLayer)
                          }}
                        >
                          [ up ]
                        </span>
                      </>
                    )}
                  </p>
                )}

              <ProducerScore score={videoProducer.score} />
            </>
          )}

          {videoConsumer && (
            <>
              <p>id: {videoConsumer.id}</p>
              <p>codec: {videoConsumer.codec}</p>
              {!isMe && videoMultiLayer && (
                <>
                  <p>
                    {`current spatial-temporal layers: ${videoConsumer.currentSpatialLayer} ${videoConsumer.currentTemporalLayer}`}
                  </p>
                  <p>
                    {`preferred spatial-temporal layers: ${videoConsumer.preferredSpatialLayer} ${videoConsumer.preferredTemporalLayer}`}
                    {onChangeVideoPreferredLayers && (
                      <>
                        <span
                          className="changeParam"
                          onClick={(event) => {
                            event.stopPropagation()

                            let newPreferredSpatialLayer =
                              videoConsumer.preferredSpatialLayer
                            let newPreferredTemporalLayer

                            if (videoConsumer.preferredTemporalLayer > 0) {
                              newPreferredTemporalLayer =
                                videoConsumer.preferredTemporalLayer - 1
                            } else {
                              if (videoConsumer.preferredSpatialLayer > 0)
                                newPreferredSpatialLayer =
                                  videoConsumer.preferredSpatialLayer - 1
                              else
                                newPreferredSpatialLayer = videoConsumer.spatialLayers - 1

                              newPreferredTemporalLayer = videoConsumer.temporalLayers - 1
                            }

                            onChangeVideoPreferredLayers(
                              newPreferredSpatialLayer,
                              newPreferredTemporalLayer,
                            )
                          }}
                        >
                          [ down ]
                        </span>

                        <span
                          className="changeParam"
                          onClick={(event) => {
                            event.stopPropagation()

                            let newPreferredSpatialLayer =
                              videoConsumer.preferredSpatialLayer
                            let newPreferredTemporalLayer

                            if (
                              videoConsumer.preferredTemporalLayer <
                              videoConsumer.temporalLayers - 1
                            ) {
                              newPreferredTemporalLayer =
                                videoConsumer.preferredTemporalLayer + 1
                            } else {
                              if (
                                videoConsumer.preferredSpatialLayer <
                                videoConsumer.spatialLayers - 1
                              )
                                newPreferredSpatialLayer =
                                  videoConsumer.preferredSpatialLayer + 1
                              else newPreferredSpatialLayer = 0

                              newPreferredTemporalLayer = 0
                            }

                            onChangeVideoPreferredLayers(
                              newPreferredSpatialLayer,
                              newPreferredTemporalLayer,
                            )
                          }}
                        >
                          [ up ]
                        </span>
                      </>
                    )}
                  </p>
                </>
              )}

              {!isMe && videoConsumer.codec && videoConsumer.priority > 0 && (
                <p>
                  {`priority: ${videoConsumer.priority}`}

                  {onChangeVideoPriority && (
                    <>
                      <span
                        className={classnames({
                          changeParam: true,
                          disabled: videoConsumer.priority <= 0,
                        })}
                        onClick={(event) => {
                          event.stopPropagation()
                          if (videoConsumer.priority <= 0) {
                            return
                          }
                          onChangeVideoPriority(videoConsumer.priority - 1)
                        }}
                      >
                        [ down ]
                      </span>

                      <span
                        className={classnames({
                          changeParam: true,
                          disabled: videoConsumer.priority >= 255,
                        })}
                        onClick={(event) => {
                          event.stopPropagation()
                          if (videoConsumer.priority >= 255) {
                            return
                          }
                          onChangeVideoPriority(videoConsumer.priority + 1)
                        }}
                      >
                        [ up ]
                      </span>
                    </>
                  )}
                </p>
              )}

              {!isMe && videoConsumer.codec && onRequestKeyFrame && (
                <p>
                  <span
                    className="changeParam"
                    onClick={(event) => {
                      event.stopPropagation()
                      onRequestKeyFrame()
                    }}
                  >
                    [request keyframe]
                  </span>
                </p>
              )}

              <ConsumerScore score={videoConsumer.score} />
            </>
          )}

          {videoResolutionWidth && videoResolutionHeight && (
            <p>
              resolution: {videoResolutionWidth} x {videoResolutionHeight}
            </p>
          )}
        </>
      )}
    </div>
  )
}

interface ProducerScoreProps {
  score?: ScoreModel | ScoreModel[]
}

function ProducerScore({ score }: ProducerScoreProps) {
  if (!score) {
    return <></>
  }

  const scores = Array.isArray(score) ? score : [score]

  return (
    <>
      <p>streams:</p>

      {scores.map(({ ssrc, rid, score }) => (
        <p key={rid}>
          {rid !== undefined
            ? `rid:${rid}, ssrc:${ssrc}, score:${score}`
            : `ssrc:${ssrc}, score:${score}`}
        </p>
      ))}
    </>
  )
}

function ConsumerScore({ score }: { score: any }) {
  return (
    <p>
      {`score:${score?.score}, producerScore:${score?.producerScore}, producerScores:[${score?.producerScores}]`}
    </p>
  )
}

export default PeerViewInfo
