import React from 'react'
import { BiSignal1, BiSignal2, BiSignal3, BiSignal4, BiSignal5 } from 'react-icons/bi'
import { useSelector } from 'react-redux'
import { RootState } from '../store'
import { ProducerModel } from '../features/room/redux/model'

export default function CallQualityIndicator({ quality }: { quality?: number }) {
  const videoProducer: ProducerModel | undefined = useSelector((state: RootState) =>
    Object.values(state.producers.producers).find(
      (producer) => producer.type === 'back' || producer.type === 'front',
    ),
  )

  /**
   *
   * @returns score number 0-10
   */
  function getScore(): number | undefined {
    if (quality) return quality
    else
      return videoProducer?.score instanceof Array
        ? videoProducer?.score[0]?.score
        : videoProducer?.score?.score
  }

  const score = getScore()

  return (
    <>
      {score && (
        <>
          {score <= 2 && <BiSignal1 size={25} color="#224160" />}
          {score > 2 && score <= 4 && <BiSignal2 size={25} color="#224160" />}
          {score > 4 && score <= 6 && <BiSignal3 size={25} color="#224160" />}
          {score > 6 && score <= 8 && <BiSignal4 size={25} color="#224160" />}
          {score > 8 && score <= 10 && <BiSignal5 size={25} color="#224160" />}
        </>
      )}
    </>
  )
}
