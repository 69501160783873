import React from 'react'
import { Table } from 'antd'
import { TableRowSelection } from 'antd/es/table/interface'
import { useTranslation } from 'react-i18next'
import ParticipantsPagination from '../ParticipantsPagination'
import { GroupParticipant } from '../redux/groupsSlice'
import './AddUserGroup.scss'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store'

interface Props {
  participantsToBeDisplayed: GroupParticipant[]
  onChange: (
    selectedParticipantKeys: React.Key[],
    selectedParticipants: GroupParticipant[],
  ) => void
  defaultSelectedParticipants: string[]
  immutableParticipants?: string[]
}

function AddUserGroup({
  participantsToBeDisplayed,
  onChange,
  defaultSelectedParticipants,
  immutableParticipants,
}: Props) {
  const { t } = useTranslation('groups')

  const readonly = useSelector((state: RootState) => state.groups.groupToModify?.readonly)

  const columns = [
    {
      title: t('Full name', { ns: 'common' }),
      className: 'full-name-column',
      render: (p: GroupParticipant) => {
        return <>{`${p.firstName} ${p.lastName}`}</>
      },
    },
    {
      title: t('Email', { ns: 'common' }),
      className: 'name-column',
      dataIndex: 'email',
      key: 'email',
    },
  ]

  const rowSelection: TableRowSelection<GroupParticipant> = {
    type: 'checkbox',
    preserveSelectedRowKeys: true,
    onChange: onChange, //handleSelectionChanged,
    getCheckboxProps: (participant: GroupParticipant) => ({
      name: participant.email,
      disabled: immutableParticipants?.includes(participant.email),
    }),
    defaultSelectedRowKeys: defaultSelectedParticipants,
    // selections: true, //TODO: discuss with Cedric (we could make custom selection https://ant.design/components/table#selection:~:text=5-,Custom%20selection,-Use%20rowSelection.selections)
  }

  return (
    <div className="AddUserGroup">
      <Table
        className="table"
        dataSource={participantsToBeDisplayed}
        rowSelection={!readonly ? rowSelection : undefined}
        // loading={loading}
        rowKey="email"
        columns={columns}
        pagination={
          !readonly ||
          (participantsToBeDisplayed && participantsToBeDisplayed?.length <= 8)
            ? false
            : { position: ['bottomCenter'] }
        } //group participants are already fetched
        // onChange={(changedValues, values) => {console.log('CHANGEchangedValues, values:', changedValues, values)}}
      />
      {!readonly && <ParticipantsPagination />}
    </div>
  )
}

export default AddUserGroup
