import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import OrganisationMenu from './OrganisationMenu'
import OrganisationTable from './OrganisationTable'
import OrganisationHeader from './OrganisationHeader'
import Layout from '../../components/Layout/Layout'

function Organisation() {
  const { t } = useTranslation('organisation')

  return (
    <Layout
      title={t('Organisation', { ns: 'common' })}
      headerTitle={<h1 className="title">{t('Organisation', { ns: 'common' })}</h1>}
      menu={<OrganisationMenu />}
      content={
        <>
          <OrganisationHeader />
          <OrganisationTable />
        </>
      }
      footer={false}
    />
  )
}

export default Organisation
