import React from 'react'
import { useTranslation } from 'react-i18next'
import Layout from '../../../components/Layout/Layout'
import { Collapse, Divider, Tooltip } from 'antd'
import { FiArrowLeftCircle } from 'react-icons/fi'
import { Link } from 'react-router-dom'
import { BiInfoCircle } from 'react-icons/bi'
import '../faq.scss'
import { CollapseProps } from 'antd/lib'

function Devices() {
  const { i18n, t } = useTranslation('faqDevices')

  /**
   *
   */
  function renderChrome() {
    return (
      <>
        <p>{t('Your camera is currently blocked by your browser, to reactivate it:')}</p>
        <ul>
          <li>
            <div className="d-flex d-flex-middle g-0_2rem">
              {t('Please click on the padlock icon on this page then allow camera.')}
              <Tooltip
                title={
                  <img
                    className="faq-tooltip-image-medium"
                    src={`/images/faq/chrome_01_${i18n.language}.jpg`}
                  />
                }
                className="cursor-pointer"
              >
                <BiInfoCircle size="1em" className="info-color hideOnMobile" />
              </Tooltip>
            </div>
          </li>
          <li>
            <div className="d-flex d-flex-middle g-0_2rem">
              {t('Then refresh the page.')}
              <Tooltip
                title={
                  <img
                    className="faq-tooltip-image-large"
                    src={`/images/faq/chrome_02_${i18n.language}.jpg`}
                  />
                }
                className="cursor-pointer"
              >
                <BiInfoCircle size="1em" className="info-color hideOnMobile" />
              </Tooltip>
            </div>
          </li>
        </ul>

        <p>
          {t('Your microphone is currently blocked by your browser, to reactivate it:')}
        </p>
        <ul className="mb-0">
          <li>
            <div className="d-flex d-flex-middle g-0_2rem">
              {t('Please click on the padlock icon on this page then allow microphone.')}
              <Tooltip
                title={
                  <img
                    className="faq-tooltip-image-medium"
                    src={`/images/faq/chrome_01_${i18n.language}.jpg`}
                  />
                }
                className="cursor-pointer"
              >
                <BiInfoCircle size="1em" className="info-color hideOnMobile" />
              </Tooltip>
            </div>
          </li>
          <li>
            <div className="d-flex d-flex-middle g-0_2rem">
              {t('Then refresh the page.')}
              <Tooltip
                title={
                  <img
                    className="faq-tooltip-image-large"
                    src={`/images/faq/chrome_02_${i18n.language}.jpg`}
                  />
                }
                className="cursor-pointer"
              >
                <BiInfoCircle size="1em" className="info-color hideOnMobile" />
              </Tooltip>
            </div>
          </li>
        </ul>
      </>
    )
  }

  /**
   *
   * @returns
   */
  function renderFirefox() {
    return (
      <>
        <p>{t('Your camera is currently blocked by your browser, to reactivate it:')}</p>
        <ul>
          <li>
            <div className="d-flex d-flex-middle g-0_2rem">
              {t(
                "Click on the Camera/Microphone icons in your browser's address bar and remove the temporary block in the menu.",
              )}
              <Tooltip
                title={
                  <img
                    className="faq-tooltip-image-large"
                    src={`/images/faq/firefox_01_${i18n.language}.jpg`}
                  />
                }
                className="cursor-pointer"
              >
                <BiInfoCircle size="1em" className="info-color hideOnMobile" />
              </Tooltip>
            </div>
          </li>
          <li>
            <div className="d-flex d-flex-middle g-0_2rem">
              {t('Then click on the camera icon on the page again.')}
              <Tooltip
                title={
                  <img
                    className="faq-tooltip-image"
                    src="/images/faq/devices_buttons_camera.png"
                  />
                }
                className="cursor-pointer"
              >
                <BiInfoCircle size="1em" className="info-color hideOnMobile" />
              </Tooltip>
            </div>
          </li>
          <li>
            <div className="d-flex d-flex-middle g-0_2rem">
              {t('Finally, authorise access to the camera.')}
              <Tooltip
                title={
                  <img
                    className="faq-tooltip-image-large"
                    src={`/images/faq/firefox_02_camera_${i18n.language}.jpg`}
                  />
                }
                className="cursor-pointer"
              >
                <BiInfoCircle size="1em" className="info-color hideOnMobile" />
              </Tooltip>
            </div>
          </li>
        </ul>
        <p>
          {t('Your microphone is currently blocked by your browser, to reactivate it:')}
        </p>
        <ul className="mb-0">
          <li>
            <div className="d-flex d-flex-middle g-0_2rem">
              {t(
                "Click on the Camera/Microphone icons in your browser's address bar and remove the temporary block in the menu.",
              )}
              <Tooltip
                title={
                  <img
                    className="faq-tooltip-image-large"
                    src={`/images/faq/firefox_01_${i18n.language}.jpg`}
                  />
                }
                className="cursor-pointer"
              >
                <BiInfoCircle size="1em" className="info-color hideOnMobile" />
              </Tooltip>
            </div>
          </li>
          <li>
            <div className="d-flex d-flex-middle g-0_2rem">
              {t('Then click on the microphone icon on the page again.')}
              <Tooltip
                title={
                  <img
                    className="faq-tooltip-image"
                    src="/images/faq/devices_buttons_micro.png"
                  />
                }
                className="cursor-pointer"
              >
                <BiInfoCircle size="1em" className="info-color hideOnMobile" />
              </Tooltip>
            </div>
          </li>
          <li>
            <div className="d-flex d-flex-middle g-0_2rem">
              {t('Finally, authorise access to the microphone.')}
              <Tooltip
                title={
                  <img
                    className="faq-tooltip-image-large"
                    src={`/images/faq/firefox_02_microphone_${i18n.language}.jpg`}
                  />
                }
                className="cursor-pointer"
              >
                <BiInfoCircle size="1em" className="info-color hideOnMobile" />
              </Tooltip>
            </div>
          </li>
        </ul>
      </>
    )
  }

  /**
   *
   */
  function renderSafari() {
    return (
      <>
        <p>{t('Your camera is currently blocked by your browser, to reactivate it:')}</p>
        <ul>
          <li>
            <div className="d-flex d-flex-middle g-0_2rem">
              {t(
                'Please click on the camera icon on this page and authorise access to the camera.',
              )}
              <Tooltip
                title={
                  <img
                    className="faq-tooltip-image"
                    src="/images/faq/devices_buttons_camera.png"
                  />
                }
                className="cursor-pointer"
              >
                <BiInfoCircle size="1em" className="info-color hideOnMobile" />
              </Tooltip>
            </div>
          </li>
          <li>
            <div className="d-flex d-flex-middle g-0_2rem">
              {t(
                "If this doesn't work, click on the Safari menu in your browser, then on 'Settings for ...'.",
              )}
              <Tooltip
                title={
                  <img
                    className="faq-tooltip-image"
                    src={`/images/faq/safari_menu_1_${i18n.language}.jpg`}
                  />
                }
                className="cursor-pointer"
              >
                <BiInfoCircle size="1em" className="info-color hideOnMobile" />
              </Tooltip>
            </div>
          </li>
          <li>
            <div className="d-flex d-flex-middle g-0_2rem">
              {t('Finally, authorise the camera in the menu.')}
              <Tooltip
                title={
                  <img
                    className="faq-tooltip-image-large"
                    src={`/images/faq/safari_menu_2_${i18n.language}.jpg`}
                  />
                }
                className="cursor-pointer"
              >
                <BiInfoCircle size="1em" className="info-color hideOnMobile" />
              </Tooltip>
            </div>
          </li>
        </ul>
        <p>
          {t('Your microphone is currently blocked by your browser, to reactivate it:')}
        </p>
        <ul className="mb-0">
          <li>
            <div className="d-flex d-flex-middle g-0_2rem">
              {t(
                'Please click on the microphone icon on this page and authorise access to the microphone.',
              )}
              <Tooltip
                title={
                  <img
                    className="faq-tooltip-image"
                    src="/images/faq/devices_buttons_micro.png"
                  />
                }
                className="cursor-pointer"
              >
                <BiInfoCircle size="1em" className="info-color hideOnMobile" />
              </Tooltip>
            </div>
          </li>
          <li>
            <div className="d-flex d-flex-middle g-0_2rem">
              {t(
                "If this doesn't work, click on the Safari menu in your browser, then on 'Settings for ...'.",
              )}
              <Tooltip
                title={
                  <img
                    className="faq-tooltip-image"
                    src={`/images/faq/safari_menu_1_${i18n.language}.jpg`}
                  />
                }
                className="cursor-pointer"
              >
                <BiInfoCircle size="1em" className="info-color hideOnMobile" />
              </Tooltip>
            </div>
          </li>
          <li>
            <div className="d-flex d-flex-middle g-0_2rem">
              {t('Finally, authorise the microphone in the menu.')}
              <Tooltip
                title={
                  <img
                    className="faq-tooltip-image-large"
                    src={`/images/faq/safari_menu_2_${i18n.language}.jpg`}
                  />
                }
                className="cursor-pointer"
              >
                <BiInfoCircle size="1em" className="info-color hideOnMobile" />
              </Tooltip>
            </div>
          </li>
        </ul>
      </>
    )
  }

  /**
   *
   */
  function renderOther() {
    return (
      <ul className="mb-0">
        <li>
          <div className="d-flex d-flex-middle g-0_2rem">
            {t(
              'Please refer to your browser documentation to activate your peripherals.',
            )}
          </div>
        </li>
      </ul>
    )
  }

  const items: CollapseProps['items'] = [
    {
      key: 'firefox',
      label: t('Mozilla Firefox'),
      children: renderFirefox(),
      extra: <img className="nav-icon" src="/images/firefox.png" />,
    },
    {
      key: 'chrome',
      label: t('Google Chrome'),
      children: renderChrome(),
      extra: <img className="nav-icon" src="/images/chrome.png" />,
    },
    {
      key: 'safari',
      label: t('Apple Safari'),
      children: renderSafari(),
      extra: <img className="nav-icon" src="/images/safari.png" />,
    },
    {
      key: 'other',
      label: t('Other browsers'),
      children: renderOther(),
    },
  ]

  function renderDevicesFaq() {
    return (
      <div className="Faq">
        <div className="d-flex d-flex-middle mb-1rem">
          <Link to="/faq" className="d-flex d-flex-middle">
            <FiArrowLeftCircle size="2rem" strokeWidth={1} />
            <span className="ml-05rem">{t('Back to FAQ', { ns: 'faq' })}</span>
          </Link>
        </div>
        <h1>{t('Prepare your devices for meetings')}</h1>
        <Divider />

        <Collapse items={items} defaultActiveKey={'firefox'} accordion />
      </div>
    )
  }

  return <Layout content={renderDevicesFaq()} footer={false} />
}

export default Devices
