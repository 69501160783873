import React, { MutableRefObject, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import { PeerModel } from './redux/model'
import PeerView from './PeerView'
import './Peer.scss'
import classNames from 'classnames'
import PeerViewInfo from './PeerViewInfo'
import RoomClient from './RoomClient'

interface Props {
  peer: PeerModel
  roomClient: MutableRefObject<RoomClient>
  promotedPeer?: string
  onPromote: (peerId?: string) => void
}

function Peer({ peer, roomClient, promotedPeer, onPromote }: Props) {
  const consumers = useSelector((state: RootState) =>
    peer.consumers.map((peerConsumer) => state.consumers.consumers[peerConsumer]),
  )

  const audioConsumer = consumers.find((consumer) => consumer.appData?.type === 'audio')
  const videoConsumer = consumers.find(
    (consumer) => consumer.appData?.type === 'front' || consumer.appData?.type === 'back',
  )
  const shareConsumer = consumers.find((consumer) => consumer.appData?.type === 'share')

  const videoVisible =
    videoConsumer != null && !videoConsumer.locallyPaused && !videoConsumer.remotelyPaused

  const [videoResolutionWidth, setVideoResolutionWidth] = useState(0)
  const [videoResolutionHeight, setVideoResolutionHeight] = useState(0)
  const promotedFullScreen = useSelector(
    (state: RootState) => state.room.promotedFullScreen,
  )

  return (
    <div
      className={classNames({
        Peer: true,
        'Peer--share': videoConsumer?.type === 'share',
        'p-0': promotedFullScreen,
      })}
    >
      <PeerView
        isMe={false}
        isMyWebcam={false}
        peer={peer}
        displayName={peer.displayName || peer.id}
        consumerCurrentSpatialLayer={
          videoConsumer ? videoConsumer.currentSpatialLayer : null
        }
        audioTrack={audioConsumer ? audioConsumer.track : null}
        audioTrackPaused={audioConsumer ? audioConsumer.remotelyPaused : undefined}
        videoTrack={videoConsumer ? videoConsumer.track : null}
        videoScore={videoConsumer ? videoConsumer.score : null}
        videoVisible={videoVisible}
        shareTrack={shareConsumer ? shareConsumer.track : null}
        promotedPeer={promotedPeer}
        onPromote={onPromote}
        onResolutionChange={(width, height) => {
          setVideoResolutionWidth(width)
          setVideoResolutionHeight(height)
        }}
      />

      <PeerViewInfo
        audioConsumer={audioConsumer}
        videoConsumer={videoConsumer}
        videoResolutionWidth={videoResolutionWidth}
        videoResolutionHeight={videoResolutionHeight}
        onChangeVideoPreferredLayers={(spatialLayer, temporalLayer) => {
          if (videoConsumer) {
            roomClient.current.setConsumerPreferredLayers(
              videoConsumer.id,
              spatialLayer,
              temporalLayer,
            )
          }
        }}
        onChangeVideoPriority={(priority) => {
          if (videoConsumer) {
            roomClient.current.setConsumerPriority(videoConsumer.id, priority)
          }
        }}
        onRequestKeyFrame={() => {
          if (videoConsumer) {
            roomClient.current.requestConsumerKeyFrame(videoConsumer.id)
          }
        }}
        isMe={false}
      />
    </div>
  )
}

export default Peer
