import React from 'react'
import { useTranslation } from 'react-i18next'
import DocumentsList from '../../../components/DocumentsList/DocumentsList'
import './room-documents.scss'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store'
import DocumentsUpload from '../../../components/DocumentsUpload/DocumentsUpload'
import { RoomInterface, RoomParticipantRoleEnum } from '../RoomInterface'
import { hasAccessFiles } from '../../../utils/Utils'

function RoomDocuments() {
  const { t } = useTranslation('meetings')
  const room = useSelector((state: RootState) => state.room)
  const auth = useSelector((state: RootState) => state.auth)

  return room?.hasAccessFiles &&
    (room?.creator?.email === auth.email || hasAccessFiles(room as RoomInterface)) ? (
    <div className="documents">
      <h4 className="documents-label">
        {t('Upload files for this meeting', { ns: 'common' })}
      </h4>
      <hr />
      <DocumentsUpload room={room} immediateSave={true} disabled={false} />
      <h4 className="documents-label">{t('Available files', { ns: 'common' })}</h4>
      <hr />
      <DocumentsList
        disabled={false}
        room={room}
        immediateSave={true}
        inAntichamber={true}
      />
    </div>
  ) : room?.participants?.find((participant) => participant.email === auth.email)
      ?.role === RoomParticipantRoleEnum.Attendee ? (
    <div className="documents">
      <h4 className="documents-label">{t('Available files', { ns: 'common' })}</h4>
      <hr />
      <div className="text-center mb-1rem pb-5em">
        {t(`Your current role doesn't allow access to files`)}
      </div>
    </div>
  ) : (
    <div className="documents">
      <h4 className="documents-label">{t('Available files', { ns: 'common' })}</h4>
      <hr />
      <div className="text-center mb-1rem pb-5em">
        {t(`You will be given access to files at the start of the meeting`)}
      </div>
    </div>
  )
}

export default RoomDocuments
