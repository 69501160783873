import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { PendingRoomInterface } from '../../room/RoomInterface'
import { Button, Col, Divider, List, Modal, Radio, Row, Spin, Table, Tooltip } from 'antd'
import {
  VoteResponse,
  idleGetVotesStatus,
  idleSendVotesStatus,
  idleSendSelectedDateStatus,
  sendVote,
  setMyVote,
  setSelectedDate,
  sendSelectedDate,
} from './MeetingVoteSlice'
import { getVotes } from './MeetingVoteSlice'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../../store'
import dayjs from 'dayjs'
import './MeetingVote.scss'
import {
  AiFillCloseCircle,
  AiFillQuestionCircle,
  AiFillCheckCircle,
} from 'react-icons/ai'
import { RoomToModify, fetchRooms } from '../MeetingsSlice'
import classNames from 'classnames'
import sanitizeHtml from 'sanitize-html'
import { unescape } from 'html-escaper'
import { allowedTagsObject } from '../../../utils/Utils'
import { useToastContext } from '../../../components/Toast/ToastContext'

interface Props {
  room: PendingRoomInterface
  close: () => void
  setRoomModify?: (room: RoomToModify) => void
  invited?: boolean
}

function MeetingVote({ room, close, setRoomModify, invited }: Props) {
  const { t } = useTranslation('meetings')
  const dispatch = useDispatch<AppDispatch>()
  const { ToastOpen } = useToastContext()
  const votes = useSelector((state: RootState) => state.meetingVote.votes)
  const selectedDate = useSelector((state: RootState) => state.meetingVote.selectedDate)
  const auth = useSelector((state: RootState) =>
    state.auth.email ? state.auth : state.invite,
  )
  const sendVotesStatus = useSelector(
    (state: RootState) => state.meetingVote.sendVotesStatus,
  )
  const getVotesStatus = useSelector(
    (state: RootState) => state.meetingVote.getVotesStatus,
  )
  const sendSelectedDateStatus = useSelector(
    (state: RootState) => state.meetingVote.sendSelectedDateStatus,
  )
  const [showErrorGetVotes, setShowErrorGetVotes] = useState(false)
  const buttonDisabled =
    sendVotesStatus === 'loading' ||
    sendSelectedDateStatus === 'loading' ||
    getVotesStatus === 'loading'

  useEffect(() => {
    if (!room.id) {
      ToastOpen({
        message: t('Room id not found.'),
        type: 'error',
      })
      return
    }
    dispatch(getVotes(room.id))

    return () => {
      dispatch(setSelectedDate(undefined))
    }
  }, [])

  useEffect(() => {
    switch (getVotesStatus) {
      case 'success':
        break
      case 'error':
        setShowErrorGetVotes(true)
        break
    }
    return () => {
      dispatch(idleGetVotesStatus())
    }
  }, [getVotesStatus])

  useEffect(() => {
    switch (sendVotesStatus) {
      case 'success':
        ToastOpen({
          message: t('Your preferences are taken into account.'),
          type: 'success',
        })
        if (!invited) {
          dispatch(fetchRooms({ status: 'pending' }))
        }
        close()
        break
      case 'error':
        ToastOpen({
          message: t('Error sending choice.'),
          type: 'error',
        })
        break
    }
    return () => {
      dispatch(idleSendVotesStatus())
    }
  }, [sendVotesStatus])

  useEffect(() => {
    switch (sendSelectedDateStatus) {
      case 'success':
        ToastOpen({
          message: t('Meeting successfully created.'),
          type: 'success',
        })
        dispatch(fetchRooms({ status: 'pending' }))
        dispatch(fetchRooms({ status: 'upcoming' }))
        close()
        break
      case 'error':
        ToastOpen({
          message: t('Error creating meeting.'),
          type: 'error',
        })
        break
    }
    return () => {
      dispatch(idleSendSelectedDateStatus())
    }
  }, [sendSelectedDateStatus])

  const columns = [
    ...[
      {
        title: t('Date'),
        dataIndex: 'date',
        key: 'date',
        fixed: true,
        width: '11rem',
      },
      {
        title: t('Total'),
        dataIndex: 'total',
        key: 'total',
        fixed: true,
        width: '5rem',
      },
    ],
    ...(room.creator?.email === auth.email
      ? []
      : [
          {
            title: t('My choice'),
            key: 'me',
            fixed: true,
            width: '8rem',
            render: (record: { [key: string]: VoteResponse }) => {
              return (
                <div className="choose-vote myvote d-flex d-flex-center">
                  <Radio.Group className="d-flex">
                    <Radio.Button
                      value="yes"
                      className={`d-flex d-flex-middle d-flex-center w-5rem hover-text-bold ${
                        record[auth.email || ''] === 'yes'
                          ? 'success-color hover-success-color'
                          : 'black-color'
                      }`}
                      onClick={() =>
                        dispatch(
                          setMyVote({
                            date: record.dateISO,
                            email: auth.email || '',
                            vote: 'yes',
                          }),
                        )
                      }
                    >
                      {record[auth.email || ''] === 'yes' ? (
                        <AiFillCheckCircle size="1.2em" />
                      ) : (
                        t('Yes', { ns: 'common' })
                      )}
                    </Radio.Button>
                    <Radio.Button
                      value="maybe"
                      className={`d-flex d-flex-middle d-flex-center w-5rem hover-text-bold ${
                        record[auth.email || ''] === 'maybe'
                          ? 'warning-color hover-warning-color'
                          : 'black-color'
                      }`}
                      onClick={() =>
                        dispatch(
                          setMyVote({
                            date: record.dateISO,
                            email: auth.email || '',
                            vote: 'maybe',
                          }),
                        )
                      }
                    >
                      {record[auth.email || ''] === 'maybe' ? (
                        <AiFillQuestionCircle size="1.2em" />
                      ) : (
                        t('Maybe', { ns: 'common' })
                      )}
                    </Radio.Button>
                    <Radio.Button
                      value="no"
                      className={`d-flex d-flex-middle d-flex-center w-5rem hover-text-bold ${
                        record[auth.email || ''] === 'no'
                          ? 'error-color hover-error-color'
                          : 'black-color'
                      }`}
                      onClick={() =>
                        dispatch(
                          setMyVote({
                            date: record.dateISO,
                            email: auth.email || '',
                            vote: 'no',
                          }),
                        )
                      }
                    >
                      {record[auth.email || ''] === 'no' ? (
                        <AiFillCloseCircle size="1.2em" />
                      ) : (
                        t('No', { ns: 'common' })
                      )}
                    </Radio.Button>
                  </Radio.Group>
                </div>
              )
            },
          },
        ]),
    ...(room.creator?.email === auth.email
      ? [...(room.participants || []), ...(room.invited || [])]
          .filter((participant) => participant.email !== auth.email)
          .map((participant) => {
            return {
              title: (
                <Tooltip title={participant.email}>
                  <span>{participant.email}</span>
                </Tooltip>
              ),
              key: participant.email,
              render: (record: { [key: string]: VoteResponse }) => {
                return (
                  <div className="hide-on-mobile choose-vote d-flex d-flex-center">
                    {record[participant.email] === 'yes' && (
                      <span className="success-color">
                        <AiFillCheckCircle size="1.2em" />
                      </span>
                    )}
                    {record[participant.email] === 'maybe' && (
                      <span className="warning-color">
                        <AiFillQuestionCircle size="1.2em" />
                      </span>
                    )}
                    {record[participant.email] === 'no' && (
                      <span className="error-color">
                        <AiFillCloseCircle size="1.2em" />
                      </span>
                    )}
                  </div>
                )
              },
            }
          })
      : []),
  ]

  const data = Object.entries(votes).map(([date, vote]) => {
    const yesCount = Object.values(vote).filter((v) => v === 'yes').length
    const totalCount = Object.values(vote).length
    const isYes = yesCount > 0

    return {
      date: dayjs(date).format('LLL'),
      dateISO: date,
      key: date,
      total: (
        <div className="d-flex d-flex-center d-flex-middle g-0_1rem">
          <span className={isYes ? 'row-voted' : 'row-not-voted'}>
            {yesCount}/{totalCount}
          </span>
        </div>
      ),
      ...vote,
    }
  })

  const handleSendVote = () => {
    const yes: string[] = []
    const no: string[] = []
    const maybe: string[] = []
    Object.entries(votes).forEach(([date, vote]) => {
      switch (vote[auth.email || '']) {
        case 'yes':
          yes.push(date)
          break
        case 'no':
          no.push(date)
          break
        case 'maybe':
          maybe.push(date)
          break
        default:
          break
      }
    })
    if (yes.length === 0 && no.length === 0 && maybe.length === 0) {
      ToastOpen({
        message: t('Please specify your availabilities.'),
        type: 'info',
      })
      return
    }
    dispatch(sendVote({ meetingId: room.id || '', myVotes: { yes, no, maybe } }))
  }

  const createMeeting = () => {
    if (!setRoomModify || !selectedDate) return
    setRoomModify({
      ...room,
      startDate: selectedDate.date,
      createFromPending: true,
    })
    close()
  }

  const MeetingVoteComponent = () => {
    return (
      <>
        <div className="layout-header">
          <h1 className="text-one-line mb-0 mt-05rem">{`${t(
            'Proposed dates for the meeting',
          )} ${unescape(room.name || '')}`}</h1>

          <div className="layout-header-bottom">
            <h4 className="layout-list-header">{t("Organizer's notes")}</h4>
            {!invited && (
              <Button type="primary" onClick={close}>
                <span>{t('Close', { ns: 'common' })}</span>
              </Button>
            )}
          </div>
          <Divider />
          <div className="meeting-vote-notes">
            {room?.creatorNote ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: sanitizeHtml(room?.creatorNote, allowedTagsObject),
                }}
              />
            ) : (
              <p>{t('No note from the organizer')}</p>
            )}
          </div>
        </div>
        <div
          className={classNames({
            'layout-header': true,
            'mt-2rem': room.creatorNote,
          })}
        >
          <div className="layout-header-bottom">
            <h4 className="layout-list-header">
              {t('Number of participants:')}{' '}
              {(room.participants?.length || 0) + (room.invited?.length || 0)}
            </h4>
          </div>
          <Divider />
        </div>
        <div className="meeting-vote-table-container">
          {(data.length === 0 && <Spin size="large" />) ||
            (data.length > 0 && (
              <Table
                rootClassName={classNames({
                  'meeting-vote-table': true,
                  'cursor-pointer': room.creator?.email === auth.email,
                })}
                dataSource={data}
                columns={columns}
                pagination={false}
                scroll={{
                  x: 650,
                }}
                onRow={(record) => {
                  return {
                    onClick: () => {
                      if (room.creator?.email !== auth.email) return
                      dispatch(setSelectedDate(record.dateISO))
                    },
                  }
                }}
              />
            ))}
          {room.creator?.email !== auth.email ? ( // Not creator
            <div className="d-flex d-flex-right mt-1rem">
              <Button type="primary" onClick={handleSendVote} disabled={buttonDisabled}>
                <span>{t('Validate', { ns: 'common' })}</span>
              </Button>
            </div>
          ) : selectedDate !== undefined ? (
            <>
              <Divider />
              <h4 className="layout-list-header text-center mt-05rem mb-05rem gap-header">
                {t('Selected date')} : &nbsp;
                <strong>{dayjs(selectedDate.date).format('LLL')}</strong>
              </h4>
              <Row gutter={[24, 8]}>
                <Col span={24} sm={8}>
                  <List
                    rootClassName="meeting-vote-list"
                    header={
                      <span className="d-flex d-flex-middle">
                        <AiFillCheckCircle
                          className="success-color mr-05rem"
                          size="1.2em"
                        />
                        {t('Yes', { ns: 'common' })}
                      </span>
                    }
                    bordered
                    key="yes"
                    dataSource={
                      selectedDate.yes.length ? selectedDate.yes : [t('No choices')]
                    }
                    renderItem={(item) => <List.Item>{item}</List.Item>}
                  />
                </Col>
                <Col span={24} sm={8}>
                  <List
                    rootClassName="meeting-vote-list"
                    header={
                      <span className="d-flex d-flex-middle">
                        <AiFillQuestionCircle
                          className="warning-color mr-05rem"
                          size="1.2em"
                        />
                        {t('Maybe', { ns: 'common' })}
                      </span>
                    }
                    bordered
                    key="maybe"
                    dataSource={
                      selectedDate.maybe.length ? selectedDate.maybe : [t('No choices')]
                    }
                    renderItem={(item) => <List.Item>{item}</List.Item>}
                  />
                </Col>
                <Col span={24} sm={8}>
                  <List
                    rootClassName="meeting-vote-list"
                    header={
                      <span className="d-flex d-flex-middle">
                        <AiFillCloseCircle
                          className="error-color mr-05rem"
                          size="1.2em"
                        />
                        {t('No', { ns: 'common' })}
                      </span>
                    }
                    bordered
                    key="no"
                    dataSource={
                      selectedDate.no.length ? selectedDate.no : [t('No choices')]
                    }
                    renderItem={(item) => <List.Item>{item}</List.Item>}
                  />
                </Col>
              </Row>
              <div className="d-flex mt-1rem d-flex-reverse d-flex-center">
                <Button
                  type="primary"
                  onClick={() => createMeeting()}
                  disabled={buttonDisabled}
                >
                  <span>{t('Choose this date')}</span>
                </Button>
              </div>
            </>
          ) : (
            <div className="vote-schedule">
              <b>{t('Please select a date to display more details')}</b>
            </div>
          )}
        </div>
      </>
    )
  }

  return (
    <>
      {showErrorGetVotes ? (
        <Row justify="center" className="mt-1rem">
          <Col span={24}>
            <h1 className="text-center">
              {t('The meeting has already been scheduled or has been cancelled.')}
            </h1>
          </Col>
        </Row>
      ) : (
        <MeetingVoteComponent />
      )}
    </>
  )
}

export default MeetingVote
