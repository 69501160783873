import React from 'react'
import { Dropdown, Menu } from 'antd'
import { useTranslation } from 'react-i18next'
import './language-switch.scss'
import { BsChevronDown } from 'react-icons/bs'
import { useDispatch } from 'react-redux'
import { updateLanguage } from '../../features/account/redux/accountSlice'
import { AppDispatch } from '../../store'
import { GlobalOutlined } from '@ant-design/icons'

interface Props {
  light?: boolean
  disablePreferencesFetch?: boolean
}

export function LanguageSwitch({ light, disablePreferencesFetch }: Props) {
  const { i18n } = useTranslation([])
  const dispatch = useDispatch<AppDispatch>()

  function onLanguageChanged(info: { key: string }) {
    i18n.changeLanguage(info.key)
    if (disablePreferencesFetch) return
    dispatch(updateLanguage({ lang: info.key }))
  }

  const languagesList = [
    {
      label: 'Deutsch',
      key: 'de',
      country_code: 'DE',
    },
    {
      label: 'English',
      key: 'en',
      country_code: 'GB',
    },
    {
      label: 'Français',
      key: 'fr',
      country_code: 'FR',
    },
    {
      label: 'Italiano',
      key: 'it',
      country_code: 'IT',
    },
    {
      label: 'Український',
      key: 'uk',
      country_code: 'UA',
    },
  ]

  const menu = (
    <Menu onClick={onLanguageChanged} defaultSelectedKeys={[i18n.language]} selectable>
      {languagesList.map((lang) => (
        <Menu.Item key={lang.key}>
          <span>{lang.label}</span>
        </Menu.Item>
      ))}
    </Menu>
  )

  return (
    <Dropdown overlay={menu} trigger={['click']} className="language-dropdown">
      <div className="d-flex mr-05rem language-icon">
        <GlobalOutlined />
        <span
          className={`language-text d-flex d-flex-middle ${
            light ? 'language-text--light' : ''
          }`}
        >
          {
            languagesList.find((element) => {
              return element.key == i18n.language
            })?.label
          }
          <BsChevronDown />
        </span>
      </div>
    </Dropdown>
  )
}
