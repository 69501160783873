import React, { DragEvent, MouseEvent, useEffect, useRef, useState } from 'react'
import { Form, Spin } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../../store'
import {
  FileInterface,
  addEncryptDriveFiles,
  idleMoveDriveContentStatus,
  selectableInterface,
  setDraggingOver,
  setSelectedFilesFolders,
  setContextMenuVisible,
  setTargetedFolder,
  setUploadListFiles,
  idleaddDriveFilesAndFoldersStatus,
  idleAddDriveFilesStatus,
  FolderInterface,
} from '../../../features/Drives/redux/drivesSlice'
import './DriveListContent.scss'
import '../../../index.scss'
import { Trans, useTranslation } from 'react-i18next'
import classNames from 'classnames'
import FileRow from './FileRow'
import { useAttemptsListener } from 'auxasphere-react-kit'
import { compareByDate, compareByName, limitText, renderIcon } from '../../../utils/Utils'
import RenameFileModal from '../RenameFileModal'
import _ from 'lodash'
import DeleteFileModal from '../DeleteFileModal'
import RenameFolderModal from '../RenameFolderModal'
import UploadArea from '../UploadArea'
import UploadBanner from '../UploadBanner'
import DrivesPagination from '../DrivesPagination'
import { getFilesAndFolders } from '../DrivesService'
import { Scrollbars } from 'react-custom-scrollbars-2'
import UploadFilesList from '../UploadFileList'
import DriveContextMenu from './DriveContextMenu'
import { useDriveLoader } from '../../../utils/hooks/UseDriveLoader'
import FolderRow from './FolderRow'
import { ItemType } from 'antd/es/menu/interface'
import { useToastContext } from '../../Toast/ToastContext'
import { TiArrowSortedDown, TiArrowSortedUp } from 'react-icons/ti'
import dayjs from 'dayjs'

export const compareFilesFolders = (a: selectableInterface, b: selectableInterface) => {
  return a.id === b.id && 'mimeType' in a === 'mimeType' in b
}

enum SORTABLE_COLUMN {
  NAME,
  UPDATED_AT,
}

function DriveListContent() {
  const { t } = useTranslation('drives')
  const dispatch = useDispatch<AppDispatch>()
  const { ToastOpen } = useToastContext()
  const auth = useSelector((state: RootState) => state.auth)
  const { fileRights, selectedDriveFolder, selectedFilesFolders, driveEncryptKey } =
    useDriveLoader()

  const droppedFilesLoading = useSelector(
    (state: RootState) => state.drive.droppedFilesLoading,
  )

  const moveDriveContentStatus = useSelector(
    (state: RootState) => state.drive.moveDriveContentStatus,
  )
  const targetedFolder = useSelector((state: RootState) => state.drive.targetedFolder)
  const draggingOver = useSelector((state: RootState) => state.drive.draggingOver)

  const [dragged, setDragged] = useState(false)
  const [openDeleteFileModal, setOpenDeleteFileModal] = useState(false)
  const [fileToDelete, setFileToDelete] = useState<FileInterface>()
  const [renameFolderForm] = Form.useForm()
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(20)
  const startIndex = (currentPage == 0 ? currentPage : currentPage - 1) * itemsPerPage
  const scrollbarRef = useRef<Scrollbars>(null)
  const uploadingFiles = useSelector((state: RootState) => state.drive.uploadListFiles)
  const contextMenuVisible = useSelector(
    (state: RootState) => state.drive.contextMenuVisible,
  )
  const [contextMenuCoordinates, setContextMenuCoordinates] = useState({ x: 0, y: 0 })
  const [itemsFilesOrFolders, setItemsFilesOrFolders] = useState<ItemType[] | undefined>(
    undefined,
  )

  const addDriveFilesAndFoldersStatus = useSelector(
    (state: RootState) => state.drive.addDriveFilesAndFoldersStatus,
  )

  const addDriveFilesStatus = useSelector(
    (state: RootState) => state.drive.addDriveFilesStatus,
  )

  const [sortColumn, setSortColumn] = useState<SORTABLE_COLUMN>(SORTABLE_COLUMN.NAME)
  const [sortOrder, setSortOrder] = useState<'ASC' | 'DESC'>('ASC')
  const [sortedItems, setSortedItems] = useState<(FileInterface | FolderInterface)[]>([])

  useEffect(() => {
    sortItems()
  }, [selectedDriveFolder, sortColumn, sortOrder, currentPage, itemsPerPage])

  function sortItems() {
    let sortedFolders: FolderInterface[] = []
    let sortedFiles: FileInterface[] = []

    if (selectedDriveFolder) {
      if (sortColumn === SORTABLE_COLUMN.NAME) {
        sortedFolders = [...selectedDriveFolder.childrenFolders].sort((a, b) =>
          compareByName(a, b, sortOrder),
        )

        sortedFiles = [...selectedDriveFolder.childrenFiles].sort((a, b) =>
          compareByName(a, b, sortOrder),
        )
      } else if (sortColumn === SORTABLE_COLUMN.UPDATED_AT) {
        sortedFolders = [...selectedDriveFolder.childrenFolders].sort((a, b) =>
          compareByDate(a, b, sortOrder),
        )

        sortedFiles = [...selectedDriveFolder.childrenFiles].sort((a, b) =>
          compareByDate(a, b, sortOrder),
        )
      }
    }
    setSortedItems(
      [...sortedFolders, ...sortedFiles].slice(startIndex, startIndex + itemsPerPage),
    )
  }

  useAttemptsListener([
    [
      moveDriveContentStatus.status,
      {
        success_single_file: () => {
          ToastOpen({
            message: t('FILE_MOVED', {
              ns: 'drives',
              shouldUnescape: true,
              name: limitText(moveDriveContentStatus.info, 30),
              components: { bold: <strong /> },
            }),

            type: 'success',
          })
        },
        success_single_folder: () => {
          ToastOpen({
            message: t('FOLDER_MOVED', {
              ns: 'drives',
              shouldUnescape: true,
              name: limitText(moveDriveContentStatus.info, 30),
              components: { bold: <strong /> },
            }),
            type: 'success',
          })
        },
        success_some_files: () => {
          ToastOpen({
            message: t('Some files have been imported successfully.'),
            type: 'success',
          })
        },
        success_multiple_files: () => {
          ToastOpen({
            message: t('Files have been moved successfully.'),
            type: 'success',
          })
        },
        success_multiple_folders: () => {
          ToastOpen({
            message: t('Folders have been moved successfully.'),
            type: 'success',
          })
        },
        NOT_ENOUGH_FILE_RIGHT: () => {
          ToastOpen({
            message: t(
              'You do not have the necessary rights to move files to this project.',
            ),
            type: 'error',
          })
        },
        unknown_error: () => {
          ToastOpen({
            message: t('Error moving file.'),
            type: 'error',
          })
        },
        FETCH_ERROR: () => {
          ToastOpen({
            message: t('The drive service is not available at the moment.'),
            type: 'error',
            duration: 50,
          })
        },
      },
      () => dispatch(idleMoveDriveContentStatus()),
    ],
    [
      addDriveFilesAndFoldersStatus.status,
      {
        success_some_files: () => {
          ToastOpen({
            message: t('Some files have been imported successfully.'),
            type: 'success',
          })
        },
        success_multiple_files: () => {
          ToastOpen({
            message: t('Files have been imported successfully.'),
            type: 'success',
          })
        },
        NOT_ENOUGH_FILE_RIGHT: () => {
          ToastOpen({
            message: t(
              'You do not have the necessary rights to add files to this project.',
            ),
            type: 'error',
          })
          dispatch(setUploadListFiles([]))
        },
        NOT_ENOUGH_FOLDER_RIGHT: () => {
          ToastOpen({
            message: t(
              'You do not have the necessary rights to create folders in this project.',
            ),
            type: 'error',
          })
          dispatch(setUploadListFiles([]))
        },
        FETCH_ERROR: () => {
          ToastOpen({
            message: t('The drive service is not available at the moment.'),
            type: 'error',
            duration: 50,
          })
        },
        unknown_error: () => {
          ToastOpen({
            message: t('Error adding file.'),
            type: 'error',
          })
        },
      },
      () => {
        setTimeout(() => dispatch(setUploadListFiles([])), 3000)
        dispatch(idleaddDriveFilesAndFoldersStatus())
      },
    ],
    [
      addDriveFilesStatus.status,
      {
        success_single_file: () => {
          ToastOpen({
            message: t('FILE_ADDED', {
              ns: 'drives',
              shouldUnescape: true,
              name: limitText(addDriveFilesStatus.info, 30),
              components: { bold: <strong /> },
            }),
            type: 'success',
          })
        },
        success_some_files: () => {
          ToastOpen({
            message: t('Some files have been imported successfully.'),
            type: 'success',
          })
        },
        success_multiple_files: () => {
          ToastOpen({
            message: t('Files have been imported successfully.'),
            type: 'success',
          })
        },
        NOT_ENOUGH_FILE_RIGHT: () => {
          ToastOpen({
            message: t(
              'You do not have the necessary rights to add files to this project.',
            ),
            type: 'error',
          })
          dispatch(setUploadListFiles([]))
        },
        NOT_ENOUGH_FOLDER_RIGHT: () => {
          ToastOpen({
            message: t(
              'You do not have the necessary rights to create folders in this project.',
            ),
            type: 'error',
          })
          dispatch(setUploadListFiles([]))
        },
        FETCH_ERROR: () => {
          ToastOpen({
            message: t('The drive service is not available at the moment.'),
            type: 'error',
            duration: 50,
          })
        },
        unknown_error: () => {
          ToastOpen({
            message: t('Error adding file.'),
            type: 'error',
          })
        },
      },
      () => {
        setTimeout(() => dispatch(setUploadListFiles([])), 3000)
        dispatch(idleAddDriveFilesStatus())
      },
    ],
  ])

  /**
   *
   */
  useEffect(() => {
    if (selectedDriveFolder) {
      dispatch(setTargetedFolder({ folder: selectedDriveFolder }))
      const totalEntries =
        selectedDriveFolder.childrenFolders.length +
        selectedDriveFolder.childrenFiles.length
      if (totalEntries < currentPage * itemsPerPage) {
        setCurrentPage(Math.trunc(totalEntries / itemsPerPage))
      }
    }

    scrollbarRef.current?.scrollToTop()

    dispatch(setSelectedFilesFolders([]))
  }, [selectedDriveFolder])

  /**
   * Handle the drag over event for the main div.
   * @param e DragEvent<HTMLDivElement>
   */
  function handleMainDivDragOver(e: DragEvent<HTMLDivElement>) {
    e.preventDefault()
  }

  /**
   * Handle the drop event for the main div.
   * @param e DragEvent<HTMLDivElement>
   */
  async function handleMainDivDrop(e: DragEvent<HTMLDivElement>) {
    e.preventDefault()
    if (
      fileRights &&
      e.dataTransfer.files &&
      selectedDriveFolder?.driveId &&
      driveEncryptKey &&
      targetedFolder
    ) {
      const { foldersToAdd, filesToAdd, filesToRemove, foldersToRemove } =
        await getFilesAndFolders(e.dataTransfer.items, selectedDriveFolder)

      if (
        (filesToAdd.length > 0 || foldersToAdd.length > 0) &&
        auth.email &&
        auth.firstName &&
        auth.lastName
      ) {
        dispatch(
          addEncryptDriveFiles({
            driveId: targetedFolder.driveId,
            folderId: targetedFolder.id,
            driveEncryptKey,
            creatorEmail: auth.email,
            creatorFirstName: auth.firstName,
            creatorLastName: auth.lastName,
            files: filesToAdd,
            folders: foldersToAdd,
            filesBlocked: filesToRemove.length > 0,
            foldersBlocked: foldersToRemove.length > 0,
          }),
        )
      }

      if (filesToRemove.length > 0) {
        filesToRemove.forEach((file) => {
          ToastOpen({
            message: (
              <Trans
                ns="drives"
                i18nKey="FILE_TOO_LARGE"
                shouldUnescape={true}
                values={{
                  name: file.name,
                  size: process.env.REACT_APP_DRIVE_SIZE_LIMIT_MEGA,
                }}
                components={{ bold: <strong /> }}
              />
            ),
            type: 'error',
          })
        })
      }

      if (foldersToRemove.length > 0) {
        foldersToRemove.forEach((folder) => {
          ToastOpen({
            message: (
              <Trans
                ns="drives"
                i18nKey="FOLDER_ALREADY_EXISTS"
                shouldUnescape={true}
                values={{
                  name: folder.name,
                }}
                components={{ bold: <strong /> }}
              />
            ),
            type: 'error',
          })
        })
      }
    }
    setDragged(false)
  }

  /**
   * Handle the drag enter event for the main div.
   * @param e DragEvent<HTMLDivElement>
   */
  function handleMainDivDragEnter(e: DragEvent<HTMLDivElement>) {
    if (!draggingOver) {
      setDragged(true)
      e.preventDefault()
      e.stopPropagation()
    }
  }

  /**
   * Handle the drop event for the main div.
   * @param e DragEvent<HTMLDivElement>
   */
  function handleMainDivDragLeave(e: DragEvent<HTMLDivElement>) {
    e.preventDefault()

    const isLeavingList =
      !e.relatedTarget || !e.currentTarget.contains(e.relatedTarget as Node)

    if (isLeavingList) {
      setDragged(false)
      e.preventDefault()
      e.stopPropagation()
    }
  }

  /**
   *
   * @param row
   * @param files
   * @returns
   */
  function shiftSelect(row: selectableInterface, files: selectableInterface[]) {
    const startIndex = selectedFilesFolders.findIndex((item) =>
      compareFilesFolders(item, row),
    )
    if (startIndex !== -1) {
      dispatch(setSelectedFilesFolders([row]))
    } else {
      if (selectedFilesFolders.length === 0) {
        dispatch(setSelectedFilesFolders([row]))
        return
      }
      const startRow = files.findIndex(
        (r) => r.id === selectedFilesFolders[selectedFilesFolders.length - 1].id,
      )
      const endRow = files.findIndex((r) => r.id === row.id)

      const newSelection = files.slice(
        Math.min(startRow, endRow),
        Math.max(startRow, endRow) + 1,
      )
      const uniqueSelectedFilesFolders = Array.from(
        new Set([...selectedFilesFolders, ...newSelection]),
      )

      dispatch(
        setSelectedFilesFolders(uniqueSelectedFilesFolders as selectableInterface[]),
      )
    }
  }

  /**
   *
   * @param row
   */
  function ctrlSelect(row: selectableInterface) {
    const selectedIndex = selectedFilesFolders.findIndex((item) =>
      compareFilesFolders(item, row),
    )

    if (selectedIndex !== -1) {
      const updatedSelection = [...selectedFilesFolders]
      updatedSelection.splice(selectedIndex, 1)
      dispatch(setSelectedFilesFolders(updatedSelection))
    } else {
      dispatch(setSelectedFilesFolders([...selectedFilesFolders, row]))
    }
  }

  /**
   * Handle the click event on a folder or file row.
   * @param row selectableInterface
   * @param event MouseEvent
   */
  function handleRowClick(row: selectableInterface, event: MouseEvent) {
    if (selectedDriveFolder) {
      if (event.shiftKey) {
        shiftSelect(row, [
          ...selectedDriveFolder.childrenFolders,
          ...selectedDriveFolder.childrenFiles,
        ])
      } else if (event.ctrlKey) {
        ctrlSelect(row)
      } else {
        dispatch(setSelectedFilesFolders([row]))
      }
    }
  }

  /**
   *
   * @param e
   */
  const handleInternalDragStart = (e: DragEvent<HTMLDivElement>) => {
    const dragPreview = document.createElement('div')
    dragPreview.id = 'dragPreview'
    dragPreview.classList.add('drag-preview')

    const firstFileFolder = selectedFilesFolders[0]
    if (firstFileFolder) {
      const fileCard = document.createElement('div')
      fileCard.classList.add('file-card')

      const fileIcon = document.createElement('span')
      if ('mimeType' in firstFileFolder) {
        fileIcon.innerHTML = `<img class="file-icon" src=${renderIcon(
          firstFileFolder.mimeType,
          firstFileFolder.name,
        )} />`
      } else {
        fileIcon.innerHTML = `<img class="file-icon" src="/images/auxadrive/folder_off.png" />`
      }
      fileCard.appendChild(fileIcon)

      const fileName = document.createElement('div')
      fileName.classList.add('name')
      fileName.textContent = firstFileFolder.name

      const badge = document.createElement('div')
      badge.classList.add('badge')
      badge.textContent = `${selectedFilesFolders.length}`

      fileCard.appendChild(fileName)

      dragPreview.appendChild(badge)
      dragPreview.appendChild(fileCard)

      document.body.appendChild(dragPreview)

      e.dataTransfer.setDragImage(
        dragPreview,
        dragPreview.clientWidth / 2,
        dragPreview.clientHeight / 2,
      )
      dispatch(setDraggingOver(true))
    }
  }

  /**
   *
   */
  const handleInternalDragEnd = () => {
    dispatch(setDraggingOver(false))
    const dragPreview = document.getElementById('dragPreview')
    if (dragPreview) {
      document.body.removeChild(dragPreview)
    }
  }

  /**
   *
   * @param event
   * @param itemsFilesOrFolders
   */
  function handleRightClick(event: MouseEvent, itemsFilesOrFolders?: ItemType[]) {
    event.preventDefault()
    event.stopPropagation()
    setContextMenuCoordinates({ x: event.clientX, y: event.clientY })
    dispatch(setContextMenuVisible(true))
    setItemsFilesOrFolders(itemsFilesOrFolders)
  }

  /**
   * Used when we click on then title
   * @param column
   */
  function globalHandleSort(column: SORTABLE_COLUMN) {
    console.log('globalHandleSort:')
    setSortColumn(column)
    setSortOrder(sortOrder === 'ASC' ? 'DESC' : 'ASC')
  }

  /***
   * Used when we click on specific chevron
   */
  function handleSort(
    e: MouseEvent,
    column: SORTABLE_COLUMN,
    newSortOrder: 'ASC' | 'DESC',
  ) {
    e.stopPropagation()
    setSortColumn(column)
    setSortOrder(newSortOrder)
  }

  return (
    <>
      <div
        onDrop={handleMainDivDrop}
        onDragLeave={handleMainDivDragLeave}
        onDragOver={handleMainDivDragOver}
        onDragEnter={handleMainDivDragEnter}
        className="DriveListContent"
        onContextMenu={(e) => handleRightClick(e)}
      >
        {selectedDriveFolder && (
          <div
            className={classNames({
              'DriveListContent-table': true,
              'DriveListContent-table-dragged': dragged,
              'DriveListContent-table-loading': droppedFilesLoading,
            })}
          >
            <div className="DriveListContent-table-header">
              <div
                className="DriveListContent-table-name-column"
                onClick={() => globalHandleSort(SORTABLE_COLUMN.NAME)}
              >
                {t('Name', { ns: 'common' })}
                <span className="ml-04rem sort-icon-container">
                  <TiArrowSortedUp
                    onClick={(e) => handleSort(e, SORTABLE_COLUMN.NAME, 'ASC')}
                    className={`${sortColumn === SORTABLE_COLUMN.NAME && sortOrder === 'ASC' ? 'active' : 'inactive'}`}
                  />
                  <TiArrowSortedDown
                    onClick={(e) => handleSort(e, SORTABLE_COLUMN.NAME, 'DESC')}
                    className={`${sortColumn === SORTABLE_COLUMN.NAME && sortOrder === 'DESC' ? 'active' : 'inactive'}`}
                  />
                </span>
              </div>
              <div className="DriveListContent-table-owner-column">{t('Owner')}</div>
              <div
                className="DriveListContent-table-date-column"
                onClick={() => globalHandleSort(SORTABLE_COLUMN.UPDATED_AT)}
              >
                {t('Last modified')}
                <span className="ml-04rem sort-icon-container mr-05rem">
                  <TiArrowSortedUp
                    onClick={(e) => handleSort(e, SORTABLE_COLUMN.UPDATED_AT, 'ASC')}
                    className={`${sortColumn === SORTABLE_COLUMN.UPDATED_AT && sortOrder === 'ASC' ? 'active' : 'inactive'}`}
                  />
                  <TiArrowSortedDown
                    onClick={(e) => handleSort(e, SORTABLE_COLUMN.UPDATED_AT, 'DESC')}
                    className={`${sortColumn === SORTABLE_COLUMN.UPDATED_AT && sortOrder === 'DESC' ? 'active' : 'inactive'}`}
                  />
                </span>
              </div>
              <div className="DriveListContent-table-size-column">{t('Size')}</div>
              <div className="DriveListContent-table-actions-column" />
            </div>
            <Scrollbars
              autoHide
              ref={scrollbarRef}
              className="DriveListContent-table-content"
            >
              {sortedItems.map((item) => (
                <div key={item.id}>
                  {'mimeType' in item ? (
                    <FileRow
                      file={item}
                      handleRowClick={handleRowClick}
                      handleRightClick={handleRightClick}
                      setOpenDeleteFileModal={setOpenDeleteFileModal}
                      setFileToDelete={setFileToDelete}
                      handleDragStart={handleInternalDragStart}
                      handleDragEnd={handleInternalDragEnd}
                    />
                  ) : (
                    <FolderRow
                      folder={item}
                      handleRowClick={handleRowClick}
                      handleRightClick={handleRightClick}
                      renameFolderForm={renameFolderForm}
                      handleDragStart={handleInternalDragStart}
                      handleDragEnd={handleInternalDragEnd}
                    />
                  )}
                </div>
              ))}
              <DrivesPagination
                currentPage={currentPage}
                itemsPerPage={itemsPerPage}
                setCurrentPage={setCurrentPage}
                setItemsPerPage={setItemsPerPage}
              />
            </Scrollbars>

            <UploadBanner dragged={dragged} />
            {droppedFilesLoading && (
              <div className="loading-dropped-files">
                <Spin />
              </div>
            )}

            <UploadArea dragged={dragged} />
          </div>
        )}
      </div>

      <RenameFolderModal renameFolderForm={renameFolderForm} />

      <RenameFileModal />

      <DeleteFileModal
        openDeleteFileModal={openDeleteFileModal}
        setOpenDeleteFileModal={setOpenDeleteFileModal}
        fileToDelete={fileToDelete}
        startIndex={startIndex}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />

      {uploadingFiles && uploadingFiles.length > 0 && (
        <UploadFilesList files={uploadingFiles} />
      )}

      {contextMenuVisible && (
        <DriveContextMenu
          coordinates={contextMenuCoordinates}
          itemsForRightClick={itemsFilesOrFolders}
        />
      )}
    </>
  )
}

export default DriveListContent
