import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Row, Col, Divider, Form, Switch } from 'antd'
import { RootState } from '../../store'
import './DesktopNotificationSettings.scss'
import { setDesktopNotification } from './redux/accountSlice'
import { useDesktopNotificationPermission } from '../../utils/hooks/useDesktopNotificationPermission'

function DesktopNotificationSettings() {
  const { t } = useTranslation('account')
  const dispatch = useDispatch()
  const { askPermission } = useDesktopNotificationPermission()

  const isDesktopNotificationEnabled = useSelector(
    (state: RootState) => state.account.IsDesktopNotificationEnabled,
  )
  const [form] = Form.useForm()

  useEffect(() => {
    form.setFieldsValue({
      desktopNotification: isDesktopNotificationEnabled,
    })
  }, [isDesktopNotificationEnabled])

  const handleDesktopNotificationChange = (checked: boolean) => {
    if (checked) {
      askPermission()
    } else {
      dispatch(setDesktopNotification({ status: false }))
    }
  }

  return (
    <>
      <div>
        <div className="layout-header-bottom">
          <h4 className="layout-list-header">{t('Desktop Notification')}</h4>
        </div>
      </div>
      <Divider />
      <Form
        form={form}
        className="account-modify-form mb-1rem"
        initialValues={{
          desktopNotification: isDesktopNotificationEnabled,
        }}
      >
        <Row gutter={[24, 8]}>
          <Col span={24} className="account-notification">
            <Form.Item
              label={t('Enable desktop notification')}
              name="desktopNotification"
              valuePropName="checked"
            >
              <Switch
                checked={isDesktopNotificationEnabled}
                onChange={handleDesktopNotificationChange}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  )
}

export default DesktopNotificationSettings
