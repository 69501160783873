import React, { useEffect } from 'react'
import { Col, Divider, List, Modal, Row, Tooltip } from 'antd'
import { Trans, useTranslation } from 'react-i18next'
import { BsDownload, BsEye } from 'react-icons/bs'
import { IoTrashOutline } from 'react-icons/io5'
import { useDispatch, useSelector } from 'react-redux'
import {
  RoomToModify,
  removeUnconfirmedFile,
  updateFiles,
} from '../../features/Meetings/MeetingsSlice'
import { idleNewNotarisationEvent } from '../../features/Notarisation/roomNotarisationSlice'
import '../RoomManage/DocumentsTab.scss'
import {
  RoomFile,
  RoomInterface,
  RoomParticipantRoleEnum,
} from '../../features/room/RoomInterface'
import { AppDispatch, RootState } from '../../store'
import {
  downloadEncryptedFile,
  getAllRoomMembers,
  hasAccessFiles,
  limitText,
} from '../../utils/Utils'
import '../RoomManage/DocumentsTab.scss'
import { fetchFileMetadata } from '../Viewer/ViewerSlice'
import { useToastContext } from '../Toast/ToastContext'

const { confirm } = Modal
interface Props {
  room?: RoomToModify
  immediateSave: boolean
  disabled: boolean
  inAntichamber?: boolean
  newRoomRemoveUnconfirmedFile?: (fileUid: string) => void
}

export default function DocumentsList({
  room,
  immediateSave,
  disabled,
  inAntichamber,
  newRoomRemoveUnconfirmedFile,
}: Props) {
  const { t } = useTranslation('meetings')
  const auth = useSelector((state: RootState) => state.auth)
  const invite = useSelector((state: RootState) => state.invite)
  const inviteMail = useSelector((state: RootState) => state.invite.email)
  const jwt = invite.jwt ?? auth.jwt
  const members = getAllRoomMembers(room as RoomInterface)
  const encryptKey = room?.decrypt_key
  const newNotarisationEventError = useSelector(
    (state: RootState) => state.roomNotarisation.newNotarisationEventError,
  )
  const dispatch = useDispatch<AppDispatch>()
  const { ToastOpen } = useToastContext()

  useEffect(() => {
    switch (newNotarisationEventError) {
      case 'unknown_error':
        // dispatch(ToastOpen({
        //   message: t('Error during notarization.', { ns: 'common' }),
        //   type: 'error',
        // })
        break
    }
  }, [newNotarisationEventError])

  useEffect(() => {
    return () => {
      dispatch(idleNewNotarisationEvent())
    }
  }, [])

  const onClickRemove = (file: RoomFile) => {
    confirm({
      centered: true,
      title: t('Delete file', { ns: 'drives' }),
      icon: null,
      content: (
        <Trans
          ns="drives"
          i18nKey="DELETE_FILE"
          shouldUnescape={true}
          values={{
            name: limitText(file?.filename),
          }}
          components={{ bold: <strong /> }}
        />
      ),
      okText: t('Yes', { ns: 'common' }),
      cancelText: t('No', { ns: 'common' }),
      onOk: () => {
        remove(file.uid, file.filename)
      },
      maskClosable: true,
      closable: true,
    })
  }

  async function open(uid: string, filename: string) {
    dispatch(
      fetchFileMetadata({
        uid,
        encryptKey: room?.decrypt_key || '',
      }),
    )
  }

  //TODO: refactor this function, move to redux
  async function remove(uid: string, filename: string) {
    if (immediateSave) {
      const removeApiPromise = fetch(
        process.env.REACT_APP_MEDIA_BASE_URL + '/key-storage/remove',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${jwt}`,
          },
          body: JSON.stringify({
            meetingId: room?.id,
            uid,
            filename,
          }),
        },
      )

      const response = await removeApiPromise
      if (response.ok) {
        dispatch(
          updateFiles({
            roomId: room?.id || '',
            files: [{ uid, filename }] as RoomFile[],
          }),
        )
        ToastOpen({
          message: t('File was successfully removed.'),
          type: 'success',
        })
      } else {
        ToastOpen({
          message: t('An error occurred.'),
          type: 'error',
        })
      }
    } else {
      if (newRoomRemoveUnconfirmedFile) {
        newRoomRemoveUnconfirmedFile(uid)
      } else {
        dispatch(removeUnconfirmedFile({ roomId: room?.id || '', fileUid: uid }))
      }
    }
  }

  const files = [...(room?.files || []), ...(room?.unconfirmedFiles || [])].filter(
    (f) => !room?.unconfirmedFileDeletions?.includes(f.uid),
  )

  const isAttendee = members?.some(
    (member) =>
      member.email === (auth.email ?? inviteMail) &&
      member.role === RoomParticipantRoleEnum.Attendee,
  )

  return (
    <Row className={room?.name ? 'document-row' : ''}>
      {hasAccessFiles(room as RoomInterface) ? (
        files.map((file, index) => (
          <Col span={24} key={file.uid}>
            <div className="document-list-file">
              <span className="filename">{file.filename}</span>
              <span className="actions">
                <Tooltip title={t('Open the file')} className="cursor-pointer">
                  <div
                    className={
                      inAntichamber
                        ? 'action-rounded-icon buttons'
                        : 'action-rounded-icon'
                    }
                    onClick={() => open(file.uid, file.filename)}
                  >
                    <BsEye size="1.5em" />
                  </div>
                </Tooltip>
                <Tooltip title={t('Download')} className="cursor-pointer" key="download">
                  <div
                    className={
                      inAntichamber
                        ? 'action-rounded-icon buttons'
                        : 'action-rounded-icon'
                    }
                    onClick={() =>
                      encryptKey &&
                      downloadEncryptedFile(file.uid, encryptKey, file.filename)
                    }
                  >
                    <BsDownload size="1.5em" />
                  </div>
                </Tooltip>
                {!disabled && (
                  <Tooltip title={t('Remove')} className="cursor-pointer">
                    <div
                      className="action-rounded-icon action-rounded-icon--red"
                      onClick={() => onClickRemove(file)}
                    >
                      <IoTrashOutline size="1.5em" />
                    </div>
                  </Tooltip>
                )}
              </span>
            </div>
            {index !== files.length - 1 && <Divider className="mt-0_5rem mb-0_5rem" />}
          </Col>
        ))
      ) : (
        <Col span={24}>
          <div className="text-center mb-1rem pb-5rem">
            {isAttendee
              ? t(`Your current role doesn't allow access to files`)
              : t(`You will be given access to files at the start of the meeting`)}
          </div>
        </Col>
      )}
      {files.length === 0 && !isAttendee && (
        <Col span={24}>
          <div className="text-center mb-1rem">{t('No files have been uploaded')}</div>
        </Col>
      )}
    </Row>
  )
}
