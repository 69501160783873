import React, { useEffect } from 'react'
import { Drawer, Spin } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../store'
import { useTranslation } from 'react-i18next'
import NewMeetingForm from '../Meetings/NewMeeting/NewMeetingForm'
import { setNewMeeting } from './redux/calendarSlice'

function NewMeetingDrawer() {
  const newMeeting = useSelector((state: RootState) => state.calendar.newMeeting)
  const { t } = useTranslation('calendar')
  const dispatch = useDispatch<AppDispatch>()

  useEffect(() => {
    return () => {
      dispatch(setNewMeeting())
    }
  }, [])

  /**
   *
   */
  function onDrawerClosed() {
    dispatch(setNewMeeting())
  }

  /**
   *
   */
  function onMeetingCreated() {
    dispatch(setNewMeeting())
  }

  return (
    <Drawer
      width="40rem"
      title={
        <div className="drawer-title">
          <span>{t('New meeting', { ns: 'meetings' })}</span>
        </div>
      }
      placement="right"
      open={!!newMeeting?.meetingDuration && !!newMeeting?.meetingStartDate}
      onClose={onDrawerClosed}
      mask={true}
    >
      {!!newMeeting?.meetingDuration && !!newMeeting?.meetingStartDate ? (
        <NewMeetingForm
          meetingDuration={newMeeting?.meetingDuration}
          meetingStartDate={newMeeting?.meetingStartDate}
          onPendingMeetingCreated={onMeetingCreated}
          onMeetingCreated={onMeetingCreated}
          onMeetingCancel={() => dispatch(setNewMeeting())}
          fromCalendar={true}
        />
      ) : (
        <div className="d-flex d-flex-center d-flex-middle 2rem">
          <Spin size="large" />
        </div>
      )}
    </Drawer>
  )
}

export default NewMeetingDrawer
