// hooks/useDesktopNotification.ts
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setDesktopNotification } from '../../features/account/redux/accountSlice'
import { useDesktopNotificationPermission } from './useDesktopNotificationPermission'

function useDesktopNotification() {
  const dispatch = useDispatch()
  const { askPermission } = useDesktopNotificationPermission()

  useEffect(() => {
    if (typeof Notification === 'undefined') {
      console.warn('Notifications are not supported in this browser');
      return
    }

    if (Notification.permission !== 'denied' && Notification.permission !== 'granted') {
      askPermission()
    } else {
      dispatch(setDesktopNotification({ status: Notification.permission === 'granted' }))
    }
  }, [])
}

export default useDesktopNotification
