import React, { useEffect, useState } from 'react'
import { Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import { IoArrowBack, IoCloseOutline } from 'react-icons/io5'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../../../store'
import { renderIcon } from '../../../../utils/Utils'
import '../../../Viewer/viewer.scss'
import { setImgToView } from './PreviewerSlice'
import { UploadFile } from 'antd/lib'
import { RcFile } from 'antd/es/upload'
import useEventListener from '../../../../utils/hooks/useEventListener'

function Previewer() {
  const { t } = useTranslation('drives')
  const imgToView = useSelector((state: RootState) => state.previewer.imgToView?.file)
  const [imgSrc, setImgSrc] = useState('')

  useEventListener('keydown', (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      onClose()
    }
  })

  const getBase64 = (file: RcFile) =>
    new Promise<string>((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result as string)
      reader.onerror = (error) => reject(error)
    })

  async function handlePreview(file: UploadFile<any>) {
    if (!file.url && !file.preview) {
      const result = await getBase64(file.originFileObj as RcFile)
      setImgSrc(result)
      return result
    } else if (file.preview) {
      setImgSrc(file.preview)
    }
  }

  useEffect(() => {
    imgToView && handlePreview(imgToView)
  }, [imgToView])

  const dispatch = useDispatch<AppDispatch>()

  const onClose = () => {
    dispatch(setImgToView(undefined))
    setImgSrc('')
  }

  if (!imgToView) return <></>
  else
    return (
      <div className="Viewer">
        <div className="Viewer-control-bar">
          <div className="Viewer-control-bar-left">
            <div className="action-rounded-icon" onClick={onClose}>
              <IoArrowBack size="1.5em" className="back" />
            </div>

            <img
              className="file-icon"
              src={renderIcon(
                imgToView.type || imgToView.response.mimeType,
                imgToView.fileName || imgToView.name,
              )}
            />
            <span className="title">{imgToView.name}</span>
          </div>
          <div className="Viewer-control-bar-right">
            <Tooltip title={t('Close', { ns: 'common' })} placement="bottom">
              <div className="action-rounded-icon" onClick={onClose}>
                <IoCloseOutline size="1.5em" className="back" />
              </div>
            </Tooltip>
          </div>
        </div>
        <div className="Viewer-wrapper Viewer-wrapper-transparent">
          <img src={imgSrc || imgToView.url} alt={imgToView.uid} />
        </div>
      </div>
    )
}

export default Previewer
