import React from 'react'
import sanitizeHtml from 'sanitize-html'
import { allowedTagsObject } from '../../../utils/Utils'

import './MessageText.scss'

interface Props {
  text: string
}

export default function Message({ text }: Props) {
  const urlRegex = /(https?:\/\/[^\s<]+)/g
  const urls = text.replace(urlRegex, '<a target="_blank" href="$1">$1</a>')

  return (
    <div
      className="chat_message_text"
      dangerouslySetInnerHTML={{
        __html: sanitizeHtml(urls, allowedTagsObject),
      }}
    />
  )
}
