import React from 'react'
import { Dropdown, Menu } from 'antd'
import { useTranslation } from 'react-i18next'
import { BsChevronDown } from 'react-icons/bs'
import { GlobalOutlined } from '@ant-design/icons'

export function LanguageSwitchPreview() {
  const { i18n } = useTranslation([])

  const languages = (
    <Menu
      selectable
      selectedKeys={[i18n.language]}
      items={[
        {
          key: 'en',
          label: (
            <div>
              <span>English</span>
            </div>
          ),
        },
        {
          key: 'fr',
          label: (
            <div>
              <span>Français</span>
            </div>
          ),
        },
        {
          key: 'de',
          label: (
            <div>
              <span>Deutsch</span>
            </div>
          ),
        },
        {
          key: 'it',
          label: (
            <div>
              <span>Italiano</span>
            </div>
          ),
        },
        {
          key: 'uk',
          label: (
            <div>
              <span>Українська</span>
            </div>
          ),
        },
      ]}
    />
  )

  return (
    <Dropdown overlay={languages} trigger={['click']} overlayClassName="preview">
      <div className="d-flex mr-05rem language-icon">
        <GlobalOutlined />
        <span className="language-text d-flex d-flex-middle">
          {i18n.language.toUpperCase()}
          <BsChevronDown />
        </span>
      </div>
    </Dropdown>
  )
}
