import React from 'react'
import { LuUnplug } from 'react-icons/lu'
import { useTranslation } from 'react-i18next'
import './ServiceUnavailableError.scss'

type ServiceUnavailableError = {}

export const ServiceUnavailableError = () => {
  const { t } = useTranslation('drives')

  return (
    <div className="error-container">
      <LuUnplug size="2.5em" />
      <div className="text-center">
        <h3>{t('The service is currently unavailable.')}</h3>
        <h4>{t('Please try again later or contact your administrator.')}</h4>
      </div>
    </div>
  )
}
