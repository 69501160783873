import React, { useEffect, useState } from 'react'
import { Button } from 'antd'

export type ValueType = string | number

interface Props {
  value: ValueType[]
  onChange: (value: ValueType[]) => void
  entries: { label: string; value: ValueType }[]
}

function CustomCheckboxGroup({ value = [], onChange, entries }: Props) {
  return (
    <div className="d-flex d-flex-row d-flex-between">
      {entries.map((entry) => {
        return (
          <Button
            key={entry.value}
            shape="circle"
            type={value.includes(entry.value) ? 'primary' : 'default'}
            size="middle"
            onClick={(e) => {
              if (value.includes(entry.value)) {
                onChange(value.filter((item) => item != entry.value))
              } else {
                onChange(value.concat([entry.value]))
              }
            }}
          >
            {entry.label}
          </Button>
        )
      })}
    </div>
  )
}

export default CustomCheckboxGroup
