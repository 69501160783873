export enum OrganizationRole {
  ADMIN = 'admin',
  USER = 'user',
  EXTERNAL = 'external',
}
export interface OrganizationParticipant {
  email: string
  firstName: string
  lastName: string
  lang: string
  organizationRole: OrganizationRole
  groups: string[]
}

export interface OrganizationInterface extends Partial<OrganizationSummaryInterface> {
  id: string
  name: string
}

interface OrganizationSummaryInterface {
  licences: number
  licencesTaken: number
  groupsCount: number
}
