import React from 'react'
import { ImPhoneHangUp } from 'react-icons/im'
import { GoFile } from 'react-icons/go'
import { BsFilePersonFill } from 'react-icons/bs'
import { TbScreenShare, TbVideo, TbMicrophone } from 'react-icons/tb'
import { Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import { callLogoFilename } from '../../../assets/personnalization'

interface Props {
  logo: string | undefined
}

function CallPreview({ logo }: Props) {
  const { t } = useTranslation('room')

  return (
    <>
      <div className="room_page">
        <div className="Peers">
          <div
            className="fake-video-container--left"
            style={{ height: '100%', width: '50%' }}
          >
            <img
              style={{ height: '100%', width: '100%' }}
              src="/images/fake_video_1.png"
            />
          </div>
          <div
            className="fake-video-container--right"
            style={{ height: '100%', width: '50%' }}
          >
            <img
              style={{ height: '100%', width: '100%' }}
              src="/images/fake_video_1.png"
            />
          </div>
        </div>
        <div className="banner-container">
          <div className="banner-content">
            <img
              className="logo-image"
              src={logo ? logo : `/logo/${callLogoFilename}`}
              alt="logo"
            />
          </div>
        </div>
        <div className="call-controls-container">
          <div className="call-controls">
            <div className="call-controls--left">
              <div className="call-control call-control--active">
                <TbVideo size="2rem" color="#ffffff" strokeWidth={1} />
              </div>
              <div className="call-control call-control--active">
                <TbMicrophone size="2rem" color="#ffffff" strokeWidth={1} />
              </div>
            </div>
            <div className="call-controls--middle">
              <div className="call-control">
                <TbScreenShare size="2rem" color="#ffffff" strokeWidth={1} />
              </div>
              <div className="call-control">
                <BsFilePersonFill size="2rem" color="#ffffff" />
              </div>
              <div className="call-control">
                <GoFile size="2rem" color="#ffffff" />
              </div>

              <div className="call-control">
                <img className="users" src="/images/users.svg" alt="users" />
              </div>
            </div>

            <div className="call-controls--right">
              <Tooltip title={t('Hang up')}>
                <div className="call-control call-control--hangup">
                  <ImPhoneHangUp size="2rem" color="#ffffff" />
                </div>
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CallPreview
