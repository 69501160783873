import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../store'
import { logout } from '../features/auth/redux/authSlice'
import { useToastContext } from './Toast/ToastContext'
import { useTranslation } from 'react-i18next'

export default function SessionExpiration() {
  const expiresAt = useSelector(
    (state: RootState) => state.auth.sessionExpiresAt || state.invite.sessionExpiresAt,
  )
  const timeoutRef = useRef<number | ReturnType<typeof setTimeout>>()
  const dispatch = useDispatch<AppDispatch>()
  const { ToastOpen, ToastDestroy } = useToastContext()
  const { t } = useTranslation('auth')

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }

    if (expiresAt != undefined) {
      const timeoutsIn = expiresAt - Date.now()

      timeoutRef.current = setTimeout(
        () => {
          if (timeoutsIn > 0) {
            ToastOpen({
              message: t('Your session has expired. Please log in again.'),
              type: 'error',
            })
          }
          dispatch(logout())
        },
        Math.max(0, timeoutsIn),
      )
    }
  }, [expiresAt])

  return <></>
}
