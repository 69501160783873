import React from 'react'
import { Notification } from './liveWidgetSlice'
import ChatMessageNotificationItem from './types/ChatMessageNotificationItem'
import DriveModifiedNotificationItem from './types/DriveModifiedNotificationItem'
import ParticipantRemovedFromDriveNotificationItem from './types/ParticipantRemovedFromDriveNotificationItem'
import ParticipantAddedToDriveNotificationItem from './types/ParticipantAddedToDriveNotificationItem'
import classNames from 'classnames'

interface Props {
  notification: Notification
  isRowTable?: boolean
}

export default function NotificationList({ notification, isRowTable }: Props) {
  return (
    <>
      <div
        className={classNames({
          notification_widget_content_item: true,
          'fw-bold': notification.unseenCount && notification.unseenCount > 0,
          notification_widget_content_item_row_format: isRowTable,
        })}
      >
        {notification.type === 'CHAT_MESSAGE' && (
          <ChatMessageNotificationItem
            notification={notification}
            isRowTable={isRowTable}
          />
        )}
        {(notification.type === 'DRIVE_CREATED' ||
          notification.type === 'DRIVE_DELETED') && (
          <DriveModifiedNotificationItem
            notification={notification}
            isRowTable={isRowTable}
          />
        )}
        {notification.type === 'PARTICIPANT_ADDED_TO_DRIVE' && (
          <ParticipantAddedToDriveNotificationItem
            notification={notification}
            isRowTable={isRowTable}
          />
        )}
        {notification.type === 'PARTICIPANT_REMOVED_FROM_DRIVE' && (
          <ParticipantRemovedFromDriveNotificationItem
            notification={notification}
            isRowTable={isRowTable}
          />
        )}
      </div>
    </>
  )
}
