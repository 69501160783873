import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store'
import { Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import { LuUnplug } from 'react-icons/lu'
import './ChatDisconnectedAlert.scss'

function ChatDisconnectedAlert() {
  const { t } = useTranslation(['chat'])
  const websocketConnected = useSelector(
    (state: RootState) => state.chatWebsocket.connected,
  )

  return !websocketConnected ? (
    <Tooltip title={t('The connection to the server was lost. Reconnecting...')}>
      <div className="websocket-disconnected">
        <LuUnplug />
      </div>
    </Tooltip>
  ) : (
    <></>
  )
}

export default ChatDisconnectedAlert
