import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface State {
  connected: boolean
}

export const initialState: State = {
  connected: false,
}

export const callWebsocketSlice = createSlice({
  name: 'callWebsocket',
  initialState,
  reducers: {
    setConnected: (state, { payload }: PayloadAction<boolean>) => {
      state.connected = payload
    },
  },
})

export const { setConnected } = callWebsocketSlice.actions

export default callWebsocketSlice.reducer
