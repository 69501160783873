import React, { useState, useEffect } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import './TextAreaEditor.scss'

interface Props {
  value?: string
  disabled?: boolean
  hiddenToolbar?: boolean
  onChange?: (content: string) => void
}

function TextAreaEditor({ value, disabled, hiddenToolbar, onChange }: Props) {
  const [stateValue, setStateValue] = useState(value || '')

  useEffect(() => {
    setStateValue(value || '')
  }, [value])

  const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],
    [{ list: 'ordered' }, { list: 'bullet' }],
  ]

  const format = [
    'bold',
    'color',
    'font',
    'code',
    'italic',
    'link',
    'strike',
    'script',
    'underline',
    'blockquote',
    'header',
    'indent',
    'list',
    'align',
    'direction',
    'code-block',
    'formula',
  ]

  return (
    <>
      <div id="hidden-toolbar" style={{ display: 'none' }}></div>
      <ReactQuill
        className="text-area-editor"
        theme="snow"
        readOnly={disabled}
        value={stateValue}
        onChange={(content) => {
          setStateValue(content)
          onChange?.(content)
        }}
        modules={{
          toolbar: hiddenToolbar ? { container: '#hidden-toolbar' } : toolbarOptions,
        }}
        formats={format}
      />
    </>
  )
}

export default TextAreaEditor
