import React from 'react'
import { ChatMessage } from './chatsSlice'
import { useTranslation } from 'react-i18next'
import { DeletedMessageContent } from './Message'
import sanitizeHtml from 'sanitize-html'
import ChatMessageFileInList from './ChatMessageFileInList'

interface LastMessageProps {
  lastMessage?: ChatMessage
  unreadMessagesCount: number
}

function LastMessage({ lastMessage, unreadMessagesCount }: LastMessageProps) {
  const { t } = useTranslation('chat')

  if (!lastMessage)
    return (
      <div className="chat_item_no_messages">
        {t('Send a first message to this conversation')}
      </div>
    )
  else if (lastMessage.deleted)
    return (
      <>
        <DeletedMessageContent />
        {unreadMessagesCount > 0 && (
          <div className="chat_item_unread_messages_count">{unreadMessagesCount}</div>
        )}
      </>
    )
  else
    return (
      <>
        {lastMessage.text && (
          <div
            className="chat_item_last_message"
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(lastMessage.text, {
                allowedTags: [],
              }),
            }}
          />
        )}
        {lastMessage.files && <ChatMessageFileInList content={lastMessage.files} />}
        {unreadMessagesCount > 0 && (
          <div className="chat_item_unread_messages_count">{unreadMessagesCount}</div>
        )}
      </>
    )
}

export default LastMessage
