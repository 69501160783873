import React from 'react'
import { Button, List } from 'antd'
import VirtualList from 'rc-virtual-list'
import { SearchUserDTO } from '../../../features/Organisation/OrganisationService'
import { IoTrashOutline } from 'react-icons/io5'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store'

interface Props {
  peers: SearchUserDTO[]
  deletePeer: (email: string) => void
}

function ChatUsers({ peers, deletePeer }: Props) {
  const email = useSelector((state: RootState) => state.auth.email)

  return (
    <div className="virtual-list-participant">
      <List>
        <VirtualList data={peers} itemKey="email" className="list">
          {(peer: SearchUserDTO) => (
            <List.Item key={peer.email}>
              <List.Item.Meta
                title={`${peer.firstName} ${peer.lastName}`}
                description={peer.email}
              />
              {peer.email !== email && (
                <Button
                  className="btn-danger-border"
                  onClick={() => deletePeer(peer.email)}
                  disabled={peer.email === email}
                >
                  <IoTrashOutline className="error-color" size="1em" />
                </Button>
              )}
            </List.Item>
          )}
        </VirtualList>
      </List>
    </div>
  )
}

export default ChatUsers
