import classNames from 'classnames'
import React, { MutableRefObject, useEffect, useReducer, useRef, useState } from 'react'
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs'
import { useDispatch, useSelector } from 'react-redux'
import useLongPress from '../../utils/hooks/useLongPress'
import { RootState } from '../../store'
import Me from './Me'
import { getMaxAreaLines } from './packer'
import Peer from './Peer'
import RoomClient from './RoomClient'
import './Peers.scss'
import Viewer from '../../components/Viewer/Viewer'

interface Props {
  roomClient: MutableRefObject<RoomClient>
}

function Peers({ roomClient }: Props) {
  const peers = useSelector((state: RootState) => state.room.peers)

  const [elementWidth, setElementWidth] = useState<string | number>(1)
  const [elementHeight, setElementHeight] = useState<string | number>(1)
  const containerRef = useRef<HTMLDivElement>(null)
  const [promotedPeer, setPromotedPeer] = useState<string>()
  const DecreasedPeersRef = useRef<HTMLDivElement>(null)
  const DecreasedPeersScrollableRef = useRef<HTMLDivElement>(null)
  const meBokehCanvas = useRef<HTMLCanvasElement>(null)
  const promotedFullScreen = useSelector(
    (state: RootState) => state.room.promotedFullScreen,
  )

  interface DecreasedPeersOffsetAction {
    type: 'RESET' | 'INCREASE' | 'DECREASE'
    payload?: number
  }

  interface DecreasedPeersOffsetState {
    offset: number
  }

  function decreasedPeersOffsetReducer(
    state: DecreasedPeersOffsetState,
    action: DecreasedPeersOffsetAction,
  ) {
    const { type, payload } = action
    switch (type) {
      case 'RESET':
        return { offset: 0 }

      case 'INCREASE':
        if (
          DecreasedPeersRef.current &&
          DecreasedPeersScrollableRef.current &&
          state.offset < 0
        ) {
          return { offset: state.offset + (payload || 0) }
        }
        break

      case 'DECREASE':
        if (
          DecreasedPeersRef.current &&
          DecreasedPeersScrollableRef.current &&
          DecreasedPeersRef.current.clientWidth <
            DecreasedPeersScrollableRef.current.clientWidth +
              DecreasedPeersScrollableRef.current.offsetLeft
        ) {
          return { offset: state.offset - (payload || 0) }
        }
        break
    }

    return state
  }

  const [decreasedPeersOffset, dispatchDecreasedPeersOffset] = useReducer(
    decreasedPeersOffsetReducer,
    { offset: 0 },
  )

  function decreasedPeersScrollReducer(state: { show: boolean }) {
    return {
      show:
        !!DecreasedPeersRef.current &&
        !!DecreasedPeersScrollableRef.current &&
        DecreasedPeersRef.current.clientWidth <
          DecreasedPeersScrollableRef.current.clientWidth,
    }
  }

  const [showDecreasedPeersScroll, dispatchShowDecreasedPeersScroll] = useReducer(
    decreasedPeersScrollReducer,
    { show: false },
  )

  function updateLines() {
    if (containerRef.current) {
      if (Object.entries(peers).length === 1) {
        const ratio = containerRef.current.offsetWidth / containerRef.current.offsetHeight
        if (ratio > 1) {
          setElementWidth('50%')
          setElementHeight('100%')
        } else {
          setElementWidth('100%')
          setElementHeight('50%')
        }
      } else {
        if (Object.entries(peers).length > 1 && Object.entries(peers).length < 4) {
          //Force split screeen equally(4 users)
          setElementWidth('50%')
          setElementHeight('50%')
        } else {
          const { elementWidth: newElementWidth, elementHeight: newElementHeight } =
            getMaxAreaLines(
              containerRef.current.children.length,
              containerRef.current.clientWidth,
              containerRef.current.clientHeight,
            )
          setElementWidth(newElementWidth + 'px')
          setElementHeight(newElementHeight + 'px')
        }
      }
    }

    if (DecreasedPeersRef.current && DecreasedPeersScrollableRef.current) {
      dispatchDecreasedPeersOffset({ type: 'RESET' })
    }

    dispatchShowDecreasedPeersScroll()
  }

  useEffect(() => {
    updateLines()
  }, [peers, promotedPeer])

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      updateLines()
    })

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current)
    }

    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current)
      }
    }
  }, [containerRef])

  const me = (
    <Me promotedPeer={promotedPeer} onPromote={setPromotedPeer} roomClient={roomClient} />
  )
  const promoted = promotedPeer && peers[promotedPeer]

  function moveDecreasedPeersToLeft(offset: number) {
    dispatchDecreasedPeersOffset({
      type: 'INCREASE',
      payload: offset,
    })
  }

  const moveDecreasedPeersToLeftLongPressHandlers = useLongPress(() => {
    moveDecreasedPeersToLeft(1)
  })

  function moveDecreasedPeersToRight(offset: number) {
    dispatchDecreasedPeersOffset({
      type: 'DECREASE',
      payload: offset,
    })
  }

  const moveDecreasedPeersToRightLongPressHandlers = useLongPress(() => {
    moveDecreasedPeersToRight(1)
  })

  return (
    <>
      <canvas style={{ display: 'none' }} ref={meBokehCanvas} />

      {promoted || promotedPeer === 'me' ? (
        <div className={promotedFullScreen ? 'p-0 m-0' : 'PeersWithPromoted'}>
          <div
            className={promotedFullScreen ? 'd-none' : 'DecreasedPeers'}
            ref={DecreasedPeersRef}
          >
            <div className="DecreasedPeers-left" hidden={!showDecreasedPeersScroll.show}>
              <div className="chevron-container">
                <BsChevronLeft
                  size="2rem"
                  fill="#ffffff"
                  {...moveDecreasedPeersToLeftLongPressHandlers}
                />
              </div>
            </div>
            <div className="DecreasedPeers-right" hidden={!showDecreasedPeersScroll.show}>
              <div className="chevron-container">
                <BsChevronRight
                  size="2rem"
                  fill="#ffffff"
                  {...moveDecreasedPeersToRightLongPressHandlers}
                />
              </div>
            </div>

            <div
              className="DecreasedPeers-scrollable"
              ref={DecreasedPeersScrollableRef}
              style={{ marginLeft: decreasedPeersOffset.offset + 'rem' }}
            >
              {promoted && (
                <>
                  {me}
                  {Object.entries(peers).map(
                    ([key, peer]) =>
                      key !== promoted.id && (
                        <Peer
                          key={peer.id}
                          peer={peer}
                          roomClient={roomClient}
                          promotedPeer={promotedPeer}
                          onPromote={setPromotedPeer}
                        />
                      ),
                  )}
                </>
              )}
              {promotedPeer === 'me' &&
                Object.entries(peers).map(([key, peer]) => (
                  <Peer
                    key={peer.id}
                    peer={peer}
                    roomClient={roomClient}
                    promotedPeer={promotedPeer}
                    onPromote={setPromotedPeer}
                  />
                ))}
            </div>
          </div>
          <div
            className={classNames({
              PromotedPeer: true,
              'PromotedPeer--Single': Object.entries(peers).length === 0,
            })}
          >
            {promoted && (
              <Peer
                peer={promoted}
                roomClient={roomClient}
                promotedPeer={promotedPeer}
                onPromote={setPromotedPeer}
              />
            )}
            {promotedPeer === 'me' && me}
          </div>
        </div>
      ) : (
        <>
          <div className="Peers" ref={containerRef}>
            <div style={{ height: elementHeight, width: elementWidth }}>{me}</div>

            {Object.entries(peers).map(([key, peer]) => (
              <div key={peer.id} style={{ height: elementHeight, width: elementWidth }}>
                <Peer
                  peer={peer}
                  roomClient={roomClient}
                  promotedPeer={promotedPeer}
                  onPromote={setPromotedPeer}
                />
              </div>
            ))}
          </div>
        </>
      )}
      <Viewer fullSize={true} />
    </>
  )
}

export default Peers
