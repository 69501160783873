import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import { AppDispatch, RootState } from '../../store'
import {
  claimResetPasswordToken,
  changePassword,
  idleAuthReinitPasswordTokenStatus,
  idleChangePasswordStatus,
} from './redux/reinitPasswordSlice'
import { Button, Form, Input, Spin } from 'antd'
import './ReinitPassword.scss'
import { authLogoFilename, backgroundImageFilename } from '../../assets/personnalization'
import { useTranslation } from 'react-i18next'
import { LanguageSwitch } from '../../components/LanguageSwitch'
import { LockOutlined } from '@ant-design/icons'
import useStrongPassword from '../account/useStrongPassword'
import { useToastContext } from '../../components/Toast/ToastContext'

function ReinitPassword() {
  const { token } = useParams()
  const { i18n, t } = useTranslation('reinitpassword')
  const dispatch: AppDispatch = useDispatch<AppDispatch>()
  const { ToastOpen } = useToastContext()
  const authReinitPasswordTokenStatus = useSelector(
    (state: RootState) => state.reinitPassword.authReinitPasswordTokenStatus,
  )
  const changePasswordStatus = useSelector(
    (state: RootState) => state.reinitPassword.changePasswordStatus,
  )
  const jwt = useSelector((state: RootState) => state.auth.jwt)
  const navigate = useNavigate()
  const { verifyProvidedPassword } = useStrongPassword()

  useEffect(() => {
    if (token) {
      dispatch(claimResetPasswordToken({ token }))
    }

    return () => {
      dispatch(idleAuthReinitPasswordTokenStatus())
    }
  }, [])

  useEffect(() => {
    switch (changePasswordStatus) {
      case 'success':
        ToastOpen({
          type: 'success',
          message: t('Your password has been successfully changed.'),
        })
        navigate('/upcoming-meetings')
        break
      case 'unknown_error':
        ToastOpen({
          type: 'error',
          message: t('An error occurred, please try later.'),
        })
        break
    }

    return () => {
      dispatch(idleChangePasswordStatus())
    }
  }, [changePasswordStatus])

  useEffect(() => {
    if (authReinitPasswordTokenStatus === 'unknown_error') {
      ToastOpen({
        message: t('You do not have permission to access the reset password page.'),
        type: 'error',
      })
      navigate('/login')
    }
  }, [authReinitPasswordTokenStatus])

  return (
    <div
      className="ReinitPassword"
      style={{ backgroundImage: `url(/images/${backgroundImageFilename})` }}
    >
      <img className="logo" src={`/logo/${authLogoFilename}`} />
      {authReinitPasswordTokenStatus === 'loading' && (
        <div className="reinit_password_title_loading_container">
          <Spin size="large" />
        </div>
      )}
      {token && (
        <div className="auth-methods">
          {jwt && (
            <>
              <LanguageSwitch />
              <div className="password-requirements">
                <p className="mb-0">
                  {t(
                    'Your password must contain at least:\n8 characters; 1 lowercase letter; 1 uppercase letter; 1 digit; 1 special character',
                  )}
                </p>
              </div>
              <Form
                name="basic"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                onFinish={({ password }: { password: string }) =>
                  dispatch(changePassword({ password }))
                }
                scrollToFirstError={true}
              >
                <Form.Item
                  className="password-input"
                  label={<span className="login-label mb-0">{t('New password')}</span>}
                  name="password"
                  validateTrigger="onSubmit"
                  rules={[
                    { message: t('Please enter a new password') },
                    {
                      validator(_, value) {
                        const notPassed = verifyProvidedPassword(value)
                        if (notPassed) {
                          return Promise.reject(
                            new Error(
                              t('The format of the password is not correct', {
                                ns: 'reinitpassword',
                              }),
                            ),
                          )
                        }
                        return Promise.resolve()
                      },
                    },
                  ]}
                >
                  <Input.Password
                    prefix={<LockOutlined className="prefix-label-icon" />}
                  />
                </Form.Item>

                <Form.Item
                  label={
                    <span className="login-label mb-0">{t('Confirm password')}</span>
                  }
                  name="confirmPassword"
                  dependencies={['password']}
                  rules={[
                    {
                      required: true,
                      message: t('Please confirm your password'),
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve()
                        }
                        return Promise.reject(new Error(t('The passwords are different')))
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    prefix={<LockOutlined className="prefix-label-icon" />}
                  />
                </Form.Item>

                <Form.Item className="d-flex d-flex-center mb-0">
                  <Button
                    type="primary"
                    htmlType="submit"
                    block
                    loading={changePasswordStatus === 'loading'}
                  >
                    {t('Submit', { ns: 'common' })}
                  </Button>
                </Form.Item>
              </Form>
            </>
          )}
        </div>
      )}
    </div>
  )
}

export default ReinitPassword
