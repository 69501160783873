import React from 'react'
import { Divider } from 'antd'
import { useTranslation } from 'react-i18next'
import Layout from '../../components/Layout/Layout'
import OrganisationMenu from '../Organisation/OrganisationMenu'
import GroupsList from './GroupsList/GroupsList'

function Groups() {
  const { t } = useTranslation('groups')

  return (
    <>
      <Layout
        title={t('Organisation', { ns: 'common' })}
        headerTitle={<h1 className="title">{t('Organisation', { ns: 'common' })}</h1>}
        menu={<OrganisationMenu />}
        content={
          <>
            <div className="layout-header-bottom">
              <h4 className="layout-list-header">{t('Groups management')}</h4>
            </div>
            <Divider />
            <GroupsList />
          </>
        }
      />
    </>
  )
}

export default Groups
