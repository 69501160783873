import React from 'react'
import { Form, Input, InputRef, UploadProps } from 'antd'
import { useTranslation } from 'react-i18next'
import { FormInstance } from 'antd/lib'
import Dragger from 'antd/es/upload/Dragger'
import { useToastContext } from '../../components/Toast/ToastContext'
import { BsUpload } from 'react-icons/bs'

interface Props {
  form: FormInstance
  onSubmit: () => void
  inputFocusRef?: React.RefObject<InputRef>
  iconUploaded?: string
  setIconUploaded: (value: string) => void
}

function FavoriteForm({
  form,
  onSubmit,
  inputFocusRef,
  iconUploaded,
  setIconUploaded,
}: Props) {
  const MAX_ICON_SIZE_KO = 100
  const { t } = useTranslation('applications')
  const { ToastOpen } = useToastContext()

  const handleKeyDown = (event: React.KeyboardEvent<HTMLFormElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      form.submit()
    }
  }

  const getBase64 = (file: File) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })

  const logoDraggerProps: UploadProps = {
    name: 'logo',
    accept: 'image/*',
    multiple: false,
    showUploadList: false,
    beforeUpload: async (file) => {
      const isValidSize = file.size / 1024 <= MAX_ICON_SIZE_KO
      console.log('isValidSize:', isValidSize)
      if (!isValidSize) {
        ToastOpen({
          type: 'error',
          message: t('Image must be less than or equal to 100 KB.'),
        })
        return false
      }

      return true
    },
    onChange: async (info) => {
      setIconUploaded((await getBase64(info.file.originFileObj as File)) as string)
    },
  }

  return (
    <Form
      form={form}
      layout="vertical"
      autoComplete="off"
      onFinish={onSubmit}
      onKeyDown={handleKeyDown}
    >
      <Form.Item
        name="name"
        label={t('Name', { ns: 'common' })}
        rules={[{ required: true, message: t('Please input a name') }]}
      >
        <Input ref={inputFocusRef} />
      </Form.Item>
      <Form.Item
        name="url"
        label={t('URL')}
        rules={[
          {
            required: true,
            message: t('Please input a URL'),
          },
          {
            type: 'url',
            message: t('Please enter a valid URL'),
          },
          {
            pattern: /^https:\/\//,
            message: t('URL must start with https://'),
          },
        ]}
      >
        <Input placeholder="https://example.com" />
      </Form.Item>
      <Form.Item name="icon" label={t('Image upload (Optionnal)')} className="mb-0">
        <Dragger {...logoDraggerProps} className="upload-dragger">
          {iconUploaded ? (
            <img
              className="uploaded-image-preview uploaded-image-preview-favorite"
              src={iconUploaded}
              alt="logo"
            />
          ) : (
            <>
              <p className="ant-upload-drag-icon m-0">
                <BsUpload />
              </p>
              <p className="ant-upload-text m-0">
                {t('Click or drag file to this area to upload (max 100 KB)')}
              </p>
            </>
          )}
        </Dragger>
      </Form.Item>
    </Form>
  )
}

export default FavoriteForm
