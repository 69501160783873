import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router'
import { RootState } from '../../store'

function RequireAuth({
  children,
  invitedAccepted,
}: {
  children: any
  invitedAccepted?: boolean
}) {
  const username = useSelector(
    (state: RootState) => state.auth.jwt || (invitedAccepted && state.invite.jwt),
  )

  if (!username) {
    return <Navigate to="/login" />
  }

  return children
}

export default RequireAuth
